import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddMeetingsInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import axios from "axios";

type AddInitialValues = {
  meeting_title: string;
  meeting_description: string;
  meeting_link: string;
  document: string;
  imagefile: string;
};

export const MeetingSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  imagefile: any
) => {
  if (id) {
    const formData = new FormData();
    if (imagefile) {
      formData.append("documents", values?.document);
    }
    formData.append("title", values?.meeting_title);
    formData.append("meeting_link", values?.meeting_link);
    formData.append("body", values?.meeting_description);
    axios({
      method: "patch",
      url: `${BaseUrl}/announcement/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response: any) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Announcement Details Updated ",{toastId:"Announcementupdate"});
          refetch();
        }
      })
      .catch(function (response: any) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"AnnouncementupdateError"});
        }
      });
  }

  // Edit
  else {
    const formData = new FormData();
    formData.append("documents", values?.document);
    formData.append("title", values?.meeting_title);
    formData.append("meeting_link", values?.meeting_link);
    formData.append("body", values?.meeting_description);
    axios({
      method: "post",
      url: `${BaseUrl}/announcement`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Announcement  Added",{toastId:"Announcementadd"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Announcementadderror"});
        }
      });
  }
};
export const InviteClickHandler = (
  data: any,
  refetch: Function,
  selectedId: any,
  value: any
) => {
  const roleIds =
    value?.role.filter((roleId: string) => roleId.trim() !== "") || null;
  const district_ids =
    value?.district.filter((districtId: string) => districtId.trim() !== "") ||
    null;
  api
    .post(
      `/announcement/usercreate`,
      {
        announcement_id: selectedId,
        district_ids: district_ids,
        role_ids: roleIds,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Invited",{toastId:"Announcementinvite"});
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Announcementinviteerror"});
    });
};

// extract Departmentdata for table
export const extractMeetingData = (data: any) => {
  const res = data?.items?.map((item: any) => {
    return {
      id: item?.id,
      meetings_title: item?.title,
      meeting_description: item?.body,
      documents: item?.documents,
    };
  });
  return res;
};
export const extractMeetingMemberData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      meeting_title: modalEditData?.[0]?.title,
      meeting_type: modalEditData?.[0]?.type,
      meeting_link: modalEditData?.[0]?.meeting_link,
      meeting_description: modalEditData?.[0]?.body,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddMeetingsInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.items?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/announcement/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Announcementdeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Announcementdeletederror"});
    });
};

export const setCurrentPageMeeting = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/announcement/${pageNo}`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/announcement/${pageNo}`);
  }
};

export const setCurrentPageMeetingmember = (
  navigate: Function,
  pageNo: any,
  id: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/announcement-invite/${pageNo}/${id}`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/announcement-invite/${pageNo}/${id}`);
  }
};
