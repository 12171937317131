import * as Yup from "yup";
import { phoneSchema } from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const AccomadationValidationSchema = Yup.object().shape({
  office: Yup.string().required("Field is required"),
  manager_name: Yup.string().required("Field is required"),
  room_availble_count: Yup.string().required("Field is required"),
  room_occupied_count: Yup.string().required("Field is required"),
  phone_number: phoneSchema.required("Field is required"),
  // alter_phone_number: phoneSchema.required("Field is required"),
});

export const AddAccomadationInitialValues = {
  office: "",
  manager_name: "",
  room_availble_count: "",
  room_occupied_count: "",
  phone_number: "",
  alter_phone_number: "",
};
