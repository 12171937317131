import React, { useState } from "react";
import {
  List,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Col,
  Label,
} from "reactstrap";
import { AdminSidebarMenuItems } from "../../../Utils/Constants/Sidebar/AdminSidebarData";
import { Link } from "react-router-dom";
import { AdminSidebarType, SidebarMenuItemType } from "../../../Type";
import { activateSidebarTab } from "../../../Utils/helpers/ActivateSidebarTabs";
import { usePermission } from "../../Permissions/Admin/AdminPermission";

const AdminSidebar = ({ isOpen }: AdminSidebarType) => {
  const pathname = window.location.pathname;
  const { checkPermission } = usePermission();
  return (
    <>
      <Col
        sm={3}
        md={3}
        lg={2}
        className={`fixed ${isOpen ? "hamburger-open" : "hamburger-closed"}`}
      >
        <div className="admin-sidebar">
          <div className="sidebar-item">
            <div className="site-logo-wrap">
              <div className="site-logo-inner">
                <div>
                  {isOpen ? (
                    <img src="/images/site-logo.png" alt="Site Logo" />
                  ) : (
                    <img src="/images/site-logo.png" alt="Site Logo" />
                  )}
                </div>
                <div>
                  <span className="logo-text">AOAOK</span>
                </div>
              </div>
            </div>
            {/* Sidebar links */}

            <List type="unstyled">
              {AdminSidebarMenuItems?.map(
                (element: SidebarMenuItemType, i: number) => {
                  if (
                    !element.permission_key ||
                    checkPermission(element.permission_key)
                  ) {
                    return (
                      <Link to={element?.path} title={element?.label}>
                        <li
                          key={element?.label}
                          className={activateSidebarTab(element, pathname)}
                        >
                          {element?.icon}{" "}
                          <span className="link-label">{element?.label}</span>
                        </li>
                      </Link>
                    );
                  }
                }
              )}
            </List>
          </div>
        </div>
      </Col>
      {/* Mobile Sidebar */}

      <Offcanvas className="admin-sidebar-mobile">
        <OffcanvasHeader>
          <div className="site-logo">
            <img src="/images/logo-blue.svg" alt="Site Logo" />
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="sidebar-item">
            {/* Sidebar links */}
            <List type="unstyled">
              {AdminSidebarMenuItems?.map(
                (element: SidebarMenuItemType, i: number) => {
                  return (
                    <li key={element?.label}>
                      <Link to={element?.path}>
                        {element?.icon}
                        <label>{element?.label}</label>
                      </Link>
                    </li>
                  );
                }
              )}
            </List>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default AdminSidebar;
