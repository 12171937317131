import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDistrict, getOtherfund } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageOtherfund,
} from "./AddOtherfund/AddOtherfundMethods/AddOtherfundModalMethods";
import { handleOtherfundSearch } from "./OtherfundMethods";
import AddOtherfund from "./AddOtherfund";
import Select from "react-select";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const OtherfundIndex = () => {
  const { currentPage = "1", officeID } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false);
  const [modalEditData, setModalEditData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const location = useLocation();
  const [value, setValue] = useState({
    district_id: "",
  });
  const handleChange = (e: any, feild_name: any) => {
    if (feild_name === "district") {
      setValue({
        ...value,
        district_id: e,
      });
    }
  };

  const {
    data: other,
    error: otherError,
    isLoading: otherLoading,
    refetch: otherListRefetch,
  }: any = useQuery(
    [
      `other${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      officeID,
      value?.district_id,
    ],
    () => getOtherfund(currentPage, searchQuery, value?.district_id),
    { keepPreviousData: true }
  );
  const { data: district = [] }: any = useQuery(
    [`district`],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(() => extractStaffData(other), [other]);

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Other Fund Management", link: "/member/other-fund/1" },
      { label: "Members", link: "/member/other-fund/1" },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Other Fund Management", link: "/member/other-fund/1" },
      { label: "Members ", link: "/member/other-fund/1" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleOtherfundSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={district}
                value={
                  district?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "district")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>

            <div className="right-side-wrap">
              <div className="button-wrap">
                {checkPermission("other-member-fund:write") ? (
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Other fund
                  </Button>
                ) : null}
              </div>
              <Button
                color="primary"
                className="sm"
                outline
                onClick={() => {
                  setValue({
                    district_id: "",
                  });
                  setSearchQuery("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
          <div></div>
          {/* cards section */}
          <div>
            <CardsWithTable
              data={staffData}
              tableTitle="Other fund Details"
              tableFields={[
                "Tile",
                "Amount",
                "Start date",
                "End date",
                "District",
              ]}
              Arraykeys={[
                "title",
                "total_amount",
                "start_date",
                "end_date",
                "district",
              ]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("other-member-fund:write") ? "edit" : "",
                checkPermission("other-member-fund:delete") ? "delete" : "",
              ]}
              isLoading={otherLoading}
              error={otherError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("other-member-fund:write"))
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    other
                  );
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, otherListRefetch)
              }
              paginationData={other?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageOtherfund(navigate, pageNo, pathname)
              }
              from_otherfund={true}
            />
          </div>
        </div>
      </Container>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0 ? "Edit Other Fund" : "Add Other Fund"
        }
      >
        <AddOtherfund
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={otherListRefetch}
          district={[{ value: "all", label: "All Districts" }, ...district]}
        />
      </ModalContainer>
    </section>
  );
};

export default OtherfundIndex;
