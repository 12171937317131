import { Container, Nav, NavItem, NavLink } from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import DesignationCadreIndex from "./Desingationview";
import DesignationSingleIndex from "./Desingationview/Singledesingation";

const DesignationSettingIndex = () => {
  const { tab = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Designation Management", link: "/admin/designations" },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Designation Management", link: "/admin/designations" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>

      <div className="breadcrumb-wrap">{breadcrumb}</div>
      <Container>
        <Nav pills>
          <NavItem>
            <NavLink
              className={`tab-button ${tab === "1" ? "active" : ""}`}
              onClick={() => {
                if (pathname?.startsWith("/member")) {
                  navigate("/admin/designation-management/1/1");
                }
                if (pathname?.startsWith("/admin")) {
                  navigate("/admin/designation-management/1/1");
                }
              }}
            >
              Single Designation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`tab-button ${tab === "2" ? "active" : ""}`}
              onClick={() => {
                if (pathname?.startsWith("/member")) {
                  navigate("/member/designation-management/1/2");
                }
                if (pathname?.startsWith("/admin")) {
                  navigate("/admin/designation-management/1/2");
                }
              }}
            >
              CadreWise Designation
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
      <>
        {tab === "1" && <DesignationSingleIndex />}
        {tab === "2" && <DesignationCadreIndex />}
      </>
    </section>
  );
};

export default DesignationSettingIndex;
