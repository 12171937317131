import * as Yup from "yup";

export const CadreValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  // cadre_description: Yup.string().required("Field is required"),
});

export const AddCadreInitialValues = {
  name: "",
  cadre_description: "",
};
