import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getBlock,
  getDistrict,
  getMemberList,
  getOfficeFilterFullList,
  getOfficeFullList,
} from "../../../../../Api/AdminApis";
import { api } from "../../../../../Api/Api";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddMember from "../AddMembers";
import {
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  deleteClickHandler,
  SendPaymentSubmitHandler,
  setCurrentPageMembers,
} from "../AddMembers/AddMemberMethods/AddMemberModalMethods";
import { handleAdminStaffSearch } from "../MemberMethods";
import Select from "react-select";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const Member = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { checkPermission } = usePermission();

  const [value, setValue] = useState({
    district_id: "",
    block_id: "",
    office_id: "",

  });
  const {
    data: members,
    error: membersError,
    isLoading: membersLoading,
    refetch: membersListRefetch,
  }: any = useQuery(
    [
      `members${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      value?.district_id,
      value?.block_id,
      value?.office_id,

    ],
    () =>
      getMemberList(
        currentPage,
        searchQuery,
        value?.district_id,
        value?.block_id,
        value?.office_id,

      ),
    { keepPreviousData: true }
  );

  const { data: district }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });
  const { data: block }: any = useQuery(
    [`block`, value?.district_id],
    () => (value?.district_id ? getBlock(value?.district_id) : ""),
    {
      keepPreviousData: true,
    }
  );
  const {
    data: office,
    error: officeError,
    isLoading: officeLoading,
    refetch: officeListRefetch,
  }: any = useQuery(
    [`office`, value?.district_id, value?.block_id],
    () =>
      value?.district_id
        ? getOfficeFilterFullList(value?.district_id, value?.block_id)
        : "",
    {
      keepPreviousData: true,
    }
  );
  const handleChange = (e: any, feild_name: any) => {
    
    if (feild_name === "office_id") {
      setValue({
        ...value,
        office_id: e,
      });
    }
    if (feild_name === "district_id") {
      setValue({
        ...value,
        district_id: e,
      });
    }
    if (feild_name === "block_id") {
      setValue({
        ...value,
        block_id: e,
      });
    }
  };
  const staffData = useMemo(
    () => extractStaffData(members, currentPage, 10),
    [members]
  );
  const editdata = (id: any) => {
    api
      .get(`/members/${id}`, true)
      .then(async function ([success, response]) {
        setModalEditData(response?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>

            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={Array.isArray(district) ? district : []}
                value={
                  Array?.isArray(district)
                    ? district?.filter(
                        (itm: any) => itm?.value === value?.district_id
                      ) || ""
                    : ""
                }
                onChange={(e: any) => handleChange(e?.value, "district_id")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Block"
                options={Array.isArray(block) ? block : []}
                value={
                  Array?.isArray(block)
                    ? block?.filter(
                        (itm: any) => itm?.value === value?.block_id
                      ) || ""
                    : ""
                }
                onChange={(e: any) => handleChange(e?.value, "block_id")}
                name="block_id"
                isLoading={false}
                loadingMessage={() => "Fetching Block"}
                noOptionsMessage={() => "Block appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select office"
                options={Array.isArray(office) ? office : []}
                value={
                  Array.isArray(office)
                    ? office?.filter(
                        (itm: any) => itm?.value === value?.office_id
                      ) || ""
                    : ""
                }
                onChange={(e: any) => handleChange(e?.value, "office_id")}
                name="office_id"
                isLoading={false}
                loadingMessage={() => "Fetching office"}
                noOptionsMessage={() => "office appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            
            <div className="right-side-wrap">
              {checkPermission("members:write") ? (
                <div className="button-wrap">
                  <Button
                    onClick={() => {
                      modalHandler(setModalEditData, setIsOpen);
                    }}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Member
                  </Button>{" "}
                </div>
              ) : null}

              <Button
                color="primary"
                className="sm"
                outline
                onClick={() => {
                  setValue({
                    office_id: "",
                    district_id: "",
                    block_id: "",
                  });
                  setSearchQuery("");
                }}
              >
                Reset
            </Button>
            </div>
          </div>

          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Member Details"
              tableFields={[
                "Member Type",
                "Membership ID",
                "Name",
                "Status",
                "PEN Number",
                "Office Name",
                "Date of retirement",
                "Status"
              ]}
              Arraykeys={[
                "member_membership_type",
                "membershipOrder_membership_no",
                "user_first_name",
                "user_is_active",
                "member_pen_no",
                "office_name",
                "member_date_of_retirement",
              ]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("members:write") ? "edit" : "",
                checkPermission("members:delete") ? "delete" : "",
              ]}
              isLoading={membersLoading}
              error={membersError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("members:write")) {
                  editdata(selectedId);
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    members,
                    membersListRefetch
                  );
                }
              }}
              Refetch={membersListRefetch}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, membersListRefetch)
              }
              // onPaymentClick={(selectedId: string | number) =>
              //   SendPaymentSubmitHandler(selectedId, membersListRefetch)
              // }
              paginationData={members?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageMembers(navigate, pageNo, pathname)
              }
              from_member={true}
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Member" : "Add Member"}
      >
        <AddMember
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={membersListRefetch}
          office={office}
        />
      </ModalContainer>
    </section>
  );
};

export default Member;
