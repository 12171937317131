import * as Yup from "yup";
import { api } from "../../../Api/Api";
import { setValidationErrors } from "../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { phoneSchema } from "../../../Utils/Formik/FormikCommonValidationSchemas";

// toggles password visibility
export const togglePasswordVisibility = (key: string, setState: Function) => {
  setState((prev: any) => {
    return { ...prev, [key]: !prev?.[key] };
  });
};

// formik validation schema
export const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      'Password should contain at least one uppercase letter, one lowercase letter, and one digit'
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Confirm password is required"),
  // otp: Yup.string().required("Password is required"),
});

export const OtpValidationSchema = Yup.object().shape({
  phone_number: phoneSchema.required("Feild is required"),
});
export const handlePasswordReset = (
  values: any,
  actions: any,
  navigate: Function
) => {
  api
    .post("/reset-password/create", values, false)
    .then(async function ([success, response]: any) {
      actions.setSubmitting(false);
      toast.success("Password Changed");
      navigate("/");
    })
    .catch((err) => {
      console.log(err?.response?.data?.message);

      actions?.setSubmitting(false);
      if (err?.response?.data?.message) {
        for (const errorObj of err?.response?.data?.message) {
          for (const [key, value] of Object.entries(errorObj)) {
            actions?.setFieldError(key, value)
          }
        }
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};

export const handleOtpsend = (
  values: any,
  actions: any,
  navigate: Function,
  setOtpSent: any,
  setTimer: any
) => {
  api
    .post("/public/otp", values, false)
    .then(async function ([success, response]: any) {
      actions.setSubmitting(false);
      toast.success("OTP Send to Registred Phone number");
      navigate("/password/reset/:token");
      setOtpSent(true);
      setTimer(60);
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.message) {
        toast?.error(err?.response?.data?.message);
        navigate("/");
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};

export const tokenValidator = async (token: string | undefined) => {
  if (!token) return;
  const [, data] = await api.get(`/reset-password/verify/${token}`, false);
  return data;
};
