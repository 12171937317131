import { Link, useLocation } from "react-router-dom";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import { Container, Col, Row } from "reactstrap";
import { useEffect, useState } from "react";


export default function PrescriptionSettings() {
  const { pathname } = useLocation()
  const [url, setUrl] = useState('')
  useEffect(() => {
    if (pathname.startsWith("/member")) {
      setUrl("/member");
    }
    if (pathname.startsWith("/admin")) {
      setUrl("/admin");
    }
  }, [pathname])

  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Prescription Management", link: "/admin/prescription-settings" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Prescription Management", link: "/member/prescription-settings" }
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <>

      <section>
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="content-outer-wrap">
            <div className="settings-menu-wrap">
              <div>
                <h6>Prescription Management</h6>
              </div>

              <Row className="my-1 gy-4">
                <Col md={4}>
                  <Link to={`${url}/crops/1`}>
                    <div className="menu-wrap">
                      <div><small>Crop Management</small></div>
                      <div>
                        <i className="icon xl icon-next-arrow"></i>
                      </div>
                    </div>
                  </Link>
                </Col>

                {/* <Col md={4}>
                  <Link to={`${url}/pdd/1`}>
                    <div className="menu-wrap">
                      <div><small>Disease/Pest/Deficiency</small></div>
                      <div>
                        <i className="icon xl icon-next-arrow"></i>
                      </div>
                    </div>
                  </Link>
                </Col> */}

                <Col md={4}>
                  <Link to={`${url}/symptoms/1`}>
                    <div className="menu-wrap">
                      <div><small>Crop Details</small></div>
                      <div>
                        <i className="icon xl icon-next-arrow"></i>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>

            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
