import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddOfficeProps } from "../../../../../Type";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";

import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddAssociateMethods/AddAssociateMethods";
import {
  AssociateValidationSchema,
  AddAssociateInitialValues,
} from "./AddAssociateMethods/FormikValidation";
import Select from "react-select";
import { ASSOCIATIVE_STATUS } from "../../../../../Utils/Constants/selection_options";
import TextError from "../../../../../Components/FormikError/TextError";

const AddAssociate = ({
  setIsOpen,
  modalEditData,
  refetch,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddAssociateInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [image_change, setImageChange] = useState(false);
  const [imageView, setImageView] = useState("");
  const [imagefile, setImageFile] = useState("");
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );

    if (modalEditData && modalEditData[0]?.image) {
      setImageView(modalEditData[0]?.image);
      setImageChange(true);
    }
  }, [modalEditData]);


  return (
    <Formik
      validationSchema={AssociateValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Status</Label>
                  <Select
                    placeholder="Select Status"
                    name="status"
                    options={ASSOCIATIVE_STATUS}
                    value={
                      ASSOCIATIVE_STATUS?.filter(
                        (itm: any) => itm?.value === values?.status
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("status", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Status"}
                    noOptionsMessage={() => "Status appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="status" component={TextError} />
                </Col>
                <Col md={12}>
                  <Label>Reason</Label>
                  <Input
                    name="reject_reason"
                    value={values?.reject_reason}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="status" component={TextError} />
                </Col>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAssociate;
