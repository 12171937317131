import { SidebarMenuItemType } from "../../../Type";

const AdminSidebarMenuItems: SidebarMenuItemType[] = [
  {
    icon: <i className="icon xl icon-dashboard"></i>,
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    icon: <i className="icon xl icon-office-management"></i>,
    label: "Office Management",
    path: "/admin/office-management/1",
  },
  {
    icon: <i className="icon xl icon-members"></i>,
    label: "Members",
    path: "/admin/member-management/1",
  },
  // {
  //   icon: <i className="icon xl icon-members"></i>,
  //   label: "Associate Members Request",
  //   path: "/admin/associate/1",
  // },
  {
    icon: <i className="icon xl icon-fund"></i>,
    label: "Fund Mangement",
    path: "/admin/fund-management/1",
    AllPaths: ["/admin/patient-profile"],
  },
  {
    icon: <i className="icon xl icon-accomodation"></i>,
    label: "Accommodation Management",
    path: "/admin/accomadation-management/1",
    AllPaths: ["/admin/doctors-profile", "/admin/doctors/card"],
  },
  {
    icon: <i className="icon xl icon-magazine"></i>,
    label: "Magazine",
    path: "/admin/magazine/1",
    AllPaths: ["/admin/feedback/1", "/admin/feedback/0"],
  },

  {
    icon: <i className="icon xl icon-blog"></i>,
    label: "Blogs",
    path: "/admin/blog/1",
  },
  {
    icon: <i className="icon xl icon-prescription"></i>,
    label: "Prescription Generation",
    path: "/admin/prescription/1",
  },
  {
    icon: <i className="icon xl icon-transfer"></i>,
    label: "Office Change Request",
    path: "/admin/transfer-request",
    permission_key: "transfer:accept",

  },
  // {
  //   icon: <i className="icon xl icon-transfer"></i>,
  //   label: "My Office Change Request",
  //   path: "/admin/my-transfer-request",
  //   permission_key: "transfer:read",

  // },
  {
    icon: <i className="icon xl icon-reports"></i>,
    label: "Reports",
    path: "/admin/reports",
    permission_key: "reports:read",
  },

  {
    icon: <i className="icon xl icon-asset"></i>,
    label: "Asset Mangement",
    path: "/admin/asset-settings/1",
  },
  {
    icon: <i className="icon xl icon-download"></i>,
    label: "Downloads",
    path: "/admin/download-settings/1",
    permission_key: "downloads:read",

  },
  {
    icon: <i className="icon xl icon-meetings"></i>,
    label: "Meetings",
    path: "/admin/meetings/1",
  },
  {
    icon: <i className="icon xl icon-announcement"></i>,
    label: "Announcement",
    path: "/admin/announcement/1",
  },
  // leave accept
  {
    icon: <i className="icon xl icon-leave"></i>,
    label: "Leave Management",
    path: "/admin/leave-management/1",
    permission_key: "leave:accept",
  },
  {
    icon: <i className="icon xl icon-attendance"></i>,
    label: "Attendance",
    path: "/admin/attandance/1",
  },
  {
    icon: <i className="icon xl icon-bank"></i>,
    label: "Bank Accounts",
    path: "/admin/bank-accounts",
  },
  {
    icon: <i className="icon xl icon-leave"></i>,
    label: "My Leave",
    path: "/admin/my-leave/1",
    permission_key: "leave:read",
  },
  {
    icon: <i className="icon xl icon-website"></i>,
    label: "My Website",
    path: "/admin/mywebsite",
  },
  {
    icon: <i className="icon xl icon-settings"></i>,
    label: "Settings",
    path: "/admin/settings",
    AllPaths: [
      "/admin/notification-settings",
      "/admin/asset-sub-category-settings",
    ],
  },
];

export { AdminSidebarMenuItems };
