import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { geVacancyList, getDistrict } from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import {
  extractStaffData,
  handleDownloadClick,
  setCurrentPageStaff,
} from "./VacancyMethods";
import Select from "react-select";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const Vacanyreport = () => {
  const { currentPage = "1" } = useParams();
  const { checkPermission } = usePermission();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    district_id: "",
    block: "",
    cadre: "",
    designation: "",
  });
  const handleChange = (e: any, feild_name: any) => {
    setValue({
      ...value,
      [feild_name]: e,
    });
  };

  const {
    data: loginreports,
    error: loginreportsError,
    isLoading: loginreportsLoading,
    refetch: loginreportsListRefetch,
  }: any = useQuery(
    [`vacancy${currentPage}`, currentPage, value?.district_id],
    () => geVacancyList(currentPage, value?.district_id),
    { keepPreviousData: true }
  );

  const { data: district }: any = useQuery(
    [`designation`],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(
    () => extractStaffData(loginreports, currentPage, 10),
    [loginreports]
  );

  const handleClickDownload = () => {
    handleDownloadClick();
  };

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Reports", link: "/admin/reports" },
      {
        label: "Prescription Reports",
        link: "/admin/report-prescription/1",
      },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Reports", link: "/member/reports" },
      {
        label: "Prescription Reports",
        link: "/member/report-prescription/1",
      },
    ];
  }

  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={district}
                value={
                  district?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "district_id")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>

            <div className="right-side-wrap">
              {checkPermission("reports:write") ? (
                <div className="button-wrap">
                  <Button
                    color="primary"
                    className="sm"
                    onClick={handleClickDownload}
                  >
                    <i className="icon xl icon-download-white"></i>Download
                  </Button>
                </div>
              ) : null}

              <Button
                color="primary"
                className="sm"
                outline
                onClick={() => {
                  setValue({
                    district_id: "",
                    block: "",
                    cadre: "",
                    designation: "",
                  });
                }}
              >
                Reset
              </Button>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Vacany Report"
              tableFields={[
                "Cadre Name",
                "District Name",
                "Office  Name",
                "Seat Count",
                "Remaining Seat Count",
              ]}
              Arraykeys={[
                "cadre_name",
                "district_name",
                "office_name",
                "seat_count",
                "remaining_seat_count",
              ]}
              viewType="table"
              toURL={""}
              actions={["", ""]}
              isLoading={loginreportsLoading}
              error={loginreportsError}
              onEditClick={(selectedId: any) => {}}
              Refetch={loginreportsListRefetch}
              onDeleteClick={(selectedId: string | number) => {}}
              onPaymentClick={(selectedId: string | number) => {}}
              paginationData={loginreports?.data?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo)
              }
              from_report={true}
            />
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Vacanyreport;
