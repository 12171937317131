import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddRoleInitialValues } from "./FormikValidation";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { api } from "../../../../../../Api/Api";

type AddInitialValues = {
  role_name: string;
  permissions: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .patch(
        `/role/${id}`,
        { name: values?.role_name, permissions: values?.permissions },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Role Details Updated ",{toastId:"Roleupdate"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Roleupdaterr"});
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/role",
        {
          name: values?.role_name,
          permissions: values?.permissions,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Role Added ",{toastId:"Roleadd"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Roleadder"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  
  if (modalEditData?.length !== 0) {
    let new_array: any = modalEditData?.[0]?.permission?.filter((item: any) => item.is_check === true)
      .map((item: any) => item.id);
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      role_name: modalEditData?.[0]?.name,
      permissions: new_array,
    });
 
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddRoleInitialValues);
  }
  
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/role/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"RoleDeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Roledeleterr"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/roles/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/roles/${pageNo}`)
  }
};
