import { Button, Col, Form, Input, Row } from "reactstrap";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { TransferRejecthandler } from "../../../Pages/Private/MemberModule/TransferRequest/AddTransferRequest/AddTransferRequestMethods/AddTransferRequestModalMethods";

const TransferRejectModal = (props: any) => {    
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const handleRejectClick = () => {
    if (!reason) {
      setReasonError("Reason is required"); // Set error message if reason is empty
      return; // Prevent further execution if reason is empty
    }
    TransferRejecthandler(props.setRejectModal, props.rejectId, reason, props.Refetch);
  };



  return (
    <>
      <div className="form-wrap">
        <Form>
          <Row className="gy-4">
            <Col lg={12}>
              <div className="modal-warning-text-wrap">
                <h5 className="modal-warning-title">Are you sure?</h5>
              </div>
            </Col>
            <Col lg={12}>
              <Input
                type="text"
                value={reason}
                onChange={(e) => {
                    setReason(e.target.value)
                    setReasonError(""); // Clear error message when input changes

                } }
                name="reason"
                placeholder="Enter reason"
                required
            />
            {reasonError && <div className="text-danger">{reasonError}</div>} {/* Display error message */}

            </Col>
            <Col lg={12}>
              <div className="submit-btn-wrap centered">
                {/* Cancel button */}
                <Button
                  color={"danger"}
                  outline
                  className="sm"
                  onClick={() => props?.setRejectModal(false)}
                >
                  Cancel
                </Button>
                {/* Reject button */}
                <Button
                  color={"primary"}
                  className="sm"
                  onClick={handleRejectClick} // Call handleRejectClick on click
                >
                  Reject
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default TransferRejectModal;
