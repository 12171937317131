import { useMemo, useState } from "react";
import {
  Container,
  Button,
  InputGroup,
  Input,
  Row,
  Table,
  CardBody,
  Card,
  Col,
} from "reactstrap";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAdminDownloadSearch } from "../DownloadMethods";
import {
  deleteCategoryClickHandler,
  deleteDownloadCategoryHandler,
  extractSubDownloadData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageDownload,
  Addfilemodal,
  deleteClickHandler,
  deleteSubHandler,
} from "../AddDownload/AddSubDownloadMethods/AddDownloadModalMethods";
import AddSubDownload from "../AddDownload/subcategory";
import AddFile from "../AddDownload/AddFile";
import {
  getDownloadCategoreyList,
  handlePublishModal,
} from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import DownloadPublishModal from "../DownloadPublishModal";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import { api } from "../../../../../Api/Api";
import CustomSpinner from "../../../../../Components/CustomElements/Spinner/SpinnerCustom";
import DeleteAlertModal from "../../../../../Components/Containers/AlertModal/DeleteAlertModal";

const DownloadCategoreyIndex = () => {
  const { currentPage = "1", id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [publishModalData, setPublishModalData] = useState({});
  const [addFileOpen, setAddFileOpen] = useState(false);
  const { checkPermission } = usePermission();
  const [openMoadl, setModal] = useState(false);
  const [delete_id, setDeleteId] = useState();
  const [displayedItems, setDisplayedItems] = useState<number>(3); // Number of items to display initially
  const [loadingMore, setLoadingMore] = useState<boolean>(false);


  const {
    data: downloadData,
    error: downloadError,
    isLoading: downloadLoading,
    refetch: downloadListRefetch,
  }: any = useQuery(
    [`download${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getDownloadCategoreyList(currentPage, searchQuery, id),
    { keepPreviousData: true }
  );

  const [data, setData] = useState<any>(downloadData || []);
  const DownloadDataTable = useMemo(() => {
    if (downloadData && downloadData.data && downloadData.data.items) {
      const slicedData = {
        ...downloadData,
        data: {
          ...downloadData.data,
          items: downloadData.data.items.slice(0, displayedItems)
        }
      };
      setData(slicedData);
    }
  }, [downloadData, displayedItems]);
   
  

  const check_data = (itemIndex: number, subcategoryIndex: number) => {
    // Create a shallow copy of the items array
    const newItems = downloadData?.data?.items.map(
      (item: any, index: number) => {
        if (index === itemIndex) {
          // Create a shallow copy of the subcategories array for the current item
          const newSubcategories = item?.subcategories?.map(
            (subcategory: any, subIndex: number) => {
              if (subIndex === subcategoryIndex) {
                // Toggle the is_check property for the specific subcategory
                return {
                  ...subcategory,
                  is_check: !subcategory.is_check,
                };
              }
              return subcategory; // Return the original subcategory if not the targeted one
            }
          );

          // Return the updated item with the modified subcategories array
          return {
            ...item,
            subcategories: newSubcategories,
          };
        }
        return item; // Return the original item if not the targeted one
      }
    );

    // Update the state with the new array of items
    setData({
      ...downloadData,
      data: {
        ...downloadData?.data,
        items: newItems,
      },
    });
  };

  const [sub_data, setSubData] = useState([]);
  const getAllSubSubcategory = async (main_id: any, id: string) => {
    const [, datas] = await api.get(
      `/download-categories/${main_id}?parent_id=${id}`,
      true
    );
    setSubData(datas);
  };
  const delete_Confirm = (id: any) => {
    setModal(true);
    setDeleteId(id);
  };
  const loadMoreItems = () => {
    setLoadingMore(true);
    setTimeout(() => { // Simulating a delay, replace with actual data loading logic
      setDisplayedItems(displayedItems + 3); // Increase the number of displayed items
      setLoadingMore(false);
    }, 1000);
  };
  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminDownloadSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("downloads:write") && (
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Subcategory
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <div className="resources-cards-out-container">
              {/* <div className="gy-4"> */}
              {data?.data?.items?.length > 0 ? (
                data?.data?.items?.map((item: any, i: any) => (
                  <Row key={i}>
                    <Col md={8} lg={8} xs={6}>
                      <h4>{item?.name}</h4>
                    </Col>
                    <Col md={4} lg={4} xs={6}>
                      <div className="action-btn">
                        <i
                          title="Add File"
                          className="icon icon-plus-new xl"
                          onClick={() =>
                            Addfilemodal(
                              item?.id,
                              setModalEditData,
                              setAddFileOpen,
                              downloadData
                            )
                          }
                        ></i>
                        <i
                          title="Edit"
                          className="icon icon-edit xl"
                          onClick={() =>
                            onEditClickHandler(
                              item?.id,
                              setModalEditData,
                              setIsOpen,
                              downloadData
                            )
                          }
                        ></i>
                        <i
                          title="Delete"
                          className="icon icon-delete xl"
                          onClick={() => {
                            delete_Confirm(
                              item?.id
                            );
                            downloadListRefetch();
                          }}
                        ></i>
                      </div>
                    </Col>
                    <div className="category-wrap sub-category-wrap">
                      <div className="category-wrap-inner">
                        {/* <div className="category-heading d-flex align-items-center justify-content-between">
                            
                          </div> */}
                        <div className="category-card-list-wrap">
                          <Row className="gy-2">
                            {item?.subcategories?.map(
                              (subItem: any, subIndex: any) => (
                                <Col xs={12} md={4} lg={4} key={subIndex}>
                                  <Card className="resources-categ-card">
                                    <CardBody>
                                      <div className="categ-body-inner row">
                                        <div className="row align-items-center">
                                          <div className="content col">
                                            <h6>{subItem?.name}</h6>
                                            {/* <small>{subItem?.type}</small> */}
                                          </div>
                                          <div className="col-auto">
                                            <div className="action-btn">
                                              <Link
                                                title="View"
                                                to={subItem?.document_file_url}
                                              >
                                                <i className="icon icon-eye-open xl"></i>
                                              </Link>
                                              <Link
                                                title="Delete"
                                                to="#"
                                                onClick={() => {
                                                  delete_Confirm(
                                                    subItem?.id
                                                  );
                                                  downloadListRefetch();
                                                }}
                                              >
                                                <i className="icon icon-delete xl"></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Row>
                ))
              ) : (
                <div className=" text-primary text-center">
                  <p className="text-center text-primary">No Data</p>
                </div>
              )}
              {/* </div> */}

            </div>
            <div className="load-more-wrap center text-center">

            {!loadingMore && downloadData?.data?.items?.length > displayedItems && (
                <Button color="primary" className="sm" outline onClick={loadMoreItems}>
                  Load More
                </Button>

              )}
              {loadingMore && <CustomSpinner />}{/* Display spinner while loading */}
            </div>

          </div>
        </Container>
      </div>
      <ModalContainer isOpen={openMoadl} setIsopen={setModal} title="">
        <DeleteAlertModal
          setModal={setModal}
          delete_id={delete_id}
          onDeleteClick={deleteSubHandler}
          refetch={downloadListRefetch} // Pass the deleteSubHandler function here

        />
      </ModalContainer>
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit Subcategory"
            : "Add New Subcategory"
        }
      >
        <AddSubDownload
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={downloadListRefetch}
          id={id}
        />
      </ModalContainer>
      {/* ADD file in existing category */}
      <ModalContainer isOpen={addFileOpen} setIsopen={setAddFileOpen} title={"Add File"}>
        <AddFile
          setIsOpen={setAddFileOpen}
          modalEditData={modalEditData}
          refetch={downloadListRefetch}
          id={id}
        />
      </ModalContainer>
      <ModalContainer isOpen={publishModalOpen} setIsopen={setPublishModalOpen}>
        <DownloadPublishModal
          data={publishModalData}
          setIsOpen={setPublishModalOpen}
          refetch={downloadListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default DownloadCategoreyIndex;
