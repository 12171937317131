import { Button, Container } from 'reactstrap';
import AddBankAccountForm from './AddBankAccountForm';
import { useQuery } from 'react-query';
import { getAccountsTotal, getAllBankAccountDetails } from './BankAccountsMethods';
import CustomSpinner from '../../../../Components/CustomElements/Spinner/SpinnerCustom';
import { useEffect, useState } from 'react';
import CustomPagination from '../../../../Components/Pagination/Pagination';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { usePermission } from '../../../../Components/Permissions/Admin/AdminPermission';

const BankAccounts = () => {
    const { pageNo = '1' } = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { checkPermission } = usePermission();
    const [addNewShow, setAddNewShow] = useState(false)
    const [currentDate, setCurrentDate] = useState<string>('');

    useEffect(() => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date().toLocaleDateString('en-US', options);
        setCurrentDate(formattedDate);
    }, []);

    const {
        data: AccountsTotal,
        refetch: AccountsTotalRefetch
    }: any = useQuery(['AccountsTotal'], () => getAccountsTotal(),
        { keepPreviousData: true }
    );

    const {
        data: AllBankAccountData,
        isLoading: AllBankAccountLoading,
        refetch: AllBankAccountRefetch,
    }: any = useQuery([`AllBankAccountData${pageNo}`, pageNo], () => getAllBankAccountDetails(pageNo),
        {
            keepPreviousData: true,
            onSuccess: () => AccountsTotalRefetch()
        }
    );


    return (
        <section>
            <div className="breadcrumb-wrap"></div>
            <div className="content-outer-wrap">
                <Container>
                    {/* <div className="search-bar-full-wrap">
                        <div className="search-bar"></div>
                        <div className="right-side-wrap">
                            <div className="filter-wrap">
                                <div className="button-wrap"></div>
                            </div>
                        </div>
                    </div> */}

                    <div className="membership-table-outer">
                        <div className="membership-table-head">
                            <div>
                                <h6>Bank Accounts</h6>
                            </div>
                            <div className='text-end'>
                                {checkPermission('bank:write') ? <Button color='primary' outline className="sm primary" onClick={() => setAddNewShow(!addNewShow)}>
                                    {!addNewShow ? "Add New" : "Close"}
                                </Button> : null}
                            </div>
                        </div>
                        {AccountsTotal?.totalamount && <div>
                            <span className='sub-heading'>
                                Total Opening from January 1, {new Date().getFullYear()} to {currentDate} is
                                <span className='inner-heading'> ₹{AccountsTotal?.totalamount}</span>
                            </span>
                        </div>}

                        {/* cards section */}
                        <div className="content-list-outer-wrap">
                            {AllBankAccountLoading ? <div className='text-center'>
                                <CustomSpinner />
                            </div> : null}

                            {addNewShow ?
                                <AddBankAccountForm
                                    editData={[]}
                                    editable={true}
                                    refetch={() => {
                                        setAddNewShow(false)
                                        AllBankAccountRefetch()
                                    }}
                                /> : null}

                            {AllBankAccountData?.data?.map((item: any) =>
                                <AddBankAccountForm
                                    editData={item}
                                    key={item?.id}
                                    editable={false}
                                    refetch={() => {
                                        setAddNewShow(false)
                                        AllBankAccountRefetch()
                                    }}
                                />)}
                            {AllBankAccountData?.data?.length === 0 ?
                                <div className='text-center'>
                                    <span>No Data</span>
                                </div> : null
                            }
                        </div>
                    </div>
                </Container>
            </div>
            {AllBankAccountData?.data?.length !== 0 && <CustomPagination
                activePage={Number(pageNo)}
                itemsPerPage={AllBankAccountData?.meta?.itemsPerPage}
                setCurrentPage={(page_no: any) => { navigate(`/ admin / bank - accounts / ${page_no}`) }}
                totalItems={AllBankAccountData?.meta?.totalItems}
            />}
        </section>
    )
}

export default BankAccounts