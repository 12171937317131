import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { handleAdminStaffSearch } from "./ControlMeasureMethods";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddControlMeasures/AddControlMeasuresMethods/AddControlModalMethods";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddControlMeasures from "./AddControlMeasures";
import { getOfficeList } from "../../../../../Api/AdminApis";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";

const ControlmeasuresIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    data: staff,
    error: staffError,
    isLoading: staffLoading,
    refetch: staffListRefetch,
  }: any = useQuery(
    [`staff${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getOfficeList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(staff), [staff]);
  const breadcrumbItems = [
    { label: "Settings", link: "/admin/settings" },
    { label: "Prescription Management", link: "/admin/prescription-settings" },
    { label: "Control Measures", link: "/admin/controll-measures" },
  ];
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <section>
      <div className="breadcrumb-wrap">{breadcrumb}</div>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Add Control Measure
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle="Control Measure Details"
            tableFields={[
              "ID",
              "Name",
              "District",
              "Phone Number",
              "Location",
              "Seat Count",
              "Vacant Seat",
            ]}
            Arraykeys={[
              "id",
              "name",
              "mobile",
              "gender",
              "email",
              "department",
              "designation",
            ]}
            viewType="table"
            toURL={""}
            actions={["edit", "delete"]}
            isLoading={staffLoading}
            error={staffError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(selectedId, setModalEditData, setIsOpen, staff)
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, staffListRefetch)
            }
            paginationData={staff?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo)
            }
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit Control Measure"
            : "Add Control Measure"
        }
      >
        <AddControlMeasures
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={staffListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default ControlmeasuresIndex;
