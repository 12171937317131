import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Input,
  Label,
  Button,
} from "reactstrap";
import { CardsWithIconType } from "../../../Type";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import { colors } from "react-select/dist/declarations/src/theme";
import { capitalizeWord, removeUnderScore } from "../../../Utils/helpers/helpers";
import Slider from "react-slick";
import { usePermission } from "../../Permissions/Admin/AdminPermission";

const CardsWithIcon = ({
  data,
  paginationData,
  setCurrentPage,
  viewType,
  toURL,
  tableTitle,
  tableFields,
  actions,
  Arraykeys,
  isLoading,
  error,
  onEditClick,
  onDeleteClick,
  type,
  onStatusClick,
  from,
}: CardsWithIconType) => {
  const { checkPermission } = usePermission();
  return (
    <>
      <Row className="gy-4">
        {isLoading ? (
          <div>
            <CustomSpinner />
          </div>
        ) : error ? (
          <div>Error Occurred</div>
        ) : data?.length === 0 ? (
          <div>No Data Available</div>
        ) : (
          data?.map((itm: any, i: number) => {
            return (
              <>
                <Col md={4}>
                  <Card className="widget-card settings-card mt-2">
                    <CardBody className="widget-inner-wrap">
                      <div className="content-wrap">
                        <div className="action-flex-wrap">
                          {!from && (
                            actions.includes('edit') && <Link
                              to="#"
                              onClick={() => onEditClick(itm?.id)}
                            >
                              <i className="icon icon-edit xl"></i>
                            </Link>

                          )}

                          {actions.includes('delete') && <Link
                            to="#"
                            onClick={() => onDeleteClick(itm?.id)}
                          >
                            <i className="icon icon-delete xl"></i>
                          </Link>}
                        </div>
                          
                          <div className="my-website-card-details">
                              <div className="detail-wrap">
                                {itm?.image && (
                                    <div className="image-wrap">
                                      {" "}
                                        <img
                                          src={itm?.image}
                                          alt="banner-image"
                                        />
                                    </div>
                                  )}

                               {/* banner */}
                                {Arraykeys?.map((el: any, num: number) => (
                                  <div key={num} className="detail-wrap-inner">
                                    <small className="heading">
                                      {capitalizeWord(removeUnderScore(el))} {" "}
                                    </small>
                                    <small className="clamp-two-lines">
                                      {/* {itm?.[el]} */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: itm?.[el],
                                        }}
                                      ></div>
                                    </small>
                                  </div>
                                ))}

                                {/* gallery */}
                                {itm?.galleryImage &&
                                  itm.galleryImage.length > 0 && (
                                    <div className="detail-wrap-inner">
                                      <Slider>
                                        {itm?.galleryImage.map(
                                          (image: any) => (
                                            <div
                                              key={image.id}
                                              className="gallery-img-wrap"
                                            >
                                              <img
                                                src={image.images}
                                                alt="Gallery Image"
                                              />
                                            </div>
                                          )
                                        )}
                                      </Slider>
                                    </div>
                                  )}

                                  {/* services and facilities */}
                                {itm?.icon && (
                                  <div className="detail-wrap-inner">
                                    <small className="heading">
                                      Icon
                                    </small>
                                    <div className="icon-wrap">
                                      <img
                                        src={itm?.icon}
                                        alt="icon"
                                      />
                                    </div>
                                  </div>
                                )}

                                
                              </div>

                              {itm?.is_published && (
                                <>
                                    {/* checkbox */}
                                    <div className="check-flex-outer">
                                      <div className="check-flex-wrap">
                                        <Input
                                          type="checkbox"
                                          checked={
                                            itm?.is_published === 2
                                              ? false
                                              : true
                                          }
                                          onChange={() => {
                                            if (checkPermission("website:publish")) {
                                              const newStatus =
                                              itm?.is_published === 2 ? 1 : 2;
                                              onStatusClick(itm.id, newStatus);
                                            }
                                          }}
                                        />
                                        <Label>
                                          {itm?.is_published === 2
                                            ? "Check to Publish"
                                            : "Uncheck to Unpublish"}
                                        </Label>
                                      </div>
                                    </div>
                                </>
                              )}
                          </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            );
          })
        )}
      </Row>
    </>
  );
};

export default CardsWithIcon;
