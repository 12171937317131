import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAdminFundSearch } from "./FundMethods";
import AddFund from "./AddFundManagement";
import {
  deleteClickHandler,
  extractFundData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageFund,
} from "./AddFundManagement/AddFundMethods/AddFundModalMethods";
import { getFundList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const FundIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { checkPermission } = usePermission();

  const {
    data: fund,
    error: fundError,
    isLoading: fundLoading,
    refetch: fundListRefetch,
  }: any = useQuery(
    [`fund${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getFundList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const fundData = useMemo(() => extractFundData(fund), [fund]);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminFundSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {checkPermission("fund:write") ? <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Fund
                  </Button>
                </div> : null}
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={fundData}
              tableTitle="Fund Details"
              tableFields={[
                "Name",
                "Received Fund",
                "Expanded Fund",
              ]}
              Arraykeys={["name", "received_fund", "expended_fund"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("fund:write") ? "edit" : "",
                checkPermission("fund:delete") ? "delete" : "",
              ]}
              isLoading={fundLoading}
              error={fundError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("fund:write")) {
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  fund
                )
                }
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, fundListRefetch)
              }
              paginationData={fund?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageFund(navigate, pageNo, pathname)
              }
              from_fund={true}
              Refetch={fundListRefetch}
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Fund" : "Add Fund"}
      >
        <AddFund
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={fundListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default FundIndex;
