import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddCrops/AddCropsMethods/AddCropsModalMethods";
import { handleAdminStaffSearch } from "./CropsMethods";
import AddCrop from "./AddCrops";
import { getCropList, getOfficeList } from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const CropIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    data: staff,
    error: staffError,
    isLoading: staffLoading,
    refetch: staffListRefetch,
  }: any = useQuery(
    [`crop${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getCropList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(staff), [staff]);

  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Prescription Management", link: "/admin/prescription-settings" },
      { label: "Crop", link: "/admin/crops" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Prescription Management", link: "/member/prescription-settings" },
      { label: "Crop", link: "/member/crops" },
    ];
  }

  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <div className="button-wrap">
                    {checkPermission("crop:write") ? <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Crop
                    </Button> : null}
                  </div>
                </div>
              </div>
            </div>
            {/* cards section */}
            <div className="content-list-outer-wrap">
              <CardsWithTable
                data={staffData}
                tableTitle="Crop Details"
                tableFields={["Crop Name"]}
                Arraykeys={["name"]}
                viewType="table"
                toURL={""}
                actions={[
                  checkPermission("crop:write") ? "edit" : "",
                  checkPermission("crop:delete") ? "delete" : "",
                ]}
                isLoading={staffLoading}
                error={staffError}
                onEditClick={(selectedId: any) => { if (checkPermission("crop:write")) onEditClickHandler(selectedId, setModalEditData, setIsOpen, staff) }
                }
                onDeleteClick={(selectedId: string | number) =>
                  deleteClickHandler(selectedId, staffListRefetch)
                }
                paginationData={staff?.meta}
                setCurrentPage={(pageNo: string) =>
                  setCurrentPageStaff(navigate, pageNo, pathname)
                }
              />
            </div>
        </div>
      </Container>


      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Crop" : "Add Crop"}
      >
        <AddCrop
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={staffListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default CropIndex;
