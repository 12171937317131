import * as Yup from "yup";

export const MemberShipNotificationValidationSchema = Yup.object().shape({
  sms: Yup.string().required("Field is required").nullable(),
  whatsapp: Yup.string().required("Field is required").nullable(),
  email: Yup.string().required("Field is required").nullable(),
});

export const MemberShipNotificationinitialValues = {
  sms: "",
  whatsapp: "",
  email: "",
};

export const OtherfundNotificationValidationSchema = Yup.object().shape({
  sms: Yup.string().required("Field is required").nullable(),
  whatsapp: Yup.string().required("Field is required").nullable(),
  email: Yup.string().required("Field is required").nullable(),
});

export const OtherfundNotificationinitialValues = {
  sms: "",
  whatsapp: "",
  email: "",
};

export const MeetingsNotificationValidationSchema = Yup.object().shape({
  sms: Yup.string().required("Field is required").nullable(),
  whatsapp: Yup.string().required("Field is required").nullable(),
  email: Yup.string().required("Field is required").nullable(),
});

export const MeetingsNotificationinitialValues = {
  sms: "",
  whatsapp: "",
  email: "",
};
