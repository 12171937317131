import * as Yup from "yup";
import { phoneSchema } from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const PrescriptionValidationSchema = Yup.object().shape({
  farmer_name: Yup.string().required("Field is required"),
  district: Yup.string().required("Field is required"),
  farmer_mobile: phoneSchema.required("Field is required"),
  farmer_address: Yup.string().required("Field is required"),
  type: Yup.number(),
  crops: Yup.string().required("Field is required").nullable(),
  name_of_pest_disease_deficiency: Yup.string()
    .required("Field is required")
    .nullable(),
  symptoms: Yup.array().of(
    Yup.object().shape({
      symptoms: Yup.string().required("Field is required"),
    })
  ),
  control_measures: Yup.array().of(
    Yup.object().shape({
      control_measures: Yup.string().required("Field is required"),
    })
  ),
});

export const AddPrescriptionInitialValues = {
  farmer_name: "",
  farmer_mobile: "",
  farmer_address: "",
  type:"",
  crops: "",
  name_of_pest_disease_deficiency: "",
  symptoms: [],
  control_measures: [],
  smart_id: "",
  symptoms_new: [],
  control_measures_new: [],
  district: "",
};
