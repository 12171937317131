// PermissionContext.tsx
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { getPermissionAllList } from "../../../Api/AdminApis";
import { useQuery } from "react-query";

interface PermissionContextProps {
  children: ReactNode;
}

interface PermissionContextType {
  setPermissions: (permissions: string[]) => void;
  checkPermission: (permission: string) => boolean;
  permissionList: any;
}

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined
);

export const usePermission = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermission must be used within a PermissionProvider");
  }
  return context;
};

export const PermissionProvider: React.FC<PermissionContextProps> = ({
  children,
}) => {
  const { data: permissionList }: any = useQuery(
    [`permission`],
    () => getPermissionAllList(),
    {
      keepPreviousData: true,
    }
  );

  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const setPermissions = (permissions: string[]) => {
    setUserPermissions(permissions);
  };

  const checkPermission = (permission: string) => {
    let allPermission = permission.replace(/:(.*)/, ':all') // to check the requested permission have all allowed
    return (permissionList?.permission.includes(permission) || permissionList?.permission.includes(allPermission));
  }

  return (
    <PermissionContext.Provider
      value={{ setPermissions, checkPermission, permissionList }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
