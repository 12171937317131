import { useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getOfficeFullList, getTransferRequests, handleTransferSearch, setCurrentPageTransferReq } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import {
  deleteClickHandler,
  modalHandler,
  onEditClickHandler
} from "./AddTransferRequest/AddTransferRequestMethods/AddTransferRequestModalMethods";
import AddTransferRequest from "./AddTransferRequest";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { ROLES_PERMISSIONS } from "../../../../Utils/Constants/ROLES_PERMISSIONS";

const TransferIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { checkPermission } = usePermission();

  const {
    data: transferData,
    refetch: transferDataRefetch,
    isLoading: transferDataIsLoading,
    isError: transferDataIsError
  }: any = useQuery([`transferData${currentPage}${searchQuery}`, currentPage, searchQuery], () => getTransferRequests(currentPage, searchQuery), {
    keepPreviousData: true,
  });

  const {
    data: office,
    error: officeError,
  }: any = useQuery([`office`], () => getOfficeFullList(), {
    keepPreviousData: true,
  });



  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleTransferSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {/* {checkPermission(ROLES_PERMISSIONS?.TRANSFER_REQUEST?.WRITE) ?  */}

                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Office Change Request
                  </Button>
                </div>
                {/* : null} */}

              </div>
              
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={transferData?.filteredData}
            tableTitle="Office Change Request Details"
            tableFields={[
              "District",
              "Block",
              "To Office",
              "GOV Order",
              "GOV Order Date",
              "Rejected Reason",
            ]}
            Arraykeys={[
              "district",
              "block",
              "to_office",
              "GO_order",
              "GO_order_date",
              "reason",
            ]}
            viewType="table"
            toURL={""}
            actions={[
              checkPermission(ROLES_PERMISSIONS?.TRANSFER_REQUEST?.WRITE) ? "edit" : "",
              checkPermission(ROLES_PERMISSIONS?.TRANSFER_REQUEST?.DELETE) ? "delete" : ""
            ]}
            isLoading={transferDataIsLoading}
            error={transferDataIsError}
            onEditClick={(selectedId: any) => {
              if (checkPermission(ROLES_PERMISSIONS?.TRANSFER_REQUEST?.WRITE)) {
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  transferData?.data
                )
              }
            }
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, transferDataRefetch)
            } 
            paginationData={transferData?.data?.meta}
            setCurrentPage={(pageNo: string) => setCurrentPageTransferReq(navigate, pageNo)}
            from_my_transfer_req={true}
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit Transfer Request"
            : "Add Transfer Request"
        }
      >
        <AddTransferRequest
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={transferDataRefetch}
          office={office}
        />
      </ModalContainer>
    </section>
  );
};

export default TransferIndex;
