import { Button, Col, Row } from "reactstrap";
import { AcceptRejectSubmitHandler } from "../../../Pages/Private/Admin/LeaveMangement/AddLeave/AddLeaveMethods/AddLeaveModalMethods";
import { officeChange } from "../../../Pages/Private/Admin/TransferRequest/TransferRequestMethods";

const Transfer_Modal = (props: any) => {

    return (
        <>
            <div className="form-wrap">
                <Row className="gy-4">
                    <Col lg={12}>
                        <div className="modal-warning-text-wrap">
                            <div className="warning-img-wrap">
                                <img src="/images/delete-warning.svg" alt="warning-icon" />
                            </div>
                            {props?.seat != "notavailable" && <h5 className="modal-warning-title">Are you sure ?</h5>}
                            < small className="sub-text">
                                {" "}
                                {
                                    props?.seat == "notavailable"
                                        ? "No seats available please move a member from this office to transit for making seat available"
                                        : props?.type === "change"
                                            ? " Do you really want to accept the transfer"
                                            : props?.status === 2
                                                ? "Do you really want to Publish"
                                                : "Do you really want to Delete"
                                }
                                .
                            </small>
                        </div>
                    </Col>

                    <Col lg={12}>
                        <div className="submit-btn-wrap centered">
                            <Button
                                color={"danger"}
                                outline
                                className="sm"
                                onClick={() => props?.setModal(false)}
                            >
                                Cancel
                            </Button>

                            {props?.type === "change" && props?.seat == "available" ? (
                                <>
                                    {/* {props?.status !== 2 && ( */}
                                    <>
                                        <Button
                                            color={"primary"}
                                            className="sm"
                                            onClick={() => {
                                                // return
                                                AcceptRejectSubmitHandler(
                                                    props?.setModal,
                                                    props?.Refetch,
                                                    props?.delete_id,
                                                    "2",
                                                    props?.blockVerify,
                                                    props?.from_transfer_req,

                                                );
                                                officeChange(props?.user_id, props?.block, props?.district, props?.office, props?.designation, props?.cadre_id)
                                                props?.setModal(false);

                                            }}
                                        >
                                            verify
                                        </Button>
                                    </>
                                </>
                            )
                                : props?.seat != "notavailable" ?
                                    (

                                        <Button
                                            color={"danger"}
                                            className="sm"
                                            onClick={() => {
                                                props.onDeleteClick(props.delete_id, props?.refetch); // Invoke onDeleteClick with delete_id and refetch function
                                                props?.setModal(false);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    ) : ""}
                        </div>
                    </Col>
                </Row>
            </div >
        </>
    );
};

export default Transfer_Modal;
