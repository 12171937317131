
export const handleAdminFundSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function
  ) => {
    const userRole = localStorage.getItem("ROLE");
    if (userRole === "admin") {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/admin/fund-management/1`);
      } else {
        setSearchQuery("");
      }
    } else {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/member/fund-management/1`);
      } else {
        setSearchQuery("");
      }
    }
  };
  