import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { useQuery } from "react-query";
import { getMeta } from "../../../../Api/AdminApis";
import { useNavigate, useParams } from "react-router-dom";
import {
  MetaSatusSubmitHandler,
  deleteClickHandler,
  extractStaffData,
  handleAdminStaffSearch,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddMeta/AddMetaMethods/AddMetaModalMethods";
import AddMeta from "./AddMeta";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const MetaIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    data: meta,
    error: metaError,
    isLoading: metaLoading,
    refetch: metaListRefetch,
  }: any = useQuery(
    [`meta${currentPage}`, currentPage, searchQuery],
    () => getMeta(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const metaData = useMemo(() => extractStaffData(meta), [meta]);
  const breadcrumbItems = [
    { label: "Settings", link: "/admin/settings" },
    { label: "Meta", link: "/admin/meta/1" },
  ];
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <section>
      <Container>
      <div className="content-outer-wrap">
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"

                  >
                    <i className="icon icon-add-new xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        {/* cards section */}
        <div>
          <CardsWithTable
            data={metaData}
            tableTitle="Meta Details"
            tableFields={["Title of website", "Description"]}
            Arraykeys={["meta_title", "meta_body"]}
            viewType="table"
            toURL={""}
            actions={["edit", "delete"]}
            isLoading={metaLoading}
            error={metaError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(selectedId, setModalEditData, setIsOpen, meta)
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, metaListRefetch)
            }
            onStatusClick={(selectedId: string | number, newStatus: any) =>
              MetaSatusSubmitHandler(selectedId, newStatus, metaListRefetch, setIsOpen)
            }
            paginationData={meta?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo)
            }
            meta={true}
            Refetch={metaListRefetch}
          />
        </div>
      </div>
      </Container>


      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Add Meta">
        <AddMeta
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={metaListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default MetaIndex;
