import { useRef, useState } from "react";
import {
  Button, Col,
  Card,
  CardBody
} from "reactstrap";
import { useQuery } from "react-query";

import { getServicesList } from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import {
  StatusClickHandler,
  deleteClickHandler, modalHandler,
  onEditClickHandler
} from "./AddServices/AddServicesMethods/AddServicesMethods";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddServices from "./AddServices";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";


const ServiceList = () => {
  const myDivRef: any = useRef(null);
  const { checkPermission } = usePermission();
  const [currentPage, setCurrentPage] = useState<string>('1')
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const {
    data: services,
    error: servicesError,
    isLoading: servicesLoading,
    refetch: servicesListRefetch,
  }: any = useQuery(
    [`service${currentPage}`, currentPage],
    () => getServicesList(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };

  return (
    <>
      <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading" ref={myDivRef}>
                <h6>Services & Facilities</h6>
              </div>
              <div className="button-container">
                {checkPermission("website:write") ? <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="primary"
                  className="sm"
                  outline
                >
                  <i className="icon icon-add-green xl"></i> Add
                </Button> : null}
              </div>
            </div>
            <CardsWithIcon
              type={"services"}
              data={services?.data}
              tableTitle="Services"
              Arraykeys={["title" ]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={[
                checkPermission("website:write") ? "edit" : "",
                checkPermission("website:delete") ? "delete" : ""
              ]}
              isLoading={servicesLoading}
              error={servicesError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  services
                )
              }
              onDeleteClick={(selectedId: string | number) => handleDeleteConfirmation(selectedId)}
             
            />
          </CardBody>
        </Card>
      </Col>
      {services?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={services?.meta?.totalItems}
            itemsPerPage={services?.meta?.itemsPerPage}
            activePage={services?.meta?.currentPage}
            setCurrentPage={(page: string) => {
              setCurrentPage(page)
              ScrollToContainerTop(myDivRef)
            }
            }
            disableScrollAfterPageChange
          />
        </div>
      )}
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length === 0 ? "New Service" : "Edit Services"}
      >
        <AddServices
          setIsOpen={setIsOpen}
          refetch={servicesListRefetch}
          modalEditData={modalEditData}
          servicesError={servicesError}
        />
      </ModalContainer>

      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, servicesListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default ServiceList;
