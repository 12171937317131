import { useMemo, useState } from "react";
import {
  Container,
  Button,
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Col,
} from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getDistrict,
  getFullOfficeCategory,
  getOfficeCategoreyList,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./OfficeCategoryMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddOfficeCategory/AddOfficeCategoryMethods/AddOfficeCategoryModalMethods";
import AddOfficeCategory from "./AddOfficeCategory";
import Select from "react-select";
import { api } from "../../../../Api/Api";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const OfficeCategoryIndex = () => {
  const { currentPage = "1", officeID } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false);
  const [modalEditData, setModalEditData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tab, setTab] = useState("1");
  const [value, setValue] = useState({
    district: "",
  });

  const handleChange = (e: any, field_name: any) => {
    setValue({
      ...value,
      [field_name ? field_name : field_name]: e.value,
    });
  };
  const {
    data: office_category,
    error: office_categorError,
    isLoading: office_categorLoading,
    refetch: office_categorListRefetch,
  }: any = useQuery(
    [
      `office-category${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      value?.district,
    ],
    () => getOfficeCategoreyList(currentPage, searchQuery, value?.district),
    {
      keepPreviousData: true,
    }
  );
  const { data: district }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });
  const { data: office_cat, refetch: office_fullRefetch }: any = useQuery(
    [`office_cat`],
    () => getFullOfficeCategory(),
    {
      keepPreviousData: true,
    }
  );
  const staffData = useMemo(
    () => extractStaffData(office_category),
    [office_category]
  );

  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Office Category", link: "/admin/office-categorey" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Office Category", link: "/member/office-categorey/1" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  const editdata = (data: any) => {
    api
      .get(`/office-category/${data?.id}?district_id=${data?.district_id}`, true)
      .then(async function ([success, response]) {
        setModalEditData(response?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section>
      <Container>
      <div className="breadcrumb-wrap">{breadcrumb}</div>
      <div className="content-outer-wrap">
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={district}
                value={
                  Array.isArray(district)
                    ? district?.filter((itm: any) =>
                      value?.district.includes(itm?.value)
                    ) || []
                    : []
                }
                onChange={(e: any) => handleChange(e, "district")}
                name="district"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="right-side-wrap">
              <div className="button-wrap">
                {checkPermission("office category:write") ? <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="primary"
                  className="sm"
                >
                  <i className="icon icon-add-new xl"></i>Office Category
                </Button> : null}
              </div>
            </div>
          </div>

        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle="Office Category Details"
            tableFields={["Category Name", "District", "Blocks"]}
            Arraykeys={["name", "district", "block"]}
            viewType="table"
            toURL={""}
            actions={[
              checkPermission("office category:write") ? "edit" : "",
              checkPermission("office category:delete") ? "delete" : "",
            ]}
            isLoading={office_categorLoading}
            error={office_categorError}
            onEditClick={(selectedId: any) => {
              
              if (checkPermission("office category:write")) {
                 editdata(selectedId);
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  office_category
                );
              }
            }}
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, office_categorListRefetch)
            }
            paginationData={office_category?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo, pathname)
            }
            from_office_cate={true}
          />
        </div>
      </div>
      </Container>


      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit Office Category"
            : "Add Office Category"
        }
      >
        <AddOfficeCategory
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={office_categorListRefetch}
          district={district}
          office_cat={office_cat}
          office_fullRefetch={office_fullRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default OfficeCategoryIndex;
