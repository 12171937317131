import { Button, Col, Row } from "reactstrap";
import { activeRevokeClickHandler } from "../AddUser/AddUserMethods/AddUserModalMethods";

const ActiveAlertModal = (props: any) => {
  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Do you really want to{" "}
                {props?.preview_id === 2 ? "Active" : " Suspend"} .
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setIsOpen(false)}
              >
                Cancel
              </Button>
              {props?.preview_id === 2 ? (
                <Button
                  color={"primary"}
                  className="sm"
                  onClick={() => {
                    activeRevokeClickHandler(
                      props?.paymnet_login_id,
                      1,
                      props?.Refetch,
                      props?.setIsOpen
                    );
                  }}
                >
                  Active
                </Button>
              ) : (
                <Button
                  color={"danger"}
                  className="sm"
                  onClick={() => {
                    activeRevokeClickHandler(
                      props?.paymnet_login_id,
                      2,
                      props?.Refetch,
                      props?.setIsOpen
                    );
                  }}
                >
                  Suspend
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActiveAlertModal;
