import AdminLayout from "./Admin";
import { childrenType } from "../../Type";
import MemberLayout from "./Member";

const LayoutContainer = ({ children }: childrenType) => {
  const ROLE: any = localStorage?.getItem("ROLE");
  const Layout: any = {
    admin: <AdminLayout ROLE={ROLE}>{children} </AdminLayout>,
    member: <MemberLayout ROLE={ROLE}>{children} </MemberLayout>,
    associative:<MemberLayout ROLE={ROLE}>{children} </MemberLayout>,

  };

  return Layout?.[ROLE];
};

export default LayoutContainer;
