import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { AddBannerProps } from "../../../../../../Type";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddHistoryInitialValues,
  HistoryValidationSchema,
} from "./AddHistoryMethods/FormikValidation";
import {
  HistorySubmitHandler,
  addModalInitialValuesHandler,
} from "./AddHistoryMethods/AddHistoryMethods";

const AddHistory = ({ setIsOpen, refetch, modalEditData }: AddBannerProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddHistoryInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [image_change, setImageChange] = useState(false);
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  const [imagefile, setImageFile] = useState("");
  const [imageView, seImageView] = useState("");
  const create_imageURL = (imgfile: any) => {
    seImageView(URL?.createObjectURL(imgfile));
    setImageChange(true);
  };

  return (
    <Formik
      validationSchema={HistoryValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        HistorySubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          imagefile
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <>
            <div className="Add-web-settings-wrap">
              <div className="form-wrap">
                <Form onSubmit={handleSubmit}>
                  <Row className="gy-4">
                    <Col md={12}>
                      <Label>Year</Label>
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values?.title}
                        name="title"
                        placeholder="Year"
                      />
                      <ErrorMessage name="title" component={TextError} />
                    </Col>

                    <Col md={12}>
                      <Label>History</Label>
                      <Input
                        type="textarea"
                        onChange={handleChange}
                        value={values?.body}
                        name="body"
                        placeholder="History Description"
                      />
                      <ErrorMessage name="body" component={TextError} />
                    </Col>
                    <Col lg={12}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Add"
                        color="primary"
                        className="sm submit-btn"
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddHistory;
