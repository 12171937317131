import * as Yup from "yup";
import {
  emailSchema,
  phoneSchema,
} from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const OfficeValidationSchema = Yup.object().shape({
  office_name: Yup.string().required("Field is required"),
  phone_no: Yup.string()
    .matches(/^[0-9+-]+$/, "Invalid phone number"),
  email: Yup.string().email('Invalid email format'),
  // phone_number: phoneSchema.required("Field is required"),
  // email: emailSchema.required("Field is required"),
  district: Yup.string().required("Field is required").nullable(),
  office_cat_id: Yup.string().required("Field is required").nullable(),
  block: Yup.string().required("Field is required").nullable(),
  // panchayat: Yup.string().required("Field is required").nullable(),
  // pincode: Yup.number().required("Field is required").nullable(),
  // location: Yup.string().required("Field is required").nullable(),
  // latitude: Yup.string().required("Field is required").nullable(),
  // longitude: Yup.string().required("Field is required").nullable(),
  // state: Yup.string().required("Field is required").nullable(),
  // address: Yup.string().required("Field is required").nullable(),
  // office_no: Yup.string().required("Field is required").nullable(),

});

export const AddOfficeInitialValues = {
  office_name: "",
  phone_number: "",
  email: "",
  district: "",
  block: "",
  panchayat: "",
  pincode: "",
  location: "",
  latitude: "",
  longitude: "",
  state: "",
  address: "",
  office_no: "",
  office_cat_id: "",
};
