import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddCadre from "./AddCadre";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddCadre/AddCadreMethods/AddCadreModalMethods";
import { getCadreList } from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./CadreMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const CadreIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { pathname } = useLocation()
  const {
    data: cadre,
    error: cadreError,
    isLoading: cadreLoading,
    refetch: cadreListRefetch,
  }: any = useQuery([`cadre${currentPage + searchQuery}`, currentPage, searchQuery], () => getCadreList(currentPage, searchQuery), {
    keepPreviousData: true,
  });

  const cadreData = useMemo(() => extractStaffData(cadre), [cadre]);
  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Cadre Management", link: "/admin/cader-settings/1" }
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Cadre Management", link: "/member/cader-settings/1" }
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("cadre:write") ? <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Cadre
                  </Button> : null}
                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={cadreData}
              tableTitle="Cadre Details"
              tableFields={["Name"]}
              Arraykeys={["name"]}
              viewType="table"
              toURL={""}
              // actions={["edit", "delete"]}
              actions={[
                checkPermission("cadre:write") ? "edit" : "",
                checkPermission("cadre:delete") ? "delete" : "",
              ]}
              isLoading={cadreLoading}
              error={cadreError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("cadre:write")) onEditClickHandler(selectedId, setModalEditData, setIsOpen, cadre)
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, cadreListRefetch)
              }
              paginationData={cadre?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </Container>

      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Cadre" : "Add Cadre"}
      >
        <AddCadre
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={cadreListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default CadreIndex;
