import { Button, Col, Row } from "reactstrap";

const ConfirmOtherAlertModal = (props: any) => {
  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Send Payment Link
                <br />
                <br />
                <b>Do you wish to continue .</b>
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"primary"}
                className="sm"
                onClick={() => {
                  props?.sentPaymentLinkToAll(props?.id, props?.setIsopen);
                }}
              >
                Yes
              </Button>
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setIsopen(false)}
              >
                No
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConfirmOtherAlertModal;
