import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthRouteProps } from '../../../Type'
import LayoutContainer from '../../../Components/Layouts'
import { PermissionProvider } from '../../../Components/Permissions/Admin/AdminPermission'

const PrivateAuthProvider = ({ loginStatus }: AuthRouteProps) => {

  const [auth, setAuth] = useState<boolean>(!!localStorage.getItem("AUTH_TOKEN" || null))
  const location = useLocation()

  useEffect(() => {
    const authStatus: string | null = localStorage.getItem("AUTH_TOKEN" || null)
    setAuth(authStatus !== null)
  }, [loginStatus])

  return auth ?
    <PermissionProvider>
      <LayoutContainer >
        <Outlet />
      </LayoutContainer>
    </PermissionProvider>
    :
    <Navigate to="/" replace state={{ from: location }} />
}

export default PrivateAuthProvider