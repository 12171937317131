import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddSeatInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  seat_name: string;
  cadre: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  office_id: string | number
) => {
  // Add
  if (editMode) {
    api
      .patch(
        `/seats/${id}`,
        {
          seat_count: values?.seat_name,
          cadre_id: parseInt(values?.cadre),
          office_id: office_id,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Seats Details Updated ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/seats",
        {
          seat_count: values?.seat_name,
          cadre_id: parseInt(values?.cadre),
          office_id: office_id,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Seats Added ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.items?.map((item: any) => {
    return {
      id: item?.seat_id,
      cadre: item?.cadre_name,
      seat_count: item?.seat_seat_count,
      used_seat_count: item?.used_seat_count,
      remaining_seat_count: item?.remaining_seat_count,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
 
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.id,
      seat_name: modalEditData?.seat_count,
      cadre: modalEditData?.cadre?.id,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddSeatInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.items?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/seats/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (
  navigate: Function,
  pageNo: any,
  officeID: any,
  currnt_page?:any
) => {
  navigate(`/admin/seat-settings/${pageNo}/${officeID}/${currnt_page}`);
};
