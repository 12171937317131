import { FormikHelpers } from "formik";
import { toast } from "react-toastify";

import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { AddOtherInitialValues } from "./FormikValidation";

type AddInitialValues = {
  title: string;
  start_date: string;
  amount: string;
  end_date: string;
  district_id: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {

  let data: any = {
    title: values?.title,
    start_date: values?.start_date,
    total_amount: values?.amount?.toString(),
    end_date: values?.end_date,
    district_status: values?.district_id === 'all' ? '1' : '2',
  }
  if (values?.district_id !== 'all') {
    data = { ...data, district_id: values?.district_id }
  }
  // Add
  if (id) {
    api
      .patch(`/other-member-fund/${id}`, data, true)
      .then(async function ([success, response]: any) {
        toast.success("Other fund Details Updated ",{toastId:"8080"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"8081"});
        }
      });
  }
  // Edit
  else {
    api
      .post("/other-member-fund", data, true)
      .then(async function ([success, response]: any) {
        toast.success("Other fund Added ",{toastId:"8082"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"8083"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      total_amount: item?.total_amount,
      start_date: item?.start_date,
      end_date: item?.end_date,
      title: item?.title,
      district: item?.district ? item?.district?.name : "All Districts",
      is_publish: item?.is_publish,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      title: modalEditData?.[0]?.title,
      start_date: modalEditData?.[0]?.start_date,
      amount: modalEditData?.[0]?.total_amount,
      end_date: modalEditData?.[0]?.end_date,
      discrict_id: modalEditData?.[0]?.district?.id,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddOtherInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/other-member-fund/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"8084"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"8085"});
    });
};

// Department delete
export const ActiveClickHandler = (
  setIsopen: Function,
  refetch: Function,
  selectedId: any
) => {
  api
    .delete(
      `/other-member-fund/order-create`,
      { other_mem_fund_id: selectedId },
      true
    )
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Active",{toastId:"8086"});
      setIsopen(false);
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"8087"});
    });
};

export const setCurrentPageOtherfund = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/other-fund/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/other-fund/${pageNo}`)
  }
};

export const setCurrentMemberPageOtherfund = (
  navigate: Function,
  pageNo: any,
  id: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/other-fund-invite/${pageNo}/${id}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/other-fund-invite/${pageNo}/${id}`)
  }
};
