import { useEffect, useRef } from 'react';

// Custom hook to focus on an input element on load
const useFocusOnLoad = <T extends HTMLElement>() => {
    const inputRef: any = useRef<T | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return inputRef;
}

export default useFocusOnLoad;
