import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  AddPDDInitialValues,
  PDDValidationSchema,
} from "./AddPDDMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddPDDMethods/AddPDDModalMethods";
import Select from "react-select";
import { AddPDDProps } from "../../../../../../Type";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";

const AddPDD = ({ setIsOpen, modalEditData, refetch, crop }: AddPDDProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddPDDInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);

  // Define PEST_DISEASE_DEFICIENCY locally
  const PEST_DISEASE_DEFICIENCY = [
    { value: 1, name: "Pest" },
    { value: 2, name: "Disease" },
    { value: 3, name: "Deficiency" },
  ];

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={PDDValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Crop</Label>
                  <Select
                    placeholder="Select Crop"
                    name="crop"
                    options={crop}
                    value={
                      crop?.filter((itm: any) => itm?.value === values?.crop) ||
                      ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("crop", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Crop"}
                    noOptionsMessage={() => "Crop appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="crop" component={TextError} />
                </Col>
                <Col md={12}>
                  <div className="radio-sec-wrap">
                    {PEST_DISEASE_DEFICIENCY.map((item: any) => (
                      <div key={item.value}>
                        <Input
                          type="radio"
                          id={item.name}
                          name="type"
                          value={item.value}
                          checked={parseInt(values?.type) === item.value} // Ensure comparing as integers
                          onChange={(e) => {
                            setFieldValue("type", parseInt(e.target.value)); // Parse value to integer
                          }}                        />
                        <Label for={item.name}>{item.name}</Label>
                      </div>
                      
                    ))}
                  </div>
                  <ErrorMessage name="type" component={TextError} />

                </Col>
                <Col md={12}>
                  <Label>PEST/DISEASE/DEFICIENCY</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    name="pdd"
                    value={values?.pdd}
                    placeholder="PEST/DISEASE/DEFICIENCY"
                  />
                  <ErrorMessage name="pdd" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddPDD;
