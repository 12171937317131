import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddMagazineInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import axios from "axios";
import { Badge } from "reactstrap";

type AddInitialValues = {
  magazine_name: string;
  magazine_body: string;
  magazine_document: string;
  magazine_release:string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  imagefile: any
) => {
  // Add
  if (id) {
    const formData = new FormData();
    if (imagefile) {
      formData.append("document_file", values?.magazine_document);
    }

    formData.append("title", values?.magazine_name);
    formData.append("released_date", values?.magazine_release);
    formData.append("body", values?.magazine_body);
    axios({
      method: "patch",
      url: `${BaseUrl}/magazines/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Magazine Details Updated ",{toastId:"Magazine_updated"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Magazine_updated_error"});
        }
      });
  }
  // Add
  else {
    const formData = new FormData();
    formData.append("document_file", values?.magazine_document);
    formData.append("title", values?.magazine_name);
    formData.append("released_date", values?.magazine_release);
    formData.append("body", values?.magazine_body);
    axios({
      method: "post",
      url: `${BaseUrl}/magazines`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Magazine  Added",{toastId:"Magazine_Added"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Magazine_Added_error"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
 
  const res = data?.data?.map((item: any) => {
 
    return {
      id: item?.id,
      title: item?.title,
      body: item?.body,
      release:item?.released_date,
      document_file:item?.document_file,
      status_data:
        item?.is_published === 2 ? (
          <Badge>Unpublished</Badge>
        ) : (
          <Badge>Published</Badge>
        ),
      status: item?.is_published,

    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      magazine_name: modalEditData?.[0]?.title,
      magazine_body: modalEditData?.[0]?.body,
      magazine_release: modalEditData?.[0]?.released_date,
      magazine_document: modalEditData?.[0]?.document_file,
      
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddMagazineInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/magazines/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"magazines_deleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"magazines_deleted_error"});
    });
};
export const statusMagazineClickHandler = (
  setIsopen: Function,
  refetch: Function,
  selectedId: string | number,
  status: any
) => {
  api
    .patch(`/magazines/publish/${selectedId}`, { status: status }, true)
    .then(async function ([success, response]: any) {
      if (response?.success === true) {
        refetch();
        toast.success("Updated",{toastId:"magazines_publish"});
        setIsopen(false);
      }
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"magazines_publish_eror"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/magazine/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/magazine/${pageNo}`)
  }
};
