import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../../Api/Api";
import axios from "axios";
import { BaseUrl } from "../../../../../../../Api/BaseUrl";
import { AddHistoryInitialValues } from "./FormikValidation";

type AddInitialValues = {
  body: any;
  title: any;
};

export const HistorySubmitHandler = async (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  Image: string
) => {
  // Edit

  if (id) {
    api
      .patch(
        `/history/${id}`,
        {
          title: parseInt(values?.title),
          body: values?.body,
        },
        true
      )
      .then(async function ([success, response]: any) {
        refetch();
        toast.success("Updated",{toastId:"testimonialupdate"});
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Unexpected Error Occurred",{toastId:"testimonialupdaterr"});
        actions.setSubmitting(false);
      });
  } else {
    api
      .post(
        `/history`,
        {
          title: parseInt(values?.title),
          body: values?.body,
        },
        true
      )
      .then(async function ([success, response]: any) {
        refetch();
        toast.success("History added",{toastId:"Historyadded"});
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Unexpected Error Occurred",{toastId:"Historyadderr"});
        actions.setSubmitting(false);
      });
  }
};

// extract Departmentdata for table
export const extractTestimonialData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      designation: item?.designation,
      image: item?.image_url,
      name: item?.name,
      description: item?.description,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      body:modalEditData?.[0]?.body,
      title:modalEditData?.[0]?.title
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddHistoryInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  testimonialData: any
) => {
  setModalEditData([]);
  setModalEditData(
    testimonialData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/history/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"HistoryDeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Historydeleterrr"});
    });
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .patch(`/history/publish/${selectedId}`, { status: newStatus }, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated",{toastId:"Historypublish"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Historypublisherr"});
    });
};
export const setCurrentPageaward = (navigate: Function, pageNo: any) => {
  navigate(`/admin/staff/${pageNo}`);
};
