import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddMeetingsInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  MeetingStatus,
  convertISODateToDate,
  formatDateTime,
  formatDateTimeForInput,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import moment from "moment";

type AddInitialValues = {
  meeting_title: string;
  meeting_description: string;
  meeting_start_date: string;
  meeting_end_date: string;
  meeting_type: string;
  meeting_link: string;
};

export const MeetingSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (id) {
    api
      .patch(
        `/meetings/${id}`,
        {
          title: values?.meeting_title,
          type: values?.meeting_type,
          start_date: values?.meeting_start_date,
          end_date: values?.meeting_end_date,
          meeting_link: values?.meeting_link,
          description: values?.meeting_description,
        },
        true
      )
      .then(async function ([success, response]: any) {
        if (response?.success === true) {
          toast.success("Meeting Details Updated",{toastId:"meetingupdate"});
          setIsOpen(false);
          refetch();
        }
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"meetingupdateerror"});
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/meetings",
        {
          title: values?.meeting_title,
          type: values?.meeting_type,
          start_date: values?.meeting_start_date,
          end_date: values?.meeting_end_date,
          meeting_link: values?.meeting_link,
          description: values?.meeting_description,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Meeting Added ",{toastId:"meetingaadd"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"meetingaadderror"});
        }
      });
  }
};
export const InviteClickHandler = (
  data: any,
  refetch: Function,
  selectedId: any,
  value: any
) => {

  api
    .post(
      `/meetings/minutes/create`,
      {
        meeting_id: selectedId,
        district_ids: value?.district?.length !== 0 ? value?.district : [],
        role_ids: value?.role?.length !== 0 ? value?.role : [],
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Invited",{toastId:"meetinginvite"});
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"meetinginvite"});
    });
};
// extract Departmentdata for table
export const extractMeetingData = (data: any) => {
  const res = data?.items?.map((item: any) => {
    return {
      id: item?.id,
      meetings_type: MeetingStatus(item?.type),
      meetings_title: item?.title,
      meetings_end_date: formatDateTime(item?.end_date),
      meetings_start_date: formatDateTime(item?.start_date),
    };
  });
  return res;
};
export const extractMeetingMemberData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {

  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      meeting_title: modalEditData?.[0]?.title,
      meeting_type: modalEditData?.[0]?.type,
      meeting_start_date: formatDateTimeForInput(modalEditData?.[0]?.start_date),
      meeting_end_date: formatDateTimeForInput(modalEditData?.[0]?.end_date),
      meeting_link: modalEditData?.[0]?.meeting_link,
      meeting_description: modalEditData?.[0]?.description,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddMeetingsInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.items?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/meeting/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"meetingdelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"meetingdeleteerror"});
    });
};

export const setCurrentPageMeeting = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/meetings/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/meetings/${pageNo}`)
  }
};

export const setCurrentPageMeetingmember = (
  navigate: Function,
  pageNo: any,
  id: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/meeting-invite/${pageNo}/${id}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/meeting-invite/${pageNo}/${id}`)
  }
};
