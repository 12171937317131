import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddMeetingsInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

type AddInitialValues = {
  title: string;
  document_upload: string;
};

export const MeetingSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  const formData = new FormData();

  if (id) {

    formData.append("meeting_document", values.document_upload);

    axios({
      method: "patch",
      url: `${BaseUrl}/meeting-attendance/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Attendance Details Updated ",{toastId:"Attendanceupdated"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  } else {
    formData.append("meeting_document", values.document_upload);
    formData.append("meeting_id", values.title);

    axios({
      method: "post",
      url: `${BaseUrl}/meeting-attendance`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Attendance Added",{toastId:"AttendanceAdd"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"AttendanceAdderror"});
        }
      });
  }
};

export const InviteClickHandler = (
  data: any,
  refetch: Function,
  selectedId: any,
  value: any
) => {

  api
    .post(
      `/meetings/minutes/create`,
      {
        meeting_id: selectedId,
        district_ids: value?.district?.length !== 0 ? value?.district : [],
        role_ids: value?.role?.length !== 0 ? value?.role : [],
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Invited");
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
// extract Departmentdata for table
export const extractMeetingData = (data: any) => {
  const dropdownOptions = data?.items?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.title,
    };
  });
  return dropdownOptions || [];
};


export const extractMeetingMemberData = (data: any) => {
  const res = data?.data?.map((item: any) => {

    return {
      id: item?.id,
      title: item?.meeting?.title,
      document_upload:item?.meeting_document
    };
  });
  return res;
};


// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {

    setEditMode(true);
    setAddModalInitialValues({
        id: modalEditData?.[0]?.id,
        document_upload: modalEditData?.[0]?.meeting_document,
        title: modalEditData?.[0]?.meeting?.title,
    });
    
} else {
    setEditMode(false);
    setAddModalInitialValues(AddMeetingsInitialValues);
}

};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {

  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/meeting-attendance/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Attendancedeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Attendancedeletederror"});
    });
};

export const setCurrentPageMeeting = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/attandance/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/attandance/${pageNo}`)
  }
};

export const setCurrentPageMeetingmember = (
  navigate: Function,
  pageNo: any,
  id: any
) => {
  navigate(`/admin/meeting-invite/${pageNo}/${id}`);
};
