import { useState } from "react";
import { Container, Button, InputGroup, Input, Table, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import {
  ActiveClickHandler,
  setCurrentMemberPageOtherfund,
} from "../AddOtherfund/AddOtherfundMethods/AddOtherfundModalMethods";
import { handleMmeberOtherfundSearch } from "../OtherfundMethods";
import { getOtherfundMemberList } from "../../../../../Api/AdminApis";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import DeleteAlertModal from "../../../../../Components/Containers/AlertModal/DeleteAlertModal";
import { getPaymentStatus, sentPaymentLinkToAll } from "./MemberListMethods";
import { DateFormatter } from "../../../../../Utils/helpers/helpers";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";
import ConfirmOtherAlertModal from "./ConfirmModal";

const OtherfundMemberIndex = () => {
  const { currentPage = "1", id } = useParams();
  const navigate = useNavigate();
  const { checkPermission } = usePermission();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState({
    role: [""],
    district: [""],
  });
  const [open, setOpen] = useState(false);
  const {
    data: members,
    error: membersError,
    isLoading: membersLoading,
    refetch: membersListRefetch,
  }: any = useQuery(
    [
      `members${currentPage + searchQuery + id}`,
      currentPage,
      searchQuery,
      value,
      id,
    ],
    () => getOtherfundMemberList(currentPage, searchQuery, value, id),
    { keepPreviousData: true }
  );

  return (
    <>
      <section>
        <div className="content-outer-wrap">
          <Container>
            <div className="search-bar-full-wrap">
              <Col md={2}>
                <div className="search-bar">
                  <InputGroup>
                    <Input
                      placeholder="Search here..."
                      onChange={(e) =>
                        handleMmeberOtherfundSearch(e, setSearchQuery, navigate)
                      }
                    />
                    <span className="icon-container">
                      <i className="icon xl icon-search"></i>
                    </span>
                  </InputGroup>
                </div>
              </Col>

              <div className="right-side-wrap">
                {/* <Button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      color="primary"
                      className="sm"
                    >
                      Active
                    </Button> */}
                {checkPermission("other-member-fund:write") ? (
                  <div className="button-wrap">
                    <Button
                      onClick={() => setOpen(true)}
                      color="primary"
                      className="sm"
                      disabled={members?.items?.length === 0 ? true : false}
                    >
                      Send Payment Link
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>

            {/* cards section */}
            <div className="content-list-outer-wrap">
              <div className="table-outer-wrap">
                <div className="table-head-outer">
                  <div className="table-heading">
                    <h6>Member Details</h6>
                  </div>
                </div>
                <div className="table-outer">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Member Name</th>
                        <th>Member Office</th>
                        <th>Member Type</th>
                        <th>District</th>

                        <th>Payment Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {members?.items?.length !== 0 ? (
                        <>
                          {members?.items?.map((item: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>{item?.user_id}</td>
                                  <td>
                                    {item?.first_name} {item?.last_name}
                                  </td>
                                  <td>{item?.office_name}</td>

                                  <td>
                                    {item?.membership_type === 1
                                      ? "Annual Member"
                                      : item?.membership_type === 2
                                      ? "Assosciate Member"
                                      : item?.membership_type === 3
                                      ? "Lifetime Type"
                                      : "Unknown Membership Type"}
                                  </td>

                                  <td>{item?.district_name}</td>

                                  <td>
                                    {item?.payment_date
                                      ? DateFormatter(item?.payment_date)
                                      : "-"}
                                  </td>
                                  <td>
                                    {getPaymentStatus(item?.payment_status)}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <td colSpan={12} className="empty-text">
                          No Data
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Container>

          {members?.meta?.totalItems !== 0 && (
            <div className="pagination">
              <CustomPagination
                totalItems={members?.meta?.totalItems}
                itemsPerPage={members?.meta?.itemsPerPage}
                activePage={members?.meta?.currentPage}
                setCurrentPage={(pageNo: string) =>
                  setCurrentMemberPageOtherfund(navigate, pageNo, id, pathname)
                }
              />
            </div>
          )}
        </div>
      </section>
      <ModalContainer isOpen={open} setIsopen={setOpen} title="">
        <ConfirmOtherAlertModal
          setIsopen={setOpen}
          Refetch={membersListRefetch}
          id={id}
          type="other_fund"
          sentPaymentLinkToAll={sentPaymentLinkToAll}
        />
      </ModalContainer>
    </>
  );
};

export default OtherfundMemberIndex;
