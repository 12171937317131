import * as Yup from "yup";

export const ServicesValidationSchema = Yup.object().shape({
  image: Yup.string().required("Service file is required"),
  title: Yup.string()
    .required("Title is required")
    .max(100, "The Title must not exceed 100 characters."),
});

export const AddServicesInitialValues = {
  image: "",
  title: "",
};
