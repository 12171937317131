import * as Yup from "yup";

export const NewsValidationSchema = Yup.object().shape({
  title: Yup.string().required("Feild is required"),
  content:Yup.string().required("Feild is required"),
});

export const AddNewsInitialValues = {
  title: "",
  content:""
};
