import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query'; // Import the necessary components
import { SearchProvider } from './Context/ContextProvider';

const queryClient = new QueryClient(); // Create a QueryClient instance

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SearchProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </SearchProvider>
  </React.StrictMode>
);
