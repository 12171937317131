import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import * as Yup from "yup";

export const handleAboutUsSubmit = (values: any, actions: any, setIsOpen: Function, refetch: Function, editMode: boolean) => {
    if (editMode) {
        const id = values?.id
        api
            .patch(`/ourmission/${id}`, values, true)
            .then(async function ([success, response]: any) {
                refetch()
                setIsOpen(false)
                toast.success("Updated Successfully",{toastId:"missionupdate"})
            })
            .catch((err) => {
                if (err?.response?.data?.message?.[0]?.body) {
                    actions?.setFieldError("body", err?.response?.data?.message?.[0]?.body)
                }
                toast.error("Unexpected Error Occurred",{toastId:"missionupdaterr"});
                actions.setSubmitting(false);
            });
    }
    else {
        api
            .post(`/ourmission`, values, true)
            .then(async function ([success, response]: any) {
                refetch()
                setIsOpen(false)
                toast.success("Added Successfully",{toastId:"missionadded"})
            })
            .catch((err) => {
                toast.error("Unexpected Error Occurred",{toastId:"missionadderr"});
                actions.setSubmitting(false);
            });
    }
}

export async function getAboutUsData(currentPage: string) {
    const [, data] = await api.get(`/ourmission?page=${currentPage}&perPage=10`, true);
    return data?.data
}

export const onAboutEditClickHandler = (
    selectedId: any,
    setModalEditData: Function,
    setIsOpen: Function,
    data: any
) => {
    setModalEditData([]);
    setModalEditData(
        data?.data?.filter((itm: any) => itm?.id === selectedId)
    );
    setIsOpen(true);
};

export const handleAboutUsInitializer = (setAboutUsInitialValue: Function, modalEditData: any, AboutUsValues: any) => {
    if (modalEditData?.length !== 0) {
        setAboutUsInitialValue({
            id: modalEditData?.[0]?.id,
            title: modalEditData?.[0]?.title,
            body: modalEditData?.[0]?.body
        })
    }
    else {
        setAboutUsInitialValue(AboutUsValues)
    }
}

export const aboutStatusClickHandler = (
    selectedId: string | number,
    refetch: Function,
    newStatus: number
) => {
    api
        .patch(`/ourmission/publish/${selectedId}`, { status: newStatus }, true)
        .then(async function ([success, response]: any) {
            refetch();
            toast.success("Updated",{toastId:"missionpublish"});
        })
        .catch((err) => {
            toast.error("Unexpected Error Occurred",{toastId:"missionpublisherr"});
        });
};

export const aboutDeleteClickHandler = (
    selectedId: string | number,
    refetch: Function
) => {
    api
        .delete(`/ourmission/${selectedId}`, {}, true)
        .then(async function ([success, response]: any) {
            refetch();
            toast.success("Deleted",{toastId:"missiondelete"});
        })
        .catch((err) => {
            toast.error("Unexpected Error Occurred",{toastId:"missiondeleterr"});
        });
};

export const aboutUsValidationSchema = Yup.object().shape({
    title: Yup.string().required("Field  is required"),
    body: Yup.string()
        .required("Field is required")
        .min(10, "The  minimmum  10 characters."),
});