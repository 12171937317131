import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddPrescription/AddPrescriptionMethods/AddPrescriptionsModalMethods";
import AddPrescription from "./AddPrescription";
import {
  getCropCategoryDetList,
  getCropCategoryFullList,
  getCropFullList,
  getDistrict,
  getPrecriptionPreview,
  getPrescriptionList,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./PrescriptionsMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { ROLES_PERMISSIONS } from "../../../../Utils/Constants/ROLES_PERMISSIONS";
import EditPrescription from "./AddPrescription/Edit";

const PrescriptionIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [pdd_id, setPDDID] = useState();
  const [pddType,setPddType] = useState()
  const [pdd_id_det, setPDDIDSet] = useState();
  const [pddTypeDet,setPddTypeDet] = useState();
  const [prescription_is, setPrescriptionId] = useState();
  const [editisOpen, setEditIsOpen] = useState(false);
  const { checkPermission } = usePermission();

  const {
    data: prescription,
    error: prescriptionError,
    isLoading: prescriptionLoading,
    refetch: prescriptionListRefetch,
  }: any = useQuery(
    [`prescription${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getPrescriptionList(currentPage, searchQuery),
    { keepPreviousData: true }
  );
  const {
    data: crop,
    error: cropError,
    isLoading: cropLoading,
    refetch: cropListRefetch,
  }: any = useQuery([`crop`], () => getCropFullList(), {
    keepPreviousData: true,
  });
  // const {
  //   data: cropPDD,
  //   error: cropPDDError,
  //   isLoading: cropPDDLoading,
  //   refetch: cropPDDListRefetch,
  // }: any = useQuery(
  //   [`cropPDD${pdd_id}${pddType}`, pdd_id, pddType], // Pass both pdd_id and type
  //   () => (pdd_id && pddType ? getCropCategoryFullList(pdd_id, pddType) : ""),
  //   {
  //     keepPreviousData: true,
  //   }
  // );
  
  
  
  const {
    data: cropsCategory,
    error: cropsCategoryPDDError,
    isLoading: cropsCategoryLoading,
    refetch: cropsCategoryListRefetch,
  }: any = useQuery(
    [`cropsCategory${pdd_id}${pddTypeDet}`, pdd_id,pddTypeDet],
    () => (pdd_id && pddTypeDet ? getCropCategoryDetList(pdd_id,pddTypeDet) : ""),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: districtList,
    error: districError,
    isLoading: districtLoading,
    refetch: districtListRefetch,
  }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });

  const {
    data: prescription_view,
    error: prescription_viewError,
    isLoading: prescription_viewLoading,
    refetch: prescription_viewRefetch,
  }: any = useQuery(
    [`prescription_view${prescription_is}`, prescription_is],
    () => (prescription_is ? getPrecriptionPreview(prescription_is) : ""),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(
    () => extractStaffData(prescription),
    [prescription]
  );

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {localStorage.getItem('ROLE') === 'member' && checkPermission(ROLES_PERMISSIONS?.PRESCRIPTION?.WRITE) ? (
                  <div className="button-wrap">
                    <Button
                      onClick={() =>
                        modalHandler(
                          setModalEditData,
                          setIsOpen,
                          setPDDID,
                          setPDDIDSet,
                          setPrescriptionId
                        )
                      }
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i> Prescription
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Prescription Details"
              tableFields={["Farmer Name", "Farmer Phone", "Smart ID", "district", "Crop"]}
              Arraykeys={["farmer_name", "farmer_phone", "smart_id", "district", "crop"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission(ROLES_PERMISSIONS?.PRESCRIPTION?.WRITE)
                  ? "edit"
                  : "",
                checkPermission(ROLES_PERMISSIONS?.PRESCRIPTION?.DELETE)
                  ? "delete"
                  : "",
              ]}
              isLoading={prescriptionLoading}
              error={prescriptionError}
              onEditClick={(selectedId: any) => {
                if (checkPermission(ROLES_PERMISSIONS?.PRESCRIPTION?.WRITE)) {
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setEditIsOpen,
                    prescription
                  )  
                }
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, prescriptionListRefetch)
              }
              paginationData={prescription?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
              from_pre={
                checkPermission(ROLES_PERMISSIONS?.PRESCRIPTION?.VIEW)
                  ? true
                  : true
              }
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={"Add Prescription"}
      >
        <AddPrescription
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={prescriptionListRefetch}
          crop={crop}
          setPDDID={setPDDID}
          setPddType={setPddType}
          setPddTypeDet={setPddTypeDet}
          // cropPDD={cropPDD || []}
          cropsCategory={cropsCategory || []}
          setPDDIDSet={setPDDIDSet}
          setPrescriptionId={setPrescriptionId}
          prescription_view={prescription_view}
          districtList={districtList}       />
      </ModalContainer>
      <ModalContainer
        isOpen={editisOpen}
        setIsopen={setEditIsOpen}
        title={"Edit Prescription"}
      >
        <EditPrescription
          setIsOpen={setEditIsOpen}
          modalEditData={modalEditData}
          refetch={prescriptionListRefetch}
          crop={crop}
          setPDDID={setPDDID}
          setPddType={setPddType}
          setPddTypeDet={setPddTypeDet}
          // cropPDD={cropPDD || []}
          cropsCategory={cropsCategory || []}
          setPDDIDSet={setPDDIDSet}
          setPrescriptionId={setPrescriptionId}
          prescription_view={prescription_view}
          districtList={districtList}       />
      </ModalContainer>
    </section>
  );
};

export default PrescriptionIndex;
