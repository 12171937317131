import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddTransferInitialValues,
  TransferValidationSchema,
} from "./AddTransferRequestMethods/FormikValidation";
import {
  addModalInitialValuesHandler,
  transferSubmitHandler
} from "./AddTransferRequestMethods/AddTransferRequestModalMethods";
import {
  getBlock,
  getDistrict,
  getPanchayat,
  getPermissionAllList,
  transferGetDesignation,
  transferGetOffice,
} from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { TRANSIT_TYPE } from "../../../../../Utils/Constants/selection_options";

const AddTransferRequest = ({
  setIsOpen,
  modalEditData,
  refetch,
  office,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddTransferInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
    setValue({
      ...value,
      office: modalEditData?.office?.id ? modalEditData?.office?.id: "",
      block: modalEditData?.block?.id ? modalEditData?.block?.id : "",
      district: modalEditData?.district?.id ? modalEditData?.district?.id : "",
    });
  }, [modalEditData]);

  

  const [value, setValue] = useState({
    district: "",
    block: addModalInitialValues?.district
      ? addModalInitialValues?.district
      : "",
    office: addModalInitialValues?.block
      ? addModalInitialValues?.block
      : "",
  });
  
  const {
    data: districtList,
  }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });

  const {
    data: designationListForTransfer,
  }: any = useQuery([`designation`, value?.district], () => (value?.district ? transferGetDesignation(value?.district) : ""), {
    keepPreviousData: true,
  });

  const {
    data: blockList,
  }: any = useQuery(
    [`block${value?.district}`, value?.district],
    () => (value?.district ? getBlock(value?.district) : ""),
    {
      keepPreviousData: true,
    }
  );
  const {
    data: panchaythList,
  }: any = useQuery(
    [`panchayth${value?.block}`, value?.block],
    () => (value?.block ? getPanchayat(value?.block) : ""),
    {
      keepPreviousData: true,
    }
  );
  const { data: OfficeList }: any = useQuery(
    [`transferOffice${value?.district}${value?.block}`, value?.district, value?.block],
    () => (value?.district && value?.block ? transferGetOffice(value?.district, value?.block) : ""),
    {
      keepPreviousData: true,
    }
  );

  const { data: permissionList }: any = useQuery(
    [`permission`],
    () => getPermissionAllList(),
    {
      keepPreviousData: true,
      onSuccess: (data) => setAddModalInitialValues((prev) => {
        return { ...prev, office: data?.member?.office?.name, office_id: data?.member?.office?.id }
      })
    }
  );

  return (
    <Formik
      validationSchema={TransferValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        transferSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        errors
      }) => {

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Current Office</Label>
                  <Input
                    type="text"
                    name="current office"
                    value={values?.office}
                    disabled
                    onChange={(e) => {
                      setFieldValue("office", e.target.value);
                    }}
                  />

                  <ErrorMessage name="office" component={TextError} />
                </Col>
                <p>Transfer To </p>
                <Col md={6}>
                  <Label>District</Label>
                  <Select
                    placeholder="Select District"
                    name="district"
                    options={districtList ? districtList : []}
                    value={
                      districtList
                        ? districtList?.filter(
                          (itm: any) => itm?.value === values?.district
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("district", e?.value);
                      setValue({
                        ...value,
                        district: values?.district
                          ? values?.district
                          : e?.value,
                      });
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching District"}
                    noOptionsMessage={() => "District appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="district" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Block</Label>
                  <Select
                    placeholder="Select Block"
                    name="block"
                    options={blockList ? blockList : []}
                    value={
                      blockList
                        ? blockList?.filter(
                          (itm: any) => itm?.value === values?.block
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("block", e?.value);
                      setValue({
                        ...value,
                        block: values?.block ? values?.block : e?.value,
                      });
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Block"}
                    noOptionsMessage={() => "Block appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="block" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Office</Label>
                  <Select
                    placeholder="Select Office"
                    name="to_office"
                    options={OfficeList ? OfficeList : []}
                    value={
                      OfficeList
                        ? OfficeList?.filter((item: any) => item.value === values?.to_office)
                        : null
                    }
                    onChange={(e: any) => {
                      setFieldValue("to_office", e?.value);
                    }}

                    isLoading={false}
                    loadingMessage={() => "Fetching Office"}
                    noOptionsMessage={() => "Office appears here"}
                    isSearchable={true}
                    isClearable={true}
                  />
                  <ErrorMessage name="to_office" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Transfer Type</Label>
                  <Select
                    placeholder="Select Transfer Type"
                    name="type"
                    options={TRANSIT_TYPE ? TRANSIT_TYPE : []}
                    value={
                      TRANSIT_TYPE
                        ? TRANSIT_TYPE?.filter(
                          (itm: any) => itm?.value === values?.type
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("type", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Transfer Type"}
                    noOptionsMessage={() => "Transfer Type appears here"}
                    isSearchable={true}
                    isClearable={true}
                  />
                  <ErrorMessage name="type" component={TextError} />
                </Col>

                {Number(values?.type) === 2 &&
                  <Col md={6}>
                    <Label>Designation</Label>
                    <Select
                      placeholder="Select Designation"
                      name="type"
                      options={designationListForTransfer ? designationListForTransfer : []}
                      value={
                        designationListForTransfer
                          ? designationListForTransfer?.filter(
                            (itm: any) => itm?.value === values?.designation
                          )
                          : ""
                      }
                      onChange={(e: any) => {
                        setFieldValue("designation", e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Designation"}
                      noOptionsMessage={() => "Designation appears here"}
                      isSearchable={true}
                      isClearable={true}
                    />
                    <ErrorMessage name="designation" component={TextError} />
                  </Col>}

                <Col md={6}>
                  <Label>GO Order</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.GO_order}
                    name="GO_order"
                    placeholder="GO Order"
                  />
                  <ErrorMessage name="GO_order" component={TextError} />

                </Col>
                <Col md={6}>
                  <Label>GO Date</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    value={values?.GO_order_date}
                    name="GO_order_date"
                  // placeholder="GO Order"

                  />
                  <ErrorMessage name="GO_order_date" component={TextError} />
                </Col>
                {/* <Col lg={6}>
                  <Label>Reason</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    value={values?.reason}
                    name="reason"
                    placeholder="Reason"
                  />
                  <ErrorMessage name="reason" component={TextError} />
                </Col> */}

                {/* <Col lg={6}>
                  <Label>Description</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    value={values?.description}
                    name="description"
                    placeholder="Description"
                  />
                  <ErrorMessage name="description" component={TextError} />
                </Col> */}

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddTransferRequest;
