import React, { useState, useEffect } from "react";
import { ErrorMessage, FieldArray } from "formik";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import TextError from "../../../../../../Components/FormikError/TextError";

interface SubCategory {
  label: any;
  description: string;
  parent: string;
  document: any;
}
interface SubId {
  product_id: string;
  spec_meta_id: string;
  key: string;
  value: string;
}
const Subcategory = (props: any) => {
  const [subcategory, setSubcategory] = useState<SubCategory[]>([]);
  const [data, setData] = useState<any>([]);

  const handleRemove = (index: number) => {
    const filteredSpec = subcategory?.filter((_, i) => i !== index);
    setSubcategory(filteredSpec);
    props?.setFieldValue("sub_category", filteredSpec);
  };

  useEffect(() => {
    if (props?.isOpen === false) {
      setSubcategory([]);
      setData([]);
      props?.setFieldValue("sub_category", "");
    } else {
      setSubcategory(props?.subcategory);
    }
  }, [props?.subcategory, props?.isOpen]);

  const handleValueChange = (index: number, field: any, e: any) => {
    const { value } = e.target;
    const { files } = e.target;
    setSubcategory((prevSubcategory) => {
      const newList = [...prevSubcategory];
      if (field === "description") {
        newList[index] = {
          ...newList[index],
          description: field === "description" ? value : newList[index]?.description
        };
      }
      if (field === "document") {
        newList[index] = {
          ...newList[index],
          document: field === "document" ? files : newList[index]?.document
        };
      }
      props?.setFieldValue("sub_category", newList);
      return newList;
    });
  };

  return (
    <>
      <FieldArray name="specifications">
        {(arrayHelpers: any) => (
          <div className="items-outer p-0">
            {subcategory?.map((item: any, index: any) => {
              return (
                <div key={index} className="item">
                  <Row className="gy-4">
                    <Col md={12}>
                      <div key={index} className="form-group-wrap">
                        <FormGroup className="form-group">
                          <Label>
                            <b>{item?.label}</b>
                          </Label>
                          <Input
                            name={`sub_category.${index}.description`}
                            type="text"
                            value={item?.description}
                            onChange={(e) => {
                              handleValueChange(index, "description", e);
                            }}
                          />
                          {item?.status === 1 && (
                            <>
                              {" "}
                              <Input
                              className="my-3"
                                name={`sub_category.${index}.document`}
                                type="file"
                                // value={item?.document}
                                onChange={(e) => {
                                  handleValueChange(index, "document", e);
                                }}
                              />
                            </>
                          )}
                          {item?.document_file_url?.includes("pdf") ? (
                            <Col md={6} className="my-3">
                              <embed src={item?.document_file_url} />
                            </Col>
                          ) : (
                            <img
                              src={item?.document_file_url}
                              alt={item?.document_file_url}
                            />
                          )}

                          <ErrorMessage
                            name={`sub_category.${index}.description`}
                            component={TextError}
                          />
                        </FormGroup>
                        <div className="close-icon-wrap">
                          <i
                            onClick={() => handleRemove(index)}
                            className="icon icon-close-red"
                          ></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                </div>
              );
            })}
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default Subcategory;
