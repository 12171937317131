import * as Yup from "yup";

export const MagazineValidationSchema = Yup.object().shape({
  magazine_name: Yup.string().required("Field is required"),
  magazine_body: Yup.string().required("Field is required"),
  magazine_document: Yup.string().required("Field is required"),
  magazine_release:Yup.string().required("Field is required"),
});

export const AddMagazineInitialValues = {
  magazine_name: "",
  magazine_body: "",
  magazine_document: "",
  magazine_release:""
};
