import { Col, Container, Row, Table } from "reactstrap";
import { MyPayment } from "../../../../../Type/index";

const Myotherfund = ({ memberPaymentData }: MyPayment) => {


  return (
    <>
      <div className="content-list-outer-wrap">
            <Col md={12}>
              <div className="table-outer-wrap member-profile-table-wrap">
                <h6>My other fund Details</h6>
                <div className="table-outer">
                  <Table>
                      <thead>
                        <tr>
                          <th>Total Amount</th>
                          <th>Payment Gateway</th>
                          <th>Transaction ID</th>
                          <th>Payment Date</th>
                          <th>Order Status</th>
                        </tr>
                      </thead>
                      <tbody>

                      {memberPaymentData?.otherMemberPaymentDetails.map((paymentDetail:any, index:any) => (
                        <tr key={index}>
                          <td>{paymentDetail.total_amount}</td>
                          <td>{paymentDetail.payment_gateway === 1 ? 'WorldLine' : 'Another'}</td>
                          <td>{paymentDetail.transaction_id}</td>
                          <td>{paymentDetail.payment_date}</td>
                          <td>{paymentDetail.order_status === 1 ? 'Paid' : 'Not Paid'}</td>
                        </tr>
                      ))}
                      
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
      </div>
    </>
  );
};

export default Myotherfund;
