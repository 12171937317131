import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddPrescriptionInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

type AddInitialValues = {
  farmer_name: string;
  farmer_mobile: string;
  farmer_address: string;
  crops: string;
  // pest_disease_deficiency: string;
  name_of_pest_disease_deficiency: string;
  symptoms: any;
  control_measures: any;
  smart_id: string;
  control_measures_new: any;
  symptoms_new: any;
  district: any;
  type: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  setPrescriptionId: any,
  selected_syptoms_array: any,
  selected_control_array: any,
  selected_syptoms_new_array: any,
  selected_control_new_array: any
) => {

  // symptoms
  const concatenatedArray =
    selected_syptoms_array?.length === 0
      ? []
      : selected_syptoms_array?.concat(selected_syptoms_new_array);
;

  let checkValues = concatenatedArray?.filter(
    (item: any) => item?.checked === true
  );

  const symptomsArray = checkValues?.map((item: any) => item.symptoms);
  const combinedSymptoms = symptomsArray?.join("\n");

  // control measure
  // renamed to control_measure
  const control_measures_array = selected_control_new_array.map((item: any) => ({
    control_measure: item.control_measures,
    checked: item.checked
  }));

  const concatenatedControlArray =
    selected_control_array?.length === 0
      ? []
      : selected_control_array?.concat(control_measures_array);


  let checkValuesControl = concatenatedControlArray?.filter((item: any) => item?.checked === true);

  const controlArray = checkValuesControl?.map((item: any) => item?.control_measure
);  

  const combinedControl = controlArray?.join("\n");
  
  api
    .post(
      "/prescription",
      {
        smart_id: values?.smart_id,
        farmer_name: values?.farmer_name,
        farmer_phone: values?.farmer_mobile,
        farmer_address: values?.farmer_address,
        crop: parseInt(values?.crops),
        // cropCategory: parseInt(values?.pest_disease_deficiency),
        cropDetails: parseInt(values?.name_of_pest_disease_deficiency),
        symptoms: combinedSymptoms,
        control_measure: combinedControl,
        district_id: Number(values?.district)
      },
      true
    )
    .then(async function ([success, response]: any) {
      if (response?.statusCode === 201) {
        toast.success("Crop Details Added ",{toastId:"Crop_added"});
        setIsOpen(false);
        refetch();
        actions?.setSubmitting(false);
        setPrescriptionId(response?.data);
      }
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"Crop_error"});
      }
    });
};
export const EditSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  const edit_control = values?.control_measures?.filter(
    (item: any) => item?.checked === true
  );

  const controlArray_final = edit_control?.map(
    (item: any) => item.control_measures
  );
  const edit_control_join = controlArray_final?.join("\n");
  const edit_symptoms = values?.symptoms?.filter(
    (item: any) => item?.checked === true
  );
  const symptomsArray_final = edit_symptoms?.map((item: any) => item.symptoms);
  const edit_symptoms_join = symptomsArray_final?.join("\n");

  // Add

  api
    .patch(
      `/prescription/${id}`,
      {
        smart_id: values?.smart_id,
        farmer_name: values?.farmer_name,
        farmer_phone: values?.farmer_mobile,
        farmer_address: values?.farmer_address,
        crop: parseInt(values?.crops),
        // cropCategory: parseInt(values?.pest_disease_deficiency),
        cropDetails: parseInt(values?.name_of_pest_disease_deficiency),
        symptoms: edit_symptoms_join,
        control_measure: edit_control_join,
        district_id: Number(values?.district)
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Crop Details Updated",{toastId:"Crop_updated"});
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"Crop_updated_error"});
      }
    });
};


export const extractStaffData = (data: any) => {
  if (!data || !data.data || !Array.isArray(data.data)) {
    return [];
  }
  const res = data.data.map((item: any) => {
    return {
      id: item?.id,
      farmer_name: item?.farmer_name,
      farmer_phone: item?.farmer_phone,
      smart_id: item?.smart_id,
      crop: item?.crop?.name,
      district: item?.district?.name,
    };
  });
  return res;
};


// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  cropsCategory?: any
) => {

  setEditMode(false);
  let symptoms_array: any = [];
  let control_array: any = [];
  cropsCategory?.symptoms?.split("\n")?.map((item: any) => {
    symptoms_array?.push({
      symptoms: item,
      checked: true,
    });
  });

  cropsCategory?.control_measure?.split("\n")?.map((item: any) => {
    control_array?.push({
      control_measure: item,
      checked: true,
    });
  });

  setAddModalInitialValues({
    farmer_name: "",
    farmer_mobile: "",
    farmer_address: "",
    type:"",
    crops: cropsCategory?.crop?.id,
    pest_disease_deficiency: cropsCategory?.cropsCategory?.id,
    name_of_pest_disease_deficiency: cropsCategory?.id,
    symptoms: [],
    control_measures: [],
    symptoms_new: symptoms_array ? symptoms_array : [],
    control_measures_new: control_array ? control_array : [],
    smart_id: "",
    district: ""
  });
};
export const editModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  cropsCategory?: any
) => {

  setEditMode(true);
  let symptoms_array: any = [];
  let control_array: any = [];
  modalEditData?.[0]?.symptoms?.split("\n")?.map((item: any) => {
    symptoms_array?.push({
      symptoms: item,
      checked: true,
    });
  });

  modalEditData?.[0]?.control_measure?.split("\n")?.map((item: any) => {
    control_array?.push({
      control_measures: item,
      checked: true,
    });
  });

  setAddModalInitialValues({
    id: modalEditData?.[0]?.id,
    farmer_name: modalEditData?.[0]?.farmer_name,
    farmer_mobile: modalEditData?.[0]?.farmer_phone,
    farmer_address: modalEditData?.[0]?.farmer_address,
    type: modalEditData?.[0]?.cropDetails?.type,
    crops: modalEditData?.[0]?.crop?.id,
    pest_disease_deficiency: modalEditData?.[0]?.cropCategory?.id,
    name_of_pest_disease_deficiency: modalEditData?.[0]?.cropDetails?.id,
    symptoms: symptoms_array,
    control_measures: control_array,
    symptoms_new: [],
    control_measures_new: [],
    smart_id: modalEditData?.[0]?.smart_id,
    district: modalEditData?.[0]?.district?.id || ""
  });

};
// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function,
  setPDDID: Function,
  setPDDIDSet: Function,
  setPrescriptionId: Function
) => {
  setModalEditData([]);
  setPDDID("");
  setPDDIDSet("");
  setPrescriptionId("");
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/prescription/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId: "prescription_delete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};


export const downloadPrescription = (id: any) => {
  const authToken = localStorage.getItem("AUTH_TOKEN");

  fetch(`${BaseUrl}/prescription/prescription-download/${id}`, {
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Prescription.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
     return blob;
    })
    .catch((error) => {
      console.error(error, ">>>");
    });
};


export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/prescriptionmanagement/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/prescription/${pageNo}`)
  }
};

