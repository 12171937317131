import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../../Api/Api";
import axios from "axios";
import { BaseUrl } from "../../../../../../../Api/BaseUrl";
import { AddNewsInitialValues } from "./FormikValidation";

type AddInitialValues = {
  title: any;
  content:any;
};


export const TestimonialSubmitHandler = async (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,

) => {
  // Edit

  if (editMode) {
    api
      .patch(`/news/${id}`, values, true)
      .then(async function ([success, response]: any) {
        refetch();
        toast.success("News Updated",{toastId:"NewsUpdated"});
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Unexpected Error Occurred");
      });
  } else {
    api
      .post(`/news`, values, true)
      .then(async function ([success, response]: any) {
        refetch();
        toast.success("News Added",{toastId:"NewsAdded"});
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Unexpected Error Occurred");
      });
    // add
  }
};


// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      title: modalEditData?.[0]?.title,
      content:modalEditData?.[0]?.content

    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddNewsInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  testimonialData: any
) => {
  setModalEditData([]);
  setModalEditData(
    testimonialData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/news/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .patch(
      `/news/publish/${selectedId}`,
      {
        status: newStatus,
      },
      true
    )
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
export const setCurrentPageaward = (navigate: Function, pageNo: any) => {
  navigate(`/admin/staff/${pageNo}`);
};
