import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { Col, Container, Form, Input, InputGroup, Row } from "reactstrap";
import {
  ResetPasswordValidationSchema,
  handlePasswordReset,
} from "./ChangePasswordMethods";
import TextError from "../../../../Components/FormikError/TextError";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showOldPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const breadcrumbItems = [
    { label: "Settings", link: "/member/settings" },
    { label: "Change Password", link: "/member/change-password" },
  ];
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <>
    <Container>
      <Row className="mt-5 justify-content-center">
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <Col lg={4} md={6} className="login-form-outer-wrap">
          <div className="login-form-wrap">
            <Formik
              validationSchema={ResetPasswordValidationSchema}
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmpassword: "",
              }}
              onSubmit={(values, actions) => handlePasswordReset(values, actions)}
            >
              {({ handleSubmit, handleChange, isSubmitting, values }) => {

                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-inner-wrap password-table">
                      <Row className="gy-4">
                        <Col lg={12}>
                          <InputGroup>
                            <Input
                              type={showOldPassword ? "text" : "password"}
                              placeholder="Old Password"
                              name="oldPassword"
                              onChange={handleChange}
                              value={values?.oldPassword}
                            />
                            <span
                              className="input-icon"
                              onClick={toggleOldPasswordVisibility}
                            >
                              {showOldPassword ? (
                                <i className="icon icon-eye-open xl"></i>
                              ) : (
                                <i className="icon icon-eye-close xl"></i>
                              )}
                            </span>
                          </InputGroup>
                          <ErrorMessage name="oldPassword" component={TextError} />
                        </Col>
                        <Col lg={12}>
                          <InputGroup>
                            <Input
                              type={showNewPassword ? "text" : "password"}
                              placeholder="New Password"
                              name="newPassword"
                              onChange={handleChange}
                              value={values?.newPassword}
                            />
                            <span
                              className="input-icon"
                              onClick={toggleNewPasswordVisibility}
                            >
                              {showNewPassword ? (
                                <i className="icon icon-eye-open xl"></i>
                              ) : (
                                <i className="icon icon-eye-close xl"></i>
                              )}
                            </span>
                          </InputGroup>
                          <ErrorMessage
                            name="newPassword"
                            component={TextError}
                          />
                        </Col>
                        <Col lg={12}>
                          <InputGroup>
                            <Input
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm New Password"
                              name="confirmpassword"
                              onChange={handleChange}
                              value={values?.confirmpassword}
                            />
                            <span
                              className="input-icon"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? (
                                <i className="icon icon-eye-open xl"></i>
                              ) : (
                                <i className="icon icon-eye-close xl"></i>
                              )}
                            </span>
                          </InputGroup>
                          <ErrorMessage
                            name="confirmpassword"
                            component={TextError}
                          />
                        </Col>
                        <Col lg={12}>
                          <div className="submit-btn-wrap">
                            <CustomButton
                              label="Save"
                              color={"primary"}
                              className="sm"
                              isLoading={isSubmitting}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
    </>

  );
};

export default ChangePassword;
