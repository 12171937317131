import { useQuery } from "react-query";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import { getPrecriptionPreview } from "../../../../../Api/AdminApis";
import {
  convertISODateToDate,
  validateDatetotime,
} from "../../../../../Utils/helpers/helpers";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function PreviewModal(props: any) {

  const { data: crop_details_view }: any = useQuery(
    [`crop-details${props?.preview_id}`, props?.preview_id],
    () => (props?.preview_id ? getPrecriptionPreview(props?.preview_id) : ""),
    {
      keepPreviousData: true,
    }
  );
  const currentDate = new Date();
  const TextConverter = ({ inputText }: any) => {
    // Split the input text into lines
    const lines = inputText?.split("\n");

    // Map through the lines and format them as "1,2" format
    const formattedText = lines?.map((line: any, index: any) => (
      <div key={index}>{`${index + 1} . ${line}`}</div>
    ));

    return <div>{formattedText}</div>;
  };
 
  const downloadPDF = () => {
    const input: any = document.getElementById("pdf-content");
  
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const contentHeight = input.offsetHeight;
    const width = 400;
      // Set up the PDF document with the calculated width and height
      const pdf = new jsPDF({
        orientation: 'portrait', // or 'landscape'
        unit: 'px',
        format: [width, contentHeight]
      });
  
      // Add the content as an image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, width, contentHeight);
  
      // Save the PDF
      pdf.save("Prescription.pdf");
    });
  };
  

  
  return (
    <>
     
        {props?.type === "from_down" && (
          <>
            {" "}
            <Button onClick={downloadPDF}>Download PDF</Button>
          </>
        )}

        <div id="pdf-content" className="mt-5">
          <section className="preview-section px-3">
            <Container>
              
              <Row>
                <div className="preview-head-outer">
                  
                  <Col md={12}>
                    <div className="preview-head-wrap">
                      <h6>{crop_details_view?.user?.member?.office?.name}</h6>
                      <p>{crop_details_view?.user?.member?.office?.address}</p>
                      <span>Ph : {crop_details_view?.user?.member?.office?.phone_no} </span>
                      <span>Email : {crop_details_view?.user?.member?.office?.email}</span>
                    </div>
                  </Col>
                  
                </div>
                <div className="preview-content-wrap">
                  <h6>Prescription Pad</h6>
                  <Col md={12}>
                    <div className="content-head-outer">
                      <div className="content-head-right">
                        <p>No: p-6/2022</p>
                        <p>Date : {convertISODateToDate(currentDate)}</p>
                        <p>Time : {validateDatetotime(currentDate)}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="crop-details-wrap">
                      <div>
                        <span>
                          <b>വിളയുടെ പേര് : </b>{" "}
                        </span>
                        <span>{crop_details_view?.crop?.name}</span>
                      </div>
                      {crop_details_view?.cropDetails?.type && (
                      <div>
                        <span>
                          <b>കീടബാധ / രോഗം / മൂലകങ്ങളുടെ അഭാവം / മറ്റുള്ളവ :</b>{" "}
                        </span>
                        <span>
                          {crop_details_view?.cropDetails?.type === 1
                              ? "കീടബാധ"
                              : crop_details_view?.cropDetails?.type === 2
                                ? "രോഗം"
                              : crop_details_view?.cropDetails?.type === 3
                                ?  "മൂലകങ്ങളുടെ വൈകല്യത്തിന്റെ പേര്"
                                : "മറ്റുള്ളവ"
                              }
                            {" "}
                        </span>
                      </div>
                      )}
                    </div>
                    {crop_details_view?.cropDetails?.type && (
                    <div className="crop-details-wrap">
                      <div>
                        <span>
                          <b>
                          {crop_details_view?.cropDetails?.type === 1
                              ? "കീടബാധയുടെ പേര്"
                              : crop_details_view?.cropDetails?.type === 2
                                ? "രോഗത്തിന്റെ പേര്"
                                : crop_details_view?.cropDetails?.type === 3
                                ?  "മൂലകങ്ങളുടെ വൈകല്യത്തിന്റെ പേര്"
                                : "മറ്റുള്ളവയുടെ പേര്"
                                }
                            :{" "}
                          </b>
                        </span>
                        <span>
                          {
                            crop_details_view?.cropDetails
                              ?.name_pest_disease_def
                          }
                        </span>
                      </div>
                    </div>
                     )}
                  </Col>
                  <Col md={12}>
                    <div>
                      <h6 className="mt-2">
                        <u>ലക്ഷണങ്ങൾ</u>
                      </h6>
                      <div>
                        <ListGroup>
                          <ListGroupItem>
                            <TextConverter
                              inputText={crop_details_view?.symptoms}
                            />
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div>
                      <h6 className="mt-2">
                        <u>നിയന്ത്രണ പ്രതിവിധി</u>
                      </h6>

                      <div>
                        <ListGroup>
                          <ListGroupItem>
                            <TextConverter
                              inputText={crop_details_view?.control_measure}
                            />
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="designation-wrap">
                      <p>
                        <b>{crop_details_view?.user?.first_name}</b>
                      </p>
                      <p>
                        <b>{crop_details_view?.user?.member?.designationMeta?.name}</b>
                      </p>
                    </div>
                  </Col>
                </div>

                <div className="pagination text-end"></div>
              </Row>
            </Container>

            <Col md={12}>
              <div className="preview-bottom-outer">
                <div className="site-logo">
                  <img src="/images/site-logo.png" alt="Site Logo" />
                </div>
                <div className="preview-bottom-wrap">
                  Association of Agricultural Officers Kerala
                </div>
              </div>
            </Col>
          </section>
        </div>
      
    </>
  );
}
