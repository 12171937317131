import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddTestimonialProps } from "../../../../../../Type";
import {
  AddTestimonialInitialValues,
  TestimonialValidationSchema,
} from "./AddGalleryMethods/FormikValidation";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  TestimonialSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddGalleryMethods/AddGalleryMethods";
import CroppedImages from "../../crop/croppedimages";

const AddTestimonial = ({
  setIsOpen,
  refetch,
  modalEditData,
  testimonialError,
}: AddTestimonialProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddTestimonialInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [images, setImages] = useState<File[]>([]);
  const [croppedImages, setCroppedImages] = useState<string[]>([]);

  const resetCroppedImages = () => {
    setCroppedImages([]);
  };
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);


  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    values: any
  ) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const newImagesArray = [...images, ...Array.from(selectedFiles)];
      setImages(newImagesArray);
      setFieldValue("image", newImagesArray);
    }
    resetCroppedImages();
  };

  const handleDeletes = (index: number) => {
    setImages((prevImages) =>
      prevImages.filter((_, currentIndex) => currentIndex !== index)
    );

    setCroppedImages((prevCroppedImages) =>
      prevCroppedImages.filter((_, currentIndex) => currentIndex !== index)
    );
  };

  return (
    <Formik
      validationSchema={TestimonialValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        const formattedImages = croppedImages.map((croppedImage, index) => ({
          id: index + 1,
          images: croppedImage,
        }));
        TestimonialSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          formattedImages
        );
      }}

    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
      }) => (
        <>
          <div className="Add-web-settings-wrap">
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col>
                    <Label>Gallery image</Label>
                    <div className="add-banner-thumb-wrap">
                      <div className="image-thumbnail-wrap">
                        {images?.length > 0 &&
                          images.map((item: any, index) => (
                            <React.Fragment key={index}>
                              <div className="display-image" key={index}>
                                <i
                                  className="icon icon-close-red"
                                  onClick={() => handleDeletes(index)}
                                  title="Remove photo"
                                ></i>
                                <img
                                  src={URL.createObjectURL(item)}
                                  alt=""
                                  className="img-fluid "
                                />
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                      {/* <div className="image-thumbnail-wrap">
                        {image_change === true ? (
                          <img
                            src={imageView}
                            alt="SelectedImage"
                            className="website-image"
                          />
                        ) : (
                          modalEditData?.[0]?.galleryImage?.[0]?.images?.length >
                            0 ? (
                            modalEditData?.[0]?.galleryImage?.map(
                              (image: any, index: any) => (
                                <div className="display-image" key={index}>
                                  <i
                                    className="icon icon-close-red"
                                    onClick={() => handleDeletes(index)}
                                    title="Remove photo"
                                  ></i>
                                  <img
                                    src={image?.images}
                                    alt="images"
                                    className="website-image"
                                  />
                                </div>
                              )
                            )
                          ) : (
                            <img
                              src="/images/empty-banner.webp"
                              alt="Empty Banner"
                              className="website-image"
                            />
                          )
                        )}
                      </div> */}
                    </div>

                    {images.map((image, index) => (
                      <CroppedImages
                        key={index}
                        src={URL.createObjectURL(image)}
                        onCrop={(croppedImage: string) => {
                          setCroppedImages([...croppedImages, croppedImage]);
                        }}
                        from_blogs={true}
                      />
                    ))}

                    <Input
                      id="cha"
                      type="file"
                      onChange={(e) =>
                        handleImageChange(e, setFieldValue, values)
                      }
                      name="image"
                      placeholder="Banner File"
                      multiple
                    />
                    <ErrorMessage name="image" component={TextError} />
                  </Col>

                  <Col md={12}>
                    <Label>Title</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.title}
                      name="title"
                      placeholder="Title"
                    />
                    <ErrorMessage name="title" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default AddTestimonial;
