import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  AddOfficeInitialValues,
  OfficeValidationSchema,
} from "./AddOfficeMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddOfficeMethods/AddOfficeModalMethods";
import { useQuery } from "react-query";
import { AddOfficeProps } from "../../../../../Type";
import {
  getOfficeBlock,
  getOfficeCategoreyByDistrictFullList,
  getPanchayat,
} from "../../../../../Api/AdminApis";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";

const AddOffice = ({
  setIsOpen,
  modalEditData,
  refetch,
  districtList,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddOfficeInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [office_cat_id, setOfiiceCatId] = useState();
  const [district_ids, setDistrictIds] = useState();
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
    setValue({
      ...value,
      block: modalEditData?.block?.id ? modalEditData?.block?.id : "",
      district: modalEditData?.district?.id ? modalEditData?.district?.id : "",
    });
    setDistrictIds(modalEditData?.district?.id);
  }, [modalEditData]);

  const [value, setValue] = useState({
    district: "",
    panchayth: addModalInitialValues?.block ? addModalInitialValues?.block : "",
    block: addModalInitialValues?.district
      ? addModalInitialValues?.district
      : "",
    state: "",
  });
  const { data: officecatList }: any = useQuery(
    [`officecat`, district_ids],
    () =>
      district_ids ? getOfficeCategoreyByDistrictFullList(district_ids) : "",
    {
      keepPreviousData: true,
    }
  );
  const { data: blockList }: any = useQuery(
    [`block${value?.district}`, value?.district],
    () => (value?.district ? getOfficeBlock(value?.district) : ""),
    {
      keepPreviousData: true,
    }
  );
  const { data: panchaythList }: any = useQuery(
    [`panchayth${value?.block}`, value?.block],
    () => (value?.block ? getPanchayat(value?.block) : ""),
    {
      keepPreviousData: true,
    }
  );


  return (
    <Formik
      validationSchema={OfficeValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Office Name</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.office_name}
                    name="office_name"
                    placeholder="Office Name"
                  />
                  <ErrorMessage name="office_name" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>District</Label>
                  <Select
                    placeholder="Select District"
                    name="district"
                    options={districtList ? districtList : []}
                    value={
                      districtList
                        ? districtList?.filter(
                          (itm: any) => itm?.value === values?.district
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("district", e?.value);
                      setDistrictIds(e?.value);
                      setValue({
                        ...value,
                        district: e?.value,
                      });
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching District"}
                    noOptionsMessage={() => "District appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="district" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Office Category</Label>
                  <Select
                    placeholder="Select Office Category"
                    name="office_cat_id"
                    options={officecatList ? officecatList : []}
                    value={
                      officecatList
                        ? officecatList?.filter(
                          (itm: any) => itm?.value === values?.office_cat_id
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("office_cat_id", e?.value);
                      setOfiiceCatId(e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Office Categorey"}
                    noOptionsMessage={() => "Office Category appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="office_cat_id" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Block</Label>
                  <Select
                    placeholder="Select Block"
                    name="block"
                    options={blockList ? blockList : []}
                    value={
                      blockList
                        ? blockList?.filter(
                          (itm: any) => itm?.value === values?.block
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("block", e?.value);
                      setValue({
                        ...value,
                        block: values?.block ? values?.block : e?.value,
                      });
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Block"}
                    noOptionsMessage={() => "Block appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="block" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Panchayat</Label>
                  <Select
                    placeholder="Select Panchayat"
                    name="panchayat"
                    options={panchaythList ? panchaythList : []}
                    value={
                      panchaythList
                        ? panchaythList?.filter(
                          (itm: any) => itm?.value === values?.panchayat
                        )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("panchayat", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Panchayat"}
                    noOptionsMessage={() => "Panchayat appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="panchayat" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    onChange={handleChange}
                    value={values?.email}
                    name="email"
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Phone Number</Label>
                  <Input
                    type="number"
                    onChange={handleChange}
                    name="phone_number"
                    value={values?.phone_number}
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="phone_number" component={TextError} />
                </Col>

                <Col lg={6}>
                  <Label>Office Reg/Number</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.office_no}
                    name="office_no"
                    placeholder="Office Number"
                  />
                  <ErrorMessage name="office_no" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Pincode</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    name="pincode"
                    value={values?.pincode}
                    placeholder="Pincode"
                  />
                  <ErrorMessage name="pincode" component={TextError} />
                </Col>

                <Col md={12}>
                  <Label>Address</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    name="address"
                    value={values?.address}
                    placeholder="Address"
                  />
                  <ErrorMessage name="address" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddOffice;
