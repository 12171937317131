import Member from "../Pages/Private/Admin/Members/MemberTabs/Member";
import { processJsonResponse } from "../Utils/helpers/helpers";
import { api } from "./Api";

// departments listing without Pagination
export async function getDepartmentFullList() {
  const [, data] = await api.get(
    `/departments?isActive=1&search_keyword=`,
    true
  );
  return processJsonResponse(data?.data, ["id", "name"]);
}

/// office listing without Pagination
export async function getOfficeList(
  currentPage: string,
  searchQuery: string,
  district?: string,
  block?: string
) {
  let url = '/office?';
  if (district && block) {
    url += `district_id=${district}&block_id=${block}&page=${currentPage}`;
  } else if (district) {
    url += `district_id=${district}&page=${currentPage}&`;
  } else if (block) {
    url += `block_id=${block}&`;
  } else {
    url += `page=${currentPage}&`;
  }
  url += `search=${searchQuery}`;
  const [, data] = await api.get(url, true);
  return data?.data;
}



/// office listing without Pagination
export async function getSingleOffice(id: any) {
  const [, data] = await api.get(`/office/${id}`, true);
  return data;
}

/// office listing without Pagination
export async function getDummyList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/staff?page=${currentPage}&search=${searchQuery}`,
    true
  );

  data?.data?.map((itm: any) => {
    itm.isCheck = false;
  });
  return data;
}
/// office listing without Pagination
export async function getState() {
  const [, data] = await api.get(`/places/state`, true);
  return processJsonResponse(data, ["id", "name"]);
}
/// office listing without Pagination
export async function getDistrict(cat_id?: any) {
  const [, data] = await api.get(`/places/district`, true);
  return processJsonResponse(data, ["id", "name"]);
}
export async function getDesignationDistrict(cat_id?: any) {
  const [, data] = await api.get(`/places/district`, true);

  let all_arry = [
    {
      value: "0",
      label: "All District",
    },
  ];
  let datas = [...all_arry, ...processJsonResponse(data, ["id", "name"])];

  return datas;
}
export async function getDistrictCategory(cat_id?: any) {
  const [, data] = await api.get(`/places/district?category=${cat_id}`, true);
  return processJsonResponse(data, ["id", "name"]);
}
/// office public without Pagination
export async function getOpenDistrict() {
  const [, data] = await api.get(`/open/district`, true);
  return processJsonResponse(data, ["id", "name"]);
}
/// office listing without Pagination
export async function getPanchayat(id: any) {
  const [, data] = await api.get(`/places/panchayath?block=${id}`, true);

  return processJsonResponse(data, ["id", "name"]);
}
/// block listing without Pagination
export async function getBlock(id: any) {
  const [, data] = await api.get(`/places/block?district=${id}`, true);

  return processJsonResponse(data, ["id", "name"]);
}

export async function transferGetOffice(district_id: any, block_id: any) {
  const [, data] = await api.get(`/transfer/office-list?district_id=${district_id}&block_id=${block_id}`, true)
  return processJsonResponse(data.data, ["id", "name"]);
}

export async function transferGetDesignation(district_id: any) {
  const [, data] = await api.get(`/transfer/get-all-designation?district_id=${district_id}`, true)
  return processJsonResponse(data?.data, ["id", "name"]);
}


export async function getOfficeBlock(id: any) {
  const [, data] = await api.get(`/places/block?district=${id}`, true);

  return processJsonResponse(data, ["id", "name"]);
}

/// openblock listing without Pagination
export async function getOpenBlock(id: any) {
  const [, data] = await api.get(`/open/block?district=${id}`, true);

  return processJsonResponse(data, ["id", "name"]);
}

/// office listing without Pagination
export async function getCadreList(currentPage: any, searchQuery: any) {
  const [, data] = await api.get(
    `/cadre?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  return data?.data;
}

export async function getAccomodationMaster(
  currentPage: any,
  searchQuery: any
) {
  const [, data] = await api.get(
    `/office-accomodation?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  return data?.data;
}

export async function getDesignationList(
  currentPage?: any,
  searchQuery?: any,
  cadre_id?: string
) {
  var url = `/designation-name?page=${currentPage}&search=${searchQuery}&perPage=10&`;
  const [, data] = await api.get(url, true);
  return data?.data;
}

export async function getDesignationGetList(
  currentPage?: any,
  searchQuery?: any,
  cadre_id?: string
) {
  var url = `/designation?page=${currentPage}&search=${searchQuery}&perPage=10&cadre_id=${cadre_id}`;
  const [, data] = await api.get(url, true);
  return data;
}

export async function getDesignationSingle(
  cadre_id?: string,
  office_cat_id?: string
) {
  var url = `/designation/${cadre_id}?office_cat_id=${office_cat_id}`;
  const [, data] = await api.get(url, true);
  return data;
}

export async function getOfficeSeat(
  district_id?: string,
  cadre?: string,
  block?: any
) {
  var url = `/office/seat-count/get-all?district_id=${district_id}&cadre_id=${cadre}&block_id=${block}`;
  const [, data] = await api.get(url, true);
  return data;
}

export async function getDesignationSingleList() {
  var url = `/designation-name/get/all`;

  const [, data] = await api.get(url, true);
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });
  return new_array;
}

/// office listing without Pagination
export async function getCadreFullList() {
  const [, data] = await api.get(`/cadre`, true);
  const new_array: any = [];
  data?.data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getDesignationFullList(cadre_id?: any) {
  const [, data] = await api.get(
    `/designation/get-all?cadre_id=${cadre_id}`,
    true
  );
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
export async function getOpenDesignationFullList() {
  const [, data] = await api.get(`/open/designations`, true);
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
export async function getOfficeFullList() {
  const [, data] = await api.get(`/office/get-all`, true);

  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getOfficeFilterFullList(district_id: any, block_id: any) {
  const [, data] = await api.get(
    `/office/get-all?district_id=${district_id}&block_id=${block_id}`,
    true
  );

  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getOpenOfficeFullList() {
  const [, data] = await api.get(`/open/office`, true);

  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export const getTransferRequests = async (
  currentPage: string,
  searchQuery: string
) => {
  const [, data] = await api.get(
    `/transfer/me?page=${currentPage}&search=${searchQuery}`,
    true
  );

  const filteredData = data?.data?.data?.map((itm: any, i: number) => {

    const pg_no =
      (data?.data?.meta.currentPage - 1) * data?.data?.meta?.itemsPerPage +
      i +
      1;
    return {
      no: pg_no,
      id: itm?.id,
      office: itm?.office?.id,
      panchayath: itm?.panchayath?.name,
      block: itm?.block?.name,
      district: itm?.district?.name,
      reason: itm?.reason,
      description: itm?.description,
      GO_order: itm?.GO_order,
      GO_order_date: itm?.GO_order_date,
      to_office: itm?.office?.name,
      block_verify: itm?.block_verify,
      district_verify: itm?.district_verify
    };
  });
  return { data: data?.data, filteredData: filteredData };
};

export const setCurrentPageTransferReq = (
  navigate: Function,
  pageNo: string
) => {
  navigate(`/member/transfer-request/${pageNo}`);
};

export const handleTransferSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    navigate("/member/transfer-request");
    setSearchQuery(e?.target?.value);
  } else {
    setSearchQuery("");
  }
};

export async function getSeatList(
  currentPage: string,
  id: any,
  searchQuery: string,
  cadre_id: any
) {
  const [, data] = await api.get(
    `/seats/office/${id}?page=${currentPage}&perPage=10&search=${searchQuery}&cadre_id=${cadre_id ? cadre_id : ""
    }`,
    true
  );
  return data?.data;
}

export async function getMembersList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/members?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
export async function getSeatFullList(id: any) {
  const [, data] = await api.get(`/seats/office/get-all/${id}`, true);
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getOpenSeatFullList(id: any) {
  const [, data] = await api.get(`/open/seats/${id}`, true);
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

/// office listing without Pagination
export async function getAssetCategoryList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/assets-categories?page=${currentPage}&search=${searchQuery}`,
    true
  );

  return data?.data;
}

export async function getAssetSubCategoryList(
  currentPage: string,
  searchQuery: string,
  id: any
) {
  const [, data] = await api.get(`/assets-categories/get-suball/${id}`, true);
  return data?.data;
}

/// office listing without Pagination
export async function getAssetCategoryFullList() {
  const [, data] = await api.get(`/assets-categories/get-all`, true);
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getAssetSubCategoryFullList(id: any) {
  const [, data] = await api.get(`/assets-categories/${id}`, true);
  const new_array: any = [];
  data?.data?.subCategory?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
      status: item?.document_status,
    });
  });
  return new_array;
}

/// office listing without Pagination
export async function getAssetList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/assets?page=${currentPage}&search=${searchQuery}`,
    true
  );

  return data?.data;
}

export async function getSingleAsset(id: any) {
  const [, data] = await api.get(`/assets/${id}`, true);
  return data?.data;
}

/// office listing without Pagination
export async function getRoleList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/role/page?page=${currentPage}&search=${searchQuery}`,
    true
  );

  return data?.data;
}

export async function getPermissionList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(`/permission?page=${currentPage}`, true);
  data?.data?.map((item: any) => {
    item.is_check = false;
  });
  return data?.data;
}

/// office listing without Pagination
export async function getMemberList(
  currentPage: string,
  searchQuery: string,
  district_id: any,
  block_id: any,
  office_id: string,
) {

  let url = "/members?";

  // Constructing URL based on filter values
  if (district_id && block_id && office_id) {

    url += `district_id=${district_id}&block_id=${block_id}&office_id=${office_id}&page=${currentPage}&`;
  } else if (district_id && block_id) {

    url += `district_id=${district_id}&block_id=${block_id}&page=${currentPage}&`;
  } else if (district_id) {

    url += `district_id=${district_id}&page=${currentPage}&`;
  } else {
    url += `page=${currentPage}&`;
  }

  // Adding search query to the URL
  url += `search=${searchQuery}&`;

  const [, data] = await api.get(url, true);
  return data?.data;
}

export async function getRetireMemberList(
  currentPage: string,
  searchQuery: string,
  office_id: string,
  district_id: any,
  block_id: any
) {
  var url = `/profile/retiring-this-Month?page=${currentPage}&search=${searchQuery}&`;
  if (office_id || district_id || block_id)
    url += `office_id=${office_id}&district_id=${district_id}&block_id=${block_id}`;
  const [, response] = await api.get(url, true);
  const data = response;
  return data;
}

export async function getMeberReportList(
  currentPage: string,
  district: any,
  designation: any,
  degree_name: any,
  active: any,
  revoke: any,
  cadre: any
) {
  let endpoint = `/reports/get-member/paginated-all?page=${currentPage}`;

  if (district) {
    endpoint += `&district_id=${district}`;
  }
  if (designation) {
    endpoint += `&designation_id=${designation}`;
  }
  if (degree_name) {
    endpoint += `&qualification_id=${degree_name}`;
  }
  if (active) {
    endpoint += `&active=${active}`;
  }
  if (revoke) {
    endpoint += `&approve=${revoke}`;
  }
  if (cadre) {
    endpoint += `&cadre_id=${cadre}`
  }

  const [, data] = await api.get(endpoint, true);
  return data;
}

export async function getLoginList(
  currentPage: string,
  designation_id: string,
  year: any
) {
  let endpoint = `/reports/member-status/get-paginated-all?page=${currentPage}`;

  if (designation_id) {
    endpoint += `&designation_id=${designation_id}`;
  }
  if (year) {
    endpoint += `&year=${year}`;
  }
  const [, data] = await api.get(endpoint, true);
  data?.data?.items?.map((item: any) => {
    item.is_check = false;
  });
  return data;
}

export async function getdashboard(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(`/dashboard/counts`, true);

  return data;
}

export async function getdashboardmember(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(`/dashboard/latest-members`, true);

  return data;
}

export async function gePrescriptionList(
  currentPage: string,
  district_id: string
) {
  let endpoint = `/reports/prescription/get-paginated-all?page=${currentPage}`;
  if (district_id) {
    endpoint += `&district_id=${district_id}`;
  }
  const [, data] = await api.get(endpoint, true);
  return data;
}

export async function geVacancyList(currentPage: string, district_id: string) {
  let endpoint = `/reports/get-vacancy/paginated-all?page=${currentPage}`;
  if (district_id) {
    endpoint += `&district_id=${district_id}`;
  }
  const [, data] = await api.get(endpoint, true);
  return data;
}

export async function getMembercontributionList(
  currentPage: string,
  year: string,
  district_id: string
) {
  let endpoint = `/reports/amount/get-all-paginated?page=${currentPage}`;
  if (year) {
    endpoint += `&year=${year}`;
  }
  if (district_id) {
    endpoint += `&district_id=${district_id}`;
  }

  const [, data] = await api.get(endpoint, true);
  return data;
}

export async function getAssociateMemberList(
  currentPage: string,
  searchQuery: string,
  office: any,
  district_id: any,
  block_id: any
) {
  const [, data] = await api.get(
    `/members/associate/get-all?page=${currentPage}&search=${searchQuery}&district_id=${district_id}&block_id=${block_id}`,
    true
  );

  return data?.data;
}

/// office listing without Pagination
export async function getCropList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/crop/page?page=${currentPage}&search=${searchQuery}&limit=10`,
    true
  );

  return data?.data;
}

export async function getCropFullList() {
  const [, data] = await api.get(`/crop`, true);
  return processJsonResponse(data?.data, ["id", "name"]);
}

export async function getCropCategoryList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/crop-category/page?page=${currentPage}&search=${searchQuery}&limit=10`,
    true
  );

  return data?.data;
}

export async function getCropCategoryFullList(id: any, type: any) {
  const [, data] = await api.get(`/crop-category?crop=${id}&type=${type}`, true);
  let pest_array: any = [];
  data?.data?.map((item: any) => {
    pest_array?.push({
      value: item?.id,
      label: item?.pest_disease_nutrition_def,
    });
  });
  return pest_array;
}

export async function getBlogsList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/blogs?page=${currentPage}&search=${searchQuery}`,
    true
  );

  return data?.data;
}

export async function getMagazineList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/magazines?page=${currentPage}&search=${searchQuery}`,
    true
  );

  return data?.data;
}

export async function getCropDetlesList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/crop-details/page?page=${currentPage}&search=${searchQuery}&limit=10`,
    true
  );

  return data?.data;
}

export async function getPrescriptionList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/prescription?page=${currentPage}&search=${searchQuery}&limit=10`,
    true
  );

  return data?.data;
}

export async function getCropCategoryDetList(id: any, type: any) {
  const [, data] = await api.get(`/crop-details?crop=${id}&type=${type}`, true);
  let pest_array: any = [];
  data?.data?.map((item: any) => {
    pest_array?.push({
      value: item?.id,
      label: item?.name_pest_disease_def,
    });
  });
  return pest_array;
}

export async function getPrecriptionPreview(id: any) {
  const [, data] = await api.get(`/prescription/${id}`, true);
  return data?.data;
}

export async function getCropDetlies(id: any) {
  const [, data] = await api.get(`/crop-details/${id}`, true);
  return data?.data;
}

export async function getOfficeCategoreyList(
  currentPage: any,
  searchQuery: string,
  district?: string
) {
  const [, data] = await api.get(
    `/office-category/page?page=${currentPage}&limit=10&search=${searchQuery}&district_id=${district}`,
    true
  );
  return data?.data;
}

export async function getOfficeCategoreyFullList() {
  const [, data] = await api.get(`/office-category`, true);
  let new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });
  return new_array;
}

export async function getOfficeCategoreyByDistrictFullList(district: any) {
  let url = "/office-category";
  if (district !== "0") {
    url += `?district_id=${district}`;
  }
  const [, data] = await api.get(url, true);
  let new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getOfficeFullLists() {
  const [, data] = await api.get(`/office/get-all`, true);

  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getaccommodationFullLists() {
  const [, response] = await api.get(`/office-accomodation`, true);
  const data = response?.data?.data;
  const new_array: any[] = [];

  if (Array.isArray(data)) {
    data.forEach((item: any) => {
      const { id, name } = item ?? {};
      new_array.push({
        value: id,
        label: name,
      });
    });
  }

  return new_array;
}

export async function getAccomadtionList(
  currentPage?: string,
  searchQuery?: string,
  district?: string
) {
  var url = `/manage-office-accomodation?page=${currentPage}&search=${searchQuery}&`;
  if (district) url += `filter.OfficeAccomodation.district.id=${district}&`;
  const [, data] = await api.get(url, true);

  return data;
}

export async function getUserList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/user-manage?page=${currentPage}&search=${searchQuery}&limit=10`,
    true
  );
  data?.data?.map((item: any) => {
    item.isCheck = false;
  });
  return data;
}

export async function getRoleFullList() {
  const [, data] = await api.get(`/role`, true);
  const new_array: any = [];

  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
export async function getMemberView(id: any) {
  const [, data] = await api.get(`/members/preview/${id}`, true);
  return data?.data;
}

export async function getLeaveList(currentPage: string, searchQuery: string) {
  const [, data] = await api.get(
    `/leave/me?page=${currentPage}&search=${searchQuery}`,
    true
  );

  return data?.data;
}
export async function getLeaveListAdmin(
  currentPage: string,
  searchQuery: string,
  leve_type: string
) {
  var url = `/leave?page=${currentPage}&search=${searchQuery}&limit=10&`;
  if (leve_type) url += `filter.leave_type=${leve_type}`;
  const [, data] = await api.get(url, true);

  return data?.data;
}


export async function getPermissionAllList() {
  const [, data] = await api.get(`/users/me`, true);

  return data;
}
export async function getMeetingListAdmin(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(`/meetings?page=${currentPage}`, true);
}
/// fund listing
export async function getFundList(currentPage: any, searchQuery: string) {
  const [, data] = await api.get(
    `/fund?page=${currentPage}&search=${searchQuery}&limit=10`,
    true
  );
  return data?.data;
}

//Download
export async function getDownloadList(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(`/downloads?&search=${searchQuery}&limit=10`, true);
  const tableData = data?.data?.data?.map((item: any) => {
    return {
      id: item?.id,
      category_name: item?.category_name,
      files: item?.downloadCategory,
      is_published: item?.is_published,
    };
  });

  return { tableData, data: data?.data };
}

export async function getDownloadCategoreyList(
  currentPage: string,
  searchQuery: string,
  id: any
) {
  const [, data] = await api.get(`/download-categories/get-all/${id}?&search=${searchQuery}`, true);
  data?.data?.items?.map((item: any) => {
    item?.subcategories?.map((items: any) => {
      items.is_check = false;
    });
  });
  const tableData = data?.data?.items?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
      parent: item?.parent,
      subcategories: item?.subcategories?.map((item: any) => {
        item.is_check = false;
      }),
      document_file_url: item?.document_file_url,
    };
  });

  return { tableData, data: data?.data };
}

export const handlePublishModal = (
  setPublishModalOpen: Function,
  setPublishModalData: Function,
  id: string | number,
  status: string | number
) => {
  setPublishModalData({ id, status });
  setPublishModalOpen(true);
};

//Meetings
export async function getMeetingDetails(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/meetings?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  return data?.data;
}

//Announcement
export async function getAnnouncementDetails(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/announcement/get-all?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  return data?.data;
}

//Meetings
export async function getAttendanceDetails(
  currentPage: string,
  searchQuery: string
) {
  const [, data] = await api.get(
    `/meeting-attendance?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  return data?.data;
}

export async function getMeetingMembers(
  currentPage: string,
  searchQuery: string,
  value: any
) {
  const [, data] = await api.get(
    `/members/meetings/filter?district_ids=[${value?.district}]&role_ids=[${value?.role}]&page=${currentPage}`,
    true
  );

  return data?.data;
}

export async function getOtherfund(
  currentPage: string,
  searchQuery: string,
  district: string
) {
  var url = `/other-member-fund?page=${currentPage}&search=${searchQuery}&perPage=10&`;
  if (district) url += `filter.district.id=${district}`;
  const [, data] = await api.get(url, true);
  return data?.data;
}
export async function getOtherfundMemberList(
  currentPage: string,
  searchQuery: string,
  value: any,
  id: any
) {
  const [, data] = await api.get(
    `/other-member-fund/get-member-all/${id}?page=${currentPage}&search=${searchQuery}&perPage=10`,
    true
  );
  return data?.data;
}

export async function getTestimonialList(currentPage: string) {
  const [, data] = await api.get(
    `/testimonial?page=${currentPage}&limit=6`,
    true
  );
  return data?.data;
}
export async function getServicesList(currentPage: any) {
  // Your implementation here
  const [, data] = await api.get(`/service?page=${currentPage}&limit=6`, true);
  return data?.data;
}

export const getBannerList = async (currentPage: any) => {
  const [, data] = await api.get(`/banner?page=${currentPage}&limit=6`, true);
  return data?.data;
};

export const getGalleryList = async (currentPage: any) => {
  const [, data] = await api.get(`/gallery?page=${currentPage}&limit=6`, true);
  return data?.data;
};
export async function getHistoryList(currentPage: string) {
  const [, data] = await api.get(`/history?page=${currentPage}&limit=6`, true);
  return data?.data;
}

export async function getMembershipSetting() {
  const [, data] = await api.get(`/membership-settings`, true);

  return data?.data;
}

export async function getVisionData(currentPage: string) {
  const [, data] = await api.get(`/ourmission`, true);

  return data?.data;
}

export async function getVisionPhoto(currentPage: string) {
  const [, data] = await api.get(`/about-image`, true);
  return data?.data;
}

export async function getPaymentUser(token: string) {
  const [, data] = await api.get(`/payment/order-details/${token}`, true);
  return data?.data;
}

export async function getintouch(currentPage: string, search: string) {
  const [, data] = await api.get(
    `/about-us?page=${currentPage}&search=${search}`,
    true
  );
  return data?.data;
}

export async function getMeta(currentPage: any, search_keyword: any) {
  const [, data] = await api.get(
    `/seo?page=${currentPage}&&search=${search_keyword}`,
    true
  );
  return data?.data;
}

export async function getSIngleMeta(active: any) {
  const [, data] = await api.get(`/open/seo?status=${active}`, true);
  return data;
}

export async function getFullOfficeCategory(block_id?: any) {
  const [, data] = await api.get(
    `/open/office-category?block_id=${block_id ? block_id : ""}`,
    true
  );
  const new_array: any = [];

  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getFullOfficeCategoryWise(office_cat: any, block: any) {
  const [, data] = await api.get(
    `/open/office-list?office_cat_id=${office_cat}&block_id=${block}`,
    true
  );
  const new_array: any = [];

  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}
export async function getOpenCader(office_id?: any) {
  const [, data] = await api.get(`/open/cadre?office_id=${office_id}`, true);
  return processJsonResponse(data?.data, ["id", "name"]);
}
export async function getDesignationmemberFullList(id: any, district_id: any) {
  const [, data] = await api.get(
    `/open/all_designation?cadre_id=${id}&district_id=${district_id}`,
    true
  );
  const new_array: any = [];
  data?.data?.map((item: any) => {
    new_array?.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return new_array;
}

export async function getnews(page: any) {
  const [, data] = await api.get(`/news?page=${page}`, true);
  return data?.data;
}
