import { Col, Container, Row, Table } from "reactstrap";
import { MyProfileProps } from "../../../../../Type";
import EducationIndex from "./Education";
import {
  MembershipStatus,
  convertISODateToDate,
} from "../../../../../Utils/helpers/helpers";
import { modalHandler } from "../MemberProfileMethods";

const MyOffice = ({
  memberOfficeData,
  setModalEditData,
  setIsOpen,
  memberOfficeDataRefetch,
  modalEditData,
}: MyProfileProps) => {
  return (
    <>
      <div className="content-list-outer-wrap mt-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="table-outer-wrap member-profile-table-wrap">
                <h6>My office</h6>
                <div className="edit-wrap">
                  <i
                    className="icon xl icon-edit"
                    onClick={() => {
                      modalHandler(setModalEditData, setIsOpen);
                    }}
                  ></i>
                </div>
                <div className="table-outer">
                  <Table>
                    <tbody>
                      <tr>
                        {" "}
                        <td>Office Name</td>
                        <td>{memberOfficeData?.office_name}</td>
                      </tr>
                      <tr>
                        <td>Office Address</td>
                        <td>{memberOfficeData?.office_address}</td>
                      </tr>

                      <tr>
                        <td>Office PhoneNo</td>
                        <td>{memberOfficeData?.office_phone_no}</td>
                      </tr>

                      <tr>
                        <td>Office Email</td>
                        <td>{memberOfficeData?.office_email}</td>
                      </tr>

                      <tr>
                        <td>District</td>
                        <td>{memberOfficeData?.district_name}</td>

                      </tr>

                      <tr>
                        <td>Block</td>
                        <td>{memberOfficeData?.block_name}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyOffice;
