import * as Yup from 'yup';

export const FundValidationSchema = Yup.object().shape({
  office: Yup.number().required("Field is required").nullable(),
  received_fund: Yup.number().required("Field is required").nullable(),
  expended_fund: Yup.number().required("Field is required").nullable(),
  document: Yup.string().required("Field is required").nullable(),
});

export const AddFundInitialValues = {
  office: "",
  received_fund: "",
  expended_fund: "",
  document: "",
};

