import { Button, Col, Row } from "reactstrap";
import { SendLoginSubmitHandler } from "../AddMembers/AddMemberMethods/AddMemberModalMethods";
import { useEffect, useState } from "react";

const SendLoginModal = (props: any) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Do you really want to Send Login Data .
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setIsOpen(false)}
              >
                Cancel
              </Button>

              <Button
                color={"primary"}
                className="sm"
                onClick={() => {
                  SendLoginSubmitHandler(
                    props?.preview_id,
                    props?.Refetch,
                    props?.setIsOpen,
                    setLoading
                  );
                  if (!loading) {
                    props?.setIsOpen(false);
                  }
                }}
                disabled={loading}
              >
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SendLoginModal;
