import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAssetSubCategoryList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddSubAssetCategory/AddAssetSubCategoryMethods/AddAssetSubCategoryModalMethods";
import { handleAdminStaffSearch } from "./SubCategoryMethods";
import AddAssetSubCategory from "./AddSubAssetCategory";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";


const AssetSubCategoryIndex = () => {
  const { currentPage = "1", id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const location = useLocation();
  const {
    data: assetCategory,
    error: assetCategoryError,
    isLoading: assetCategoryLoading,
    refetch: assetCategoryListRefetch,
  }: any = useQuery(
    [
      `asset-sub-categorey${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      id,
    ],
    () => getAssetSubCategoryList(currentPage, searchQuery, id),
    { keepPreviousData: true }
  );

  const staffData = useMemo(
    () => extractStaffData(assetCategory),
    [assetCategory]
  );

  return (
    <section>
      <Container>
      <div className="content-outer-wrap">
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("asset category:write") ? <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Sub Category
                  </Button> : null}
                </div>
              </div>
            </div>
          </div>
        <div></div>
        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle={`Sub Category Details`}
            tableFields={["Category", "Name"]}
            Arraykeys={["category", "name"]}
            viewType="table"
            toURL={""}
            actions={[
              checkPermission("asset category:write") ? "edit" : "",
              checkPermission("asset category:delete") ? "delete" : "",
            ]}
            isLoading={assetCategoryLoading}
            error={assetCategoryError}
            onEditClick={(selectedId: any) => {
              if (checkPermission("asset category:write")) onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                assetCategory
              )
            }
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, assetCategoryListRefetch)
            }
            paginationData={assetCategory?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, id, pathname)
            }
          />
        </div>
      </div>
      </Container>


      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit Asset Sub Category"
            : "Add Asset  Sub Category"
        }
      >
        <AddAssetSubCategory
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={assetCategoryListRefetch}
          id={id}
        />
      </ModalContainer>
    </section>
  );
};

export default AssetSubCategoryIndex;
