import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../../Api/Api";
import { AddTestimonialInitialValues } from "./FormikValidation";


type AddInitialValues = {
  name: string;
  testimonial: string;
  designation: string;
};

export const TestimonialSubmitHandler = async (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  Image: string
) => {
  // Edit

  if (editMode) {
    api
      .patch(`/testimonial/${id}`, values, true)
      .then(async function ([success, response]: any) {
        refetch();
        toast.success("Updated",{toastId:"testimonialupdate"});
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Unexpected Error Occurred",{toastId:"testimonialupdaterr"});
      });
  } else {
    api
      .post(`/testimonial`, values, true)
      .then(async function ([success, response]: any) {
        refetch();
        toast.success("Testimonial Added",{toastId:"testimonialadd"});
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Unexpected Error Occurred",{toastId:"testimonialadderr"});
      });
    // add
  }
};

// extract Departmentdata for table
export const extractTestimonialData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      designation: item?.designation,
      name: item?.name,
      testimonial: item?.testimonial,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      designation: modalEditData?.[0]?.designation,
      name: modalEditData?.[0]?.name,
      testimonial: modalEditData?.[0]?.testimonial,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddTestimonialInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  testimonialData: any
) => {
  setModalEditData([]);
  setModalEditData(
    testimonialData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/testimonial/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"testimonialdelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"testimonialdeleterr"});
    });
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .patch(
      `/testimonial/publish/${selectedId}`,
      {
        status: newStatus,
      },
      true
    )
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated",{toastId:"publishupdate"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
export const setCurrentPageaward = (navigate: Function, pageNo: any) => {
  navigate(`/admin/staff/${pageNo}`);
};
