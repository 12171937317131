import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddBlogInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import { Badge } from "reactstrap";

type AddInitialValues = {
  blog_name: string;
  blog_body: string;
  blog_document: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  image: any
) => {
  // Add
  if (editMode) {
    const formData = new FormData();
    if (image === true) {
      formData.append("image", values?.blog_document);
    }

    formData.append("title", values?.blog_name);
    formData.append("body", values?.blog_body);

    axios({
      method: "patch",
      url: `${BaseUrl}/blogs/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Blog Details Updated ",{toastId:"Blog_updated"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Blog_error"});
        }
      });
  }
  // Edit
  else {
    const formData = new FormData();
    formData.append("image", values?.blog_document);
    formData.append("title", values?.blog_name);
    formData.append("body", values?.blog_body);
    axios({
      method: "post",
      url: `${BaseUrl}/blogs`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Blog Added",{toastId:"Blog_Added"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.errors) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Blog_added_error"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      title: item?.title,
      body: item?.body,
      image: item?.image,
      status_data:
        item?.is_published === 2 ? (
          <Badge>Unpublished</Badge>
        ) : (
          <Badge>Published</Badge>
        ),
      status: item?.is_published,
      blog_verify:item?.status
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      blog_name: modalEditData?.[0]?.title,
      blog_body: modalEditData?.[0]?.body,
      blog_document: modalEditData?.[0]?.image,
      image: modalEditData?.[0]?.image,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddBlogInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/blogs/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Blog_deleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Blog__deleteerror"});
    });
};

// Department delete
export const statusClickHandler = (
  setIsopen: Function,
  refetch: Function,
  selectedId: string | number,
  status: any
) => {
  api
    .patch(`/blogs/publish/${selectedId}`, { status: status }, true)
    .then(async function ([success, response]: any) {
      if (response?.success === true) {
        refetch();
        toast.success("Updated",{toastId:"Blog_publishupdate"});
        setIsopen(false);
      }
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
export const BlogClickHandler = (
  setIsopen: Function,
  refetch: Function,
  selectedId: string | number,
  status: any
) => {
  api
    .patch(`/blogs/verify/${selectedId}`, { status: status }, true)
    .then(async function ([success, response]: any) {
      if (response?.success === true) {
        refetch();
        toast.success("Updated",{toastId:"Blog_verify"});
        setIsopen(false);
      }
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/blog/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/blog/${pageNo}`)
  }
};
