import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAdminDownloadSearch } from "./DownloadMethods";
import {
  deleteCategoryClickHandler,
  extractDownloadData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageDownload,
} from "./AddDownload/AddDownloadMethods/AddDownloadModalMethods";
import AddDownload from "./AddDownload";
import { getDownloadList, handlePublishModal } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import DownloadPublishModal from "./DownloadPublishModal";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const DownloadIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [publishModalData, setPublishModalData] = useState({});
  const { checkPermission } = usePermission();

  const {
    data: downloadData,
    error: downloadError,
    isLoading: downloadLoading,
    refetch: downloadListRefetch,
  }: any = useQuery(
    [`download${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getDownloadList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const DownloadDataTable = useMemo(
    () => extractDownloadData(downloadData),
    [downloadData]
  );

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminDownloadSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>

            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("downloads:write") ? (
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Upload
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={DownloadDataTable}
              tableTitle="Download Details"
              tableFields={["Category Name"]}
              Arraykeys={["category_name"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("downloads:write") ? "edit" : "",
                checkPermission("downloads:delete") ? "delete" : "",
              ]}
              isLoading={downloadLoading}
              error={downloadError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  downloadData
                )
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteCategoryClickHandler(selectedId, downloadListRefetch)
              }
              paginationData={downloadData?.data?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageDownload(navigate, pageNo, pathname)
              }
              from_downloads
              handleDownloadPublishClick={(id: any, status: number) =>
                handlePublishModal(
                  setPublishModalOpen,
                  setPublishModalData,
                  id,
                  status
                )
              }
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Downloads" : "Add Downloads"}
      >
        <AddDownload
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={downloadListRefetch}
         
        />
      </ModalContainer>

      <ModalContainer isOpen={publishModalOpen} setIsopen={setPublishModalOpen}>
        <DownloadPublishModal
          data={publishModalData}
          setIsOpen={setPublishModalOpen}
          refetch={downloadListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default DownloadIndex;
