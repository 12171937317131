import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddTestimonialProps } from "../../../../../../Type";
import CroppedImages from "../../crop/croppedimages";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../../Components/FormikError/TextError";
import {
  AddNewsInitialValues,
  NewsValidationSchema,
} from "./AddNewsMethods/FormikValidation";
import {
  TestimonialSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddNewsMethods/AddNewsMethods";

const AddNews = ({
  setIsOpen,
  refetch,
  modalEditData,
  newsError,
}: AddTestimonialProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddNewsInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [images, setImages] = useState<File[]>([]);
  const [croppedImages, setCroppedImages] = useState<string[]>([]);

  const resetCroppedImages = () => {
    setCroppedImages([]);
  };
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);


  return (
    <Formik
      validationSchema={NewsValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {


        TestimonialSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,

        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
      }) => (
        <>
          <div className="Add-web-settings-wrap">
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col md={12}>
                    <Label>News Title</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.title}
                      name="title"
                      placeholder="Title"
                    />
                    <ErrorMessage name="title" component={TextError} />
                  </Col>
                  <Col md={12}>
                    <Label>News Content</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.content}
                      name="content"
                      placeholder="Content"
                    />
                    <ErrorMessage name="content" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default AddNews;
