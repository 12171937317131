import * as Yup from "yup";

export const HistoryValidationSchema = Yup.object().shape({
  title: Yup.string().required("Feild  is required"),

  body: Yup.string()
    .required("Feild is required")
    .min(10, "The  minimmum  10 characters."),
});

export const AddHistoryInitialValues = {
  title: "",
  body: "",
};
