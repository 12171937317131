import { Row, InputGroup, Input, Container } from "reactstrap";
import BannerList from "./Banner";
import ServiceList from "./Services";
import Testimonials from "./Testimonials";
import Gallery from "./Gallery";
import History from "./History";
import AboutUs from "./AboutUs";
import Vision from "./VisionAndMission";
import News from "./News";

const WebsiteSettings = () => {
  return (
    <>
      <section className="mywebsite-main-wrap">
        <Container>
          <Row className="gy-4">
            <div className="search-bar-full-wrap">
              {/* <div className="search-bar">
                  <InputGroup>
                    <Input
                      placeholder="Search here..."

                    />
                    <span className="icon-container">
                      <i className="icon xl icon-search"></i>
                    </span>
                  </InputGroup>
                </div> */}
            </div>
            <BannerList />
            <News />
            <ServiceList />
            <Gallery />
            <Testimonials />
            <History />
            <AboutUs />
            <Vision />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WebsiteSettings;
