import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { AddTransferInitialValues } from "./FormikValidation";

type AddInitialValues = {
  office: string;
  office_id: string;
  district: string;
  block: string;
  to_office: string;
  type: string;
  GO_order: string;
  GO_order_date: any;
  designation: any;
  designation_id?: any;
  // description: string;
  // panchayath: string;
  // state: string;
  // reason: string;

};

export const transferSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add


  if (editMode) {
    const patchData = {
      ...values,
      district: Number(values?.district),
      block: Number(values?.block),
      office: Number(values?.to_office),
    };

    if (values?.designation) {
      patchData.designation_id = Number(values?.designation);
    }

    api
      .patch(`/transfer/${id}`, patchData, true)
      .then(async function ([success, response]: any) {
        toast.success("Transfer Details Updated ", { toastId: "7070" });
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred", { toastId: "7071" });
        }
      });
  }
  // Edit
  else {
    const patchData = {
      ...values,
      district: Number(values?.district),
      block: Number(values?.block),
      office: Number(values?.to_office),
    };
    if (values?.designation) {
      patchData.designation_id = Number(values?.designation);
    }
    api
      .post("/transfer", patchData, true)
      .then(async function ([success, response]: any) {
        toast.success("Transfer Data Added ", { toastId: "7072" });
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred", { toastId: "7073" });
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      office: "",
      district: "",
      block: "",
      panchayat: "",
      state: "",
      transit_type: "",
      reason: "",
      discription: "",
      designation: ""
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.id,
      district: modalEditData?.district?.id,
      block: modalEditData?.block?.id,
      type: modalEditData?.type,
      GO_order: modalEditData?.GO_order,
      GO_order_date: modalEditData?.GO_order_date,
      description: modalEditData?.description,
      to_office: modalEditData?.office?.id,
      designation: modalEditData?.designation_id?.id
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddTransferInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  data: any
) => {
  setModalEditData([]);
  setModalEditData(
    data?.data?.filter((itm: any) => itm?.id === selectedId)?.[0]
  );
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/transfer/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted", { toastId: "7074" });
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred", { toastId: "7075" });
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/office-management/${pageNo}`);
};

export const TransferRejecthandler = (
  setopen: Function,
  selectedId: string | number,
  reason: string,
  refetch: Function
) => {
  if (selectedId) {
    api
      .patch(`/transfer/block-verify/${selectedId}`, { reason: reason, status: 4 }, true)
      .then(async function (response: any) {
        // if (response?.success) {
        toast.success("Transfer rejected successfully", { toastId: "transferSuccess" });
        setopen(false);
        refetch();
        // } 
      });
  }
};
