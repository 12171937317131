import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  AddMeetingsInitialValues,
  MeetingsValidationSchema,
} from "./AddMeetingsMethods/FormikValidation";
import {
  MeetingSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddMeetingsMethods/AddMeetingsModalMethods";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { MEETING_TYPE } from "../../../../../Utils/Constants/selection_options";

const AddMeetings = ({ setIsOpen, modalEditData, refetch }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddMeetingsInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={MeetingsValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        MeetingSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Meeting Type</Label>
                  <Select
                    placeholder="Select Meeting Type"
                    options={MEETING_TYPE}
                    value={
                      MEETING_TYPE?.filter(
                        (itm: any) => itm?.value === values?.meeting_type
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("meeting_type", e?.value);
                    }}
                    name="meeting_type"
                    isLoading={false}
                    loadingMessage={() => "Fetching Meeting Type"}
                    noOptionsMessage={() => "Meeting Type appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="meeting_type" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>Meeting Title</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.meeting_title}
                    name="meeting_title"
                    placeholder="Meeting title"
                  />
                  <ErrorMessage name="meeting_title" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>Meeting Link</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.meeting_link}
                    name="meeting_link"
                    placeholder="Meeting Link"
                  />
                  <ErrorMessage name="meeting_link" component={TextError} />
                </Col>

                <Col md={12}>
                  <Label>Description</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    name="meeting_description"
                    value={values?.meeting_description}
                    placeholder="Description"
                  />
                  <ErrorMessage
                    name="meeting_description"
                    component={TextError}
                  />
                </Col>
                <Col md={6}>
                  <Label>Meeting Start Date</Label>
                  <Input
                    type="datetime-local"
                    onChange={handleChange}
                    name="meeting_start_date"
                    value={values?.meeting_start_date}
                    placeholder="Description"
                  />
                  <ErrorMessage
                    name="meeting_start_date"
                    component={TextError}
                  />
                </Col>
                <Col md={6}>
                  <Label>Meeting End Date</Label>
                  <Input
                    type="datetime-local"
                    onChange={handleChange}
                    name="meeting_end_date"
                    value={values?.meeting_end_date}
                    placeholder="Description"
                  />
                  <ErrorMessage name="meeting_end_date" component={TextError} />
                </Col>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddMeetings;
