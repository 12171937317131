import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddDesignationInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { SetStateAction } from "react";

type AddInitialValues = {
  designation_name: string;
  cadre_id: string;
  block_id: string;
  district: string;
  office_id: string;
  designation_meta: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (id) {
    const designationMetaArray = Array?.isArray(values?.designation_meta)
      ? values.designation_meta
      : [values.designation_meta];
    api
      .patch(
        `/designation`,
        {
          cadre_id: values?.cadre_id,
          office_cat_id: values?.office_id,
          designation_meta_id: designationMetaArray,
          district_id: values?.district,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Designation Details Updated",{toastId:"DesignationUpdated"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Designationupdaterr"});
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/designation",
        {
          cadre_id: values?.cadre_id,
          district_id: values?.district,
          office_cat_id: values?.office_id,
          designation_meta_id: values?.designation_meta,
          district_status: values?.district === "0" ? 1 : 2,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Designation Added ",{toastId:"Designationadd"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Designationadder"});
        }
      });
  }
};

// Extract department data for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.items?.map((item: any, index: number) => {
    const id = index.toString();

    return {
      id: id,
      name: item?.name,
      cadre: item?.cadre_name,
      cadre_id: item?.cadre_id,
      district_name: item?.district_name,
      district_id: item?.district_id,
      officeCategory_id: item?.officeCategory_id,
      designation_metas: item?.designation_metas,
      officeCategory_name: item?.officeCategory_name,
    };
  });

  return res;
};

// Extract department data for table
export const extractSData = (data: any) => {
  const res = data?.data?.meta?.map((item: any, index: number) => {
    const id = index.toString();

    return {
      id: id,
      name: item?.name,
      cadre: item?.cadre_name,
      cadre_id: data?.data?.cadre_id,
      district_name: data?.data?.district_name,
      district_id: data?.data?.district_id,
      officeCategory_id: data?.data?.officeCategory_id,
      designation_metas: item?.designation_metas,
    };
  });

  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  setDistrictId: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    let new_desigantion_array: any = [];

    modalEditData?.meta?.map((item: any) => {
      new_desigantion_array?.push(item?.id);
    });
    setAddModalInitialValues({
      id: modalEditData?.id,
      designation_meta: new_desigantion_array,
      cadre: modalEditData?.cadre,
      cadre_id: modalEditData?.cadre_id,
      district:
        modalEditData?.district_id === null ? "0" : modalEditData?.district_id,
      office_id: modalEditData?.officeCategory_id,
    });
    setDistrictId(modalEditData?.district_id);
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddDesignationInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(selectedId);
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

export const deleteClickHandler = async (
  selectedId: string | number,
  refetch: Function,
  setModalEditData: Function,
  designationfull: any,
  modalEditData: any
) => {
  const selectedItem = designationfull.find(
    (item: any) => item.id === selectedId
  );

  if (!selectedItem) {
    console.error("Item not found in List");
    return;
  }
  setModalEditData(selectedItem);

  try {
    const [success, response] = await api.delete(
      `/designation`,
      {
        cadre_id: selectedItem.cadre_id,
        office_cat_id: selectedItem.officeCategory_id,
      },
      true
    );
    if (success) {
      refetch();
      toast.success("Deleted",{toastId:"Designationdelete"});
    } else {
      toast.error("Delete request failed",{toastId:"requestfail"});
    }
  } catch (error) {
    toast.error("An unexpected error occurred",{toastId:"requestfailerr"});
    console.error("Delete request error:", error);
  }
};

export const setCurrentPageStaff = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`member/designation-management/${pageNo}/2`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/designation-management/${pageNo}/2`);
  }
};
