import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  AddAssetInitialValues,
  AssetValidationSchema,
} from "./AddAssetsMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddAssetsMethods/AddAssetModalMethods";
import { AddAssetProps } from "../../../../../Type";
import Select from "react-select";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import Subcategory from "./AddAssetsMethods/SubCategory";

const AddAssets = ({
  setIsOpen,
  modalEditData,
  refetch,
  category,
  setSubCatId,
  subcategory,
  office,
  isOpen,
}: AddAssetProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddAssetInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [imageView, seImageView] = useState("");
  const [categorye, setCategory] = useState(false);
  const [image_change, setImageChange] = useState(false);
  const [type_file, setFileType] = useState();
  const create_imageURL = (imgfile: any) => {
    seImageView(URL?.createObjectURL(imgfile));
    setFileType(imgfile?.type);
    setImageChange(true);
  };

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={AssetValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={6}>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.title}
                    name="title"
                    placeholder="Title"
                  />
                  <ErrorMessage name="title" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Office</Label>
                  <Select
                    placeholder="Select Office"
                    name="office"
                    options={office}
                    value={
                      office?.filter(
                        (itm: any) => itm?.value === values?.office
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("office", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Office"}
                    noOptionsMessage={() => "Office appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="office" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Category</Label>
                  <Select
                    placeholder="Select Category"
                    name="category"
                    options={category}
                    value={
                      category?.filter(
                        (itm: any) => itm?.value === values?.category
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("category", e?.value);
                      setSubCatId(e?.value);
                      setCategory(true);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Category"}
                    noOptionsMessage={() => "Category appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="category" component={TextError} />
                </Col>

                {categorye === true ? (
                  <>
                    <Col md={12}>
                      <Label>
                        <b>Sub Category</b>
                      </Label>
                      <Subcategory
                        subcategory={subcategory ? subcategory : []}
                        setFieldValue={setFieldValue}
                        isOpen={isOpen}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    {modalEditData?.id ? (
                      <>
                        {" "}
                        {values?.sub_category && (
                          <>
                            <Col md={12}>
                              <Label>
                                <b>Sub Category</b>
                              </Label>
                              <Subcategory
                                subcategory={values?.sub_category}
                                setFieldValue={setFieldValue}
                                isOpen={isOpen}
                              />
                            </Col>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                )}

                <Col md={6}>
                  <Label>Asset Value</Label>
                  <Input
                    type="number"
                    name="asset_value"
                    onWheel={(e: any) => e.target.blur()}
                    onChange={handleChange}
                    value={values?.asset_value}
                    placeholder="Asset Value"
                  />
                  <ErrorMessage name="asset_value" component={TextError} />
                </Col>
                {/* <Col md={6}>
                  <Label>Asset Document</Label>
                  <Input
                    type="file"
                    name="asset_document"
                    onChange={(e: any) => {
                      setFieldValue("asset_document", e?.target?.files[0]);
                      create_imageURL(e?.target?.files[0]);
                    }}
                    placeholder="Asset Document"
                  />
                  <ErrorMessage name="asset_document" component={TextError} />
                </Col> */}
                {modalEditData?.document_file_url?.includes("pdf") ? (
                  <Col md={6}>
                    <embed src={modalEditData?.document_file_url} />
                  </Col>
                ) : <img
                    src={
                      image_change === true
                        ? imageView
                        : modalEditData?.document_file_url
                        ? modalEditData?.document_file_url
                        : ""
                    }
                    alt={modalEditData?.document_file_url}
                  /> ? (
                  type_file === "application/pdf" ? (
                    <Col md={6}>
                      <embed src={modalEditData?.document_file_url} />
                    </Col>
                  ) : (
                    <img
                      src={
                        image_change === true
                          ? imageView
                          : modalEditData?.document_file_url
                          ? modalEditData?.document_file_url
                          : ""
                      }
                      alt={modalEditData?.document_file_url}
                    />
                  )
                ) : (
                  ""
                )}

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAssets;
