import * as Yup from "yup";

export const TestimonialValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Feild is required")
    .max(100, "The Feild must not exceed 100 characters."),
  designation: Yup.string()
    .required("Feild is required")
    .max(250, "The Feild must not exceed 250 characters."),
  testimonial: Yup.string()
    .required("Feild is required")
    .min(10, "The Feild must 10 characters."),
});

export const AddTestimonialInitialValues = {
  name: "",
  testimonial: "",
  designation: "",
};
