import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { AddMeetingsInitialValues, MeetingsValidationSchema } from "./AddAnnouncementMethods/FormikValidation";
import { MeetingSubmitHandler, addModalInitialValuesHandler } from "./AddAnnouncementMethods/AddMeetingsModalMethods";


const AddAnnouncement = ({
  setIsOpen,
  modalEditData,
  refetch,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddMeetingsInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);


  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);
  const [imageView, seImageView] = useState("");
  const [imagefile, setImageFile] = useState("");
  const [image_change, setImageChange] = useState(false);
  const [type_file, setFileType] = useState();
  const create_imageURL = (imgfile: any) => {
    seImageView(URL?.createObjectURL(imgfile));
    setFileType(imgfile?.type);
    setImageChange(true);
  };

  return (
    <Formik
      validationSchema={MeetingsValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        MeetingSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          imagefile
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        console.log(errors, "errors");

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                
                <Col lg={12}>
                  <Label>Announcement Title</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.meeting_title}
                    name="meeting_title"
                    placeholder="Announcement title"
                  />
                  <ErrorMessage name="meeting_title" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>Announcement Link</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.meeting_link}
                    name="meeting_link"
                    placeholder="Announcement Link"
                  />
                  <ErrorMessage name="meeting_link" component={TextError} />
                </Col>

                <Col md={12}>
                  <Label>Body</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    name="meeting_description"
                    value={values?.meeting_description}
                    placeholder="Body"
                  />
                  <ErrorMessage
                    name="meeting_description"
                    component={TextError}
                  />
                </Col>

                <Col md={12}>
                  <Label>Document</Label>
                  <Input
                    type="file"
                    onChange={(e: any) => {
                      setImageFile(e.target.files[0]);
                      setFieldValue("document", e.target.files[0]);
                      create_imageURL(e.target.files[0]);
                    }}
                    name="document"
                    placeholder="Body"
                    className="mb-3"
                  />
                  <ErrorMessage
                    name="document"
                    component={TextError}
                  />
              

                </Col>
                {type_file === "application/pdf" ? (
                  <div>
                    <embed src={imageView} />
                  </div>
                ) : (
                  <img
                    src={
                      image_change === true
                        ? imageView
                        : modalEditData?.[0]?.document
                          ? modalEditData?.[0]?.document
                          : ""
                    }
                    alt={modalEditData?.[0]?.documents}
                  />
                )}
              

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAnnouncement;
