import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddSeatMethods/AddSeatModalMethods";
import {
  AddSeatInitialValues,
  SeatValidationSchema,
} from "./AddSeatMethods/FormikValidation";
import { AddSeatProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
const AddSeat = ({
  setIsOpen,
  modalEditData,
  refetch,
  designation,
  cadre,
  id,
  value,
  setValue,
}: AddSeatProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddSeatInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={SeatValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.id,
          id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
       
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Seat Count</Label>
                  <Input
                    type="number"
                    onChange={handleChange}
                    value={values?.seat_name}
                    name="seat_name"
                    placeholder="Seat Count"
                    min={0}
                  />
                  <ErrorMessage name="seat_name" component={TextError} />
                </Col>

                <Col md={12}>
                  <Label>Cadre</Label>
                  <Select
                    placeholder="Select Cadre"
                    className="select-cadre"
                    name="role"
                    options={cadre}
                    value={
                      cadre?.filter(
                        (itm: any) => itm?.value === values?.cadre
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("cadre", e?.value);
                      setValue({
                        ...value,
                        cader_value: e?.value,
                      });
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Cadre"}
                    noOptionsMessage={() => "Cadre appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="cadre" component={TextError} />
                </Col>

                {/* <Col md={12}>
                  <Label>Designation</Label>
                  <Select
                    placeholder="Select Designation"
                    name="designation"
                    options={designation}
                    value={
                      designation?.filter(
                        (itm: any) => itm?.value === values?.designation
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("designation", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Designation"}
                    noOptionsMessage={() => "Designation appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="designation" component={TextError} />
                </Col> */}
                {/* <Col md={6}>
                  <Label>Office</Label>
                  <Select
                    placeholder="Select Office"
                    name="office"
                    options={office}
                    value={
                      office?.filter(
                        (itm: any) => itm?.value === values?.office
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("office", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Office"}
                    noOptionsMessage={() => "Office appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="office" component={TextError} />
                </Col> */}

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddSeat;
