import * as Yup from "yup";

export const RoleValidationSchema = Yup.object().shape({
  role_name: Yup.string().required("Field is required"),
  permissions: Yup.array()
    .of(Yup.string().required("Permission is required")) // Adjust the error message
    .min(1, "At least one permission is required"),
});

export const AddRoleInitialValues = {
  role_name: "",
  permissions: [],
};
