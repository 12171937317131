import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddPDDInitialValues } from "./FormikValidation";
import { api } from "../../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../../Utils/helpers/helpers";
// import PEST_DISEASE_DEFICIENCY from "../../../../../../../Utils/Constants"

type AddInitialValues = {
  crop: string;
  pdd: string;
  type: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  
  if (editMode) {
    api
      .patch(
        `/crop-category/${id}`,
        {
          crop: parseInt(values?.crop),
          pest_disease_nutrition_def: values?.pdd,
          type: values?.type
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Crop Categorey Updated");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
  // Add 
  else {
    api
      .post(
        "/crop-category",
        {
          crop: parseInt(values?.crop),
          pest_disease_nutrition_def: values?.pdd,
          type: values?.type
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Crop Categorey Added ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      crop: item?.crop?.name,
      pest_disease_nutrition_def: item?.pest_disease_nutrition_def,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      crop: modalEditData?.[0]?.crop?.id,
      pdd: modalEditData?.[0]?.pest_disease_nutrition_def,
      type: modalEditData?.[0]?.type
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddPDDInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/crop-category/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/pdd/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/pdd/${pageNo}`)
  }
};
