import { api } from "../../../../../Api/Api";
import { BaseUrl } from "../../../../../Api/BaseUrl";

export const extractStaffData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const res = data?.data?.items?.map((item: any, index: number) => {
    return {
      id: item?.id,
      district_name: item?.district_name,
      office_name: item?.office_name,
      office_member_status: item?.office_member_status,
      remaining_seat_count: item?.remaining_seat_count,
      seat_count: item?.seat_count,
      cadre_name: item?.cadre_name,
    };
  });
  return res;
};

export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    setSearchQuery(e?.target?.value);
  } else {
    setSearchQuery("");
  }
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/vacancyreport-list/${pageNo}`);
};

export const handleDownloadClick = () => {
  // const requestData = {
  //   year,
  // };
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Content-Type": "application/json",
      responseType: "blob",
    },
    // body: JSON.stringify({ requestData }),
  };
  fetch(`${BaseUrl}/exports/vacancy-details`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "VacancyList.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      return blob;
    })
    .catch((e) => {
      console.log(e, ">>>");
    });
};
