import { useMemo, useState, useEffect } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddRoles/AddRolesMethods/AddRoleModalMethods";
import AddRole from "./AddRoles";
import {
  getOfficeList,
  getPermissionList,
  getRoleList,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./RolesMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import { Formik } from "formik";
import {
  AddRoleInitialValues,
  RoleValidationSchema,
} from "./AddRoles/AddRolesMethods/FormikValidation";
import { api } from "../../../../Api/Api";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const RoleIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [length_of_slect, setLength] = useState();
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddRoleInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValues] = useState({ permissions: [] });
  const [not, setNot] = useState([]);
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  const {
    data: role,
    error: roleError,
    isLoading: roleLoading,
    refetch: roleListRefetch,
  }: any = useQuery(
    [`staff${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getRoleList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const { data: permission }: any = useQuery(
    [`permission${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getPermissionList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const editdata = (id: any) => {
    api
      .get(`/role/${id}`, true)
      .then(async function ([success, response]) {
        if (response?.data === null) {
          setNot(permission);
        } else {
          let data = response?.data?.permission;
          setLength(response?.data?.permission?.length);
          let second_data = permission;
          second_data?.map((item: any) => {
            item.is_check = false;
          });
          data?.map((item: any) => {
            item.is_check = true;
          });
          let r = [...data, ...second_data];
          // Create a Set to keep track of unique 'id' values in order
          const uniqueIdsSet = new Set();

          // Filter the array to keep only the first occurrence of each unique 'id'
          const uniqueArray: any = r?.filter((item: any) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              return true;
            }
            return false;
          });

          // Sort the array based on the 'id' property
          uniqueArray?.sort((a: any, b: any) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );

          let final_array: any = [];
          final_array?.push({
            id: response?.data?.id,
            name: response?.data?.name,
            permission: uniqueArray,
          });

          setModalEditData(final_array);
        }

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const staffData = useMemo(() => extractStaffData(role), [role]);

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Role Management", link: "/admin/roles" },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Role Management", link: "/member/roles/1" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("roles:write") ? (
                    <Button
                      onClick={() => {
                        modalHandler(setModalEditData, setIsOpen);
                        editdata(0);
                      }}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Role
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Role Details"
              tableFields={["Role Name"]}
              Arraykeys={["name"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("roles:write") ? "edit" : "",
                checkPermission("roles:delete") ? "delete" : "",
              ]}
              isLoading={roleLoading}
              error={roleError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("roles:write")) {
                  editdata(selectedId);
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    role
                  );
                }
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, roleListRefetch)
              }
              paginationData={role?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </div>
      </Container>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Role" : "Add Role"}
      >
        <Formik
          validationSchema={RoleValidationSchema}
          initialValues={addModalInitialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            StaffSubmitHandler(
              values,
              actions,
              setIsOpen,
              roleListRefetch,
              editMode,
              modalEditData?.[0]?.id
            );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            isSubmitting,
            values,
            setFieldValue,
            setFieldError,
            errors,
          }) => {
            return (
              <AddRole
                setIsOpen={setIsOpen}
                modalEditData={modalEditData}
                refetch={roleListRefetch}
                permission={modalEditData?.[0]?.permission || not}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                values={values}
                setFieldValue={setFieldValue}
                length_of_slect={length_of_slect}
              />
            );
          }}
        </Formik>
      </ModalContainer>
    </section>
  );
};

export default RoleIndex;
