import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { AddOfficeCategoryInitialValues } from "./FormikValidation";

type AddInitialValues = {
  office_cat_name: any;
  district_id: any;
  block_id: any;
};
type NewCategory = {
  name: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  edit_cat: any
) => {
  // Add

  if (id) {
    if (edit_cat === true) {
      api
        .patch(
          `/office-category/${id}`,
          {
            name: values?.office_cat_name,
            district_id: Number(values?.district_id),
            block_id: values?.block_id,
          },
          true
        )
        .then(async function ([success, response]: any) {
          toast.success("Office Categorey Updated ",{toastId:"officecategory"});
          setIsOpen(false);
          refetch();
        })
        .catch((err) => {
          actions?.setSubmitting(false);
          if (err?.response?.data?.errors) {
            setValidationErrors(err?.response?.data?.errors, actions);
          } else {
            toast.error("Unexpected Error Occurred",{toastId:"officecategoryerror"});
          }
        });
    } else {
      api
        .patch(
          `/office-category/${id}`,
          {
            district_id: Number(values?.district_id),
            block_id: values?.block_id,
            office_cat_id: parseInt(values?.office_cat_name),
          },
          true
        )
        .then(async function ([success, response]: any) {
          toast.success("Office Categorey Updated ",{toastId:"updatedcategory"});
          setIsOpen(false);
          refetch();
        })
        .catch((err) => {
          actions?.setSubmitting(false);
          if (err?.response?.data?.errors) {
            setValidationErrors(err?.response?.data?.errors, actions);
          } else {
            toast.error("Unexpected Error Occurred",{toastId:"updatedcategoryerr"});
          }
        });
    }
  }
  // Edit
  else {
    if (values?.block_id?.length !== 0) {
      if (edit_cat === true) {
        api
          .post(
            "/office-category",
            {
              name: values?.office_cat_name,
              district_id: Number(values?.district_id),
              block_id: values?.block_id,
            },
            true
          )
          .then(async function ([success, response]: any) {
            toast.success("Office Categorey Added ",{toastId:"categoryAdded"});
            setIsOpen(false);
            refetch();
          })
          .catch((err) => {
            actions?.setSubmitting(false);
            if (err?.response?.data?.errors) {
              setValidationErrors(err?.response?.data?.errors, actions);
            } else {
              toast.error("Unexpected Error Occurred",{toastId:"updatedcategoryerrr"});
            }
          });
      } else {
        api
          .post(
            "/office-category",
            {
              district_id: Number(values?.district_id),
              block_id: values?.block_id,
              office_cat_id: parseInt(values?.office_cat_name),
            },
            true
          )
          .then(async function ([success, response]: any) {
            toast.success("Office Categorey Added ",{toastId:"officecategoryadded"});
            setIsOpen(false);
            refetch();
          })
          .catch((err) => {
            actions?.setSubmitting(false);
            if (err?.response?.data?.errors) {
              setValidationErrors(err?.response?.data?.errors, actions);
            } else {
              toast.error("Unexpected Error Occurred",{toastId:"officecategoryadderr"});
            }
          });
      }
    } else {
      if (edit_cat === true) {
        api
          .post(
            "/office-category",
            {
              name: values?.office_cat_name,
              district_id: Number(values?.district_id),
            },
            true
          )
          .then(async function ([success, response]: any) {
            toast.success("Office Categorey Added ",{toastId:"officecatadd"});
            setIsOpen(false);
            refetch();
          })
          .catch((err) => {
            actions?.setSubmitting(false);
            if (err?.response?.data?.errors) {
              setValidationErrors(err?.response?.data?.errors, actions);
            } else {
              toast.error("Unexpected Error Occurred",{toastId:"officecataddederr"});
            }
          });
      } else {
        api
          .post(
            "/office-category",
            {
              district_id: Number(values?.district_id),
              office_cat_id: values?.office_cat_name,
            },
            true
          )
          .then(async function ([success, response]: any) {
            toast.success("Office Categorey Added ",{toastId:"addcat"});
            setIsOpen(false);
            refetch();
          })
          .catch((err) => {
            actions?.setSubmitting(false);
            if (err?.response?.data?.errors) {
              setValidationErrors(err?.response?.data?.errors, actions);
            } else {
              toast.error("Unexpected Error Occurred",{toastId:"addcaterr"});
            }
          });
      }
    }
  }
};

// Department delete
export const NewCategoryClickHandler = (
  values: NewCategory,
  actions: FormikHelpers<NewCategory>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  api
    .post(
      "/office-category/category-name",
      {
        name: values?.name,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Office Categorey Added ",{toastId:"addcatoff"});
      refetch();
      actions?.setSubmitting(false);
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"addcatofferr"});
      }
    });
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.items?.map((item: any) => {
    const locationsArray = item?.block_names?.split(",");

    return {
      id: item?.office_category_id,
      name: item?.name,
      district: item?.district_name,
      district_id:item?.district_id,
      block: locationsArray?.map((location: any, index: any) => (
        <div key={index}> {location}</div>
      )),
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    let new_array: any = [];
    modalEditData?.officeCategoryDetails?.map((item: any) => {
      new_array?.push(item?.block?.id);
    });

    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.id,
      office_cat_name: modalEditData?.id,
      district_id: modalEditData?.officeCategoryDetails?.[0]?.district?.id,
      block_id: new_array,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddOfficeCategoryInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  bannerData: any
) => {
  setModalEditData([]);
  setModalEditData(
    bannerData?.items?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/office-category/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"deletecat"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"deletecaterr"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/office-categorey/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/office-categorey/${pageNo}`)
  }
};
