import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddSymptomsInitialValues } from "./FormikValidation";
import { api } from "../../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  crop: string;
  symptoms: any;
  control_measure_description: any;
  // pdd: string;
  name_pdd: string;
  type: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  const symptomsArray = values?.symptoms?.map((item: any) => item.symptoms);
  const combinedSymptoms = symptomsArray?.join("\n");

  const controlArray = values?.control_measure_description?.map(
    (item: any) => item.control_measure_description
  );
  const combinedControl = controlArray?.join("\n");
  // Add

  if (editMode) {
    api
      .patch(
        `/crop-details/${id}`,
        {
          crop: parseInt(values?.crop),
          // cropsCategory: parseInt(values?.pdd),
          name_pest_disease_def: values?.name_pdd,
          type: values?.type,
          symptoms: combinedSymptoms,
          control_measure: combinedControl,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Crop Details Updated ",{toastId:"CropdetailsUpdated"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Please fill required field",{toastId:"croperror"});
        }
      });
  }
  // edit
  else {
    api
      .post(
        "/crop-details",
        {
          crop: parseInt(values?.crop),
          // cropsCategory: parseInt(values?.pdd),
          name_pest_disease_def: values?.name_pdd,
          type: values?.type,
          symptoms: combinedSymptoms,
          control_measure: combinedControl,
        },
        true
      )
      .then(async function ([success, response]: any) {        
        toast.success("Crop Details Added ",{toastId:"Cropdetailsadded"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Please fill required field",{toastId:"Cropdetailserr"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      crop: item?.crop?.name,
      cropsCategory: item?.cropsCategory?.pest_disease_nutrition_def,
      name_pest_disease_def: item?.name_pest_disease_def,
      symptoms: item?.symptoms,
      control_measure: item?.control_measure,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    let symptoms_array: any = [];
    let control_array: any = [];
    modalEditData?.[0]?.symptoms?.split("\n")?.map((item: any) => {
      symptoms_array?.push({
        symptoms: item,
      });
    });
    
    modalEditData?.[0]?.control_measure?.split("\n")?.map((item: any) => {
      control_array?.push({
        control_measure_description: item,
      });
    });
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      crop: modalEditData?.[0]?.crop?.id,
      symptoms: symptoms_array,
      control_measure_description:
      control_array,
      pdd: modalEditData?.[0]?.cropsCategory?.id,
      name_pdd: modalEditData?.[0]?.name_pest_disease_def,
      type: modalEditData?.[0]?.type
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddSymptomsInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/crop-details/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Cropdetailsdelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Cropdetailsdeleterr"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/symptoms/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/symptoms/${pageNo}`)
  }
};
