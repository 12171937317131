import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddTestimonialProps } from "../../../../../../Type";
import {
  AddTestimonialInitialValues,
  TestimonialValidationSchema,
} from "./AddVisionphotoMethods/FormikValidation";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  TestimonialSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddVisionphotoMethods/AddvisionphotoMethods";
import CroppedImages from "../../crop/croppedimages";


const AddTestimonial = ({
  setIsOpen,
  refetch,
  modalEditData,
}: AddTestimonialProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddTestimonialInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);


  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  const [imageView, seImageView] = useState("");
  const [image_change, setImageChange] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [imagefile, setImageFile] = useState("");
  const create_imageURL = (imgfile: any) => {
    if (imgfile) {
      seImageView(URL.createObjectURL(imgfile));
      setImageChange(true);
    }
  };

  const handleDeletes = (
    img: any,
    indexs: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      "image",
      values?.image?.filter((_: any, index: any) => index !== indexs)
    );
    const s1 = images?.filter((key, index) => index !== indexs);
    setImages(s1);
  };


  return (
    <Formik
      validationSchema={TestimonialValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        TestimonialSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          imagefile
        );
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        values,
      }) => {
        const handleCrop = (croppedImage: string) => {
          setCroppedImage(croppedImage);
          setFieldValue("image", croppedImage);
        };

        return (
          <>
            {" "}
            <div className="Add-web-settings-wrap">
              <div className="form-wrap">
                <Form onSubmit={handleSubmit}>
                  <Row className="gy-4">
                    <Col>
                      <Label>Vision Photo</Label>
                      <div className="add-banner-thumb-wrap">
                        <div className="image-thumbnail-wrap">
                          {images?.length > 0 &&
                            images.map((item: any, index, key) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="display-image" key={index}>
                                    <i
                                      className="icon icon-close-red"
                                      onClick={() =>
                                        handleDeletes(
                                          item,
                                          index,
                                          setFieldValue,
                                          values
                                        )
                                      }
                                      title="Remove photo"
                                    ></i>
                                    <img
                                      src={URL.createObjectURL(item)}
                                      alt=""
                                      className="img-fluid "
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>

                        <div className="image-thumbnail-wrap">


                          <img
                            src={
                              image_change === true
                                ? imageView
                                : modalEditData?.[0]?.image
                                  ? modalEditData?.[0]?.image
                                  : "/images/empty-banner.webp"
                            }
                            alt={modalEditData?.[0]?.banner_image}
                            className="website-image"
                          />  

                            <CroppedImages
                               src={
                                imagefile
                                  ? URL.createObjectURL(new Blob([imagefile]))
                                  : modalEditData?.[0]?.image
                              }
                              onCrop={handleCrop}
                              from_vision={true}
                            />

                          </div>

                          <Input
                            id="cha"
                            type="file"
                            onChange={(e: any) => {
                              setImageFile(e.target.files[0]);
                              setFieldValue("image", e.target.files[0]);
                              create_imageURL(e.target.files[0]);
                            }}
                            name="image"
                            placeholder="Banner File"
                            multiple
                          />

                          {/* <Link to="#">Choose Image</Link> */}
                          <ErrorMessage name="image" component={TextError} />
                        </div>
                    </Col>
                    <Col lg={12}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Submit"
                        color="primary"
                        className="sm submit-btn"
                      />
                    </Col>
                  </Row>
                </Form>



              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddTestimonial;
