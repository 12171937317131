import { Col, Form, Input, Row } from "reactstrap";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { ErrorMessage, Formik } from "formik";
import {
  AddNewOfficeCategoryInitialValues,
  NewOfficeCategoryValidationSchema,
} from "../AddOfficeCategory/AddOfficeCategoryMethods/FormikValidation";
import { NewCategoryClickHandler } from "../AddOfficeCategory/AddOfficeCategoryMethods/AddOfficeCategoryModalMethods";
import TextError from "../../../../../Components/FormikError/TextError";

const NewCategory = ({
  setIsOpen,
  refetch,
  editMode,
  modalEditData,
  edit_cat,
  office_fullRefetch
}: any) => {
  return (
    <>
      <Formik
        validationSchema={NewOfficeCategoryValidationSchema}
        initialValues={AddNewOfficeCategoryInitialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          NewCategoryClickHandler(
            values,
            actions,
            setIsOpen,
            office_fullRefetch,
            editMode,
            modalEditData?.[0]?.id
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          errors,
        }) => {
          return (
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col lg={12}>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.name}
                      name="name"
                      placeholder="Office Category"
                    />

                    <ErrorMessage name="name" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default NewCategory;
