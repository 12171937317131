import { api } from "../../../../../Api/Api";
import { BaseUrl } from "../../../../../Api/BaseUrl";
import { ISODateToDateYearConverter } from "../../../../../Utils/helpers/helpers";

export const extractStaffData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const res = data?.data?.items?.map((item: any, index: number) => {
    return {
      id: item?.id,
      district_name: item?.district_name,
      Membership_Fee: item?.Membership_Fee,
      Other_amount_paid: item?.Other_amount_paid,
      Enrollment_Date: ISODateToDateYearConverter(item?.Enrollment_Date),
      pen_no: item?.pen_no,
      total_contributions: item?.total_contributions,
      name: item?.user_name
    };
  });
  return res;
};

export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    setSearchQuery(e?.target?.value);
  } else {
    setSearchQuery("");
  }
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {

  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    navigate(`/admin/membercontribution-report/${pageNo}`);
  } else if (userRole === 'member') {
    navigate(`/member/membercontribution-report/${pageNo}`);
  }

};


export const handleDownloadClick = (year: string, district_id: string) => {

  const requestData = {
    year,
    district_id,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Content-Type": "application/json",
      responseType: "blob",
    },
    body: JSON.stringify(requestData),

  };
  fetch(`${BaseUrl}/exports/member-contribution`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Member Contribution.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      return blob;
    })
    .catch((e) => {
      console.log(e, ">>>");
    });
};
