import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddOfficeProps } from "../../../../../Type";
import Select from "react-select";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  ProfileInitialValues,
  ProfileValidationSchema,
} from "./FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
  getEducationData,
  getEducationListData,
} from "../MemberProfileMethods";
import {
  Bachelor_Degree_SELECT_DATA,
  EMPLOYMENT_TYPE_VALS,
  PRESENT_EMPLOYMENT_STATUS,
  YES_NO_SELECT_DATA,
  YES_NO_SELECT_DATAS,
} from "../../../../../Utils/Constants/selection_options";
import TextError from "../../../../../Components/FormikError/TextError";
import { useQuery } from "react-query";
import CroppedImages from "../../../Admin/WebsiteSettings/crop/croppedimages";
import { handleProfileImageCrop } from "./MemberProfileEditMethods";
import EducationIndex from "./Education";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";

const MemberProfileEdit = ({
  setIsOpen,
  modalEditData,
  refetch,
  filteredEduList,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(ProfileInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [imagefile, setImageFile] = useState("");
  const [imageView, seImageView] = useState("");
  const [isImageCropped, setIsImageCropped] = useState(false);


  const { data: educationData }: any = useQuery(
    [`educationData`],
    () => getEducationData(),
    { keepPreviousData: true }
  );
  const [startDate, setStartDate] = useState<any>();
  const [startDatepg, setStartDatePg] = useState<any>();
  const [startDatedo, setStartDateDo] = useState<any>();
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode,
      filteredEduList,
      seImageView,
      setStartDate,
      setStartDatePg,
      setStartDateDo

    );
  }, [modalEditData]);
  
  return (
    <Formik
      validationSchema={ProfileValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        if (values.profile_pic && !isImageCropped) { // Check if an image is chosen but not cropped
          toast.error("Please crop the image before submitting.");
        } else {
    
        StaffSubmitHandler(
          values,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.id,
          actions
        );
      }
      }}
    
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        errors,
      }) => {
       
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <div className="profile-image-wrap">
                    <div className="image-wrap sm">
                      <img
                        src={
                          imageView ? imageView : "/images/empty-avatar.webp"
                        }
                        alt="profile"
                      />
                    </div>
                    <Input
                      hidden
                      title="Upload the Image"
                      class="form-control"
                      name="profile_pic"
                      type="file"
                      onChange={(e: any) => {
                        setImageFile(e.target.files[0]);
                        setFieldValue("profile_pic", e.target.files[0]);
                      }}
                      id="fileInput"
                    />
                    <ErrorMessage name="profile_pic" component={TextError} />
                    <Label for="fileInput" className="update-btn text-primary">
                      Choose Image
                    </Label>
                  </div>
                  <div>
                    <CroppedImages
                      src={
                        imagefile
                          ? URL.createObjectURL(new Blob([imagefile]))
                          : modalEditData?.[0]?.image
                      }
                      onCrop={(croppedImage: string) =>{
                        handleProfileImageCrop(
                          croppedImage,
                          setFieldValue,
                          seImageView
                        )
                        setIsImageCropped(true); // Set the state to indicate image has been cropped

                      }}
                      from_blogs={true}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.email}
                    name="email"
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Mobile</Label>
                  <Input
                    type="number"
                    onChange={handleChange}
                    name="mobile_number"
                    value={values?.mobile_number}
                    placeholder="Body"
                  />
                  <ErrorMessage name="mobile_number" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Whatsapp Number</Label>
                  <Input
                    type="number"
                    onChange={handleChange}
                    name="mobile_number"
                    value={values?.whatsapp_number}
                    placeholder="Whatsapp number"
                  />
                  <ErrorMessage name="whatsapp_number" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>About me</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    value={values?.about}
                    name="about"
                    placeholder="About me"
                  />
                  <ErrorMessage name="about" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Linkedin profile</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.linkedin_id}
                    name="linkedin_id"
                    placeholder="Linkedin profile"
                  />
                  <ErrorMessage name="linkedin_id" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Twitter profile</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.twitter_id}
                    name="twitter_id"
                    placeholder="Twitter profile"
                  />
                  <ErrorMessage name="twitter_id" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Instagram profile</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.instagram}
                    name="instagram"
                    placeholder="Instagram profile"
                  />
                  <ErrorMessage name="instagram" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Employement Status</Label>
                  <Select
                    placeholder="Select Employment Status"
                    name="employement_status"
                    options={PRESENT_EMPLOYMENT_STATUS}
                    value={
                      PRESENT_EMPLOYMENT_STATUS?.filter(
                        (itm: any) => itm?.value === values?.employment_status
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("from_where", "");
                      setFieldValue("start_date", "");
                      setFieldValue("end_date", "");
                      setFieldValue("employment_status", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Employment status"}
                    noOptionsMessage={() => "Employment status appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage
                    name="employment_status"
                    component={TextError}
                  />
                </Col>

                {/* Deputation only  */}
                {values?.employment_status ===
                  EMPLOYMENT_TYPE_VALS.DEPUTATION && (
                  <Col md={6}>
                    <Label>Deputed To</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      name="from_where"
                      value={values?.from_where}
                      placeholder="Deputed To"
                    />
                    <ErrorMessage name="from_where" component={TextError} />
                  </Col>
                )}

                {/* Leave only  */}
                {values?.employment_status === EMPLOYMENT_TYPE_VALS.LEAVE && (
                  <>
                    <Col md={6}>
                      <Label>Leave Start Date</Label>
                      <Input
                        type="date"
                        onChange={handleChange}
                        name="start_date"
                        placeholder="Start Date"
                        value={values?.start_date}
                      />
                      <ErrorMessage name="start_date" component={TextError} />
                    </Col>
                    <Col md={6}>
                      <Label>Leave End Date</Label>
                      <Input
                        type="date"
                        onChange={handleChange}
                        name="end_date"
                        placeholder="Start Date"
                        value={values?.end_date}
                      />
                      <ErrorMessage name="end_date" component={TextError} />
                    </Col>
                  </>
                )}

                <hr />
                <Row className="mt-3">
                  <Label>
                    {" "}
                    <b>Bachelor Degree Details</b>
                  </Label>
                  <hr />
                  <Col md={6}>
                    <Label>Bachelor Degree</Label>
                    <Select
                      placeholder="Select Bachelor Degree"
                      name="bachelor_degree"
                      options={YES_NO_SELECT_DATAS}
                      value={
                        YES_NO_SELECT_DATAS?.filter(
                          (itm: any) => itm?.value === values?.bachelor_degree
                        ) || ""
                      }
                      onChange={(e: any) => {
                        setFieldValue("year_of_pass", "");
                        setFieldValue("bachelor_degree", e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Bachelor Degree"}
                      noOptionsMessage={() => "Bachelor Degree appears here"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage
                      name="bachelor_degree"
                      component={TextError}
                    />
                  </Col>
                  {/* Bachelor Degree  */}
                  {values?.bachelor_degree === "1" && (
                    <>
                      <Col md={6}>
                        <Label>Bachelor Degree Course</Label>
                        <Select
                          placeholder="Select Bachelor Degree"
                          name="bachelor_degree_course"
                          // options={Bachelor_Degree_SELECT_DATA}
                          options={getEducationListData(
                            educationData,
                            "BACHELORS DEGREE"
                          )}
                          value={
                            educationData?.[0]?.category?.filter(
                              (itm: any) =>
                                itm?.value === values?.bachelor_degree_course
                            ) || ""
                          }
                          onChange={(e: any) => {
                            // setFieldValue("year_of_pass", "");
                            setFieldValue("bachelor_degree_course", e?.value);
                          }}
                          isLoading={false}
                          loadingMessage={() => "Fetching Bachelor Degree"}
                          noOptionsMessage={() =>
                            "Bachelor Degree appears here"
                          }
                          isSearchable={true}
                          isClearable
                        />
                        <ErrorMessage
                          name="bachelor_degree_course"
                          component={TextError}
                        />
                      </Col>
                      <Col Col md={6}>
                        <Label>Bachelor Degree Year Of Pass</Label>

                        <ReactDatePicker
                          // value={values?.year_of_pass}
                          onChange={(date: any) => {
                            setFieldValue("year_of_pass", date);
                            setStartDate(date);
                            
                          }}
                          name="year_of_pass"
                          selected={startDate}
                          showYearPicker
                          dateFormat="yyyy"
                          placeholderText="Year"
                          showDisabledMonthNavigation={false}
                        />
                        <ErrorMessage
                          name="year_of_pass"
                          component={TextError}
                        />
                      </Col>
                      {values?.bachelor_degree_course === "44" && (
                        <>
                          <Col Col md={6}>
                            <Label>Details of other qualification</Label>
                            <Input
                              type="text"
                              onChange={(e: any) => {
                                handleChange(e);
                              }}
                              name="if_any_other_bachelor_degree"
                              placeholder="Details of other qualification"
                              value={values?.if_any_other_bachelor_degree}
                            />
                            <ErrorMessage
                              name="if_any_other_bachelor_degree"
                              component={TextError}
                            />
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
                <hr />
                <Row className="mt-3">
                  <Label>
                    {" "}
                    <b>Post Graduation Degree Details</b>
                  </Label>
                  <hr />
                  {/* Post graduation  */}
                  <Col md={6}>
                    <Label>Post Graduation Degree</Label>
                    <Select
                      placeholder="Select PG Degree"
                      name="pg_degree"
                      options={YES_NO_SELECT_DATA}
                      value={
                        YES_NO_SELECT_DATA?.filter(
                          (itm: any) => itm?.value === values?.pg_degree
                        ) || ""
                      }
                      onChange={(e: any) => {
                        // setFieldValue("pg_year_of_pass", "");
                        setFieldValue("pg_degree", e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Post Graduation Degree"}
                      noOptionsMessage={() =>
                        "Post Graduation Degree appears here"
                      }
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage name="pg_degree" component={TextError} />
                  </Col>
                  {/* Post Graduation Degree  */}
                  {values?.pg_degree === "1" && (
                    <>
                      <Col Col md={6}>
                        <Label>PG Course</Label>
                        <Select
                          placeholder="Select PG Course"
                          name="pg_course"
                          options={getEducationListData(
                            educationData,
                            "POST GRADUATION"
                          )}
                          value={
                            getEducationListData(
                              educationData,
                              "POST GRADUATION"
                            )?.filter(
                              (itm: any) => itm?.value === values?.pg_course
                            ) || ""
                          }
                          onChange={(e: any) => {
                            setFieldValue("pg_course", e?.value);
                          }}
                          isLoading={false}
                          loadingMessage={() =>
                            "Fetching Post Graduation Courses"
                          }
                          noOptionsMessage={() =>
                            "Post Graduation Courses appears here"
                          }
                          isSearchable={true}
                          isClearable
                        />
                        <ErrorMessage name="pg_course" component={TextError} />
                      </Col>
                      <Col Col md={6}>
                        <Label>Post Graduation Year Of Pass</Label>

                        <ReactDatePicker
                          // value={values?.pg_year_of_pass}
                          onChange={(date: any) => {
                            setFieldValue("pg_year_of_pass", date);
                            setStartDatePg(date);
                          }}
                          name="pg_year_of_pass"
                          selected={startDatepg}
                          showYearPicker
                          dateFormat="yyyy"
                          placeholderText="Year"
                          showDisabledMonthNavigation={false}
                        />
                        <ErrorMessage
                          name="pg_year_of_pass"
                          component={TextError}
                        />
                      </Col>
                      {values?.pg_course === "21" && (
                        <Col Col md={6}>
                          <Label>Details of other qualification </Label>
                          <Input
                            type="text"
                            onChange={(e: any) => {
                              handleChange(e);
                            }}
                            name="pg_other"
                            placeholder="Details of other qualification"
                            value={values?.pg_other}
                          />
                          <ErrorMessage name="pg_other" component={TextError} />
                        </Col>
                      )}
                    </>
                  )}
                </Row>

                <hr />
                <Row className="mt-3">
                  <Col md={6}>
                    <Label>Doctoral Degree</Label>
                    <Select
                      placeholder="Select PG Degree"
                      name="doctoral_degree"
                      options={YES_NO_SELECT_DATA}
                      value={
                        YES_NO_SELECT_DATA?.filter(
                          (itm: any) => itm?.value === values?.doctoral_degree
                        ) || ""
                      }
                      onChange={(e: any) => {
                        // setFieldValue("doctoral_degree_pass", "");
                        setFieldValue("doctoral_degree", e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Post Graduation Degree"}
                      noOptionsMessage={() =>
                        "Post Graduation Degree appears here"
                      }
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage
                      name="doctoral_degree"
                      component={TextError}
                    />
                  </Col>
                  {/* Doctoral Degree */}
                  {values?.doctoral_degree === "1" && (
                    <>
                      <Col Col md={6}>
                        <Label>Doctoral Degree Course</Label>
                        <Select
                          placeholder="Select Doctoral Degree Course"
                          name="doctoral_degree_course"
                          options={getEducationListData(
                            educationData,
                            "DOCTORAL DEGREE"
                          )}
                          value={
                            getEducationListData(
                              educationData,
                              "DOCTORAL DEGREE"
                            )?.filter(
                              (itm: any) =>
                                itm?.value === values?.doctoral_degree_course
                            ) || ""
                          }
                          onChange={(e: any) => {
                            setFieldValue("doctoral_degree_course", e?.value);
                          }}
                          isLoading={false}
                          loadingMessage={() =>
                            "Fetching Doctoral Degree Courses"
                          }
                          noOptionsMessage={() =>
                            "Doctoral Degree Courses appears here"
                          }
                          isSearchable
                          isClearable
                        />
                        <ErrorMessage
                          name="doctoral_degree_course"
                          component={TextError}
                        />
                      </Col>
                      <Col Col md={6}>
                        <Label>Doctoral Degree Year Of Pass</Label>

                        <ReactDatePicker
                          // value={values?.doctoral_degree_pass}
                          onChange={(date: any) => {
                            setFieldValue("doctoral_degree_pass", date);
                            setStartDateDo(date);
                          }}
                          name="doctoral_degree_pass"
                          selected={startDatedo}
                          showYearPicker
                          dateFormat="yyyy"
                          placeholderText="Year"
                          showDisabledMonthNavigation={false}
                        />
                        <ErrorMessage
                          name="doctoral_degree_pass"
                          component={TextError}
                        />
                      </Col>
                      {values?.doctoral_degree_course === "38" && (
                        <Col Col md={6}>
                          <Label>Details of other qualification</Label>
                          <Input
                            type="text"
                            onChange={(e: any) => {
                              handleChange(e);
                            }}
                            name="doctoral_degree_pg_other"
                            placeholder="Details of other qualification"
                            value={values?.doctoral_degree_pg_other}
                          />
                          <ErrorMessage
                            name="doctoral_degree_pg_other"
                            component={TextError}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </Row>

                <hr />
                <Row className="mt-3">
                  <EducationIndex
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    educationData={educationData}
                  />
                </Row>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                    // disabled={!isImageCropped} // Disable if image is not cropped
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default MemberProfileEdit;
