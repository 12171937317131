import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../../Api/Api";
import { AddTestimonialInitialValues } from "./FormikValidation";
import axios from "axios";
import { BaseUrl } from "../../../../../../../Api/BaseUrl";

type AddInitialValues = {
  image: any;
  title: any;
};

export const TestimonialSubmitHandler = async (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  formattedImages:any

) => {
  // Edit

  if (editMode) {
    try {
      const formData = new FormData();
      formattedImages.forEach((imageData: { id: number, images: File }) => {
        formData.append("images", imageData.images);
      });
      formData.append("title", values?.title);
      const res = await axios.patch(
        `${BaseUrl}/gallery/${id}`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
          },
        }
      );
      if (res) {
        toast.success("Gallery updated",{toastId:"Galleryupdate"});
        setIsOpen(false);
        refetch();
      }
    } catch (error: any) {
      actions?.setSubmitting(false);
      actions.setFieldError("image", error?.response?.data?.errors?.image);
      actions.setFieldError("title", error?.response?.data?.errors?.name);
    }
  } else {
    // add
    try {
      const formData = new FormData();

      formattedImages.forEach((imageData: { id: number, images: File }) => {
        formData.append("images", imageData.images);
      });
      
      formData.append("title", values?.title);
      const res = await axios.post(`${BaseUrl}/gallery`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
        },
      });
      if (res) {
        toast.success("Gallery added",{toastId:"Galleryadded"});
        setIsOpen(false);
        refetch();
      }
    } catch (error: any) {
      actions?.setSubmitting(false);
      actions.setFieldError("image", error?.response?.data?.errors?.image);
      actions.setFieldError("title", error?.response?.data?.errors?.name);
    }
  }
};


// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => 
{
  if (modalEditData?.length !== 0) {

    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      title: modalEditData?.[0]?.title,
      image: modalEditData?.[0]?.galleryImage?.images,

    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddTestimonialInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  testimonialData: any
) => {
  setModalEditData([]);
  setModalEditData(
    testimonialData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/gallery/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Gallerydelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Gallerydeleterr"});
    });
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .put(`/gallery/${selectedId}?status=${newStatus}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated",{toastId:"Galleryupdate"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Galleryupdateerr"});
    });
};
export const setCurrentPageaward = (navigate: Function, pageNo: any) => {
  navigate(`/admin/staff/${pageNo}`);
};
