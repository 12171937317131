import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "reactstrap";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AssociativeMemberApply from "./AssociateMembershipApply";
import { getOpenDistrict } from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { getMemberProfileData } from "../MemberProfile/MemberProfileMethods";

export default function AssociativeMemberDashboard() {
  const navigate = useNavigate();

  const handleApplyClick = () => {
    setIsOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const { data: districtList, isLoading } = useQuery(
    ["districtopen"],
    () => getOpenDistrict(),
    {
      keepPreviousData: true,
    }
  );
  const { data: memberProfileData, refetch: memberProfileDataRefetch }: any =
    useQuery([`profile_data`], () => getMemberProfileData(), {
      keepPreviousData: true,
    });
  return (
    <>
      <section>
        <div className="content-outer-wrap">
          {memberProfileData?.associative_status === 3 && (
            <Container>
              <h3>ASSOCIATE Membership Applied Successfully </h3>
              <span>Pending</span>
            </Container>
          )}
          {memberProfileData?.associative_status === 2 && (
            <Container>
              <h3>ASSOCIATE Membership Rejected </h3>
              <Button onClick={handleApplyClick}>Apply</Button>
            </Container>
          )}{" "}
          {memberProfileData?.associative_status === 1 && (
            <Container>
              <h3>ASSOCIATE Membership Approved Successfully </h3>
            </Container>
          )}
          {memberProfileData?.associative_status === null && (
            <Container>
              <h3>You are eligible for ASSOCIATE Membership</h3>
              <Button onClick={handleApplyClick}>Apply</Button>
            </Container>
          )}
        </div>
        <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title={""}>
          <AssociativeMemberApply
            setIsOpen={setIsOpen}
            refetch={memberProfileDataRefetch}
            district={districtList}
          />
        </ModalContainer>
      </section>
    </>
  );
}
