import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";

import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddAssetCategoryMethods/AddAssetCategoryModalMethods";
import {
  AddAssetCategoryInitialValues,
  AssetCategoryValidationSchema,
} from "./AddAssetCategoryMethods/FormikValidation";
import { AddSeatProps } from "../../../../../Type";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../Components/FormikError/TextError";

const AddAssetCategory = ({
  setIsOpen,
  modalEditData,
  refetch,
}: AddSeatProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddAssetCategoryInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={AssetCategoryValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Asset Category Name</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.name}
                    name="name"
                    placeholder="Asset Category Name"
                  />
                  <ErrorMessage name="name" component={TextError} />
                </Col>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAssetCategory;
