import { Button, Col, Row } from "reactstrap";
import { VerifySubmitHandler } from "./AddMemberMethods/AddMemberModalMethods";

const ConfirmAlertModal = (props: any) => {
  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Once verified Pen number, Date of birth and Date of joining
                service of the employee cannot be edited,
                <br/><br/><b>Do you wish to continue .</b>
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"primary"}
                className="sm"
                onClick={() => {
                  VerifySubmitHandler(
                    props?.setIsOpen,
                    props?.preview_id,
                    props?.actions,
                    props?.Refetch,
                    props?.member_block_verify,
                    props?.setIsOpens,
                    props?.values
                  );
                }}
              >
                Yes
              </Button>
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setIsOpen(false)}
              >
                No
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConfirmAlertModal;
