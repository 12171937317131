import * as Yup from "yup";

export const SymptomsValidationSchema = Yup.object().shape({
  crop: Yup.string().required("Field is required"),
  symptoms: Yup.array().of(
    Yup.object().shape({
      symptoms: Yup.string().required("Field is required"),
    })
  ),
  control_measure_description: Yup.array().of(
    Yup.object().shape({
      control_measure_description: Yup.string().required("Field is required"),
    })
  ),
  // pdd: Yup.string().required("Field is required"),
  name_pdd: Yup.string().required("Field is required"),
  type: Yup.number().required("Field is required")
});

export const AddSymptomsInitialValues = {
  crop: "",
  type:"",
  symptoms: [{ symptoms: "" }],
  control_measure_description: [{ control_measure_description: "" }],
  // pdd: "",
  name_pdd: "",
};
