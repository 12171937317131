import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { AddAssociateInitialValues } from "../../../../MemberModule/AssociativeMember/FormikValidation";

type AddInitialValues = {
  status: string;
  reject_reason: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  api
    .patch(
      `/associate-members/${id}`,
      {
        status: values?.status,
        reason_for_decline: values?.reject_reason,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Updated");
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      status: modalEditData?.[0]?.status,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddAssociateInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/mmmm/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/cader-settings/${pageNo}`);
};
