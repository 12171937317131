import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  AddDesignationInitialValues,
  DesignationValidationSchema,
} from "./AddDesignationMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddDesignationMethods/AddDesignationModalMethods";
import Select from "react-select";
import { AddCaderProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";


const AddDesignation = ({
  setIsOpen,
  modalEditData,
  refetch,
  cadre,
  setDistrictId,
  district,
  office,
  designationData,
  setEditValue,
}: AddCaderProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddDesignationInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode,
      setDistrictId
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={DesignationValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.cadre_id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Cadre</Label>
                  <Select
                    placeholder="Select Cadre"
                    name="cadre_id"
                    options={cadre}
                    value={
                      cadre?.filter(
                        (itm: any) => itm?.value === values?.cadre_id
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("cadre_id", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Cadre"}
                    noOptionsMessage={() => "Cadre appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="cadre_id" component={TextError} />
                </Col>

                <Col lg={12}>
                  <Label>Designation</Label>
                  <Select
                    placeholder="Select Designation"
                    name="designation_meta"
                    options={designationData ? designationData : []}
                    value={
                      designationData?.length !== 0
                        ? designationData?.filter((itm: any) =>
                            values?.designation_meta?.includes(itm?.value)
                          )
                        : []
                    }
                    onChange={(selectedOptions: any) => {
                      const selectedValues = selectedOptions.map(
                        (option: any) => option.value
                      );
                      setFieldValue("designation_meta", selectedValues);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Designation"}
                    noOptionsMessage={() => "No options available"}
                    isSearchable={true}
                    isClearable
                    isMulti
                  />
                </Col>

                <Col md={6}>
                  <Label>District</Label>
                  <Select
                    placeholder="Select District"
                    options={district}
                    value={
                      district?.filter(
                        (itm: any) => itm?.value === values?.district
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("district", e?.value);
                      setDistrictId(e?.value);
                    }}
                    name="district"
                    isLoading={false}
                    loadingMessage={() => "Fetching District"}
                    noOptionsMessage={() => "District appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="district" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Office Category</Label>
                  <Select
                    placeholder="Select Category"
                    options={office ? office : []}
                    value={
                      Array?.isArray(office)
                        ? office?.filter(
                            (itm: any) => itm?.value === values?.office_id
                          )
                        : ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("office_id", e?.value);
                    }}
                    name="office_id"
                    isLoading={false}
                    loadingMessage={() => "Fetching Category"}
                    noOptionsMessage={() => "Category appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </Col>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddDesignation;
