import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddNamePDDInitialValues } from "./FormikValidation";
import { api } from "../../../../../../../Api/Api";
import { capitalizeWord, setValidationErrors } from "../../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  crop: string;
  name_pdd: string;
  
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .put(`/staff/${id}`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Details Updated ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
  // Edit
  else {
    api
      .post("/staff", values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Added ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
      mobile: item?.mobile,
      email: item?.email,
      gender: capitalizeWord(item?.gender),
      department: item?.department?.name,
      designation: item?.designation?.name,
      dob: item?.dob,
      joinedAt: item?.joinedAt,
      departmentId: item?.department?.name,
      designationId: item?.designation?.name,
      type: item?.type,
      // id: item?.id,
      // office_name: "",
      // phone_number: "",
      // email: "",
      // district: "",
      // block: "",
      // panchayat: "",
      // pincode: "",
      // location: "",
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      office_name: "",
      phone_number: "",
      email: "",
      district: "",
      block: "",
      panchayat: "",
      pincode: "",
      location: "",
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddNamePDDInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/staff/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/office-management/${pageNo}`);
};
