import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddAccomadationInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  office: string;
  manager_name: string;
  room_availble_count: string;
  room_occupied_count: string;
  phone_number: string;
  alter_phone_number: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // edit
  if (id) {
    api
      .patch(
        `/manage-office-accomodation/${id}`,
        {
          manager_name: values?.manager_name,
          no_of_rooms: values?.room_availble_count,
          room_occupied: values?.room_occupied_count,
          id: Number(values?.office),
          phone_no: values?.phone_number,
          alternative_phone_no: values?.alter_phone_number,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Accommodation Details Updated",{toastId:"Accommodation_updated"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Accommodation_updated_error"});
        }
      });
  }
  // App
  else {
    api
      .post(
        `/manage-office-accomodation`,
        {
          manager_name: values?.manager_name,
          no_of_rooms: values?.room_availble_count,
          room_occupied: values?.room_occupied_count,
          officeaccomodation_id: Number(values?.office),
          phone_no: values?.phone_number,
          alternative_phone_no: values?.alter_phone_number,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Accommodation Added",{toastId:"Accommodation_added"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Accommodation_adde_error"});
        }
      });
  }
};

export const extractStaffData = (data: any) => {

  const res = data?.data?.data?.map((item: any) => {
    const officeAccomodation = item?.OfficeAccomodation || {};
    return {
      id: item?.id,
      acc_manager_details: item?.manager_name,
      alternative_phone_no: item?.alternative_phone_no,
      no_of_rooms: item?.no_of_rooms,
      room_occupied: item?.room_occupied,
      phone_no: item?.phone_no,
      office_name: officeAccomodation?.name,
      office_district: officeAccomodation?.district?.name || "",
    };
  });

  return res;
};


// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      office: modalEditData?.[0]?.office_id,
      manager_name: modalEditData?.[0]?.manager_name,
      room_availble_count: modalEditData?.[0]?.no_of_rooms,
      room_occupied_count: modalEditData?.[0]?.room_occupied,
      phone_number: modalEditData?.[0]?.phone_no,
      alter_phone_number: modalEditData?.[0]?.alternative_phone_no,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddAccomadationInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData.data?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/manage-office-accomodation/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Accommodation_deleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Accommodation_deleted_error"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/accomadation-management/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/accomadation-management/${pageNo}`)
  }
};
