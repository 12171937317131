import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddOfficeProps } from "../../../../../Type";
import Select from "react-select";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  OfficeInitialValues,
  OfficeValidationSchema,
} from "./FormikValidation";
import {
  OfficeSubmitHandler,
  addOfficeModalInitialValuesHandler,
  getEducationData,
} from "../MemberProfileMethods";

import TextError from "../../../../../Components/FormikError/TextError";
import { useQuery } from "react-query";

const MemberOfficeEdit = ({
  setIsOpen,
  modalEditData,
  refetch,
  filteredEduList,
  memberProfileData,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(OfficeInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [imagefile, setImageFile] = useState("");
  const [imageView, seImageView] = useState("");

  const { data: educationData }: any = useQuery(
    [`educationData`],
    () => getEducationData(),
    { keepPreviousData: true }
  );

  useEffect(() => {
    addOfficeModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode,
      filteredEduList,
      seImageView
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={OfficeValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        OfficeSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          memberProfileData?.user_id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        errors,
      }) => {
        console.log(values, "aHXAHGSXHAS");
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={6}>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.email}
                    name="email"
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Mobile</Label>
                  <Input
                    type="number"
                    onChange={handleChange}
                    name="phone_no"
                    value={values?.phone_no}
                    placeholder="Mobile"
                  />
                  <ErrorMessage name="phone_no" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Address</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    name="address"
                    value={values?.address}
                    placeholder="Address"
                  />
                  <ErrorMessage name="address" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default MemberOfficeEdit;
