
// export const handleAdminStaffSearch = (e: any, setSearchQuery: Function, navigate: Function, setSearchValue: Function) => {
//     setSearchValue(e?.target?.value)
//     if (e?.target?.value?.length > 2) {
//         navigate('/admin/office-management/1')
//         setSearchQuery(e?.target?.value)
//     }
//     else {
//         setSearchQuery('')
//     }
// }
export const handleAdminStaffSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
    setSearchValue: Function
  ) => {
    setSearchValue(e?.target?.value)
    const userRole = localStorage.getItem("ROLE");
    if (userRole === "admin") {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/admin/office-management/1`);
      } else {
        setSearchQuery("");
      }
    } else {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/member/office-management/1`);
      } else {
        setSearchQuery("");
      }
    }
  };
  
  
  
  
  
  
  
  
  
  