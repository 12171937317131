import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAdminMeetingSearch } from "./AnnouncementMethods";
import { getAnnouncementDetails, getMeetingDetails } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { deleteClickHandler, extractMeetingData, onEditClickHandler, setCurrentPageMeeting } from "./AddAnnouncement/AddAnnouncementMethods/AddMeetingsModalMethods";
import { modalHandler } from "../NotificationsSettings/AddNotificationMethods/AddNotificationMethods";
import AddAnnouncement from "./AddAnnouncement";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const AnnouncementIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    data: meeting,
    error: meetingError,
    isLoading: meetingLoading,
    refetch: meetingListRefetch,
  }: any = useQuery(
    [`Announcement${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getAnnouncementDetails(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const meetingData = useMemo(() => extractMeetingData(meeting), [meeting]);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminMeetingSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("announcements:write") ? <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Announcement
                  </Button> : null}
                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={meetingData}
              tableTitle="Announcement Details"
              tableFields={[

                "Announcement Title",
                "Body",

              ]}
              Arraykeys={[

                "meetings_title",
                "meeting_description",

              ]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("announcements:write") ? "edit" : "",
                checkPermission("announcements:delete") ? "delete" : "",
              ]}
              isLoading={meetingLoading}
              error={meetingError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("announcements:write")) {
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    meeting
                  )
                }
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, meetingListRefetch)
              }
              paginationData={meeting?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageMeeting(navigate, pageNo, pathname)
              }
              from_anno={true}
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Announcement" : "Add Announcement"}
      >
        <AddAnnouncement
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={meetingListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default AnnouncementIndex;
