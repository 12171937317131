
export const handleOtherfundSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
    if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value)
        navigate(`/admin/other-fund/1`);
    }
    else {
        setSearchQuery('')
    }
}

export const handleMmeberOtherfundSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
    if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value)
        navigate(`/member/other-fund/1`);
    }
    else {
        setSearchQuery('')
    }
}