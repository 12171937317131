import axios from "axios";
import * as Yup from 'yup';
import { api } from "../../../../Api/Api";
import { toast } from "react-toastify";

export const resetBankDetails = (setFieldValue: Function) => {
    setFieldValue("bank_name", "")
    setFieldValue("branch", "")
    setFieldValue("city", "")
    setFieldValue("district", "")
    setFieldValue("state", "")
    setFieldValue("state", "")
}

export const addBankAccountsInitialValue = {
    account_no: "",
    ifsc: "",
    bank_name: "",
    branch: "",
    city: "",
    district: "",
    state: "",
    amount: ""
}

export const bankAccountValidationSchema = Yup.object().shape({
    account_no: Yup.string().required('Account number is required'),
    ifsc: Yup.string().required('IFSC code is required').length(11, 'IFSC code must be exactly 11 characters'),
    bank_name: Yup.string().required('Bank name is required'),
    branch: Yup.string().required('Branch is required'),
    city: Yup.string().required('City is required'),
    district: Yup.string().required('District is required'),
    state: Yup.string().required('State is required'),
    amount: Yup.number().positive('Amount must be a positive number').required('Amount is required'),
});

export const getBankDetails = async (ifsc: string, setFieldValue: Function, setFieldError: Function) => {
    if (ifsc?.length !== 11) return
    axios({
        method: 'get',
        url: `https://ifsc.razorpay.com/${ifsc}`,
        responseType: 'json'
    })
        .then(function ({ data }) {
            if (data) {
                setFieldValue("bank_name", data?.BANK)
                setFieldValue("branch", data?.BRANCH)
                setFieldValue("city", data?.CITY)
                setFieldValue("district", data?.DISTRICT)
                setFieldValue("state", data?.STATE)
            }
        }).catch((err: any) => {
            setFieldError("ifsc", "Invalid IFSC Code")
        })
}

export const handleBankAccountSubmit = (values: any, actions: any, refetch: Function, editMode: boolean, setEditForm: Function) => {
    if (editMode) {
        api
            .patch(`/bank-account/${values?.id}`, values, true)
            .then(async function ([success, response]: any) {
                toast.success("Account Details Updated",{toastId:"AccountDetails"});
                setEditForm(false)
                refetch();
            })
            .catch((err) => {
                actions?.setSubmitting(false);
                toast.error("Unexpected Error Occurred",{toastId:"AccountDetailserror"});
            });
    }
    else {
        api.post("/bank-account", values, true)
            .then(async function ([success, response]: any) {
                refetch()
                toast.success('Account Added',{toastId:"AccountDetailsAdded"})
                actions?.setSubmitting(false)
            })
            .catch((err) => {
                actions?.setSubmitting(false)
                if (err?.response?.data?.message) {
                    toast.error(err?.response?.data?.message)
                }
                else {
                    toast.error('Unexpected Error Occurred',{toastId:"AccountDetailsAddederror"})
                }
        });
    }
}

export const handleBankAccountDelete = (id: string | number, refetch: Function) => {
    api
        .delete(`/bank-account/${id}`, {}, true)
        .then(async function ([success, response]: any) {
            refetch();
            toast.success("Deleted",{toastId:"AccountDeleted"});
        })
        .catch((err) => {
            toast.error("Unexpected Error Occurred",{toastId:"Accounterror"});
        });
}

export const getAllBankAccountDetails = async (pageNo: string) => {
    const [, data] = await api.get(`/bank-account?page=${pageNo}`, true);
    return data?.data;
}

export const getAccountsTotal = async () => {
    const [, data] = await api.get(`/bank-account/total`, true);
    return data
}

export const handleReInitializeValues = (setInitialValues: Function, editData: any, setEditMode: Function) => {
    if (editData?.length !== 0) {
        setEditMode(true)
        setInitialValues({
            id: editData?.id,
            account_no: editData?.account_no,
            ifsc: editData?.ifsc,
            bank_name: editData?.bank_name,
            branch: editData?.branch,
            city: editData?.city,
            district: editData?.district,
            state: editData?.state,
            amount: editData?.amount
        })
    }
    else {
        setInitialValues({
            account_no: "",
            ifsc: "",
            bank_name: "",
            branch: "",
            city: "",
            district: "",
            state: "",
            amount: ""
        })
    }
}
