import { usePermission } from "../../../Components/Permissions/Admin/AdminPermission";
import { SidebarMenuItemType } from "../../../Type";

const isAssociative = localStorage.getItem("ROLE") === "associative";
const MemberSidebarMenuItems: SidebarMenuItemType[] = [];

if (isAssociative) {
  // for associative members
  MemberSidebarMenuItems.push(
    {
      icon: <i className="icon xl icon-dashboard"></i>,
      label: "Dashboard",
      path: "/member/dashboard",
    },

    {
      icon: <i className="icon xl icon-settings"></i>,
      label: "Change Password",
      path: "/associativemember/change-password",
      AllPaths: ["/member/change-password"],
    }
  );
} else {
  // non-associative members
  MemberSidebarMenuItems?.push(
    {
      icon: <i className="icon xl icon-dashboard"></i>,
      label: "Dashboard",
      path: "/member/dashboard",
    },
    {
      icon: <i className="icon xl icon-profile"></i>,
      label: "Profile",
      path: "/member/profile",
    },
    {
      icon: <i className="icon xl icon-office-management"></i>,
      label: "Office Management",
      path: "/member/office-management/1",
      permission_key: "office:read",
    },
    {
      icon: <i className="icon xl icon-members"></i>,
      label: "Members",
      path: "/member/member-management/1",
      permission_key: "members:read",
    },
    {
      icon: <i className="icon xl icon-fund"></i>,
      label: "Fund Mangement",
      path: "/member/fund-management/1",
      AllPaths: ["/admin/patient-profile"],
      permission_key: "fund:read",
    },
    {
      icon: <i className="icon xl icon-accomodation"></i>,
      label: "Accommodation Management",
      path: "/member/accomadation-management/1",
      AllPaths: ["/admin/doctors-profile", "/admin/doctors/card"],
      permission_key: "accommodation:read",
    },
    {
      icon: <i className="icon xl icon-magazine"></i>,
      label: "Magazine",
      path: "/member/magazine/1",
      AllPaths: ["/doctor/patient-profile", "/doctor/patient-prescription"],
      permission_key: "magazine:read",
    },
    {
      icon: <i className="icon xl icon-blog"></i>,
      label: "Blog",
      path: "/member/blog/1",
      AllPaths: ["/doctor/patient-profile", "/doctor/patient-prescription"],
      permission_key: "blog:read",
    },
    {
      icon: <i className="icon xl icon-leave"></i>,
      label: "Prescription Management",
      path: "/member/prescriptionmanagement/1",
      AllPaths: ["/doctor/patient-profile", "/doctor/patient-prescription"],
      permission_key: "prescription:read",
    },
    {
      icon: <i className="icon xl icon-transfer"></i>,
      label: "Office Change Request",
      path: "/member/transfer-request/1",
      AllPaths: ["/doctor/patient-profile", "/doctor/patient-prescription"],
      permission_key: "transfer:accept",
    },
    {
      icon: <i className="icon xl icon-transfer"></i>,
      label: "My Office Change Request",
      path: "/member/mytransfer-request/1",
      AllPaths: ["/doctor/patient-profile", "/doctor/patient-prescription"],
      permission_key: "transfer:read",
    },

    {
      icon: <i className="icon xl icon-reports"></i>,
      label: "Reports",
      path: "/member/reports",
      permission_key: "reports:read",
    },

    {
      icon: <i className="icon xl icon-asset"></i>,
      label: "Asset Mangement",
      path: "/member/asset-settings/1",
      permission_key: "asset:read",
    },
    // {
    //   icon: <i className="icon xl icon-download"></i>,
    //   label: "Downloads",
    //   path: "/member/download-settings/1",
    //   permission_key: "downloads:read",
    // },
    {
      icon: <i className="icon xl icon-meetings"></i>,
      label: "Meetings",
      path: "/member/meetings/1",
      permission_key: "meetings:read",
    },
    {
      icon: <i className="icon xl icon-announcement"></i>,
      label: "Announcement",
      path: "/member/announcement/1",
      permission_key: "announcements:read",
    },
    // leave accept
    {
      icon: <i className="icon xl icon-leave"></i>,
      label: "Leave Management",
      path: "/member/leave-management/1",
      permission_key: "leave:accept",
    },
    {
      icon: <i className="icon xl icon-attendance"></i>,
      label: "Attendance",
      path: "/member/attandance/1",
      permission_key: "meetings:read",
    },
    // leave read
    {
      icon: <i className="icon xl icon-leave"></i>,
      label: "My Leave",
      path: "/member/my-leave/1",
      permission_key: "leave:read",
    },
    {
      icon: <i className="icon xl icon-bank"></i>,
      label: "Bank Accounts",
      path: "/member/bank-accounts",
      permission_key: "bank:read",
    },
    {
      icon: <i className="icon xl icon-website"></i>,
      label: "My Website",
      path: "/member/mywebsite",
      permission_key: "website:read",
    },

    {
      icon: <i className="icon xl icon-payment"></i>,
      label: "Payment",
      path: "/member/payment/1",
    },
    {
      icon: <i className="icon xl icon-settings"></i>,
      label: "Settings",
      path: "/member/settings",
      AllPaths: ["/member/change-password"],
    }
  );
}

export { MemberSidebarMenuItems };
