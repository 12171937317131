import { ErrorMessage, Formik } from "formik";
import { MembershipNotificationSubmitHandler } from "./AddNotificationMethods";
import { Container, Col, Input, Label, Row, Form, Table } from "reactstrap";
import {
  MemberShipNotificationValidationSchema,
  MemberShipNotificationinitialValues,
} from "./FormikValidation";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

export default function MeembershipNotificationSettings() {
  const { checkPermission } = usePermission();
  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Notification Management", link: "/admin/notification-settings" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Notification Management", link: "/member/notification-settings" }
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <>
    <Container>
      <div className="breadcrumb-wrap">{breadcrumb}</div>
      <Formik
        validationSchema={MemberShipNotificationValidationSchema}
        initialValues={MemberShipNotificationinitialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          checkPermission("notification:write") && MembershipNotificationSubmitHandler(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          setFieldError,
          errors,
        }) => {
          return (
            <section>
              <div className="content-outer-wrap">
                <div className="content-list-outer-wrap">
                  <Container>
                    <Row className="gy-4">
                      <div className="table-outer-wrap">
                        <div className="table-head-outer">
                          <div className="table-heading">
                            <h6>Notification Table</h6>
                          </div>
                        </div>
                        <div className="table-outer">
                          <Table className="table-section">
                            <thead>
                              <tr>
                                <th>Role</th>
                                <th>SMS</th>
                                <th>Whatsapp</th>
                                <th>Email</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td data-th="Role">
                                  <span className="mobile-heading">Role</span>
                                  <span className="table-data">Membership</span>
                                </td>
                                <td>
                                  <span className="mobile-heading">SMS</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.sms}
                                      name="sms"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="present_status"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    Whatsapp
                                  </span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.whatsapp}
                                      name="whatsapp"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="whatsapp"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">Email</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.email}
                                      name="email"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td data-th="Role">
                                  <span className="mobile-heading">Role</span>
                                  <span className="table-data">Other Fund</span>
                                </td>
                                <td>
                                  <span className="mobile-heading">SMS</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.sms}
                                      name="sms"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="present_status"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    Whatsapp
                                  </span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.whatsapp}
                                      name="whatsapp"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="whatsapp"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">Email</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.email}
                                      name="email"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Role">
                                  <span className="mobile-heading">Role</span>
                                  <span className="table-data">
                                    Assosiative Membership
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">SMS</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.sms}
                                      name="sms"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="present_status"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    Whatsapp
                                  </span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.whatsapp}
                                      name="whatsapp"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="whatsapp"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">Email</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.email}
                                      name="email"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Role">
                                  <span className="mobile-heading">Role</span>
                                  <span className="table-data">
                                    Registration
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">SMS</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.sms}
                                      name="sms"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="present_status"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    Whatsapp
                                  </span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.whatsapp}
                                      name="whatsapp"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="whatsapp"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">Email</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.email}
                                      name="email"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Role">
                                  <span className="mobile-heading">Role</span>
                                  <span className="table-data">Meetings</span>
                                </td>
                                <td>
                                  <span className="mobile-heading">SMS</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.sms}
                                      name="sms"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="present_status"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    Whatsapp
                                  </span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.whatsapp}
                                      name="whatsapp"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="whatsapp"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">Email</span>
                                  <span className="table-data">
                                    <Input
                                      type="checkbox"
                                      value={values?.email}
                                      name="email"
                                      onChange={handleChange}
                                      disabled={!checkPermission("notification:write")}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component={TextError}
                                    />
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                        {checkPermission("notification:write") ? <div className="text-end">
                          <CustomButton
                            isLoading={isSubmitting}
                            handleSubmit={handleSubmit}
                            label="Submit"
                            color="primary"
                            className="sm submit-btn"
                          />
                        </div> : null}
                      </div>
                    </Row>
                  </Container>
                </div>
              </div>
            </section>
          );
        }}
      </Formik>
    </Container>

    </>
  );
}
