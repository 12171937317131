import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddPrescriptionMethods/AddPrescriptionsModalMethods";
import {
  AddPrescriptionInitialValues,
  PrescriptionValidationSchema,
} from "./AddPrescriptionMethods/FormikValidation";
import { AddPrescriptionTProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import PreviewModal from "../PrescriptionView/Preview";
import { useQuery } from "react-query";
import { getCropDetlies } from "../../../../../Api/AdminApis";

const PEST_DISEASE_DEFICIENCY = [
  { value: 1, name: "കീടബാധ" },
  { value: 2, name: "രോഗം" },
  { value: 3, name: "മൂലകങ്ങളുടെ അഭാവം" },
  {value: 4, name: "മറ്റുള്ളവ"}
];
const AddPrescription = ({
  setIsOpen,
  modalEditData,
  refetch,
  crop,
  setPDDID,
  setPddType,
  setPddTypeDet,
  // cropPDD,
  cropsCategory,
  setPDDIDSet,
  setPrescriptionId,
  prescription_view,
  districtList
}: AddPrescriptionTProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddPrescriptionInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selected_syptoms_array, setSlectedSYpmtomsArray] = useState([]);
  const [selected_syptoms_new_array, setSlectedNewSYpmtomsArray] = useState([]);
  const [selected_control_array, setSlectedcontrolArray] = useState([]);
  const [selected_control_new_array, setSlectedcontrolNewArray] = useState([]);
  const [crop_id, setCropid] = useState();
  const [district_ids, setDistrictIds] = useState();
  const [value, setValue] = useState({
    district: "",
  })
  const { data: crop_details_view }: any = useQuery(
    [
      `crop-details${addModalInitialValues?.name_of_pest_disease_deficiency || crop_id
      }`,
      addModalInitialValues?.name_of_pest_disease_deficiency || crop_id,
    ],
    () =>
      addModalInitialValues?.name_of_pest_disease_deficiency || crop_id
        ? getCropDetlies(
          addModalInitialValues?.name_of_pest_disease_deficiency || crop_id
        )
        : "",
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    addModalInitialValuesHandler(
      crop_details_view,
      setAddModalInitialValues,
      setEditMode,
      crop_details_view
    );
  }, [crop_id, crop_details_view]);

  const handleSymptomsCheckboxChange = (
    index: any,
    setFieldValue: any,
    item: any
  ) => {
    var new_data: any = [...item];

    new_data[index].checked = !new_data[index]?.checked;
    let checkValues = new_data?.filter((item: any) => item?.checked === true);

    setFieldValue("symptoms_new", new_data);
    setSlectedSYpmtomsArray(checkValues);
  };

  const handleSymptomsNewCheckboxChange = (
    index: any,
    setFieldValue: any,
    item: any
  ) => {
    var new_data: any = [...item];

    new_data[index].checked = !new_data[index]?.checked;
    let checkValues = new_data?.filter((item: any) => item?.checked === true);
    setFieldValue("symptoms", new_data);
    setSlectedNewSYpmtomsArray(checkValues);
  };

  const handleControlCheckboxChange = (
    index: any,
    setFieldValue: any,
    item: any
  ) => {
    var new_data: any = [...item];

    new_data[index].checked = !new_data[index]?.checked;
    let checkValues = new_data?.filter((item: any) => item?.checked === true);
    setFieldValue("control_measures_new", new_data);
    setSlectedcontrolArray(checkValues);
  };

  const handleControlNewCheckboxChange = (
    index: any,
    setFieldValue: any,
    item: any
  ) => {
    
    var new_data: any = [...item];
    new_data[index].checked = !new_data[index]?.checked;
    let checkValues = new_data?.filter((item: any) => item?.checked === true);    
    setFieldValue("control_measures", new_data);
    setSlectedcontrolNewArray(checkValues);
  };

  return (
    <>
      <Formik
        validationSchema={PrescriptionValidationSchema}
        initialValues={addModalInitialValues}
        enableReinitialize
        onSubmit={(values, actions) => {       
          StaffSubmitHandler(
            values,
            actions,
            setIsOpen,
            refetch,
            editMode,
            modalEditData?.[0]?.id,
            setPrescriptionId,
            selected_syptoms_array?.length !== 0
              ? selected_syptoms_array
              : values?.symptoms_new,
            selected_control_array?.length !== 0
              ? selected_control_array
              : values?.control_measures_new,
            selected_syptoms_new_array,
            selected_control_new_array
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          setFieldError,
          errors,
        }) => {

          return (
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col md={12}>
                    <Label>Crops</Label>
                    <Select
                      placeholder="Select Crops"
                      name="crops"
                      options={crop}
                      value={
                        crop?.filter(
                          (itm: any) => itm?.value === values?.crops
                        ) || ""
                      }
                      onChange={(e: any) => {
                        setFieldValue("crops", e?.value);
                        setPDDID(e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Crops"}
                      noOptionsMessage={() => "Crops appears here"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage name="crops" component={TextError} />
                  </Col>

                  <Col md={12}>
                    <div className="radio-sec-wrap">
                      {PEST_DISEASE_DEFICIENCY.map((item: any) => (
                        <div key={item.value}>
                          <Input
                            type="radio"
                            id={item.name}
                            name="type"
                            value={item.value}
                            checked={parseInt(values?.type) === item.value} // Ensure comparing as integers
                            onChange={(e) => {
                              setFieldValue("type", parseInt(e.target.value));
                              setPddTypeDet(e.target.value)
                            }} />
                          <Label for={item.name}>{item.name}</Label>
                        </div>
                      ))}
                    </div>
                    {/* <ErrorMessage
                      name="type"
                      component={TextError}
                    /> */}
                  </Col>
                  {/* <Col md={12}>
                    <Label>Pest / Disease / Deficency</Label>
                    <Select
                      placeholder="Select Pest/Disease/Deficency"
                      name="pest_disease_deficiency"
                      options={cropPDD}
                      value={
                        cropPDD?.filter(
                          (itm: any) =>
                            itm?.value === values?.pest_disease_deficiency
                        ) || ""
                      }
                      onChange={(e: any) => {
                        setFieldValue("pest_disease_deficiency", e?.value);
                        setPDDIDSet(e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Pest/Disease/Deficency"}
                      noOptionsMessage={() => "Block appears here"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage
                      name="pest_disease_deficiency"
                      component={TextError}
                    />
                  </Col> */}
                  <Col md={12}>
                    <Label>Name of Pest / Disease / Deficency</Label>
                    <Select
                      placeholder="Select Pest/Disease/Deficency"
                      name="name_of_pest_disease_deficiency"
                      options={cropsCategory}
                      value={
                        cropsCategory?.filter(
                          (itm: any) =>
                            itm?.value ===
                            values?.name_of_pest_disease_deficiency
                        ) || ""
                      }
                      onChange={(e: any) => {
                        setFieldValue(
                          "name_of_pest_disease_deficiency",
                          e?.value
                        );
                        setCropid(e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Pest/Disease/Deficency"}
                      noOptionsMessage={() => "Block appears here"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage
                      name="name_of_pest_disease_deficiency"
                      component={TextError}
                    />
                  </Col>
                  {/* symptoms */}
                  <Col lg={12}>
                    <Label>
                      <b>Symptoms</b>
                    </Label>
                    {values?.symptoms_new?.map((item: any, index: any) => {
                      return (
                        <>
                          <Row>
                            <Input
                              type="checkbox"
                              checked={item?.checked}
                              onChange={(e) =>
                                handleSymptomsCheckboxChange(
                                  index,
                                  setFieldValue,
                                  values?.symptoms_new
                                )
                              }
                              value={item}
                            />
                            <Label>{item?.symptoms}</Label>
                          </Row>
                        </>
                      );
                    })}

                    <FieldArray name="symptoms">
                      {({ push, remove }) => (
                        <div>
                          {values?.symptoms?.map((item: any, index: any) => (
                            <div key={index} className="field-wrap">
                              <div className="checkbox-textarea-wrap">
                                <Input
                                  type="checkbox"
                                  checked={item?.checked}
                                  onChange={(e) =>
                                    handleSymptomsNewCheckboxChange(
                                      index,
                                      setFieldValue,
                                      values?.symptoms
                                    )
                                  }
                                  value={item?.checked}
                                />
                                <Field
                                  type="textarea"
                                  name={`symptoms.${index}.symptoms`}
                                  as={Input}
                                />
                              </div>
                              <ErrorMessage
                                name={`symptoms.${index}.symptoms`}
                                component={TextError}
                              />

                              <div className="close-icon-wrap">
                                <i onClick={() => remove(index)} className="icon icon-close-red"></i>
                              </div>
                            </div>
                          ))}
                          <div className="plus-new-wrap">
                            <i className="icon icon-plus-new xl" onClick={() => {
                              push({ symptoms: "" });
                            }}></i>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Col>
                  {/* control measures */}
                  <Col md={12}>
                    <Label>
                      <b>Control Measures</b>
                    </Label>
                    {values?.control_measures_new?.map(
                      (item: any, index: any) => {
                        return (
                          <>
                            <Row>
                              <Input
                                type="checkbox"
                                checked={item?.checked}
                                onChange={(e) =>
                                  handleControlCheckboxChange(
                                    index,
                                    setFieldValue,
                                    values?.control_measures_new
                                  )
                                }
                                value={item}
                              />
                              <Label>{item?.control_measure}</Label>
                            </Row>
                          </>
                        );
                      }
                    )}

                    <FieldArray name="control_measures">
                      {({ push, remove }) => (
                        <div>
                          {values?.control_measures?.map(
                            (item: any, index: any) => (
                              <div key={index} className="field-wrap">
                                <div className="checkbox-textarea-wrap">
                                  <Input
                                    type="checkbox"
                                    checked={item?.checked}
                                    onChange={(e) =>
                                      handleControlNewCheckboxChange(
                                        index,
                                        setFieldValue,
                                        values?.control_measures
                                      )
                                    }
                                    value={item?.checked}
                                  />
                                  <Field
                                    type="textarea"
                                    name={`control_measures.${index}.control_measures`}
                                    as={Input}
                                  />
                                </div>

                                <ErrorMessage
                                  name={`control_measures.${index}.control_measures`}
                                  component={TextError}
                                />

                                <div className="close-icon-wrap">
                                  <i onClick={() => remove(index)} className="icon icon-close-red"></i>
                                </div>
                              </div>
                            )
                          )}

                          <div className="plus-new-wrap">
                            <i className="icon icon-plus-new xl" onClick={() => {
                              push({ control_measures: "" });
                            }}></i>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Col>
                  <Col md={12}>
                    <Label>District</Label>
                    <Select
                      placeholder="Select District"
                      name="district"
                      options={districtList ? districtList : []}
                      value={
                        districtList
                          ? districtList?.filter(
                            (itm: any) => itm?.value === values?.district
                          )
                          : ""
                      }
                      onChange={(e: any) => {
                        setFieldValue("district", e?.value);
                        setDistrictIds(e?.value);
                        setValue({
                          ...value,
                          district: e?.value,
                        });
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching District"}
                      noOptionsMessage={() => "District appears here"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage name="district" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <Label>Farmer Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.farmer_name}
                      name="farmer_name"
                      placeholder="Farmer Name"
                    />
                    <ErrorMessage name="farmer_name" component={TextError} />
                  </Col>

                  <Col md={12}>
                    <Label>Farmer Phone Number</Label>
                    <Input
                      type="tel"
                      onChange={handleChange}
                      name="farmer_mobile"
                      value={values?.farmer_mobile}
                      placeholder="Farmer Phone Number"
                    />
                    <ErrorMessage name="farmer_mobile" component={TextError} />
                  </Col>
                  <Col md={12}>
                    <Label>Address</Label>
                    <Input
                      type="textarea"
                      onChange={handleChange}
                      name="farmer_address"
                      value={values?.farmer_address}
                      placeholder="Farmer Address"
                    />
                    <ErrorMessage name="farmer_address" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <Label>SMART ID</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.smart_id}
                      name="smart_id"
                      placeholder="SMART ID"
                    />
                    <ErrorMessage name="smart_id" component={TextError} />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />
                  </Col>
                  {/* <Col lg={6}>
                    <Button
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                      onClick={() => {
                        setPreviewModal(true);
                      }}
                    >
                      Preview
                    </Button>
                  </Col> */}
                </Row>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default AddPrescription;
