import * as Yup from "yup";

export const TransferValidationSchema = Yup.object().shape({
  office: Yup.string(),
  office_id: Yup.number(),
  district: Yup.string().required("Field is required"),
  block: Yup.string().required("Field is required"),
  // panchayath: Yup.string().required("Field is required"),
  type: Yup.string().required("Field is required"),
  reason: Yup.string(),
  // description: Yup.string().required("Field is required"),
  GO_order: Yup.string().required("Field is required"),
  GO_order_date: Yup.date().required("Field is required"),
  to_office: Yup.string().required("Field is required"),
});

export const AddTransferInitialValues = {
  office: "",
  office_id: "",
  district: "",
  block: "",
  to_office: "",
  type: "",
  GO_order: "",
  GO_order_date: "",
  // description: "",
  designation:""
};
