import { Button, Col, Row } from "reactstrap";
import { AcceptRejectSubmitHandler } from "../../../Pages/Private/Admin/LeaveMangement/AddLeave/AddLeaveMethods/AddLeaveModalMethods";
import { FundStatusSubmitHandler } from "../../../Pages/Private/Admin/FundManagment/AddFundManagement/AddFundMethods/AddFundModalMethods";
import { MembershipStatusSubmitHandler } from "../../../Pages/Private/Admin/MemberShip/AddMemberShip/AddMembershipMethods/AddMembershipMethods";
import { ActiveClickHandler } from "../../../Pages/Private/Admin/Otherfund/AddOtherfund/AddOtherfundMethods/AddOtherfundModalMethods";
import {
  BlogClickHandler,
  statusClickHandler,
} from "../../../Pages/Private/Admin/Blogs/AddBlogs/AddBlogsMethods/AddBlogModalMethods";
import { statusMagazineClickHandler } from "../../../Pages/Private/Admin/Magazine/AddMagazine/AddMagazineMethods/AddMagazineModalMethods";

const DeleteAlertModal = (props: any) => {

  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure?</h5>
              < small className="sub-text">
                {" "}
                {
                  // props?.seat == "notavailable"
                  //   ? "No seats available please move a member from this office to transit for making seat available"
                  props?.type === "change"
                    ? " Do you really want to accept the transfer"
                    : props?.status === false
                      ? "Do you really want to publish"
                      : props?.status === true
                        ? "Do you really want to Un publish"
                        : props?.blog_verify === "blog_verify"
                          ? "Do you really want to Verify"
                          : props?.blog_verify === "blog_un"
                            ? "Do you really want to Unverify"
                            : props?.status === 1
                              ? "Do you really want to Unpublish"
                              : props?.status === 2
                                ? "Do you really want to Publish"
                                : "Do you really want to Delete"
                }
                .
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setModal(false)}
              >
                Cancel
              </Button>
              {props?.type === "change" ? (
                <>
                  {/* {props?.status !== 2 && ( */}
                  <>
                    <Button
                      color={"primary"}
                      className="sm"
                      onClick={() => {
                        // console.log( props?.blockVerify," props?.blockVerify");
                        // return
                        AcceptRejectSubmitHandler(
                          props?.setModal,
                          props?.Refetch,
                          props?.delete_id,
                          "2",
                          props?.blockVerify,
                          props?.from_transfer_req,
                        );
                        props?.setModal(false);
                      }}
                    >
                      verify
                    </Button>
                  </>
                  {/* )} */}
                  {props?.status !== 3 && (
                    <>
                      {/* <Button
                        color={"danger"}
                        className="sm"
                        onClick={() => {
                          AcceptRejectSubmitHandler(
                            props?.setModal,
                            props?.Refetch,
                            props?.delete_id,
                            "3",
                            props?.from_transfer_req
                          );
                          props?.setModal(false);
                        }}
                      >
                        Reject
                      </Button> */}
                    </>
                  )}
                </>
              ) : props?.type === "publish" ? (
                <>
                  {props?.status === false && (
                    <>
                      <Button
                        color={"primary"}
                        className="sm"
                        onClick={() => {
                          FundStatusSubmitHandler(
                            props?.setModal,
                            props?.Refetch,
                            props?.delete_id,
                            true
                          );
                          props?.setModal(false);
                        }}
                      >
                        Publish
                      </Button>
                    </>
                  )}
                  {props?.type === "noChange" && (
                    <>

                    </>
                  )}

                  {props?.status === true && (
                    <>
                      <Button
                        color={"danger"}
                        className="sm"
                        onClick={() => {
                          FundStatusSubmitHandler(
                            props?.setModal,
                            props?.Refetch,
                            props?.delete_id,
                            false
                          );
                          props?.setModal(false);
                        }}
                      >
                        UnPublish
                      </Button>
                    </>
                  )}
                </>
              ) : props?.type === "mebership_renewwal" ? (
                <>
                  {props?.status === 1 && (
                    <>
                      <Button
                        color={"primary"}
                        className="sm"
                        onClick={() => {
                          MembershipStatusSubmitHandler(
                            props?.setModal,
                            props?.Refetch,
                            props?.delete_id
                          );
                          props?.setModal(false);
                        }}
                      >
                        Active
                      </Button>
                    </>
                  )}
                  {props?.status === true && (
                    <>
                      <Button
                        color={"danger"}
                        className="sm"
                        onClick={() => {
                          MembershipStatusSubmitHandler(
                            props?.setModal,
                            props?.Refetch,
                            props?.delete_id
                          );
                          props?.setModal(false);
                        }}
                      >
                        Inactive
                      </Button>
                    </>
                  )}
                </>
              ) : props?.type === "status_updation" ? (
                <>
                  {props?.status === 2 && (
                    <>
                      <Button
                        color={"primary"}
                        className="sm"
                        onClick={() => {
                          props?.from_magazine === true
                            ? statusMagazineClickHandler(
                              props?.setModal,
                              props?.Refetch,
                              props?.delete_id,
                              true
                            )
                            : statusClickHandler(
                              props?.setModal,
                              props?.Refetch,
                              props?.delete_id,
                              true
                            );

                          props?.setModal(false);
                        }}
                      >
                        Publish
                      </Button>
                    </>
                  )}
                  {props?.status === 1 && (
                    <>
                      <Button
                        color={"danger"}
                        className="sm"
                        onClick={() => {
                          props?.from_magazine === true
                            ? statusMagazineClickHandler(
                              props?.setModal,
                              props?.Refetch,
                              props?.delete_id,
                              false
                            )
                            : statusClickHandler(
                              props?.setModal,
                              props?.Refetch,
                              props?.delete_id,
                              false
                            );
                          props?.setModal(false);
                        }}
                      >
                        Unpublish
                      </Button>
                    </>
                  )}
                </>
              ) : props?.type === "other_fund" ? (
                <>
                  <Button
                    color={"primary"}
                    className="sm"
                    onClick={() => {
                      ActiveClickHandler(
                        props?.setModal,
                        props?.Refetch,
                        props?.id
                      );
                      props?.setModal(false);
                    }}
                  >
                    Active
                  </Button>
                </>
              ) :
                (

                  <Button
                    color={"danger"}
                    className="sm"
                    onClick={() => {
                      props.onDeleteClick(props.delete_id, props?.refetch); // Invoke onDeleteClick with delete_id and refetch function
                      props?.setModal(false);
                    }}
                  >
                    Delete
                  </Button>
                )}
            </div>
          </Col>
        </Row>
      </div >
    </>
  );
};

export default DeleteAlertModal;
