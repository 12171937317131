import { FormikHelpers } from "formik";

import { toast } from "react-toastify";
import { api } from "../../../../../../../Api/Api";
import axios from "axios";
import { BaseUrl } from "../../../../../../../Api/BaseUrl";
import { AddBannerInitialValues } from "./FormikValidation";

type AddInitialValues = {
  image: string;
  heading: string;
  sub_heading: string;
  content:string;
  sub_content:string;
};

export const BannerSubmitHandler = async (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  Image: any
) => {
  // Edit

  if (editMode) {
    try {
      const formData = new FormData();
      if (Image === true) {
        formData.append("banner_image", values?.image);
      }
      formData.append("heading", values?.heading);
      formData.append("sub_heading", values?.sub_heading);
      formData.append("content", values?.content);
      formData.append("sub_content", values?.sub_content);
      const res = await axios.patch(
        `${BaseUrl}/banner/${id}`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
          },
        }
      );
      if (res) {
        toast.success("Banner Updated",{toastId:"BannerUpdated"});
        setIsOpen(false);
        refetch();
      }
    } catch (error: any) {
      actions?.setSubmitting(false);
      actions.setFieldError("image", error?.response?.data?.errors?.image);
      actions.setFieldError("heading", error?.response?.data?.errors?.heading);
      actions.setFieldError("sub_heading", values?.sub_heading);
      actions.setFieldError("content", values?.content);
      actions.setFieldError("sub_content", values?.sub_content);
      actions.setFieldError(
        "sub_heading",
        error?.response?.data?.errors?.sub_heading
      );
    }
  } else {
    // add
    try {
      const formData = new FormData();
      formData.append("banner_image", values?.image);
      formData.append("heading", values?.heading);
      formData.append("sub_heading", values?.sub_heading);
      formData.append("content", values?.content);
      formData.append("sub_content", values?.sub_content);
      const res: any = await axios.post(`${BaseUrl}/banner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
        },
      });

      if (res) {
        toast.success("Banner added",{toastId:"Banneradded"});
        setIsOpen(false);
        refetch();
      }
    } catch (error: any) {
      actions?.setSubmitting(false);
      actions.setFieldError("image", error?.response?.data?.errors?.image);
      actions.setFieldError("heading", error?.response?.data?.errors?.heading);
      actions.setFieldError(
        "sub_heading",
        error?.response?.data?.errors?.sub_heading
      );
    }
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      description: item?.description,
      image_name: item?.image_url,
      title: item?.title,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      heading: modalEditData?.[0]?.heading,
      sub_heading: modalEditData?.[0]?.sub_heading,
      content: modalEditData?.[0]?.content,
      sub_content: modalEditData?.[0]?.sub_content,
      image: modalEditData?.[0]?.image,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddBannerInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  awardData: any
) => {
  setModalEditData([]);
  setModalEditData(
    awardData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/banner/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"BannerDeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .patch(
      `/banner/publish/${selectedId}`,
      {
        status: newStatus,
      },
      true
    )
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated",{toastId:"Updated"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageaward = (navigate: Function, pageNo: any) => {
  navigate(`/admin/staff/${pageNo}`);
};
