import { useState } from "react";
import { Container, Button, InputGroup, Input, Table, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getDistrict,
  getMeetingMembers,
  getRoleFullList,
} from "../../../../Api/AdminApis";
import { handleMemberMeetingSearch } from "./MeetingsMethods";
import Select from "react-select";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import {
  InviteClickHandler,
  setCurrentPageMeetingmember,
} from "./AddMeetings/AddMeetingsMethods/AddMeetingsModalMethods";
import {
  MembershipStatus,
  capitalizeFirst,
} from "../../../../Utils/helpers/helpers";

const MeetingMemberIndex = () => {
  const { currentPage = "1", id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState({
    role: [""],
    district: [""],
  });

  const { data: role }: any = useQuery([`role`], () => getRoleFullList(), {
    keepPreviousData: true,
  });
  const { data: districtList }: any = useQuery(
    [`district`],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: members,
    error: membersError,
    isLoading: membersLoading,
    refetch: membersListRefetch,
  }: any = useQuery(
    [
      `members${currentPage + searchQuery + value}`,
      currentPage,
      searchQuery,
      value,
    ],
    () => getMeetingMembers(currentPage, searchQuery, value),
    { keepPreviousData: true }
  );
  const handleChange = (e: any, name: any) => {
    if (name === "role") {
      const selectedValues = e?.map((option: any) => option.value);
      setValue({
        ...value,
        role: selectedValues,
      });
    }
    if (name === "district") {
      const selectedValues = e?.map((option: any) => option.value);
      setValue({
        ...value,
        district: selectedValues,
      });
    }
  };

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleMemberMeetingSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>
            <Col md={4} className="select-wrap-spacing">
              <Select
                placeholder="Select Role"
                options={role}
                value={
                  role?.filter((itm: any) =>
                    value?.role?.includes(itm?.value)
                  ) || []
                }
                onChange={(e: any) => handleChange(e, "role")}
                name="role"
                isLoading={false}
                loadingMessage={() => "Fetching Role"}
                noOptionsMessage={() => "Meeting Type appears here"}
                isSearchable={true}
                isClearable
                isMulti
              />
            </Col>
            <Col md={2}>
              <Select
                placeholder="Select District"
                options={districtList}
                value={
                  districtList?.filter((itm: any) =>
                    value?.district?.includes(itm?.value)
                  ) || []
                }
                onChange={(e: any) => handleChange(e, "district")}
                name="district"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
                isMulti
              />
            </Col>

            <div className="right-side-wrap">
              <Button
                onClick={() => {
                  InviteClickHandler(data, membersListRefetch, id, value);
                }}
                color="primary"
                className="sm"
              >
                Send Meeting Invite
              </Button>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <div className="table-outer-wrap">
              <div className="table-head-outer">
                <div className="table-heading">
                  <h6>Member Details</h6>
                </div>
              </div>
              <div className="table-outer">
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Member Name</th>
                      <th>Member Office</th>
                      <th>Member Type</th>
                      <th>District</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members?.items?.length !== 0 ? (
                      <>
                        {members?.items?.map((item: any, index: any) => {
                          const currentIndex =
                            (members?.meta?.currentPage - 1) *
                              members?.meta?.itemsPerPage +
                            index +
                            1;
                          return (
                            <>
                              <tr>
                                <td>{currentIndex}</td>
                                <td>
                                  {item?.first_name} {item?.last_name}
                                </td>
                                <td>{item?.member?.office?.name}</td>
                                <td>
                                  {MembershipStatus(
                                    item?.member?.membership_type
                                  )}
                                </td>
                                <td>{item?.member?.office?.district?.name}</td>
                                <td>
                                  {item?.roles?.length === 0
                                    ? "No Role add"
                                    : item?.roles
                                        ?.map((location: any) => location.name)
                                        .join(", ")}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <td colSpan={12} className="empty-text">
                        No Data
                      </td>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>


        {members?.meta?.totalItems !== 0 && (
          <div className="pagination">
            <CustomPagination
              totalItems={members?.meta?.totalItems}
              itemsPerPage={members?.meta?.itemsPerPage}
              activePage={members?.meta?.currentPage}
              setCurrentPage={() =>
                setCurrentPageMeetingmember(navigate, currentPage, id,pathname)
              }
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default MeetingMemberIndex;
