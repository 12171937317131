import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CustomTable from "../../Lists/CustomTable";
import { CardsWithTableType } from "../../../Type";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import CustomPagination from "../../Pagination/Pagination";

const CardsWithTable = ({
  data,
  paginationData,
  setCurrentPage,
  viewType,
  toURL,
  tableTitle,
  tableFields,
  actions,
  Arraykeys,
  isLoading,
  error,
  onEditClick,
  onDeleteClick,
  meta,
  Refetch,
  ActionsLabel,
  form_,
  onClickCheck,
  from_office,
  from_cat,
  from_leave,
  from_pre,
  from_user_managemet,
  from_member,
  onPaymentClick,
  from_meeting,
  from_fund,
  from_otherfund,
  from_magazine,
  from_transfer_req,
  from_getintouch,
  from_downloads,
  handleDownloadPublishClick,
  onStatusClick,
  page_number,
  searchQuery,
  from_associtive,
  from_blog,
  from_attendance,
  from_associate_request,
  from_anno,
  from_report,
  from_abouttoretire,
  from_categorydownloads,
  from_des,
  from_office_cate,
  download_cat_id,
  from_login_report,
  from_my_transfer_req,
}: CardsWithTableType) => {
  return (
    <>
      <Container>
        <Row className="gy-4">
          <div className="table-outer-wrap">
            <div className="table-head-outer">
              <div className="table-heading">
                <h6>{tableTitle || ""}</h6>
              </div>
              <CustomTable
                data={data || []}
                tableFields={tableFields}
                Arraykeys={Arraykeys}
                actions={actions}
                isLoading={isLoading}
                error={error}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                url={toURL}
                meta={meta}
                Refetch={Refetch}
                ActionsLabel={ActionsLabel}
                form_={form_}
                onClickCheck={onClickCheck}
                from_office={from_office}
                from_cat={from_cat}
                from_leave={from_leave}
                from_pre={from_pre}
                from_user_managemet={from_user_managemet}
                from_member={from_member}
                onPaymentClick={onPaymentClick}
                from_meeting={from_meeting}
                from_fund={from_fund}
                activePage={paginationData?.currentPage}
                itemsPerPage={paginationData?.itemsPerPage}
                from_otherfund={from_otherfund}
                from_magazine={from_magazine}
                from_transfer_req={from_transfer_req || false}
                from_getintouch={from_getintouch}
                onStatusClick={onStatusClick}
                page_number={page_number}
                searchQuery={searchQuery}
                from_associtive={from_associtive}
                from_blog={from_blog}
                from_attendance={from_attendance}
                from_associate_request={from_associate_request}
                from_anno={from_anno}
                from_downloads={from_downloads}
                handleDownloadPublishClick={handleDownloadPublishClick}
                from_report={from_report}
                from_abouttoretire={from_abouttoretire}
                from_des={from_des}
                from_office_cate={from_office_cate}
                from_categorydownloads={from_categorydownloads}
                download_cat_id={download_cat_id}
                from_login_report={from_login_report}
                from_my_transfer_req={from_my_transfer_req}
              />
            </div>
          </div>
        </Row>
      </Container>
      {paginationData?.totalItems !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={paginationData?.totalItems}
            itemsPerPage={paginationData?.itemsPerPage}
            activePage={paginationData?.currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default CardsWithTable;
