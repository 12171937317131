import MeembershipNotificationSettings from "./AddNotificationMethods/MembershipSettings";

export default function NotificationSettings() {
  return (
    <>
      <div>
        <MeembershipNotificationSettings />
      </div>
    </>
  );
}
