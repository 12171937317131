import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import {
  AddSymptomsInitialValues,
  SymptomsValidationSchema,
} from "./AddSymptomsMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddSymptomsMethods/AddSymptomsModalMethods";

import Select from "react-select";
import { AddOfficeProps, AddPDDDETProps } from "../../../../../../Type";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";

const PEST_DISEASE_DEFICIENCY = [
  { value: 1, name: "കീടബാധ" },
  { value: 2, name: "രോഗം" },
  { value: 3, name: "മൂലകങ്ങളുടെ അഭാവം" },
  {value: 4, name: "മറ്റുള്ളവ"}
];
const AddSymptom = ({
  setIsOpen,
  modalEditData,
  refetch,
  setPDDID,
  setPddType,
  crop,
  // cropPDD,
}: AddPDDDETProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddSymptomsInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);
  useEffect(() => {
    setPDDID(addModalInitialValues?.crop);
  }, [addModalInitialValues?.crop]);

  return (
    <Formik
      validationSchema={SymptomsValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Crop</Label>
                  <Select
                    placeholder="Select Crop"
                    name="crop"
                    options={crop}
                    value={crop?.filter((itm: any) => itm?.value === values?.crop) || null}
                    onChange={(e: any) => {
                      setFieldValue("crop", e?.value || "");
                      setPDDID(e?.value || "");
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Crop"}
                    noOptionsMessage={() => "Crop appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="crop" component={TextError} />
                </Col>
                <Col md={12}>
                  <div className="radio-sec-wrap">
                    {PEST_DISEASE_DEFICIENCY.map((item: any) => (
                      <div key={item.value}>
                        <Input
                          type="radio"
                          id={item.name}
                          name="type"
                          value={item.value}
                          checked={parseInt(values?.type) === item.value}
                          onChange={(e) => {
                            setFieldValue("type", parseInt(e.target.value));
                             // Parse value to integer
                             setPddType(e.target.value )
                          }}                        />
                        <Label for={item.name}>{item.name}</Label>

                      </div>
                    ))}
                  </div>
                  <ErrorMessage name="type" component={TextError} />

                </Col>
                {/* <Col md={12}>
                  <Label>PEST/DISEASE/DEFICIENCY</Label>
                  <Select
                    placeholder="Select Pest"
                    name="pdd"
                    options={cropPDD}
                    value={
                      cropPDD?.filter(
                        (itm: any) => itm?.value === values?.pdd
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("pdd", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching PDD"}
                    noOptionsMessage={() => "PDD appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="pdd" component={TextError} />
                </Col> */}

                <Col md={12}>
                  <Label>Name of PEST/DISEASE/DEFICIENCY</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    name="name_pdd"
                    value={values?.name_pdd}
                  />
                  <ErrorMessage name="name_pdd" component={TextError} />
                </Col>
                <Col md={12}>
                  <Label>Symptoms</Label>
                  <FieldArray name="symptoms">
                    {({ push, remove }) => (
                      <div>
                        {values.symptoms.map((item: any, index: any) => (
                          <div key={index} className="field-wrap">
                            <Field
                              name={`symptoms.${index}.symptoms`}
                              placeholder="Symptoms"
                              as={Input}
                            />

                            <ErrorMessage
                              name={`symptoms.${index}.symptoms`}
                              component={TextError}
                            />

                            <div className="close-icon-wrap">
                              <i onClick={() => remove(index)} className="icon icon-close-red"></i>
                            </div>
                          </div>
                        ))}
                        <div className="plus-new-wrap"> 
                          <i className="icon icon-plus-new xl" onClick={() => {
                                push({ symptoms: "" });
                              }}></i>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Col>

                <Col lg={12}>
                  <Label>Control Measure Description</Label>

                  <FieldArray name="control_measure_description">
                    {({ push, remove }) => (
                      <div>
                        {values.control_measure_description.map(
                          (item: any, index: any) => (
                            <div key={index} className="field-wrap">
                              <Field
                                name={`control_measure_description.${index}.control_measure_description`}
                                placeholder="Control Measure Description"
                                as={Input}
                              />

                              <ErrorMessage
                                name={`control_measure_description.${index}.control_measure_description`}
                                component={TextError}
                              />

                              <div className="close-icon-wrap">
                                <i onClick={() => remove(index)} className="icon icon-close-red"></i>
                              </div>

                            </div>
                          )
                        )}
                        <div className="plus-new-wrap">
                          <i className="icon icon-plus-new xl" onClick={() => {
                              push({ control_measure_description: "" });
                            }}></i>
                        </div>

                      </div>
                    )}
                  </FieldArray>
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddSymptom;
