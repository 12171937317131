import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

const ReportIndex = () => {
  const { pathname } = useLocation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (pathname?.startsWith("/member")) setUrl("/member");
    if (pathname?.startsWith("/admin")) setUrl("/admin");
  }, [pathname]);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="settings-menu-wrap">
            <div>
              <h6>Report Details</h6>
            </div>

            <Row className="my-1 gy-4">
              <Col md={4}>
                <Link to={`${url}/membershippending-report/1`}>
                  <div className="menu-wrap">
                    <div>
                      <small>Membership Pending Status</small>
                    </div>
                    <div>
                      <i className="icon xl icon-next-arrow"></i>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link to={`${url}/membercontribution-report/1`}>
                  <div className="menu-wrap">
                    <div>
                      <small>Member Contribution Reports</small>
                    </div>
                    <div>
                      <i className="icon xl icon-next-arrow"></i>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link to={`${url}/report-prescription/1`}>
                  <div className="menu-wrap">
                    <div>
                      <small>Prescription Report</small>
                    </div>
                    <div>
                      <i className="icon xl icon-next-arrow"></i>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link to={`${url}/memberreport-list/1`}>
                  <div className="menu-wrap">
                    <div>
                      <small>Member List</small>
                    </div>
                    <div>
                      <i className="icon xl icon-next-arrow"></i>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={4}>
                <Link to={`${url}/vacancyreport-list/1`}>
                  <div className="menu-wrap">
                    <div>
                      <small>Vacancy Details</small>
                    </div>
                    <div>
                      <i className="icon xl icon-next-arrow"></i>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link to="">
                  <div className="menu-wrap">
                    <div>
                      <small>Broadcast Report</small>
                    </div>
                    <div>
                      <i className="icon xl icon-next-arrow"></i>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default ReportIndex;
