import * as Yup from "yup";

export const DownloadValidationSchema = Yup.object().shape({
  category_name: Yup.string().required("Field is required"),
  categories: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Field is required'),
      pdfname: Yup.string().required('Field is required'),
    })
  ),

});

export const AddDownloadInitialValues = {
  category_name: "",
  category_id:"",
  categories: [{
    name: "",
    pdfname: ""
  }],
  parent_id: ''
};
