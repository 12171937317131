import * as Yup from "yup";

export const UserValidationSchema = Yup.object().shape({
  role_status: Yup.array()
    .min(1, "Select at least one option")
    .required("Select at least one option"),
});

export const AddUserinitialValues = {
  role_status: [""],
};
