import * as Yup from "yup";

export const DesignationValidationSchema = Yup.object().shape({

  cadre_id: Yup.string().required("Field is required"),
  district: Yup.string().required("Field is required"),
  office_id:Yup.string().required("Field is required"),
});

export const AddDesignationInitialValues = {
  designation_name: "",
  cadre_id: "",
  block_id: "",
  district: "",
  office_id:"",
  designation_meta:""
};
