import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";

interface PaymentStatus {
  1: "Paid";
  2: "Failed";
  3: "Pending";
}
export const sentPaymentLinkToAll = (id: string | undefined, setModal: any) => {
  if (id)
    api
      .post(
        "/other-member-fund/send-payment-link",
        {
          other_mem_fund_id: id,
        },
        true
      )
      .then(async function ([success, response]: any) {
        if (response?.success === true) {
          toast.success("Payment Link Sent To All Members");
          setModal(false);
        }
      })
      .catch((err) => {
        // toast.error("Unable to send payment link");
      });
};

export const getPaymentStatus = (status: number) => {
  const payment_status: PaymentStatus = {
    1: "Paid",
    2: "Failed",
    3: "Pending",
  };
  return payment_status[status as keyof PaymentStatus];
};
