import * as Yup from "yup";

export const AccomodationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  district: Yup.string().required("Field is required"),
});

export const AddAccomodationInitialValues = {
  name: "",
  district: "",
};
