import { ErrorMessage } from "formik";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddRoleProps } from "../../../../../Type";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../Components/FormikError/TextError";
import { capitalizeWord } from "../../../../../Utils/helpers/helpers";
import { useState } from "react";

const AddRole = ({
  setIsOpen,
  modalEditData,
  refetch,
  permission,
  handleSubmit,
  handleChange,
  isSubmitting,
  permission_data,
  values,
  setFieldValue,
  length_of_slect,
}: AddRoleProps) => {
  const [all_select, setAllSelect] = useState(
    modalEditData?.length !== 0 ? true : false
  );
  const handleCheckboxChange = (index: any) => {
    var new_data: any = [...permission];

    new_data[index].is_check = !new_data[index]?.is_check;
    let checkValues = new_data?.filter((item: any) => item?.is_check === true);
    let check_array: any = [];
    checkValues?.map((item: any) => {
      check_array?.push(item?.id);
    });

    setFieldValue("permissions", check_array);
  };
  const AllSelect = (data: any) => {
    if (data === true) {
      var new_data: any = [...permission];
      new_data?.map((item: any) => {
        item.is_check = true;
      });
      let checkValues = new_data?.filter(
        (item: any) => item?.is_check === true
      );
      let check_array: any = [];
      checkValues?.map((item: any) => {
        check_array?.push(item?.id);
      });
      setFieldValue("permissions", check_array);
      setAllSelect(true);
    } else {
      var new_data: any = [...permission];
     
      new_data?.map((item: any) => {
        item.is_check = false;
      });
      let checkValues = new_data?.filter(
        (item: any) => item?.is_check === true
      );

      let check_array: any = [];
      checkValues?.map((item: any) => {
        check_array?.push(item?.id);
      });
      setFieldValue("permissions", check_array);
      setAllSelect(false);
    }
  };

  return (
    <div className="form-wrap">
      <Form onSubmit={handleSubmit}>
        <Row className="gy-4">
          <Col lg={12}>
            <Label>Role Name</Label>
            <Input
              type="text"
              onChange={handleChange}
              value={values?.role_name}
              name="role_name"
              placeholder="Role Name"
            />
            <ErrorMessage name="role_name" component={TextError} />
          </Col>
          <Col lg={12}>
            {all_select === false ? (
              <>
                {" "}
                <Input
                  type="checkbox"
                  onChange={(e) => AllSelect(true)}
                  checked={all_select}
                />
                <Label>Select all</Label>
              </>
            ) : (
              <>
                <Input
                  type="checkbox"
                  onChange={(e) => AllSelect(false)}
                  checked={all_select}
                />
                <Label>Unselect all</Label>
              </>
            )}
          </Col>

          <Row className="my-3">
            <Label>Permission</Label>

            {permission?.map((item: any, index: any) => (
              <Col md={6} key={item.id}>
                <div className="role-permission-wrap">
                  <div>
                    <Input
                      type="checkbox"
                      onChange={(e) => handleCheckboxChange(index)}
                      checked={item?.is_check}
                      name={`permissions`}
                      id={`permission+${index}`}
                      value={item}
                    />
                  </div>

                  <div>
                    <Label htmlFor={`permission+${index}`}>
                      {capitalizeWord(item?.name)} :
                      <b> {capitalizeWord(item?.type)}</b>
                    </Label>
                  </div>
                  <hr />
                </div>
              </Col>
            ))}

            <ErrorMessage name="permissions" component={TextError} />
          </Row>
          <Col lg={12}>
            <CustomButton
              isLoading={isSubmitting}
              label="Submit"
              color="primary"
              className="sm submit-btn"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddRole;
