import { ErrorMessage, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  FundSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddFundMethods/AddFundModalMethods";
import {
  AddFundInitialValues,
  FundValidationSchema,
} from "./AddFundMethods/FormikValidation";
import { AddOfficeProps } from "../../../../../Type";
import Select from "react-select";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { getAccomadtionList, getOfficeFullList, getaccommodationFullLists } from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { extractStaffData } from "../../AccomodationData/AddAccomodation/AddAccomodationMethods/AddAccomodationModalMethods";

const AddFund = ({
  setIsOpen,

  modalEditData,
  refetch,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddFundInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: accommodationlist,
    error: accommodationlisterror,
    isLoading: accommodationlistLoading,
    refetch: accommodationlistRefetch,
  }: any = useQuery([`ofice-full-list`], () => getOfficeFullList(), {
    keepPreviousData: true,
  });
  return (
    <Formik
      validationSchema={FundValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        FundSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id

        );
      }}

    >

      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={6}>
                  <Label>Name</Label>
                  <Select
                    placeholder="Select Name"
                    name="office"
                    options={accommodationlist}
                    value={
                      accommodationlist?.find((itm: any) => itm?.value === values?.office) || null
                    }
                    onChange={(e: any) => {
                      setFieldValue("office", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Office"}
                    noOptionsMessage={() => "Office appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="office" component={TextError} />

                </Col>
                <Col lg={6}>
                  <Label>Recieved Fund</Label>
                  <Input
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onChange={handleChange}
                    value={values?.received_fund}
                    name="received_fund"
                    placeholder="Recieved Fund"
                  />
                  <ErrorMessage name="received_fund" component={TextError} />
                </Col>

                <Col lg={6}>
                  <Label>Expanded Fund</Label>
                  <Input
                    type="number"
                    onChange={handleChange}
                    value={values?.expended_fund}
                    name="expended_fund"
                    placeholder="Expanded Fund"
                  />
                  <ErrorMessage name="expended_fund" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Document</Label>
                  <Input
                    type="file"
                    name="document"
                    onChange={handleChange}
                    placeholder="Document"
                  />
                  <ErrorMessage name="document" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddFund;
