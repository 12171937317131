import * as Yup from "yup";

export const ControlMeasuresValidationSchema = Yup.object().shape({
  crop_name: Yup.string().required("Field is required"),
  control_measure_description: Yup.string().required("Field is required"),
});

export const AddControlMeasuresInitialValues = {
  crop_name: "",
  control_measure_description: "",
};
