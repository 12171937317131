import { Col } from "reactstrap";
import Header from "../Header/Header";
import useHamburgerMenu from "../../../Hooks/useHamburgerMenu";
import MemberSidebar from "./MemberSidebar";

const MemberLayout = ({ children, ROLE }: any) => {
  const { isOpen, toggleMenu } = useHamburgerMenu();

  return (
    <>
      <MemberSidebar isOpen={isOpen} />
      <Col
        className={` ${
          isOpen
            ? "full-width-col"
            : " minimise offset-md-3 offset-lg-2 offset-md-sm-3"
        }`}
      >
        <Header ROLE={ROLE} toggleMenu={toggleMenu} />
        <div className="content-wrapper">{children}</div>
      </Col>
    </>
  );
};

export default MemberLayout;
