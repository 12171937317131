import * as Yup from "yup";

export const MeetingsValidationSchema = Yup.object().shape({
  meeting_title: Yup.string().required("Field is required"),
  meeting_description: Yup.string().required("Field is required"),
  meeting_start_date: Yup.date().required("Field is required"),
  meeting_end_date: Yup.date().required("Field is required"),
  meeting_type: Yup.string().required("Field is required"),
  meeting_link: Yup.string().required("Field is required"),
});

export const AddMeetingsInitialValues = {
  meeting_title: "",
  meeting_description: "",
  meeting_start_date: "",
  meeting_end_date: "",
  meeting_type: "",
  meeting_link: "",
};
