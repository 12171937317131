import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddSymptoms/AddSymptomsMethods/AddSymptomsModalMethods";
import { handleAdminStaffSearch } from "./SymptomsMethods";
import AddSymptom from "./AddSymptoms";
import {
  getCropCategoryFullList,
  getCropDetlesList,
  getCropFullList,
} from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const SymptomIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [pdd_id, setPDDID] = useState();
  const [pddType,setPddType] = useState()

  const {
    data: crop_det,
    error: crop_detError,
    isLoading: crop_detLoading,
    refetch: crop_detListRefetch,
  }: any = useQuery(
    [`crop-det${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getCropDetlesList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  // const {
  //   data: cropPDD,
  //   error: cropPDDError,
  //   isLoading: cropPDDLoading,
  //   refetch: cropPDDListRefetch,
  // }: any = useQuery(
  //   [`cropPDD${pdd_id}${pddType}`, pdd_id, pddType], // Pass both pdd_id and type
  //   () => (pdd_id && pddType ? getCropCategoryFullList(pdd_id, pddType) : ""),
  //   {
  //     keepPreviousData: true,
  //   }
  // );
  const {
    data: crop,
    error: cropError,
    isLoading: cropLoading,
    refetch: cropListRefetch,
  }: any = useQuery([`crop`], () => getCropFullList(), {
    keepPreviousData: true,
  });
  const staffData = useMemo(() => extractStaffData(crop_det), [crop_det]);

  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Prescription Management", link: "/admin/prescription-settings" },
      { label: "Crop-Symptoms", link: "/admin/symptoms/1" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Prescription Management", link: "/member/prescription-settings" },
      { label: "Crop-Symptoms", link: "/member/symptoms/1" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <div className="button-wrap">
                    {checkPermission("crop:write") ? <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Crop Details
                    </Button> : null}
                  </div>
                </div>
              </div>
            </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Crop Details"
              tableFields={[

                "Crop",
                "Name of P/D/D",
                "Symptoms",
                "Control measure",
              ]}
              Arraykeys={[

                "crop",
                "name_pest_disease_def",
                "symptoms",
                "control_measure",
              ]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("crop:write") ? "edit" : "",
                checkPermission("crop:delete") ? "delete" : "",
              ]}
              isLoading={crop_detLoading}
              error={crop_detError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("crop:write")) onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  crop_det
                );
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, crop_detListRefetch)
              }
              paginationData={crop_det?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </div>
      </Container>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0 ? "Edit Crop Details" : "Add Crop Details"
        }
      >
        <AddSymptom
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={crop_detListRefetch}
          setPDDID={setPDDID}
          setPddType={setPddType}
          crop={crop}
          // cropPDD={cropPDD || []}
        />
      </ModalContainer>
    </section>
  );
};

export default SymptomIndex;
