import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddBlogs/AddBlogsMethods/AddBlogModalMethods";
import { handleAdminStaffSearch } from "./BlogsMethods";
import AddBlog from "./AddBlogs";
import { getBlogsList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { ROLES_PERMISSIONS } from "../../../../Utils/Constants/ROLES_PERMISSIONS";

const BlogIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { checkPermission } = usePermission();

  const {
    data: blog,
    error: blogError,
    isLoading: blogLoading,
    refetch: blogListRefetch,
  }: any = useQuery(
    [`blog${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getBlogsList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(blog), [blog]);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {checkPermission(ROLES_PERMISSIONS?.BLOG?.WRITE) ? <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Blog
                  </Button>
                </div> : null}
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Blog Details"
              tableFields={["Blog Title", "Blog Body","Image","Status"]}
              Arraykeys={["title", "body","image","status_data"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission(ROLES_PERMISSIONS?.BLOG?.WRITE) ? "edit" : "",
                checkPermission(ROLES_PERMISSIONS?.BLOG?.DELETE) ? "delete" : "",
                "status"
              ]}
              isLoading={blogLoading}
              error={blogError}
              onEditClick={(selectedId: any) => {
                if (checkPermission(ROLES_PERMISSIONS?.BLOG?.WRITE)) {
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    blog
                  )  
                }
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, blogListRefetch)
              }
              paginationData={blog?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
              Refetch={blogListRefetch}
              from_blog={true}
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Blog" : "Add Blog"}
      >
        <AddBlog
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={blogListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default BlogIndex;
