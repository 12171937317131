import * as Yup from "yup";

export const PDDValidationSchema = Yup.object().shape({
  crop: Yup.string().required("Field is required"),
  pdd: Yup.string().required("Field is required"),
  type: Yup.number().required("Field is required"),
});

export const AddPDDInitialValues = {
  crop: "",
  pdd: "",
  type: "",

};
