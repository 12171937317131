export const ROLES_PERMISSIONS = {

    MAGAZINE: {
        READ: "magazine:read",
        WRITE: "magazine:write",
        DELETE: "magazine:delete",
        STATUS: "magazine:status"
    },

    BLOG: {
        READ: "blog:read",
        WRITE: "blog:write",
        DELETE: "blog:delete"
    },

    PRESCRIPTION: {
        READ: "prescription:read",
        WRITE: "prescription:write",
        DELETE: "prescription:delete",
        VIEW: "prescription:view"
    },

    TRANSFER_REQUEST: {
        READ: "transfer:read",
        WRITE: "transfer:write",
        DELETE: "transfer:delete",
        ACCEPT: "transfer:accept"
    }
}