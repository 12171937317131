import { Badge } from "reactstrap";
import { clearData } from "../../lib/keystorage";
import { USER_ROLES } from "../AuthenticationConstants";
import constants from "../Constants/Constants";
import moment from "moment";

function GetPropertyValue(obj1: any, dataToRetrieve: any) {
  return dataToRetrieve.split("?.").reduce(function (o: any, k: any) {
    return o && o[k];
  }, obj1);
}

function getStatusStyle(id: any) {
  return parseInt(id) === constants?.STATUS?.ACTIVE ? (
    <span className="">Active</span>
  ) : (
    <span className="">Inactive</span>
  );
}

export const Logout = async (navigate: Function) => {
  await clearData();
  navigate("/");
};

export const capitalizeWord = (inputString: string) => {
  return inputString
    ?.split(" ")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
};
function FirstLetterUpper(text: any) {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
}

const removeUnderScore = (stringWithUnderscore: any) => {
  const stringWithoutUnderscore = stringWithUnderscore?.replace(/_/g, " ");
  return stringWithoutUnderscore;
};

function processJsonResponse(data: any, fields = ["id", "name"]) {
  let dataProcessedResponse = data?.map(function (obj: any) {
    obj["value"] = obj[fields[0]];
    obj["label"] = obj[fields[1]];
    delete obj[fields[0]];
    delete obj[fields[1]];
    return obj;
  });
  return dataProcessedResponse;
}

// Sets error message for all fields from response
const setValidationErrors = (errors: any, actions: any) => {
  Object?.keys(errors)?.map((fieldName) =>
    errors?.[fieldName]?.map((errorMessage: string, index: number) =>
      actions?.setFieldError(fieldName, errorMessage)
    )
  );
};
const setValidationErrorsAll = (errors: any, actions: any) => {
  errors?.forEach((error: any) => {
    const fieldName = Object.keys(error)[0];
    const errorMessage = Object.values(error)[0];
    actions?.setFieldError(fieldName, errorMessage);
  });
};

// checks authentication
const AuthChecker = (navigate: any, path: string) => {
  if (localStorage.getItem("AUTH_TOKEN") !== null) {
    if (localStorage.getItem("ROLE") === USER_ROLES?.ADMIN) {
      navigate("/admin/dashboard", { replace: true });
    } else if (localStorage.getItem("ROLE") === USER_ROLES?.MEMBER) {
      navigate("/member/profile", { replace: true });
    } else if (localStorage.getItem("ROLE") === USER_ROLES?.ASSOCIATIVE) {
      navigate("/associatemember/dashboard", { replace: true });
    }
  } else {
    navigate(path, { replace: true });
  }
};

// Formats date to yyyy-mm-dd format
export const DateFormatter = (inputDate: any) => {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const DateFormatterYearOfPass = (inputDate: any) => {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}`;
  return formattedDate;
};

const capitalizeFirst = (str: any) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

function convertISODateToDate(iso_date: any) {
  
  var date = new Date(Date.parse(iso_date)); 
  
  let months = {
    month_names: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    month_names_short: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };
  return (
    months.month_names_short[date.getMonth()] +
    " " +
    date.getDate() +
    "," +
    date.getFullYear()
  );
}
function validateDatetotime(iso_date: any) {
  var date = new Date(iso_date);
  var hours = date.getHours();
  var minutes: any = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}
function getTimeFromDate(date: any) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Ensure two-digit format
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
function convertISODateToDateTime(iso_date_time: any) {
  return (
    convertISODateToDate(iso_date_time) +
    " " +
    validateDatetotime(iso_date_time)
  );
}

function formatDateTime(dateTimeString: any) {
  const dateObject = new Date(dateTimeString);

  // Check if the dateObject is a valid date
  if (isNaN(dateObject.getTime())) {
    return "Invalid Date";
  }

  // Use toISOString to get UTC representation
  const formattedDateTime = dateObject
    .toISOString()
    .slice(0, 19)
    .replace("T", ", ");

  return formattedDateTime;
}

function formatDateTimeDash(dateTimeString: any) {
  const dateObject = new Date(dateTimeString);

  // Check if the dateObject is a valid date
  if (isNaN(dateObject.getTime())) {
    return "Invalid Date";
  }

  // Use toISOString to get UTC representation
  const formattedDate = dateObject.toISOString().slice(0, 10);

  return formattedDate;
}

function formatDateTimeForInput(dateTimeString: any) {
  const dateObject = new Date(dateTimeString);

  // Check if the dateObject is a valid date
  if (isNaN(dateObject.getTime())) {
    return "Invalid Date";
  }

  // Use toISOString to get UTC representation
  const formattedDateTime = dateObject.toISOString().slice(0, 19);

  return formattedDateTime;
}

function calculate_age(dob1: string) {
  var date = moment(dob1).format("YYYY-MM-DD");
  const today = new Date();
  const birthDate = new Date(date);

  // Calculate age in years
  const ageInYears = today.getFullYear() - birthDate.getFullYear();

  // Calculate age in months
  const monthsDiff = today.getMonth() - birthDate.getMonth();
  const ageInMonths = ageInYears * 12 + monthsDiff;

  return ageInYears === 0 ? ageInMonths + " Months" : ageInYears + " Yrs";
}

function shift_time(week_day: any) {
  return week_day === 7
    ? "Sunday"
    : week_day === 1
    ? "Monday"
    : week_day === 2
    ? "Tuesday"
    : week_day === 3
    ? "Wednesday"
    : week_day === 4
    ? "Thursday"
    : week_day === 5
    ? "Friday"
    : week_day === 6
    ? "Saturday"
    : "";
}

// Sun Oct 01 2023 00:00:00 GMT+0530 (India Standard Time) to yyyy-mm-dd format
const ISODateToDateConverter = (date: string) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

// Sun Oct 01 2023 00:00:00 GMT+0530 (India Standard Time) to yyyy-mm-dd format
const ISODateToDateYearConverter = (date: string) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

var SI_SYMBOL = ["", "K", "M", "B", "T", "P", "E"];

function nFormatter(number: any) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

const customDayHeaders: any = {
  Sunday: "SUN",
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
};

function EmployeementStatus(id: any) {
  return id === constants?.PRESENT_EMPLOYMENT_STATUS?.DEPARTMENT
    ? "DEPARTMENT"
    : id === constants?.PRESENT_EMPLOYMENT_STATUS?.DEPUTATION
    ? "DEPUTATION"
    : id === constants?.PRESENT_EMPLOYMENT_STATUS?.LEAVE
    ? "LEAVE"
    : id === constants?.PRESENT_EMPLOYMENT_STATUS?.WORKING_ARRANGEMENT
    ? "WORKING_ARRANGEMENT"
    : "";
}

function JoinStatus(id: any) {
  return id === constants?.JOIN_STATUS?.EXUSER
    ? "Exist User"
    : id === constants?.JOIN_STATUS?.NEWUSER
    ? "New User"
    : "";
}
function MembershipStatus(id: any) {
  return id === constants?.MEMBERSHIP_TYPE?.ANNUALMEMBER
    ? "ANNUAL MEMBER"
    : id === constants?.MEMBERSHIP_TYPE?.RETIRED_MEMBER
    ? "ASSOCIATE MEMBER"
    : id === constants?.MEMBERSHIP_TYPE?.LIFE_MEMBER
    ? "LIFE MEMBER"
    : "";
}
function WorkingType(id: any) {
  return id === constants?.WORKING_STATUS?.WORKING
    ? "WORKING"
    : id === constants?.WORKING_STATUS?.RETIRED
    ? "RETIRED"
    : id === constants?.WORKING_STATUS?.TRANSIT
    ? "TRANSIT"
    : "";
}
function PensionType(id: any) {
  return id === constants?.EMPLOYEE_STATUS?.NEW_PENSION_SCHEME
    ? "New Pension Scheme"
    : id === constants?.EMPLOYEE_STATUS?.STATUTORY_PENSION
    ? "Statutory Pension Scheme"
    : "";
}
function LeaveType(id: any) {
  return id === constants?.LEAVE_TYPES?.Leave_for_study_purpose
    ? "Leave for study purpose"
    : id === constants?.LEAVE_TYPES?.Leave_for_joining_spouse_abroad
    ? "Leave_for_joining_spouse_abroad"
    : id === constants?.LEAVE_TYPES?.Leave_for_taking_employment_abroad
    ? "Leave_for_taking_employment_abroad"
    : id === constants?.LEAVE_TYPES?.Maternity_leave
    ? "aternity_leave"
    : id === constants?.LEAVE_TYPES?.LPR
    ? "LPR"
    : id === constants?.LEAVE_TYPES?.Others
    ? "Others"
    : "";
}
function LeaveStatus(id: any) {
  return id === constants?.LEAVE_STATUS?.PENDING
    ? "Pending"
    : id === constants?.LEAVE_STATUS?.ACCEPETED
    ? "Accepted"
    : id === constants?.LEAVE_STATUS?.REJECTED
    ? "Rejected"
    : "";
}

function LeaveStatusStyle(id: any) {
  return id === constants?.LEAVE_STATUS?.PENDING ? (
    <Badge color="warning">Pending</Badge>
  ) : id === constants?.LEAVE_STATUS?.ACCEPETED ? (
    <Badge color="success-100">Accepted</Badge>
  ) : id === constants?.LEAVE_STATUS?.REJECTED ? (
    <Badge color="danger">Rejected</Badge>
  ) : (
    ""
  );
}
function ActiveRevokeStatusStyle(id: any) {
  return id === constants?.MEMBER_ACTIVE_REVOKE?.ACTIVE ? (
    <Badge color="warning">Active</Badge>
  ) : id === constants?.LEAVE_STATUS?.ACCEPETED ? (
    <Badge color="success-100">Suspended</Badge>
  ) : (
    ""
  );
}

function TransferRequestStyle(status: any) {
  return status === constants?.LEAVE_STATUS?.PENDING ? (
    <Badge color="warning">Pending</Badge>
  ) : status === constants?.LEAVE_STATUS?.ACCEPETED ? (
    <Badge color="success-100">Accepted</Badge>
  ) : status === constants?.LEAVE_STATUS?.REJECTED ? (
    <Badge color="danger">Rejected</Badge>
  ) : (
    ""
  );
}

function MeetingStatus(id: any) {
  return id === constants?.MEETING_TYPE?.STATE
    ? "State Meeting"
    : id === constants?.MEETING_TYPE?.COUNSEL
    ? "Counsel Meeting"
    : "";
}
function FundStatus(id: any) {
  return id === constants?.FUND_PUBLISH?.PUSBLISHED
    ? "Published"
    : id === constants?.FUND_PUBLISH?.UN_PUBLISH
    ? "UnPublished"
    : "";
}
const getDownloadStatus = (id: any) => {
  return id === 1 ? "Published" : id === 2 ? "UnPublished" : "";
};
export {
  GetPropertyValue,
  getStatusStyle,
  processJsonResponse,
  setValidationErrors,
  capitalizeFirst,
  AuthChecker,
  convertISODateToDate,
  convertISODateToDateTime,
  validateDatetotime,
  calculate_age,
  ISODateToDateConverter,
  nFormatter,
  shift_time,
  customDayHeaders,
  EmployeementStatus,
  JoinStatus,
  MembershipStatus,
  WorkingType,
  PensionType,
  LeaveType,
  LeaveStatus,
  LeaveStatusStyle,
  MeetingStatus,
  FundStatus,
  TransferRequestStyle,
  setValidationErrorsAll,
  getDownloadStatus,
  getTimeFromDate,
  removeUnderScore,
  formatDateTime,
  formatDateTimeForInput,
  ISODateToDateYearConverter,
  formatDateTimeDash,
  ActiveRevokeStatusStyle,
  FirstLetterUpper,
};
