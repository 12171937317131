import * as Yup from "yup";
import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";

// formik validation schema
export const ResetPasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Old password is required")
    .min(8, "Password must be at least 8 characters long"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters long"), 
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), ""], "Passwords must match")
    .required("Confirm password is required"),
});

export const handlePasswordReset = (values: any, actions: any) => {
  api
    .post("/auth/change-password", values, true)
    .then(async function ([success, response]: any) {
      actions.setSubmitting(false);
      actions.resetForm();
      toast.success("Password changed");
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.message) {
        if (err?.response?.data?.message?.includes("Old password")) {
          actions?.setFieldError("oldPassword", err?.response?.data?.message)
        }
        toast?.error(err?.response?.data?.message);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};
