import { useEffect, useState } from "react";
import { Button, Col, Nav, NavItem, NavLink } from "reactstrap";
import { useQuery } from "react-query";
import {
  getMemberOfficeData,
  getMemberProfileData,
  handleFilterEduList,
} from "./MemberProfileMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import MemberProfileEdit from "./MemberProfileView/MemberProfileEdit";
import MyProfile from "./MemberProfileView/MyProfile";
import Myoffice from "./MemberProfileView/MyOffice";
import { useNavigate } from "react-router";
import MemberOfficeEdit from "./MemberProfileView/MyOfficeEdit";

const MemberProfileIndex = () => {
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [isOpens, setIsOpens] = useState(false);
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [filteredEduList, setFilteredEduList] = useState<any>({});

  const {
    data: memberProfileData,
    refetch: memberProfileDataRefetch,
    isError: memberProfileError,
    isLoading: memberProfileLoading,
  }: any = useQuery([`profile_data`], () => getMemberProfileData(), {
    keepPreviousData: true,
  });

  const { data: memberOfficeData, refetch: memberOfficeDataRefetch }: any =
    useQuery([`office_data`], () => getMemberOfficeData(), {
      keepPreviousData: true,
    });
  const [tab, setTab] = useState("1");

  useEffect(() => {
    handleFilterEduList(memberProfileData, setFilteredEduList);
  }, [memberProfileData]);
  const navigate = useNavigate();
  return (
    <section>
      <div className="content-outer-wrap">
        {memberProfileData?.educationQualification?.length === 0 ? (
          <>
            <div>
              {tab === "3" ? null : (
                <div className="table-head-outer">
                  <Col md={6}>Please completed your profile</Col>
                  <Col md={6}>
                    <Button
                      onClick={() => {
                        setTab("3");
                      }}
                      className="sm submit-btn w-50"
                    >
                      Edit Profile
                    </Button>
                  </Col>
                </div>
              )}
            </div>

            {tab === "3" && (
              <>
                <MyProfile
                  memberProfileData={memberProfileData}
                  setModalEditData={setModalEditData}
                  setIsOpen={setIsOpen}
                  memberProfileDataRefetch={memberProfileDataRefetch}
                  modalEditData={modalEditData}
                  filteredEduList={filteredEduList}
                  memberProfileError={memberProfileError}
                  memberProfileLoading={memberProfileLoading}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Nav pills className="justify-content-center">
              <NavItem>
                <NavLink
                  className={`tab-button ${tab === "1" ? "active" : ""}`}
                  onClick={() => setTab("1")}
                >
                  My Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`tab-button ${tab === "2" ? "active" : ""}`}
                  onClick={() => setTab("2")}
                >
                  My Office
                </NavLink>
              </NavItem>
            </Nav>

            {tab === "1" && (
              <>
                <MyProfile
                  memberProfileData={memberProfileData}
                  setModalEditData={setModalEditData}
                  setIsOpen={setIsOpen}
                  memberProfileDataRefetch={memberProfileDataRefetch}
                  modalEditData={modalEditData}
                  filteredEduList={filteredEduList}
                  memberProfileError={memberProfileError}
                  memberProfileLoading={memberProfileLoading}
                />
              </>
            )}
            {tab === "2" && (
              <>
                <Myoffice
                  memberOfficeData={memberOfficeData}
                  setModalEditData={setModalEditData}
                  setIsOpen={setIsOpens}
                  memberOfficeDataRefetch={memberOfficeDataRefetch}
                  modalEditData={memberOfficeData}
                />
              </>
            )}
          </>
        )}
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "My Profile" : "My Profile"}
      >
        <MemberProfileEdit
          setIsOpen={setIsOpen}
          modalEditData={memberProfileData}
          refetch={memberProfileDataRefetch}
          filteredEduList={filteredEduList}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={isOpens}
        setIsopen={setIsOpens}
        title={modalEditData?.length !== 0 ? "My Office" : "My Office"}
      >
        <MemberOfficeEdit
          setIsOpen={setIsOpens}
          modalEditData={memberOfficeData}
          refetch={memberOfficeDataRefetch}
          filteredEduList={filteredEduList}
          memberProfileData={memberProfileData}
        />
      </ModalContainer>
    </section>
  );
};

export default MemberProfileIndex;
