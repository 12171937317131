import { Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const MemberSettingsIndex = () => {
  const { checkPermission } = usePermission();

  return (
    <section>
      <div className="breadcrumb-wrap">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="#">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Settings</BreadcrumbItem>
        </Breadcrumb>
      </div>

      <div className="content-outer-wrap password-container">
        <div className="settings-menu-wrap">
          <Row className="my-1 gy-4 justify-content-center">
            <Col md={3}>
              <Link to="/member/change-password">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-change-pass"></i>
                    <p className="mb-0">Change Password</p>
                  </div>
                </div>
              </Link>
            </Col>

            {checkPermission("other-member-fund:read") ? <Col md={3}>
              <Link to="/member/other-fund/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-fund-grey"></i>
                    <p className="mb-0">Other fund Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("leave:read") ? <Col md={3}>
              <Link to="/member/leave-management/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-leave-grey"></i>
                    <p>Leave Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("cadre:read") ? <Col md={3}>
              <Link to="/member/cader-settings/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-cadre"></i>
                    <p>Cadre Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("designation:read") ? <Col md={3}>
              <Link to="/member/designation/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-designation-grey"></i>
                    <p>Designation Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("roles:read") ? <Col md={3}>
              <Link to="/member/roles/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-profile-grey"></i>
                    <p>Role Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("user:read") ? <Col md={3}>
              <Link to="/member/user-settings/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-user-grey"></i>
                    <p>User Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("accommodation:read") ? <Col md={3}>
              <Link to="/member/accomodation/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-accomodation-data"></i>
                    <p className="mb-0"> Accomodation Data</p>
                  </div>
                </div>
              </Link>
            </Col>
              : null}

            {checkPermission("notification:read") ? <Col md={3}>
              <Link to="/member/notification-settings">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-notification-grey"></i>
                    <p>Notification Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("notification:read") ? <Col md={3}>
              <Link to="/member/prescription-settings">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-prescription-grey"></i>
                    <p>Prescription Management</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

            {checkPermission("website:read") ? <Col md={3}>
              <Link to="/member/getintouch/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-accomodation-data"></i>
                    <p className="mb-0">Get in Touch</p>
                  </div>
                </div>
              </Link>
            </Col>
              : null}

            {checkPermission("office category:read") ? <Col md={3}>
              <Link to="/member/office-categorey/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-office-grey"></i>
                    <p>Office Category Settings</p>
                  </div>
                </div>
              </Link>
            </Col> : null}
            
            {checkPermission("membership-settings:read") ? <Col md={3}>
              <Link to="/member/membership-settings">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-accomodation-data"></i>
                    <p className="mb-0">Membership Management</p>
                  </div>
                </div>
              </Link>
            </Col>
              : null}

            {checkPermission("asset category:read") ? <Col md={3}>
              <Link to="/member/asset-category-settings/1">
                <div className="settings-menu-outer">
                  <div>
                    <i className="icon xxl icon-asset-grey"></i>
                    <p>Asset Category Settings</p>
                  </div>
                </div>
              </Link>
            </Col> : null}

          </Row>
        </div>
      </div>
    </section >
  );
};

export default MemberSettingsIndex;
