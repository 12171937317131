import { SidebarMenuItemType } from "../../../Type";

const AsoociateSidebarMenuItems: SidebarMenuItemType[] = [
  {
    icon: <i className="icon xl icon-dashboard"></i>,
    label: "Associate Membership",
    path: "/associatemember/dashboard",
  },
  {
    icon: <i className="icon xl icon-settings"></i>,
    label: "Change Password",
    path: "/associativemember/change-password",
    AllPaths: ["/member/change-password"],
  },
];
export { AsoociateSidebarMenuItems };
