import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddAssetCategory/AddAssetCategoryMethods/AddAssetCategoryModalMethods";
import { handleAdminStaffSearch } from "./CategoryMethods";
import AddAssetCategory from "./AddAssetCategory";
import { getAssetCategoryList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const AssetCategoryIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const location = useLocation();
  const {
    data: assetCategory,
    error: assetCategoryError,
    isLoading: assetCategoryLoading,
    refetch: assetCategoryListRefetch,
  }: any = useQuery(
    [`asset-categorey${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getAssetCategoryList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(
    () => extractStaffData(assetCategory),
    [assetCategory]
  );
  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Asset Category Management", link: "/admin/asset-category-settings/1" }
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Asset Category Management", link: "/member/asset-category-settings/1" }
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  return (
    <section>
      <Container>
        <div className="content-outer-wrap">
            <div className="breadcrumb-wrap">{breadcrumb}</div>
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <div className="button-wrap">
                    {checkPermission("asset category:write") ? <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Category
                    </Button> : null}
                  </div>
                </div>
              </div>
            </div>
          <div></div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Category Details"
              tableFields={["Name"]}
              Arraykeys={["name"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("asset category:write") ? "edit" : "",
                checkPermission("asset category:delete") ? "delete" : "",
              ]}
              isLoading={assetCategoryLoading}
              error={assetCategoryError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("asset category:write")) onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  assetCategory
                )
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, assetCategoryListRefetch)
              }
              paginationData={assetCategory?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
              from_cat={true}
            />
          </div>
        </div>
      </Container>


      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit Asset Category"
            : "Add Asset Category"
        }
      >
        <AddAssetCategory
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={assetCategoryListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default AssetCategoryIndex;
