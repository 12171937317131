import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  AddControlMeasuresInitialValues,
  ControlMeasuresValidationSchema,
} from "./AddControlMeasuresMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddControlMeasuresMethods/AddControlModalMethods";

import Select from "react-select";
import { AddOfficeProps } from "../../../../../../Type";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../../Components/FormikError/TextError";

const AddControlMeasures = ({
  setIsOpen,
  modalEditData,
  refetch,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddControlMeasuresInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={ControlMeasuresValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  <Label>Crop</Label>
                  <Select
                    placeholder="Select Crop"
                    name="crop_name"
                    // options={gender_data}
                    // value={
                    //   gender_data?.filter(
                    //     (itm: any) => itm?.value === values?.gender
                    //   ) || ""
                    // }
                    // onChange={(e: any) => {
                    //   setFieldValue("gender", e?.value);
                    // }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Crop"}
                    noOptionsMessage={() => "Crop appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="crop_name" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>Control measure description</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    value={values?.control_measure_description}
                    name="control_measure_description"
                    placeholder="Control measure description"
                  />
                  <ErrorMessage
                    name="control_measure_description"
                    component={TextError}
                  />
                </Col>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="secondary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddControlMeasures;
