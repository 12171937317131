import * as Yup from "yup";

export const AssetValidationSchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  asset_value: Yup.number().required("Field is required").nullable(),
  category: Yup.string().required("Field is required").nullable(),
  office: Yup.number().required("Field is required").nullable(),
  // asset_document: Yup.string().required("Field is required").nullable(),
  // sub_category: Yup.array().of(
  //   Yup.object().shape({
  //     description: Yup.string().required("Field is required"),
  //   })
  // ),
});

export const AddAssetInitialValues = {
  title: "",
  office: "",
  category: "",
  sub_category: [],
  asset_value: "",
  asset_document: "",
};
