import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddTestimonialProps } from "../../../../../../Type";
import {
  AddTestimonialInitialValues,
  TestimonialValidationSchema,
} from "./AddTestimonialsMethods/FormikValidation";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  TestimonialSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddTestimonialsMethods/AddTestimonialsMethods";

const AddTestimonial = ({
  setIsOpen,
  refetch,
  modalEditData,
  testimonialError,
}: AddTestimonialProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddTestimonialInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);
  const [imagefile, setImagefile] = useState("");
  const [imageView, seImageView] = useState("");
  const [image_change, setImageChange] = useState(false);
  const create_imageURL = (imgfile: any) => {
    seImageView(URL?.createObjectURL(imgfile));
    setImageChange(true);
  };

  return (
    <Formik
      validationSchema={TestimonialValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        TestimonialSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          imagefile
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <>
            {" "}
            <div className="Add-web-settings-wrap">
              <div className="form-wrap">
                <Form onSubmit={handleSubmit}>
                  <Row className="gy-4">
                    <Col md={12}>
                      <Label>Full Name</Label>
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values?.name}
                        name="name"
                        placeholder="Full Name"
                      />
                      <ErrorMessage name="name" component={TextError} />
                    </Col>

                    <Col md={12}>
                      <Label>Designation</Label>
                      <Input
                        type="textarea"
                        onChange={handleChange}
                        value={values?.designation}
                        name="designation"
                        placeholder="Designation"
                      />
                      <ErrorMessage name="designation" component={TextError} />
                    </Col>
                    <Col md={12}>
                      <Label>Description</Label>
                      <Input
                        type="textarea"
                        onChange={handleChange}
                        value={values?.testimonial}
                        name="testimonial"
                        placeholder="Description"
                      />
                      <ErrorMessage name="testimonial" component={TextError} />
                    </Col>
                    <Col lg={12}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Submit"
                        color="primary"
                        className="sm submit-btn"
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddTestimonial;
