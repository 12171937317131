import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";

export const handleAdminDownloadSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
    if (e?.target?.value?.length >= 1) {
        console.log(e?.target?.value,"sear")   
        setSearchQuery(e?.target?.value)
        // navigate(`/admin/download-settings/1`);
    }
    else {
        setSearchQuery('')
    }
}

export const downloadPublishHandler = (id: string | number, status: string | number, refetch: Function, setIsOpen: Function) => {
    api
        .patch(`/downloads/publish/${id}`, {
            status: status
        }, true)
        .then(async function ([success, response]: any) {
            toast.success("Updated");
            refetch()
            setIsOpen(false)
        })
        .catch((err) => {
            toast?.error("Error Updating Status")
        });
}