import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row, Table, FormGroup } from "reactstrap";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddMemberinitialValues,
  MemberValidationSchema,
} from "../AddMembers/AddMemberMethods/FormikValidation";
import { useQuery } from "react-query";
import { getMemberView } from "../../../../../Api/AdminApis";
import { VerifySubmitHandler } from "../AddMembers/AddMemberMethods/AddMemberModalMethods";
import {
  EmployeementStatus,
  JoinStatus,
  MembershipStatus,
  PensionType,
  WorkingType,
  convertISODateToDate,
} from "../../../../../Utils/helpers/helpers";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import ConfirmAlertModal from "../AddMembers/ConfirmAlert";

const VerifyMember = (props: any) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddMemberinitialValues
  );
  const [verify_modal, setVerifyModal] = useState(false);
  const [action_data, setActiondata] = useState<any>();
  const [value_data, setValuedata] = useState<any>();
  const { data: members }: any = useQuery(
    [`members${props?.preview_id}`, props?.preview_id],
    () => getMemberView(props?.preview_id),
    { keepPreviousData: true }
  );

  return (
    <>
      <Formik
        initialValues={{ user_id: props?.preview_id, member_status: "1" }}
        enableReinitialize
        onSubmit={(values, actions) => {
          setVerifyModal(true);
          setActiondata(actions);
          setValuedata(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          setFieldError,
          errors,
        }) => {
          return (
            <div className="form-wrap member-verify-table">
              <Form onSubmit={handleSubmit}>
                <div className="table-outer-wrap">
                  <div className="tale-outer">
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <Label>Membership Type</Label>
                          </td>
                          <td>
                            {MembershipStatus(members?.member_membership_type)}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Label>Working Status</Label>
                          </td>
                          <td>{WorkingType(members?.member_is_working)}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Employement Status</Label>
                          </td>
                          <td>
                            {EmployeementStatus(
                              members?.member_employment_status
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Pension Scheme</Label>
                          </td>
                          <td>{PensionType(members?.member_pension_scheme)}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>District</Label>
                          </td>
                          <td>{members?.district_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Block</Label>
                          </td>
                          <td>{members?.block_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Office</Label>
                          </td>
                          <td>{members?.office_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Name of Office</Label>
                          </td>
                          <td>{members?.office_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Cadre</Label>
                          </td>
                          <td>{members?.cadre_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Designation</Label>
                          </td>
                          <td>{members?.designationMeta_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>First Name</Label>
                          </td>
                          <td>{members?.user_first_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Last Name</Label>
                          </td>
                          <td>{members?.user_last_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label> Mobile Number</Label>
                          </td>
                          <td>{members?.member_phone_no}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Whatsapp Number</Label>
                          </td>
                          <td>{members?.member_whatsapp_no}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Email</Label>
                          </td>
                          <td>{members?.user_email}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Date of Birth</Label>
                          </td>
                          <td>
                            {convertISODateToDate(members?.member_date_of_birth)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Member PEN Number</Label>
                          </td>
                          <td>{members?.member_pen_no}</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Date of Joining </Label>
                          </td>
                          <td>
                            {convertISODateToDate(members?.member_date_of_joining_service)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Label>Date of Retirement</Label>
                          </td>
                          <td>
                            {convertISODateToDate(members?.member_date_of_retirement)}
                          </td>
                        </tr>
                        {members?.member_membership_type === 3 && (
                          <>
                            <tr>
                              <td>
                                <Label>Membership Certificate</Label>
                              </td>
                              <td>
                              <span className="table-data">
                                <Link
                                  to=
                                  {members?.membership_certificate_url}

                                  target="_blank"
                                >
                                  <i
                                    title="View"
                                    className="icon xl icon-eye-open"
                                  ></i>
                                </Link> 
                              </span>
                              </td>
                            </tr>
                          </>
                        )}

                      </tbody>
                    </Table>

                    {/* {props?.member_block_verify === 3 && <div className="checkbox-wrap">
                            <Input type="checkbox"
                              id="non-member-select"
                              checked={values?.member_status === "2"}
                              onChange={(e: any) => setFieldValue("member_status", e.target.checked ? "2" : "1")}
                            />
                            <Label htmlFor="non-member-select">Mark as Non-Member</Label>
                        </div>} */}
                  </div>

                </div>
                {
                  props?.member_block_verify === 3 ? (
                    <div className="checkbox-wrap">
                      <Input
                        type="checkbox"
                        id="member-select"
                        // checked={values?.member_status === "2"}
                        // onChange={(e: any) => setFieldValue("member_status", e.target.checked ? "2" : "1")}
                        required
                      />
                      <Label htmlFor="member-select">
                        Certified that I have verified the PEN, Date of birth, and Date of joining department of the member with the relevant details available
                      </Label>
                    </div>
                  ) : (
                    <div className="checkbox-wrap">
                      <Input
                        type="checkbox"
                        id="member-select"
                        required
                      />
                      <Label htmlFor="member-select">
                        Certified that the member is eligible for AOAOK membership
                      </Label>
                    </div>
                  )
                }

                <Col lg={12}>
                  <CustomButton
                    // isLoading={isSubmitting}
                    label="Verify"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Form>
            </div>
          );
        }}
      </Formik>

      <ModalContainer
        isOpen={verify_modal}
        setIsopen={setVerifyModal}
        title={""}
      >
        <ConfirmAlertModal
          setIsOpen={setVerifyModal}
          preview_id={props?.preview_id}
          member_block_verify={props?.member_block_verify}
          actions={action_data}
          values={value_data}
          setIsOpens={props?.setIsOpens}
          Refetch={props?.Refetch}
        />
      </ModalContainer>
    </>
  );
};

export default VerifyMember;
