import { useState, useRef } from "react";
import {
  Button, Col,
  Card,
  CardBody
} from "reactstrap";
import { useQuery } from "react-query";
import { getGalleryList, getTestimonialList, getVisionPhoto } from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import {
  StatusClickHandler,
  deleteClickHandler, modalHandler,
  onEditClickHandler
} from "./AddVisionPhoto/AddVisionphotoMethods/AddvisionphotoMethods";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddTestimonial from "./AddVisionPhoto";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";

const VisionPhoto = () => {
  const myDivRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState<string>('1')
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const {
    data: gallery,
    error: galleryError,
    isLoading: galleryLoading,
    refetch: galleryListRefetch,
  }: any = useQuery(
    [`visionPhoto${currentPage}`, currentPage],
    () => getVisionPhoto(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };


  return (
    <>
      {/* <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap"> */}
            {!gallery?.data.length ? (
              <div className="widget-head-outer">
                <div className="widget-heading" ref={myDivRef}>
                  <h6>Vision Photo</h6>
                </div>
                <div className="button-container">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm mb-3"
                    outline
                  >
                    <i className="icon icon-plus xl"></i> Add
                  </Button>
                </div>
              </div>
            ) : (
              
              <><CardsWithIcon
              type={"gallery"}
              data={gallery?.data}
              tableTitle="Vision Photo"
              Arraykeys={["Image"]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={["edit", "delete"]}
              isLoading={galleryLoading}
              error={galleryError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(selectedId, setModalEditData, setIsOpen, gallery)
              }
              onDeleteClick={(selectedId: string | number) =>
                handleDeleteConfirmation(selectedId)
              }
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(selectedId, galleryListRefetch, newStatus)
              }
            /></>
            )}
          {/* </CardBody>
        </Card>
      </Col> */}
      
      {/* {gallery?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={gallery?.meta?.totalItems}
            itemsPerPage={gallery?.meta?.itemsPerPage}
            activePage={gallery?.meta?.currentPage}
            disableScrollAfterPageChange
            setCurrentPage={(pageNo: string) => {
              ScrollToContainerTop(myDivRef)
              setCurrentPage(pageNo)
            }}
          />
        </div>
      )} */}
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add  Vision Photo"
      >
        <AddTestimonial
          setIsOpen={setIsOpen}
          refetch={galleryListRefetch}
          modalEditData={modalEditData}
          testimonialError={galleryError}
        />
      </ModalContainer>

      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, galleryListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default VisionPhoto;
