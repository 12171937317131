import { Button, Col, Row } from "reactstrap";
import { AcceptRejectSubmitHandler } from "../../../Pages/Private/Admin/LeaveMangement/AddLeave/AddLeaveMethods/AddLeaveModalMethods";
import { FundStatusSubmitHandler } from "../../../Pages/Private/Admin/FundManagment/AddFundManagement/AddFundMethods/AddFundModalMethods";
import { MembershipStatusSubmitHandler } from "../../../Pages/Private/Admin/MemberShip/AddMemberShip/AddMembershipMethods/AddMembershipMethods";
import { ActiveClickHandler } from "../../../Pages/Private/Admin/Otherfund/AddOtherfund/AddOtherfundMethods/AddOtherfundModalMethods";
import {
  BlogClickHandler,
  statusClickHandler,
} from "../../../Pages/Private/Admin/Blogs/AddBlogs/AddBlogsMethods/AddBlogModalMethods";
import { statusMagazineClickHandler } from "../../../Pages/Private/Admin/Magazine/AddMagazine/AddMagazineMethods/AddMagazineModalMethods";

const BlogAlertModal = (props: any) => {
  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Do you really want to{" "}
                {props?.blog_verify === "blog_un" ? "Verify" : "Unverify"}.
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setModal(false)}
              >
                Cancel
              </Button>
              {props?.blog_verify === "blog_un" ? (
                <Button
                  color={"primary"}
                  className="sm"
                  onClick={() => {
                    BlogClickHandler(
                      props?.setModal,
                      props?.Refetch,
                      props?.delete_id,
                      "1"
                    );
                    props?.setModal(false);
                  }}
                >
                  Verify
                </Button>
              ) : (
                <Button
                  color={"danger"}
                  className="sm"
                  onClick={() => {
                    BlogClickHandler(
                      props?.setModal,
                      props?.Refetch,
                      props?.delete_id,
                      "2"
                    );
                    props?.setModal(false);
                  }}
                >
                  Unverify
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BlogAlertModal;
