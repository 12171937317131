import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap";
import TextError from "../../../../../Components/FormikError/TextError";
import Select from "react-select";
import { YES_NO_SELECT_DATA } from "../../../../../Utils/Constants/selection_options";
import { getEducationListData } from "../MemberProfileMethods";
import ReactDatePicker from "react-datepicker";

const EducationIndex = ({
  values,
  handleChange,
  setFieldValue,
  educationData,
}: any) => {
  return (
    <>
      <Row className="gy-4">
        <Col md={12}>
          <Card className="widget-card">
            <CardBody>
              <div className="widget-head-outer">
                <div className="widget-heading">
                  <small>Add</small>
                  <h6>Other Education</h6>
                </div>
              </div>

              <div className="education-form-wrap">
                <FieldArray name="any_other_education">
                  {({ push, remove }) => (
                    <div className="education-form-wrap-inner">
                      {values?.any_other_education?.map(
                        (any_other_education: any, index: any) => (
                          <div key={index} className="form-wrap mt-4">
                            <Row className="gy-4">
                              <Col md={6}>
                                <Label>Other Degree</Label>
                                <Select
                                  placeholder="Select Other Degree"
                                  name={`any_other_education.${index}.other_degree`}
                                  options={YES_NO_SELECT_DATA}
                                  value={YES_NO_SELECT_DATA?.find(
                                    (item) =>
                                      item?.value ===
                                      any_other_education.other_degree
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `any_other_education.${index}.other_degree`,
                                      e?.value
                                    );
                                  }}
                                  isLoading={false}
                                  loadingMessage={() => "Fetching Other Degree"}
                                  noOptionsMessage={() =>
                                    "Other Degree appears here"
                                  }
                                  isSearchable={true}
                                  isClearable
                                />
                                <ErrorMessage
                                  name={`any_other_education.${index}.other_degree`}
                                  component={TextError}
                                />
                              </Col>
                              {/* Doctoral Degree */}
                              {any_other_education?.other_degree === "1" && (
                                <>
                                  <Col Col md={6}>
                                    <Label>Other Degree Course</Label>
                                    <Select
                                      placeholder="Select Other Degree Course"
                                      name={`any_other_education.${index}.other_degree_course`}
                                      options={getEducationListData(
                                        educationData,
                                        "OTHER"
                                      )}
                                      value={getEducationListData(
                                        educationData,
                                        "OTHER"
                                      )?.find(
                                        (item: any) =>
                                          item.value ===
                                          any_other_education.other_degree_course
                                      )}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `any_other_education.${index}.other_degree_course`,
                                          e?.value
                                        );
                                      }}
                                      isLoading={false}
                                      loadingMessage={() =>
                                        "Fetching Other Degree Courses"
                                      }
                                      noOptionsMessage={() =>
                                        "Other Degree Courses appears here"
                                      }
                                      isSearchable
                                      isClearable
                                    />
                                    <ErrorMessage
                                      name={`any_other_education.${index}.other_degree_course`}
                                      component={TextError}
                                    />
                                  </Col>
                                  <Col Col md={6}>
                                    <Label>Other Degree Year Of Pass</Label>
                                    {/* <Input
                                      type="date"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `any_other_education.${index}.other_degree_pass_year`,
                                          e.target.value
                                        );
                                      }}
                                      name={`any_other_education.${index}.other_degree_pass_year`}
                                      placeholder="Year Of Pass"
                                      value={
                                        any_other_education.other_degree_pass_year
                                      }
                                    /> */}
                                    <ReactDatePicker
                                      selected={values?.any_other_education[index].other_degree_pass_year}
                                      onChange={(date) => {
                                        setFieldValue(
                                          `any_other_education.${index}.other_degree_pass_year`,
                                          date
                                        );
                                      }}
                                      showYearPicker
                                      dateFormat="yyyy"
                                      placeholderText="Year"
                                      showDisabledMonthNavigation={true}
                                    />
                                    <ErrorMessage
                                      name={`any_other_education.${index}.other_degree_pass_year`}
                                      component={TextError}
                                    />
                                  </Col>
                                  {any_other_education.other_degree_course !==
                                    "38" && (
                                    <Col Col md={6}>
                                      <Label>
                                        Details of other qualification
                                      </Label>
                                      <Input
                                        type="text"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `any_other_education.${index}.other_degree_other`,
                                            e.target.value
                                          );
                                        }}
                                        name={`any_other_education.${index}.other_degree_other`}
                                        placeholder="Details of other qualification"
                                        value={
                                          any_other_education.other_degree_other
                                        }
                                      />
                                      <ErrorMessage
                                        name={`any_other_education.${index}.other_degree_other`}
                                        component={TextError}
                                      />
                                    </Col>
                                  )}
                                </>
                              )}
                            </Row>
                            <div className="edu-button-wrap">
                              <Button
                                color="danger"
                                className="sm"
                                outline
                                onClick={() => remove(index)}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        )
                      )}
                      <Button
                        outline
                        color="primary"
                        onClick={() =>
                          push({
                            other_degree: "",
                            other_degree_course: "",
                            other_degree_pass_year: "",
                            other_degree_other: "",
                          })
                        }
                      >
                        <div className="add-icon-wrap">
                          <i className="icon icon-add-green xl"></i>
                        </div>
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12}></Col>
      </Row>
    </>
  );
};

export default EducationIndex;
