import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddBannerProps } from "../../../../../../Type";
import TextError from "../../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddBannerInitialValues,
  BannerValidationSchema,
} from "./AddBannerMethods/FormikValidation";
import {
  BannerSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddBannerMethods/AddBannerMethods";
import CroppedImages from "../../crop/croppedimages";

const AddBanner = ({
  setIsOpen,
  refetch,
  modalEditData,
  bannerError,
}: AddBannerProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddBannerInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [image_change, setImageChange] = useState(false);
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  const [imagefile, setImageFile] = useState("");
  const [imageView, setImageView] = useState("");
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const create_imageURL = (imgfile: any) => {
    setImageView(URL?.createObjectURL(imgfile));
    setImageChange(true);
  };

  return (
    <Formik
      validationSchema={BannerValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        BannerSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          image_change
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        const handleCrop = (croppedImage: string) => {
          setCroppedImage(croppedImage);
          setImageChange(true);
          setFieldValue("image", croppedImage);
        };

        return (
          <>
            <div className="Add-web-settings-wrap">
              <div className="form-wrap">
                <Form onSubmit={handleSubmit}>
                  <Row className="gy-4">
                    <Col>
                      <Label>Banner image</Label>
                      <div className="add-banner-thumb-wrap">
                        <div className="image-thumbnail-wrap">
                          <img
                            src={
                              image_change === true
                                ? imageView
                                : modalEditData?.[0]?.image
                                  ? modalEditData?.[0]?.image
                                  : "/images/empty-banner.webp"
                            }
                            alt={modalEditData?.[0]?.banner_image}
                            className="website-image"
                          />
                          <CroppedImages
                            src={
                              imagefile
                                ? URL.createObjectURL(new Blob([imagefile]))
                                : modalEditData?.[0]?.image
                            }
                            onCrop={handleCrop}
                            from_banner={true}
                          />
                        </div>
                        <Input
                          id="cha"
                          type="file"
                          onChange={(e: any) => {
                            setImageFile(e.target.files[0]);
                            setFieldValue("image", e.target.files[0]);
                            create_imageURL(e.target.files[0]);
                          }}
                          name="image"
                          placeholder="Banner File"
                        />
                        {/* <Link to="#">Choose Image</Link> */}
                        <ErrorMessage name="image" component={TextError} />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Label>Heading</Label>
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values?.heading}
                        name="heading"
                        placeholder="Heading"
                      />
                      <ErrorMessage name="heading" component={TextError} />
                    </Col>
                    <Col md={12}>
                      <Label>Sub Heading</Label>
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values?.sub_heading}
                        name="sub_heading"
                        placeholder="Sub Heading"
                      />
                      <ErrorMessage name="sub_heading" component={TextError} />
                    </Col>
                    <Col md={12}>
                      <Label>Content</Label>
                      <Input
                        type="textarea"
                        onChange={handleChange}
                        value={values?.content}
                        name="content"
                        placeholder="Content"
                      />
                      <ErrorMessage name="content" component={TextError} />
                    </Col>
                    <Col md={12}>
                      <Label>Sub content</Label>
                      <Input
                        type="textarea"
                        onChange={handleChange}
                        value={values?.sub_content}
                        name="sub_content"
                        placeholder="Sub Content"
                      />
                      <ErrorMessage name="sub_content" component={TextError} />
                    </Col>
                    <Col lg={12}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Add"
                        color="primary"
                        className="sm submit-btn"
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddBanner;
