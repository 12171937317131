import React from "react";
import Member from "./MemberTabs/Member";
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router";
import AssociateMember from "./MemberTabs/AssociateMember";
import AboutToRetire from "./MemberTabs/abouttoRetire";

const MemberIndex = () => {
  const { tab = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()

  return (
    <section className="member-section">
      <Container>
        <Nav pills>
          <NavItem>
            <NavLink
              className={`tab-button ${tab === "1" ? "active" : ""}`}
              onClick={() => {
                if (pathname?.startsWith("/member")) {
                  navigate("/member/member-management/1/1")
                }
                if (pathname?.startsWith("/admin")) {
                  navigate("/admin/member-management/1/1")
                }
              }}
            >
              Members
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`tab-button ${tab === "2" ? "active" : ""}`}
              onClick={() => {
                if (pathname?.startsWith("/member")) {
                  navigate("/member/member-management/1/2")
                }
                if (pathname?.startsWith("/admin")) {
                  navigate("/admin/member-management/1/2")
                }
              }}
            >
              Associate member
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`tab-button ${tab === "3" ? "active" : ""}`}
              onClick={() => {
                if (pathname?.startsWith("/member")) {
                  navigate("/member/member-management/1/3")
                }
                if (pathname?.startsWith("/admin")) {
                  navigate("/admin/member-management/1/3")
                }
              }}
            >
              About to Retire
            </NavLink>
          </NavItem>

        </Nav>
      </Container>
      <>
        {tab === "1" && <Member />}
        {tab === "2" && <AssociateMember />}
        {tab === "3" && <AboutToRetire />}
      </>

    </section >
  );
};

export default MemberIndex;
