import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Form, Input, InputGroup, Row } from "reactstrap";

import TextError from "../../../Components/FormikError/TextError";
import {
  ResetPasswordValidationSchema,
  handlePasswordReset,
  togglePasswordVisibility,
  tokenValidator,
} from "./ChangePasswordMethods";
import { useQuery } from "react-query";
import CustomButton from "../../../Components/CustomElements/FormElements/CustomButton";
import CustomSpinner from "../../../Components/CustomElements/Spinner/SpinnerCustom";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [tokenIsValid, setTokenIsValid] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirm_password: false,
  });

  const { isLoading: tokenValidating, }: any = useQuery(
    `tokenValidator${token}`,
    () => tokenValidator(token),
    {
      keepPreviousData: true,
      enabled: token !== undefined,
      retry: 0,
      onSuccess: (data: any) => {
        setTokenIsValid(data?.success)
        if (!data?.success) {
          toast.error(data?.message, { toastId: "invalid token" })
          navigate("/")
        }
      }
    }
  );

  return (
    <>
      <section className="login-wrapper">
        <Container>
          <Row className="align-items-center p-sm-2 justify-content-center">
            <Col md={6}>
              <div className="logo-content-wrap cssanimation sequence fadeInBottom">
                <h2>Welcome to AOAOK</h2>
                <p>Uniting Agriculture for a Sustainable Future</p>
              </div>
            </Col>
            <Col md={5}>
              {tokenValidating ?
                <div className="login-form-wrap text-center">
                  <div className="heading">
                    <p>Validating</p>
                  </div>
                  <CustomSpinner />
                </div> :
                tokenIsValid ? <div className="login-form-wrap">
                  <div className="heading">
                    <h3>Reset Your Password</h3>
                  </div>
                  <Formik
                    validationSchema={ResetPasswordValidationSchema}
                    initialValues={{
                      password: "",
                      confirm_password: "",
                      token: token,
                      // otp: "",
                    }}
                    onSubmit={(values, actions) =>
                      handlePasswordReset(values, actions, navigate)
                    }
                  >
                    {({ handleSubmit, handleChange, isSubmitting, values, errors }) => {

                      return (
                        <Form onSubmit={handleSubmit}>
                          <div className="form-inner-wrap">
                            <Row className="gy-4">
                              {/* <Col lg={12}>
                              <InputGroup>
                                <Input
                                  type={"number"}
                                  placeholder="OTP"
                                  name="otp"
                                  onChange={handleChange}
                                  value={values?.otp}
                                />
                              </InputGroup>
                              <ErrorMessage name="otp" component={TextError} />
                            </Col> */}
                              <Col lg={12}>
                                <InputGroup>
                                  <Input
                                    type={
                                      passwordVisible?.password
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder="New Passowrd"
                                    name="password"
                                    onChange={handleChange}
                                    value={values?.password}
                                  />
                                  <span
                                    className="input-icon"
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "password",
                                        setPasswordVisible
                                      )
                                    }
                                  >
                                    <i className="icon icon-eye-close xl"></i>
                                  </span>
                                </InputGroup>
                                <ErrorMessage
                                  name="password"
                                  component={TextError}
                                />
                              </Col>
                              <Col lg={12}>
                                <InputGroup>
                                  <Input
                                    type={
                                      passwordVisible?.confirm_password
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder="Confirm Password"
                                    name="confirm_password"
                                    onChange={handleChange}
                                    value={values?.confirm_password}
                                  />
                                  <span
                                    className="input-icon"
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "confirm_password",
                                        setPasswordVisible
                                      )
                                    }
                                  >
                                    <i className="icon icon-eye-close xl"></i>
                                  </span>
                                </InputGroup>
                                <ErrorMessage
                                  name="confirm_password"
                                  component={TextError}
                                />
                              </Col>
                              <Col lg={12}>
                                <div className="submit-btn-wrap end">
                                  <CustomButton
                                    label="Continue"
                                    color={"secondary"}
                                    className="sm"
                                    isLoading={isSubmitting}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div> : null}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
