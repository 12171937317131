import { useMemo, useState, useEffect } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addModalInitialValuesHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddGet/AddGetMethods/AddGetModalMethods";
import { getintouch } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import { AddRoleInitialValues } from "./AddGet/AddGetMethods/FormikValidation";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const GetIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [modalEditData, setModalEditData] = useState<any>([]);
  const [addModalInitialValues, setAddModalInitialValues] = useState(AddRoleInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);
  const {
    data: get,
    error: getintouchError,
    isLoading: getintouchLoading,
    refetch: getintouchListRefetch,
  }: any = useQuery(
    [`get${currentPage}`, currentPage, searchQuery],
    () => getintouch(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  useEffect(() => {
    addModalInitialValuesHandler(modalEditData, setAddModalInitialValues, setEditMode);
  }, [modalEditData]);


  const handlegetintouchSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
    if (e?.target?.value?.length > 2) {
      setSearchQuery(e?.target?.value)
      navigate(`/admin/getintouch/1`);
    }
    else {
      setSearchQuery('')
    }
  }


  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Get In Touch", link: "/admin/getintouch/1" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Get In Touch", link: "/member/getintouch/1" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>
        <div className="content-outer-wrap">
            <div className="breadcrumb-wrap">{breadcrumb}</div>
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handlegetintouchSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
              {/* 
              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <div className="button-wrap">
                    <Button
                      onClick={() => {
                        modalHandler(setModalEditData, setIsOpen);
                        editdata(0);
                      }}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Role
                    </Button>
                  </div>
                </div>
              </div>
              */}
            </div>
        </div>

      {/* cards section */}
      <div className="content-list-outer-wrap">
        <CardsWithTable
          data={get?.data}
          tableTitle="Contacts"
          tableFields={["Name", "Phone number", "Email", "Message"]}
          Arraykeys={["first_name", "phone_no", "email", "message"]}
          viewType="table"
          toURL={""}
          isLoading={getintouchLoading}
          error={getintouchError}
          onEditClick={(selectedId: any) => {
            onEditClickHandler(selectedId, setModalEditData, setIsOpen, getintouch);
          }}
          paginationData={get?.meta}
          setCurrentPage={(pageNo: string) =>
            setCurrentPageStaff(navigate, pageNo, pathname)
          }
          from_getintouch={true}
        />
      </div>
      </Container>
    </section>
  );
};

export default GetIndex;
