import * as Yup from "yup";

export const MembershipValidationSchema = Yup.object().shape({
  start_date: Yup.date().required("Field is required"),
  amount: Yup.number()
    .required("Field is required")
    .min(1, "Value must be at least 1")
    .nullable(),
  state_share: Yup.number()
    .required("Field is required")
    .typeError("State share must be a number")
    .min(1, "Value must be at least 1")
    .nullable(),
  district_share: Yup.number()
    .required("Field is required")
    .typeError("District share must be a number")
    .min(1, "Value must be at least 1")
    .nullable(),
  other_share: Yup.number()
    .typeError("Other share must be a number")
    .min(0, "Value must be at least 0")
    .nullable(),
});

export const AddmembershipInitialValues = {
  start_date: "",
  amount: 0,
  end_date: "",
  other_share: 0,
  district_share: 0,
  state_share: 0,
};

export const AssociativeMembershipValidationSchema = Yup.object().shape({
  start_date: Yup.date().required("Field is required"),
  amount: Yup.number()
    .required("Field is required")
    .min(1, "Value must be at least 1")
    .nullable(),
  state_share: Yup.number()
    .required("Field is required")
    .typeError("State share must be a number")
    .min(1, "Value must be at least 1")
    .nullable(),
});

export const AddAssociativemembershipInitialValues = {
  start_date: "",
  amount: 0,
  end_date: "",
  other_share: 0,
  state_share: 0,
};
