import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBlock,
  getCadreFullList,
  getDesignationmemberFullList,
  getDistrict,
  getMeberReportList,
} from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import {
  extractStaffData,
  handleDownloadClick,
  setCurrentPageStaff,
} from "./memberMethods";
import Select from "react-select";
import { getEducationData } from "../../../MemberModule/MemberProfile/MemberProfileMethods";
import {
  Bachelor_Degree_SELECT_DATA,
  MEMBER_ACCEPT_STATUS,
  MEMBER_ACTIVE_STATUS,
} from "../../../../../Utils/Constants/selection_options";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const MemberListReport = () => {
  const { currentPage = "1" } = useParams();
  const { checkPermission } = usePermission();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    district_id: "",
    block: "",
    degree_name: "",
    cadre: "",
    designation: "",
    active: "",
    revoke: "",
  });
  const handleChange = (e: any, feild_name: any) => {
    setValue({
      ...value,
      [feild_name]: e,
    });
  };
  const [startDate, setStartDate] = useState(new Date());
  const {
    data: loginreports,
    error: loginreportsError,
    isLoading: loginreportsLoading,
    refetch: loginreportsListRefetch,
  }: any = useQuery(
    [
      `login${currentPage}`,
      currentPage,
      value?.designation,
      value?.district_id,
      value?.designation,
      value?.degree_name,
      startDate,
      value?.active,
      value?.revoke,
      value?.cadre
    ],
    () =>
      getMeberReportList(
        currentPage,
        value?.district_id,
        value?.designation,
        value?.degree_name,
        value?.active,
        value?.revoke,
        value?.cadre
      ),
    { keepPreviousData: true }
  );

  const { data: cadre }: any = useQuery([`cadre`], () => getCadreFullList(), {
    keepPreviousData: true,
  });
  const { data: designation }: any = useQuery(
    [`designation`, value?.cadre, value?.block],
    () =>
      value?.block || value?.cadre
        ? getDesignationmemberFullList(value?.cadre, value?.block)
        : "",
    {
      keepPreviousData: true,
    }
  );

  const { data: district }: any = useQuery(
    [`designation`],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );
  const { data: block }: any = useQuery(
    [`designation`, value?.district_id],
    () => (value?.district_id ? getBlock(value?.district_id) : ""),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(
    () => extractStaffData(loginreports, currentPage, 10),
    [loginreports]
  );

  const { data: educationData }: any = useQuery(
    [`educationData`],
    () => getEducationData(),
    { keepPreviousData: true }
  );

  const handleClickDownload = () => {
      handleDownloadClick(value?.district_id,value?.degree_name,value?.cadre,value?.designation);
  };

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Reports", link: "/admin/reports" },
      {
        label: "Members List Reports",
        link: "/admin/memberreport-list/1",
      },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Reports", link: "/member/reports" },
      {
        label: "Members List Reports",
        link: "/member/memberreport-list/1",
      },
    ];
  }

  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={district}
                value={
                  district?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "district_id")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>

            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Education"
                options={educationData?.map(
                  (item: { id: string; degree_name: string }) => ({
                    value: item.id,
                    label: item.degree_name,
                  })
                )}
                value={educationData?.find((item: any) => item.id === value)}
                onChange={(e: any) => handleChange(e?.value, "degree_name")}
                name="degree_name"
                isLoading={false}
                loadingMessage={() => "Fetching Education"}
                noOptionsMessage={() => "Education appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Cadre"
                options={cadre}
                value={
                  cadre?.filter((itm: any) => itm?.value === value?.cadre) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "cadre")}
                name="cader"
                isLoading={false}
                loadingMessage={() => "Fetching Cadre"}
                noOptionsMessage={() => "Cadre appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Designation"
                options={Array.isArray(designation) ? designation : []}
                value={
                  designation?.length !== 0
                    ? designation?.filter(
                        (itm: any) => itm?.value === value?.designation
                      )
                    : []
                }
                onChange={(e: any) => handleChange(e?.value, "designation")}
                name="designation"
                isLoading={false}
                loadingMessage={() => "Fetching Designation"}
                noOptionsMessage={() => "Designation appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="right-side-wrap">
              {/* {checkPermission("reports:write") ? (
                <div className="button-wrap">
                  <Button
                    color="primary"
                    className="sm"
                    onClick={handleClickDownload}
                  >
                    <i className="icon xl icon-download-white"></i>Download
                  </Button>
                </div>
              ) : null} */}
            </div>
            {/* <Button
              color="primary"
              className="sm"
              outline
              onClick={() => {
                setValue({
                  ...value,
                  district_id: "",
                  block: "",
                  degree_name: null,
                  cadre: "",
                  designation: "",
                  active: "",
                  revoke: "",
                });
              }}
            >
              Reset
            </Button> */}
          </div>
          <div className="search-bar-full-wrap">
            {/* <div className="select-wrap-spacing">
              <Select
                placeholder="Select Active/Inactive"
                options={MEMBER_ACTIVE_STATUS}
                value={
                  MEMBER_ACTIVE_STATUS?.filter(
                    (itm: any) => itm?.value === value?.active
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "active")}
                name="active"
                isLoading={false}
                loadingMessage={() => "Fetching Active/Inactive"}
                noOptionsMessage={() => "Active/Inactive appears here"}
                isSearchable={true}
                isClearable
              />
            </div> */}
            {/* <div className="select-wrap-spacing">
              <Select
                placeholder="Select"
                options={MEMBER_ACCEPT_STATUS}
                value={
                  MEMBER_ACCEPT_STATUS?.filter(
                    (itm: any) => itm?.value === value?.revoke
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "revoke")}
                name="revoke"
                isLoading={false}
                loadingMessage={() => "Fetching "}
                isSearchable={true}
                isClearable
              />
            </div> */}
            {checkPermission("reports:write") ? (
                <div className="button-wrap">
                  <Button
                    color="primary"
                    className="sm"
                    onClick={handleClickDownload}
                  >
                    <i className="icon xl icon-download-white"></i>Download
                  </Button>
                </div>
              ) : null}
               <Button
              color="primary"
              className="sm"
              outline
              onClick={() => {
                setValue({
                  ...value,
                  district_id: "",
                  block: "",
                  degree_name: "",
                  cadre: "",
                  designation: "",
                  active: "",
                  revoke: "",
                });
              }}
            >
              Reset
            </Button>
          </div>

          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Login Details"
              tableFields={[
                "Name",
                "Email",
                "Pen Number",
                "Office Name",
                "Designation",
                "Education",
                "Cadre",
                "Phone",
                "District",
                // "Status",
                "Date Of Birth",
                "Date Of Joining",
                "Date Of Retirement",
              ]}
              Arraykeys={[
                "user_first_name",
                "user_email",
                "member_pen_no",
                "office_name",
                "designation_name",
                "member_education",
                "member_cadre_name",
                "member_phone_no",
                "district_name",
                // "membership_status",
                "member_date_of_birth",
                "member_date_of_joining_service",
                "member_date_of_retirement",
              ]}
              viewType="table"
              toURL={""}
              actions={["", ""]}
              isLoading={loginreportsLoading}
              error={loginreportsError}
              onEditClick={(selectedId: any) => {}}
              Refetch={loginreportsListRefetch}
              onDeleteClick={(selectedId: string | number) => {}}
              onPaymentClick={(selectedId: string | number) => {}}
              paginationData={loginreports?.data?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo)
              }
              from_report={true}
            />
          </div>
        </Container>
      </div>
    </section>
  );
};

export default MemberListReport;
