import { useState, useRef } from "react";
import { Button, Col, Card, CardBody } from "reactstrap";
import { useQuery } from "react-query";
import {
  getGalleryList,
  getnews,
} from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";
import {
  StatusClickHandler,
  deleteClickHandler,
  modalHandler,
  onEditClickHandler,
} from "./AddNews/AddNewsMethods/AddNewsMethods";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddNews from "./AddNews";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const News = () => {
  const myDivRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState<string>("1");
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedId, setSelectedId] = useState<string | number>("");
  const { checkPermission } = usePermission();

  const {
    data: news,
    error: newsError,
    isLoading: newsLoading,
    refetch: newsListRefetch,
  }: any = useQuery(
    [`news${currentPage}`, currentPage],
    () => getnews(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };

  return (
    <>
      <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading" ref={myDivRef}>
                <h6>News</h6>
              </div>
              <div className="button-container">
                {checkPermission("website:write") ? <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="primary"
                  className="sm"
                  outline
                >
                  <i className="icon icon-add-green xl"></i> Add
                </Button> : null}
              </div>
            </div>
            <CardsWithIcon
              type={"newss"}
              data={news?.data}
              tableTitle="Title"
              Arraykeys={["title","content"]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={[
                checkPermission("website:write") ? "edit" : "",
                checkPermission("website:delete") ? "delete" : ""
              ]}
              isLoading={newsLoading}
              error={newsError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  news
                )
              }
              onDeleteClick={(selectedId: string | number) =>
                handleDeleteConfirmation(selectedId)
              }
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(
                  selectedId,
                  newsListRefetch,
                  newStatus
                )
              }
            />
          </CardBody>
        </Card>
      </Col>
      {news?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={news?.meta?.totalItems}
            itemsPerPage={news?.meta?.itemsPerPage}
            activePage={news?.meta?.currentpage}
            disableScrollAfterPageChange
            setCurrentPage={(pageNo: string) => {
              ScrollToContainerTop(myDivRef);
              setCurrentPage(pageNo);
            }}
          />
        </div>
      )}
      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Add  News">
        <AddNews
          setIsOpen={setIsOpen}
          refetch={newsListRefetch}
          modalEditData={modalEditData}
          newsError={newsError}
        />
      </ModalContainer>

      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, newsListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default News;
