import React from 'react'
import { downloadPublishHandler } from '../DownloadMethods';

const DownloadPublishModal = ({ data, setIsOpen, refetch }: any) => {

    return (
        <div className="form-wrap">
            <div className="gy-4 row">
                <div className="col-lg-12">
                    <div className="modal-warning-text-wrap">
                        <div className="warning-img-wrap">
                            <img src="/images/delete-warning.svg" alt="warning-icon" />
                        </div>
                        <h5 className="modal-warning-title">
                            Are you sure ?
                        </h5>

                        <small className="sub-text">
                            {data?.status === 1 ?
                                "Do you really want to UnPublish ?" :
                                "Do you really want to Publish ?"
                            }
                        </small>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="submit-btn-wrap centered">
                        <button type="button" className="sm btn btn-outline-danger" onClick={() => setIsOpen(false)}>
                            Cancel
                        </button>
                        {data?.status === 1 ?
                            <button type="button" className="sm btn btn-primary" onClick={() => downloadPublishHandler(data?.id, 2, refetch, setIsOpen)}>
                                Un-publish
                            </button> :
                            <button type="button" className="sm btn btn-primary" onClick={() => downloadPublishHandler(data?.id, 1, refetch, setIsOpen)}>
                                Publish
                            </button>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DownloadPublishModal