import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, Form, Label, Row, Table } from "reactstrap";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";
import { ErrorMessage, Formik } from "formik";
import TextError from "../../../../Components/FormikError/TextError";
import {
  AddAssociateInitialValues,
  AssociateValidationSchema,
} from "./FormikValidation";
import { StaffSubmitHandler } from "./AddMethods";
import { AddAssociateProps } from "../../../../Type";

const AssociativeMemberApply = ({
  setIsOpen,
  district,
  refetch,
}: AddAssociateProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddAssociateInitialValues
  );

  return (
    <>
      <Formik
        validationSchema={AssociateValidationSchema}
        initialValues={addModalInitialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          StaffSubmitHandler(values, actions, setIsOpen, refetch);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          setFieldError,
          errors,
        }) => {
          return (
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col lg={12}>
                    <Label>District</Label>
                    <Select
                      placeholder="Select district"
                      className="select-cadre"
                      name="district_id"
                      options={district}
                      value={
                        district?.filter(
                          (itm: any) => itm?.value === values?.district_id
                        ) || ""
                      }
                      onChange={(e: any) => {
                        setFieldValue("district_id", e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Cadre"}
                      noOptionsMessage={() => "district appears here"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage name="district_id" component={TextError} />
                  </Col>

                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default AssociativeMemberApply;
