import * as Yup from "yup";

export const NamePDDValidationSchema = Yup.object().shape({
  crop: Yup.string().required("Field is required"),
  name_pdd: Yup.string().required("Field is required"),
  
});

export const AddNamePDDInitialValues = {
  crop: "",
  name_pdd: "",
};
