import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddAssetInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

type AddInitialValues = {
  title: string;
  office: string;
  category: string;
  sub_category: any;
  asset_value: string;
  asset_document: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  let user_id: any = localStorage?.getItem("USER_ID");
  // Add
  if (editMode) {

    const formData = new FormData();
    formData.append("document_file", values?.asset_document);
    formData.append("asset_value", values?.asset_value);
    formData.append("user_id", user_id);
    formData.append("title", values?.title);
    formData.append("category_id", values?.category);
    formData.append("acc_office_id", values?.office);
    var subcat_value: any = new Array();
    values?.sub_category?.map((item: any) => {
      subcat_value?.push({
        category_id: item?.value,
        description: item?.description,
        created_by: 1,
      });
    });
    const filteredArray = values?.sub_category.filter(
      (element: any) => element?.document !== undefined
    );

    const newArray = filteredArray?.map((item: any) => {
      const labelValue = item.value;
      const documentValue = item.document;

      return {
        [`${labelValue}_document`]: documentValue,
      };
    });

    newArray?.forEach((item: any, index: any) => {
      const key = Object.keys(item)[0]; // Assuming each object has only one key
      const fileList = item[key];

      // Assuming you want to append each file from the FileList
      for (let i = 0; i < fileList.length; i++) {
        formData.append(`${key}`, fileList[i]);
      }
    });

    formData.append("sub_category", JSON.stringify(subcat_value));
    axios({
      method: "patch",
      url: `${BaseUrl}/assets/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Asset Details Updated",{toastId:"Asset_updated"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.message) {
          actions.setFieldError(
            "asset_document",
            response?.response?.data?.message
          );
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Asset_updated_error"});
        }
      });
  }
  // Edit
  else {
    const formData = new FormData();
    formData.append("document_file", values?.asset_document);
    formData.append("asset_value", values?.asset_value);
    formData.append("user_id", user_id);
    formData.append("title", values?.title);
    formData.append("category_id", values?.category);
    formData.append("acc_office_id", values?.office);
    var subcat_value: any = new Array();
    values?.sub_category?.map((item: any) => {
      subcat_value?.push({
        category_id: item?.value,
        description: item?.description,
        created_by: 1,
      });
    });

    const filteredArray = values?.sub_category.filter(
      (element: any) => element?.document !== undefined
    );

    const newArray = filteredArray?.map((item: any) => {
      const labelValue = item.value;
      const documentValue = item.document;

      return {
        [`${labelValue}_document`]: documentValue,
      };
    });

    newArray?.forEach((item: any, index: any) => {
      const key = Object.keys(item)[0]; // Assuming each object has only one key
      const fileList = item[key];

      // Assuming you want to append each file from the FileList
      for (let i = 0; i < fileList.length; i++) {
        formData.append(`${key}`, fileList[i]);
      }
    });

    formData.append("sub_category", JSON.stringify(subcat_value));
    axios({
      method: "post",
      url: `${BaseUrl}/assets`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Asset Details Added",{toastId:"Asset_Added"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.message) {
          actions.setFieldError(
            "asset_document",
            response?.response?.data?.message
          );
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Asset_Error"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      asset_value: item?.asset_value,
      office: item?.office?.name,
      user: item?.user?.first_name,
      title: item?.title,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    let new_arry: any = [];
    modalEditData?.assetDetails?.map((item: any) => {
      new_arry?.push({
        description: item?.description,
        value: item?.category?.id,
        label: item?.category?.name,
        status: item?.category?.document_status,
        document_file_url: item?.document_file_url,
        document: "",
        created_by: 1,
      });
    });
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.id,
      title: modalEditData?.title,
      office: modalEditData?.officeAccomodation?.id,
      category: modalEditData?.assetDetails[0].category?.parent?.id,
      sub_category: new_arry?.length === 0 ? [{}] : new_arry,
      asset_value: modalEditData?.asset_value,
      asset_document: modalEditData?.document_file_url,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddAssetInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function,
  editdata: any,
  setSubCatId: any
) => {
  setModalEditData([]);
  setIsOpen(true);
  editdata();
  setSubCatId();
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/assets/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Asset_deleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Asset_deleted_error"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/asset-settings/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/asset-settings/${pageNo}`)
  }
};
