import * as Yup from "yup";

export const TestimonialValidationSchema = Yup.object().shape({
  image:
    Yup.string()
    .required("File is required"),
});

export const AddTestimonialInitialValues = {
  image: "",
};
