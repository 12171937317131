import * as Yup from "yup";

export const CropValidationSchema = Yup.object().shape({
  crop_name: Yup.string().required("Field is required"),
  // crop_description: Yup.string().required("Field is required"),
});

export const AddCropInitialValues = {
  crop_name: "",
  // crop_description: "",
};
