import { useMemo, useState } from "react";
import {
  Container,
  Button,
  InputGroup,
  Input,
  Col,
  Table,
  Row,
} from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBlock,
  getCadreFullList,
  getDesignationmemberFullList,
  getDistrict,
  getLoginList,
} from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import {
  extractStaffData,
  handleDownloadClick,
  setCurrentPageStaff,
} from "./LoginHitMethods";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";
import CustomPagination from "../../../../../Components/Pagination/Pagination";

const LoginHitMember = () => {
  const { currentPage = "1" } = useParams();
  const { checkPermission } = usePermission();
  const navigate = useNavigate();

  const [value, setValue] = useState({
    district_id: "",
    block: "",
    cadre: "",
    designation: "",
  });
  const handleChange = (e: any, feild_name: any) => {
    setValue({
      ...value,
      [feild_name]: e,
    });
  };
  const [startDate, setStartDate] = useState<any>();
  const {
    data: loginreports,
    error: loginreportsError,
    isLoading: loginreportsLoading,
    refetch: loginreportsListRefetch,
  }: any = useQuery(
    [`login${currentPage}`, currentPage, value?.designation, startDate],
    () =>
      getLoginList(
        currentPage,
        value?.designation,
        startDate ? startDate?.getFullYear()?.toString() : ""
      ),
    { keepPreviousData: true }
  );

  const { data: cadre }: any = useQuery([`cadre`], () => getCadreFullList(), {
    keepPreviousData: true,
  });
  const { data: designation }: any = useQuery(
    [`designation`, value?.cadre, value?.block],
    () =>
      value?.block || value?.cadre
        ? getDesignationmemberFullList(value?.cadre, value?.block)
        : "",
    {
      keepPreviousData: true,
    }
  );

  const { data: district }: any = useQuery(
    [`designation`],
    () => getDistrict(),
    {
      keepPreviousData: true,
    }
  );
  const { data: block }: any = useQuery(
    [`designation`, value?.district_id],
    () => (value?.district_id ? getBlock(value?.district_id) : ""),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(
    () => extractStaffData(loginreports, currentPage, 10),
    [loginreports]
  );

  const handleClickDownload = () => {
    handleDownloadClick(startDate, value?.designation);
  };

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Reports", link: "/admin/reports" },
      {
        label: "Membership Pending Status",
        link: "/admin/membershippending-report/1",
      },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Reports", link: "/member/reports" },
      {
        label: "Membership Pending Status",
        link: "/member/membershippending-report/1",
      },
    ];
  }
  const [data, setData] = useState<any>(loginreports || []);
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  const check_data = (index: any) => {
    var new_data = [...loginreports?.data?.items];
    new_data[index].is_check = !new_data[index]?.is_check;
    setData(new_data);
  };

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
              <div className="calendar-wrap">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  placeholderText="Year"
                />
              </div>
            {/* <Col md={3}>
              <Select
                placeholder="Select District"
                options={district}
                value={
                  district?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "district_id")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </Col> */}
            {/* <Col md={3}>
              <Select
                placeholder="Select Block"
                options={block}
                value={
                  block?.length !== 0
                    ? block?.filter((itm: any) => itm?.value === value?.block)
                    : []
                }
                onChange={(e: any) => handleChange(e?.value, "block")}
                name="block"
                isLoading={false}
                loadingMessage={() => "Fetching Block"}
                noOptionsMessage={() => "Block appears here"}
                isSearchable={true}
                isClearable
              />
            </Col> */}
            {/* <div className="select-wrap-spacing">
              <Select
                placeholder="Select Cadre"
                options={cadre}
                value={
                  cadre?.filter((itm: any) => itm?.value === value?.cadre) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "cadre")}
                name="cader"
                isLoading={false}
                loadingMessage={() => "Fetching Cadre"}
                noOptionsMessage={() => "Cadre appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Designation"
                options={Array.isArray(designation) ? designation : []}
                value={
                  designation?.length !== 0
                    ? designation?.filter(
                        (itm: any) => itm?.value === value?.designation
                      )
                    : []
                }
                onChange={(e: any) => handleChange(e?.value, "designation")}
                name="designation"
                isLoading={false}
                loadingMessage={() => "Fetching Designation"}
                noOptionsMessage={() => "Designation appears here"}
                isSearchable={true}
                isClearable
              />
            </div> */}

            <div className="right-side-wrap">
              {checkPermission("reports:write") ? (
                <div className="button-wrap">
                  <Button
                    color="primary"
                    className="sm"
                    onClick={handleClickDownload}
                  >
                    <i className="icon xl icon-download-white"></i>Download
                  </Button>
                </div>
              ) : null}

              <Button
                color="primary"
                className="sm"
                outline
                onClick={() => {
                  setValue({
                    district_id: "",
                    block: "",
                    cadre: "",
                    designation: "",
                  });
                  setStartDate("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <Container>
              <Row className="gy-4">
                <div className="table-outer-wrap">
                  <div className="table-head-outer">
                    <div className="table-heading">
                      <h6>Membership Pending Status</h6>
                    </div>
                    <div className="table-outer">
                      <Table>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>District</th>
                            <th>Total Seat Count</th>
                            <th>Non Members</th>
                            <th>Number of Members Registered</th>
                            <th>Block Verified</th>
                            <th>District Approved</th>
                            {/* <th>Approved Application</th> */}
                            <th>Pending for Verification</th>
                            <th>Pending for Approval</th>
                            <th>Active Members Count</th>
                            <th>Pending for Payment</th>
                            <th>Pending for Registration</th>

                          </tr>
                        </thead>
                        <tbody>
                          {loginreports?.data?.items?.map(
                            (item: any, i: any) => {
                              const currentIndex =
                                (loginreports?.data?.meta?.currentPage - 1) *
                                  loginreports?.data?.meta?.itemsPerPage +
                                i +
                                1;
                              return (
                                <>
                                  <tr>
                                    <td>{currentIndex}</td>
                                    <td>
                                      {item?.district_name}
                                      <div className="icon-wrap">
                                        <span
                                          onClick={() => {
                                            check_data(i);
                                          }}
                                        >
                                          View Block
                                          <i
                                            title="View More"
                                            className="icon icon-down xl"
                                          ></i>
                                        </span>
                                      </div>
                                    </td>
                                    <td>{item?.total_seat_count}</td>
                                    <td>{item?.not_submitted}</td>
                                    <td>{item?.submitted_member_count}</td>
                                    <td>{item?.block_verified}</td>
                                    <td>{item?.district_verified}</td>
                                    {/* <td>{item?.approved_members_count}</td> */}
                                    <td>{item?.pending_for_verification}</td>
                                    <td>{item?.pending_for_approval}</td>
                                    <td>{item?.active_member_count}</td>
                                    <td>{item?.pending_for_payment}</td>
                                    <td>{item?.pending_for_registration}</td>

                                  </tr>
                                  {item?.is_check === true && (
                                    <>
                                      <h6>Block</h6>
                                      {item?.blockWise?.map((block: any) => {
                                        return (
                                          <>
                                            <tr>
                                              <td></td>
                                              <td>{block?.name}</td>
                                              <td>{block?.total_seat_count}</td>
                                              <td>{block?.not_submitted}</td>
                                              <td>
                                                {block?.submitted_member_count}
                                              </td>
                                              <td>
                                                {block?.block_verified}
                                              </td>
                                              <td>
                                                {block?.district_verified}
                                              </td>
                                              {/* <td>
                                                {block?.approved_members_count}
                                              </td> */}
                                              <td>{block?.pending_for_verification}</td>
                                              <td>
                                                {block?.pending_for_approval}
                                              </td>
                                              <td>
                                                {block?.active_member_count}
                                              </td>
                                               <td>{block?.pending_for_payment}</td>
                                              <td>{block?.pending_for_registration}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
          {loginreports?.meta?.totalItems !== 0 && (
            <div className="pagination">
              <CustomPagination
                totalItems={loginreports?.data?.meta?.totalItems}
                itemsPerPage={loginreports?.data?.meta?.itemsPerPage}
                activePage={loginreports?.data?.meta?.currentPage}
                setCurrentPage={(pageNo: string) =>
                  setCurrentPageStaff(navigate, pageNo)
                }
              />
            </div>
          )}
        </Container>
      </div>
    </section>
  );
};

export default LoginHitMember;
