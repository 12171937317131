import { Button, Col, Row } from "reactstrap";
import { SendPaymentSubmitHandler } from "./AddMemberMethods/AddMemberModalMethods";

const PaymentLinkModal = (props: any) => {
  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Do you really want to Send Payment Link .
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"primary"}
                className="sm"
                onClick={() => {
                  SendPaymentSubmitHandler(
                    props?.paymnet_login_id,
                    props?.Refetch,
                    props?.setIsOpen
                   );
                }}
              >
                Yes
              </Button>
              <Button
                color={"danger"}
                outline
                className="sm"
                onClick={() => props?.setIsOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PaymentLinkModal;
