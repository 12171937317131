import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../../Api/BaseUrl";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { useQuery } from "react-query";
import { getPaymentUser } from "../../../Api/AdminApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Item {
  itemId: string;
  amount: string;
  comAmt: string;
}

interface CustomStyle {
  PRIMARY_COLOR_CODE: string;
  SECONDARY_COLOR_CODE: string;
  BUTTON_COLOR_CODE_1: string;
  BUTTON_COLOR_CODE_2: string;
}

interface Features {
  enableAbortResponse: boolean;
  enableExpressPay: boolean;
  enableInstrumentDeRegistration: boolean;
  enableMerTxnDetails: boolean;
  enableNewWindowFlow: boolean;
  payDetailsAtMerchantEnd?: boolean;
  payWithSavedInstrument?: boolean;
  showDownloadReceipt?: boolean;
}

interface ConsumerData {
  deviceId: string;
  token: string;
  returnUrl: string;
  responseHandler: (res: any) => void;
  paymentMode: string;
  merchantLogoUrl: string;
  merchantId: string;
  currency: string;
  consumerId: string;
  consumerMobileNo: string;
  consumerEmailId: string;
  txnId: string;
  items: Item[];
  customStyle: CustomStyle;
}

interface RequestJson {
  features: Features;
  consumerData: ConsumerData;
}

const PyamentCheckoutPage: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const { token }: any = useParams();
  const navigate = useNavigate();

  const {
    data: user,
    error: userError,
    isLoading: userLoading,
    refetch: userListRefetch,
  }: any = useQuery([`user-details${token}`, token], () => getPaymentUser(token), {});

  const handleResponse = (res: any) => {
    if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode === "0300"
    ) {
      Payment_ststus_update(res);
      // success block
    } else if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode === "0398"
    ) {
      // initiated block
      Payment_ststus_update(res);
    } else {
      Payment_ststus_update(res);
      console.log(res, "err");
      setPaymentStatus(false);
      // error block
    }
  };

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    check_cout();
  };

  const check_cout = () => {
    const reqJson: RequestJson = {
      features: {
        enableAbortResponse: true,
        enableExpressPay: true,
        enableInstrumentDeRegistration: true,
        enableMerTxnDetails: true,
        enableNewWindowFlow: true,
      },
      consumerData: {
        deviceId: "WEBSH2",
        token: user?.paymentDetails?.token,
        returnUrl: "",
        responseHandler: handleResponse,
        paymentMode: "all",
        merchantLogoUrl:
          "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png",
        merchantId: user?.paymentDetails?.merchantId,
        currency: "INR",
        consumerId: user?.paymentDetails?.consumerId,
        consumerMobileNo: user?.paymentDetails?.consumerMobileNo,
        consumerEmailId: user?.paymentDetails?.consumerEmailId,
        txnId: user?.paymentDetails?.txnId,
        items: [
          {
            itemId: "first",
            amount: user?.paymentDetails?.totalamount.toString(),
            comAmt: "0",
          },
        ],
        customStyle: {
          PRIMARY_COLOR_CODE: "#45beaa",
          SECONDARY_COLOR_CODE: "#FFFFFF",
          BUTTON_COLOR_CODE_1: "#2d8c8c",
          BUTTON_COLOR_CODE_2: "#FFFFFF",
        },
      },
    };
    console.log(reqJson, "resss");
    (window as any).$.pnCheckout(reqJson);
    if (reqJson.features.enableNewWindowFlow) {
      (window as any).pnCheckoutShared.openNewWindow();
    }
  };

  useEffect(() => {
    if (user?.success === false) {
      toast.error("Payment link expired");
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src =
      "https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js";
    script1.type = "text/javascript";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src =
      "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js";
    script2.type = "text/javascript";
    document.head.appendChild(script2);

    return () => {
      // Clean up the scripts when the component unmounts
      document?.head?.removeChild(script1);
      document?.head?.removeChild(script2);
    };
  }, []);
  const Payment_ststus_update = async (data: any) => {
    try {
      const response = await axios.post(`${BaseUrl}/payment/order-update/${token}`,
        data
      );
      if (response?.data?.success === true) {
        // Handle new window flow if needed     
        console.log("success")
      } else {
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      console.log("error ")
    }
  };


  return (
    <>
      <section className="payment-wrapper">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={12}>
              <div className="payment-section">
                <h4>Membership Payment</h4>
                <div className="form-wrap">
                  <p>
                    {user?.membershipOrder?.user?.first_name} {user?.membershipOrder?.user?.last_name}
                  </p>
                  <p>
                    Phone:<Label>{user?.membershipOrder?.user?.member?.phone_no} </Label>
                  </p>

                  <div className="btn-wrap">
                    <Button
                      id="btnSubmit"
                      onClick={handleClick}
                      disabled={paymentStatus}
                      className="primary"
                      color="primary"
                    >
                      Pay ₹{user?.membershipOrder?.total_amount}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PyamentCheckoutPage;
