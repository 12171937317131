import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddDownloadInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import axios from "axios";
import { PDFDocument, rgb, degrees } from "pdf-lib";

export const DownloadSubmitHandler = (
  values: any,
  actions: any,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  files: any
) => {
  const formData = new FormData();  
  formData.append("name", values.category_name);
  if (!editMode) {
    // Append download_id only if not in edit mode
    formData.append("download_id", values?.id);
  }
  if (values?.parent_id) {
    formData.append("parent_id", values?.parent_id);
  }

  files?.map((item: any) => {
    let randomNumber = Math.floor(Math.random() * 90000) + 10000;
    if (typeof item?.pdfname === "object") {
      formData.append(`${item?.name}:${randomNumber}`, item?.pdfname);
    }
  });

  if (editMode) {
    axios({
      method: "patch",
      url: `${BaseUrl}/download-categories?id=${id}`,
      data: JSON.stringify({
        name: values.category_name
      }),
      // data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        toast.success("Updated ",{toastId:"category"});
        setIsOpen(false);
        refetch();
      })
      .catch(function (response) {
        actions?.setSubmitting(false);
        if (response?.response?.data?.message) {
          toast.error(response?.response?.data?.message);
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"category_error"});
        }
      });
  } else {
    axios({
      method: "post",
      url: `${BaseUrl}/download-categories`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        toast.success("Download Added",{toastId:"Download_added"});
        setIsOpen(false);
        refetch();
      })
      .catch(function (response) {
        actions?.setSubmitting(false);
        if (response?.response?.data?.message) {
          setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Download_error"});
        }
      });
  }
};
// delete File
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/download-categories/individual/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"categoryDeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"category_delete"});
    });
};
// delete Subcategory
export const deleteSubHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/download-categories/individual/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"sub_category_delete"});

    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"sub_category_error"});
    });
};


// Add File Handler
export const FileSubmitHandler = (
  values: any,
  actions: any,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string ,
  files: any
) => {
  
  
  const formData = new FormData();
  formData.append("subcategory_id",id);
    // Append download_id only if not in edit mode
    formData.append("download_id", values?.id);
  if (values?.parent_id) {
    formData.append("parent_id", values?.parent_id);
  }

  files?.map((item: any) => {
    let randomNumber = Math.floor(Math.random() * 90000) + 10000;
    if (typeof item?.pdfname === "object") {
      formData.append(`${item?.name}:${randomNumber}`, item?.pdfname);
    }
  });

  axios({
    method: 'POST',
    url: `${BaseUrl}/download-categories`, // Assuming id is the ID of the subcategory to be updated
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response) {
      toast.success(editMode ? "Updated" : "Download Added",{toastId:"download_added"});
      setIsOpen(false);
      refetch();
    })
    .catch(function (error) {
      actions?.setSubmitting(false);
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message);
        // setValidationErrors(error.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });

};

// export const getAllSubcategory = async (id: string) => {
//   const [, data] = await api.get(`/download-categories/${id}`, true);
//   const tableData = data?.map((item: any) => {
//     return {
//       value: item?.id,
//       label: item?.name,
//     };

//   });
//   return tableData;
  
// };

// export const getAllSubSubcategory = async (main_id:any,id: string) => {
//   const [, data] = await api.get(`/download-categories/${main_id}?parent_id=${id}`, true);
//   const tableData = data?.map((item: any) => {
//     return {
//       value: item?.id,
//       label: item?.name,
//     };
//   });
//   return tableData;
// };
// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  setFiles: Function,
  id: string
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    let alreadyAddedData = modalEditData?.[0]?.files?.map((it: any) => {
      return { name: it?.name, pdfname: it?.pdfname };
    });
    let arr = {
      category_name: modalEditData?.[0]?.name,
      id: id,
      categories: alreadyAddedData,
      parent_id: "",
    };

    setAddModalInitialValues(arr);
    setFiles();
  } else {
    setEditMode(false);
    setAddModalInitialValues({ ...AddDownloadInitialValues, id: id });
  }
};
export const addFileInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  setFiles: Function,
  id: string
) => {
  console.log(modalEditData?.[0]?.document_file__url,"editdata");
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    let alreadyAddedData = modalEditData?.[0]?.document_file_url?.map((it: any) => {
      return { name: it?.name, pdfname: it?.pdfname };
    }) || [];

    let arr = {
      category_name: modalEditData?.[0]?.name,
      id: id,
      categories: alreadyAddedData,
      parent_id: "",
    };

    setAddModalInitialValues(arr);
    setFiles(alreadyAddedData);
  } else {
    setEditMode(false);
    setAddModalInitialValues({ ...AddDownloadInitialValues, id: id });
  }
};

//table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  data: any
) => {
  
  setModalEditData([]);
  setModalEditData(
    data?.tableData?.filter((itm: any) => itm?.id === selectedId)
  );
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};
export const Addfilemodal =(
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  data: any

) => {
  setModalEditData([]);
  setModalEditData(
    data?.tableData?.filter((itm: any) => itm?.id === selectedId)
  );
  setIsOpen(true);
}

// Department delete
export const deleteCategoryClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/downloads/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const deleteDownloadCategoryHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/download-categories/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageDownload = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/download-settings/${pageNo}`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/download-settings/${pageNo}`);
  }
};

const svgToPng = async (svg: string): Promise<Uint8Array> => {
  const svgBlob = new Blob([svg], { type: "image/svg+xml" });
  const svgUrl = URL.createObjectURL(svgBlob);
  const svgImage = new Image();

  return new Promise((resolve) => {
    svgImage.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = svgImage.width;
      canvas.height = svgImage.height;

      context?.drawImage(svgImage, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const result = new Uint8Array(reader.result as ArrayBuffer);
            resolve(result);
          };
          reader.readAsArrayBuffer(blob);
        }
      }, "image/png");
    };

    svgImage.src = svgUrl;
  });
};

const addWatermark = async (
  pdfBuffer: Uint8Array,
  svgContent: string
): Promise<Uint8Array> => {
  const pdfDoc = await PDFDocument.load(pdfBuffer);

  // Convert SVG to PNG
  const watermarkPngBytes = await svgToPng(svgContent);

  // Embed the PNG image into the PDF
  const watermarkImage = await pdfDoc.embedPng(watermarkPngBytes);

  pdfDoc.getPages().forEach((page) => {
    const { width, height } = page.getSize();
    const scale = 2.0; // Adjust the scale factor for both width and height
    const opacity = 0.15; // Adjust the opacity value

    page.drawImage(watermarkImage, {
      x: width / 2 - (watermarkImage.width * scale) / 2,
      y: height / 2 - (watermarkImage.height * scale) / 2,
      width: watermarkImage.width * scale,
      height: watermarkImage.height * scale,
      opacity,
    });
  });

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const handleFileChange = async (
  e: any,
  setFiles: Function,
  setFieldValue: Function,
  index: number
) => {
  
  if (e?.target?.type === "file") {
    const fileBuffer = await e.target.files?.[0].arrayBuffer();
    const svgFilePath = "/images/site-logo.svg";
    const svgContent = await fetch(svgFilePath).then((response) =>
      response.text()
    );
    const watermarkedBuffer = await addWatermark(
      new Uint8Array(fileBuffer),
      svgContent
    );
    const blob = new Blob([watermarkedBuffer], { type: "application/pdf" });
    let file = new File([blob], "pdf-doc-with-watermark.pdf");

    setFiles((prev: any) => {
      
      const newArray = [...prev];
      newArray[index] = {
        ...newArray[index],
        pdfname: file,
        original: e.target.files?.[0],
      };
      return newArray;
    });
    setFieldValue(`categories.${index}.pdfname`, "dummy-value");
  }
  if (e?.target?.type === "text") {
    setFiles((prev: any) => {
      const newArray = [...prev];
      newArray[index] = { ...newArray[index], name: e?.target?.value };
      return newArray;
    });
    setFieldValue(`categories.${index}.name`, "dummy-value");
  }
};

export const handleRowRemove = (
  index: number,
  remove: Function,
  setFiles: Function
) => {
  setFiles((prev: any) => {
    const newArray = prev?.filter((itm: any, i: number) => i !== index);
    return newArray;
  });
  remove(index);
};

export const handleRemoveExistingDoc = (id: any, refetch: Function) => {
  api
    .delete(`/accommodafggtrtion/${id}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const extractSubDownloadData = (data: any) => {
  const res = data?.data?.items?.map((item: any) => {
    const officeAccomodation = item?.OfficeAccomodation || {};
    return {
      id: item?.id,
      category_name: item?.name,
      status: item?.status,
      is_published: item?.is_published,
      document_file_url: item?.document_file_url,
      parent: item?.parent,
      subcategories: item?.subcategories,
    };
  });

  return res;
};
