import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddDesignation from "../AddDesignation";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "../../Designation/AddDesignation/AddDesignationMethods/AddDesignationModalMethods";
import {
  getCadreFullList,
  getDesignationDistrict,
  getDesignationGetList,
  getDesignationSingleList,
  getDistrict,
  getOfficeCategoreyByDistrictFullList,
} from "../../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "../DesignationMethods";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import Select from "react-select";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";
import { api } from "../../../../../Api/Api";
import { handleAdminSecondTabStaffSearch } from "../DesignationSingleMethods";

const DesignationCadreIndex = () => {
  const { currentPage } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [district_id, setDistrictId] = useState();

  const [value, setValue] = useState({
    cadre_id: "",
  });
  const [editValue, setEditValue] = useState({
    district: "",
  });

  const {
    data: designation,
    error: designationError,
    isLoading: designationLoading,
    refetch: designationListRefetch,
  }: any = useQuery(
    [
      `designationGetList${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      value?.cadre_id,
    ],
    () => getDesignationGetList(currentPage, searchQuery, value?.cadre_id),
    { keepPreviousData: true }
  );

  const { data: cadre }: any = useQuery([`cadre`], () => getCadreFullList(), {
    keepPreviousData: true,
  });

  const { data: district }: any = useQuery(
    [`district`],
    () => getDesignationDistrict(),
    {
      keepPreviousData: true,
    }
  );

  const { data: office }: any = useQuery(
    [`officecategorey`, district_id],
    () =>
      getOfficeCategoreyByDistrictFullList(
        district_id === null || district_id === undefined ? "" : district_id
      ),
    {
      keepPreviousData: true,
    }
  );
  const { data: designationlist }: any = useQuery(
    [`desig`],
    () => getDesignationSingleList(),
    {
      keepPreviousData: true,
    }
  );

  const designationfull = useMemo(
    () => extractStaffData(designation),
    [designation]
  );

  const handleChange = (e: any, feild_name: any) => {
    if (feild_name === "cadre") {
      setValue({
        ...value,
        cadre_id: e?.value,
      });
    }
  };
  const editdata = (data: any) => {
    api
      .get(
        `/designation/${data?.cadre_id}?office_cat_id=${data?.officeCategory_id}`,
        true
      )
      .then(async function ([success, response]) {
        setModalEditData(response?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminSecondTabStaffSearch(
                        e,
                        setSearchQuery,
                        navigate,
                        localStorage?.getItem("ROLE") === "admin"
                          ? ""
                          : "/member/designation/1"
                      )
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>

            <Col md={3}>
              <Select
                placeholder="Select Cadre"
                options={cadre}
                value={
                  cadre?.filter((itm: any) => itm?.value === value?.cadre_id) ||
                  ""
                }
                onChange={(e: any) => handleChange(e, "cadre")}
                name="cadre"
                isLoading={false}
                loadingMessage={() => "Fetching Cadre"}
                noOptionsMessage={() => "Cadre appears here"}
                isSearchable={true}
                isClearable
              />
            </Col>

            <div className="right-side-wrap">
                <div className="button-wrap">
                  {checkPermission("designation:write") ? (
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Designation
                    </Button>
                  ) : null}
                </div>
                <Button
                color="primary"
                className="sm"
                onClick={() => {
                  setValue({
                    ...value,
                    cadre_id: "",
                  });
                  setSearchQuery("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Container>
        {/* cards section */}

        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={designationfull}
            tableTitle="Designation Details"
            tableFields={["cadre", "Designation", "Office Category"]}
            Arraykeys={["cadre", "designation_metas", "name"]}
            viewType="table"
            toURL={""}
            actions={[
              checkPermission("designation:write") ? "edit" : "",
              checkPermission("designation:delete") ? "delete" : "",
            ]}
            isLoading={designationLoading}
            error={designationError}
            onEditClick={(selectedId: any) => {
              if (checkPermission("designation:write")) editdata(selectedId);
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                designationfull
              );
            }}
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(
                selectedId,
                designationListRefetch,
                setModalEditData,
                designationfull,
                modalEditData
              )
            }
            paginationData={designation?.data?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo, pathname)
            }
            from_des={true}
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0 ? "Edit Designation" : "Add Designation"
        }
      >
        <AddDesignation
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={designationListRefetch}
          cadre={cadre}
          setDistrictId={setDistrictId}
          office={office}
          district={district}
          designationData={designationlist}
          setEditValue={setEditValue}
        />
      </ModalContainer>
    </section>
  );
};

export default DesignationCadreIndex;
