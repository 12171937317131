import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddUserMethods/AddUserModalMethods";
import {
  AddUserinitialValues,
  UserValidationSchema,
} from "./AddUserMethods/FormikValidation";
import { AddUserProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import Select from "react-select";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";

const AddUser = ({
  setIsOpen,
  modalEditData,
  refetch,
  data,
  role,
  setData
}: AddUserProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddUserinitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={UserValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          modalEditData?.length !== 0 ? modalEditData : data,
          setData
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={12}>
                  Selected User Count ({data?.length})
                </Col>
                <Col md={12}>
                  <Label>Role</Label>
                  <Select
                    placeholder="Select Office"
                    name="role_status"
                    options={role}
                    value={
                      role?.filter((itm: any) =>
                        values?.role_status?.includes(itm?.value)
                      ) || []
                    }
                    onChange={(selectedOptions: any) => {
                      const selectedValues = selectedOptions.map(
                        (option: any) => option.value
                      );
                      setFieldValue("role_status", selectedValues);
                    }}
                    isMulti
                    isLoading={false}
                    loadingMessage={() => "Fetching Role"}
                    noOptionsMessage={() => "Role appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="role_status" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddUser;
