import * as Yup from "yup";

export const OfficeCategoryValidationSchema = Yup.object().shape({
  office_cat_name: Yup.string().required("Field is required"),
  district_id: Yup.string().required("Field is required"),
  // block_id:Yup.array()
  //   .min(1, "Select at least one option")
  //   .required("Select at least one option"),
});

export const NewOfficeCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
});
export const AddOfficeCategoryInitialValues = {
  office_cat_name: "",
  district_id: "",
  block_id: "",
};

export const AddNewOfficeCategoryInitialValues = {
  name: "",
};
