export const activateSidebarTab = (routeList: any, currentPath: string) => {
  if (
    routeList?.AllPaths?.includes(currentPath?.replace(/\/\d+$/, "")) ||
    routeList?.path?.includes(currentPath?.replace(/\/\d+$/, ""))
  ) {
    return "active";
  }
};
export const activatesubSidebarTab = (routeList: any, currentPath: string) => {
  if (
    routeList?.subListPath?.includes(currentPath?.replace(/\/\d+$/, "")) ||
    routeList?.path?.includes(currentPath?.replace(/\/\d+$/, ""))
  ) {
    return "active";
  }
};
