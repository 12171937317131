import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddMemberinitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  EmployeementStatus,
  MembershipStatus,
  capitalizeFirst,
  convertISODateToDate,
  setValidationErrors,
  setValidationErrorsAll,
} from "../../../../../../Utils/helpers/helpers";
import moment from "moment";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

type AddInitialValues = {
  employement_status: string;
  present_status: string;
  working_status: any;
  member_f_name: string;
  member_l_name: string;
  pen_no: string;
  office: string;
  email: string;
  phone_no: string;
  member_whatsapp_number: string;
  post_graduation: any;
  post_graduation_year_of_passing: any;
  doctoral_degree: string;
  bachelor_degree_year_of_psssing: string;
  bachelor_degree: string;
  any_other_education: any;
  member_date_of_birth: string;
  designation: string;
  member_type: any;
  role: any;
  date_of_joining: string;
  date_of_retirement: string;
  join_status: string;
  membership_document: string;
  seat_id: any;
  block_id: any;
  district_id: any;
  office_category: any;
  cader_name: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  const formData = new FormData();

  formData.append("is_working", values?.working_status);
  formData.append("first_name", values?.member_f_name);
  formData.append("last_name", values?.member_l_name);
  formData.append("email", values?.email);
  formData.append("phone_no", values?.phone_no);
  formData.append("whatsapp_no", values?.member_whatsapp_number);
  formData.append("date_of_birth", values?.member_date_of_birth);
  formData.append("membership_type", values?.member_type);

  if (values?.date_of_joining) {
    formData.append("date_of_joining_service", values?.date_of_joining);
  }

  if (values?.working_status === "3") {
    if (values?.office) {
      formData.append("office_id", "null");
    }
  }
  else {
    if (values?.office) {
      formData.append("office_id", values?.office);
    }
  }
  if (values?.working_status === "3") {
    if (values?.office_category) {
      formData.append("office_category", "null");
    }
  }
  else {
    if (values?.office_category) {
      formData.append("office_category", values?.office_category);
    }
  }


  if (values?.working_status === "3") {
    if (values?.seat_id) {
      formData.append("cadre_id", "null");
    }
  }
  else {
    if (values?.seat_id) {
      formData.append("cadre_id", values?.seat_id);
    }
  }


  if (values?.district_id) {
    formData.append("district_id", values?.district_id);
  }
  if (values?.block_id) {
    formData.append("block_id", values?.block_id);
  }
  if (values?.designation) {
    formData.append("designation_id", values?.designation);
  }
  if (values?.present_status) {
    formData.append("pension_scheme", values?.present_status);
  }
  if (values?.pen_no) {
    formData.append("pen_no", values?.pen_no);
  }
  if (values?.membership_document) {
    formData.append("membership_certificate", values?.membership_document);
  }
  if (values?.employement_status) {
    formData.append("employment_status", values?.employement_status);
  }

  // Add
  if (id) {
    axios({
      method: "patch",
      url: `${BaseUrl}/members/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Member Updated", { toastId: "Member_Updated" });
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.message) {
          if (response?.response?.data?.message?.length !== 0) {
            setValidationErrorsAll(response?.response?.data?.message, actions);
          } else {
            actions.setFieldError(
              "membership_document",
              response?.response?.data?.message
            );
            actions.setFieldError(
              "email",
              response?.response?.data?.message
            );
          }
        } else {
          toast.error("Unexpected Error Occurred", { toastId: "member_update_error" });
        }
      });
  }
  // Edit
  else {
    if (values?.date_of_retirement) {
      formData.append("date_of_retirement", values?.date_of_retirement);
    }
    axios({
      method: "post",
      url: `${BaseUrl}/members`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Member Added", { toastId: "Member_Added" });
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data?.message) {
          if (response?.response?.data?.message?.length !== 0) {
            setValidationErrorsAll(response?.response?.data?.message, actions);
          } else {
            actions.setFieldError(
              "membership_document",
              response?.response?.data?.message
            );
          }
        } else {
          toast.error("Unexpected Error Occurred", { toastId: "member_add_error" });
        }
      });
  }
};

export const VerifySubmitHandler = (
  setIsOpen: Function,
  id: any,
  actions: any,
  refetch: Function,
  member_block_verify: any,
  setIsOpens: any,
  values: any
) => {
  if (member_block_verify === 3) {
    api
      .patch(
        "/members/details/block-verify",
        { status: 1, user_id: id, member_status: values?.member_status },
        true
      )
      .then(async function ([success, response]: any) {
        if (response?.success === true) {
          toast.success("Verified", { toastId: "member_block_verify" });
          setIsOpen(false);
          setIsOpens(false);
          refetch();
        }
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred", { toastId: "member_verify_error" });
        }
      });
  } else {
    api
      .patch("/members/details/verify", { status: 1, user_id: id }, true)
      .then(async function ([success, response]: any) {
        if (response?.success === true) {
          toast.success("Verified", { toastId: "member_district_verify" });
          setIsOpen(false);
          setIsOpens(false);
          refetch();
        }
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred", { toastId: "member_verify_error" });
        }
      });
  }
};
export const SendPaymentSubmitHandler = (
  id: any,
  refetch: Function,
  setIsOpen: any
) => {
  api
    .post("/members/create-order", { user_id: id }, true)
    .then(async function ([success, response]: any) {
      if (response?.success === true) {
        toast.success("Successfully  Sent Payment Link", { toastId: "payment_link_sent" });
        refetch();
        setIsOpen(false);
      } else {
        toast.error("Membership amount is not configured", { toastId: "payment_link_sent_error" });
        refetch();
      }
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
      } else {
        toast.error("Unexpected Error Occurred", { toastId: "payment_link_sent_error" });
      }
    });
};

export const SendLoginSubmitHandler = (
  id: any,
  refetch: Function,
  setIsOpen: Function,
  setLoading: Function
) => {
  setLoading(true);
  api
    .post("/members/send-login-credentials", { user_id: id }, true)
    .then(async function ([success, response]: any) {
      if (response?.success === true) {
        toast.success("Successfully sent Login data", { toastId: "logindata" });
        refetch();
        setIsOpen(false);
        setLoading(false);
      } else {
      }
    })
    .catch((err) => {
      setLoading(false);
      if (err?.response?.data?.errors) {
      } else {
        // toast.error("Unexpected Error Occurred");
      }
    });
};

export const extractRetireData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const mappedData = data?.items?.map((item: any, index: number) => ({
    id: item.users_id,
    firstName: item.users_first_name,
    profileImage: item.members_profile_img,
    officeName: item.offices_name,
    officeAddress: item.offices_address,
    districtName: item.districts_name,
    designationName: item.designationMeta_name,
    resetpassword_count: item.resetPassword_used_count,
    membership_status__payment:
      item?.membership_status === 1 ? "Active" : "Inactive",
  }));

  return mappedData;
};

// extract Departmentdata for table
export const extractStaffData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const res = data?.items?.map((item: any, index: number) => {
    return {
      id: item?.user_id,
      membershipOrder_membership_no: item?.member_membership_no,
      user_first_name: item?.user_first_name + " " + item?.user_last_name,
      employement_status: EmployeementStatus(item?.member_employment_status),
      member_pen_no: item?.member_pen_no,
      office_name: item?.office_name,
      member_employment_status: EmployeementStatus(
        item?.member_employment_status
      ),
      member_membership_type: MembershipStatus(item?.member_membership_type),
      member_membership_type_: item?.member_membership_type,
      member_status: item?.member_status,
      payment_date: item?.payment_date,
      email: item?.user_email,
      membership_status:
        item?.membership_status === 1
          ? "Active"
          : item?.membership_status === 2
            ? "Upcoming"
            : "Expired",
      payment_order_status: item?.payment_order_status,
      member_block_verify: item?.member_block_verify,
      member_district_verify: item?.member_district_verify,
      retirement_status: item?.retirement_status,
      retirement_status_:
        item?.member_is_working === 4
          ? "Transit"
          : item?.retirement_status === 1
            ? "Retired"
            : "Non-Retired",
      member_date_of_retirement: convertISODateToDate(
        item?.member_date_of_retirement
      ),
      associative_status: item?.associative_status,
      membership_status_: item?.membership_status,
      membership_status__payment:
        item?.membership_status === 1 ? "Active" : "Inactive",
      user_is_active:
        item?.user_is_active === 2
          ? "Suspended"
          : item?.membership_status === 1 && item?.member_membership_type === 1
            ? "Active"
            : item?.member_district_verify === 1 && item?.member_membership_type === 3
              ? "Active" : "Inactive",
      max_used_count: item.max_used_count,

    };
  });

  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.user_id,
      employement_status: modalEditData?.member_employment_status?.toString(),
      present_status: modalEditData?.member_pension_scheme,
      working_status: modalEditData?.member_is_working?.toString(),
      pen_no: modalEditData?.member_pen_no,
      office: modalEditData?.office_id,
      email: modalEditData?.user_email,
      phone_no: modalEditData?.member_phone_no,
      member_whatsapp_number: modalEditData?.member_whatsapp_no,
      member_date_of_birth: moment(modalEditData?.member_date_of_birth).format(
        "YYYY-MM-DD"
      ),
      designation: modalEditData?.designation_id,
      member_type: modalEditData?.member_membership_type?.toString(),
      member_f_name: modalEditData?.user_first_name,
      member_l_name: modalEditData?.user_last_name,
      date_of_retirement: modalEditData?.member_date_of_retirement
        ? moment(modalEditData?.member_date_of_retirement).format("YYYY-MM-DD")
        : "",
      date_of_joining: modalEditData?.member_date_of_joining_service
        ? moment(modalEditData?.member_date_of_joining_service).format(
          "YYYY-MM-DD"
        )
        : "",
      join_status: modalEditData?.member_join_status,
      district_id: modalEditData?.district_id,
      block_id: modalEditData?.block_id,
      seat_id: modalEditData?.cadre_id,
      office_category: modalEditData?.office_category_id,
      cader_name: modalEditData?.cadre_name,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddMemberinitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any,
  membersListRefetch: Function
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );
  membersListRefetch();
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/members/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred", { toastId: "member_delete" });
    });
};

export const setRetirePageMembers = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/member-management/${pageNo}/3`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/member-management/${pageNo}/3`);
  }
};

export const setCurrentPageAssociate = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/member-management/${pageNo}/2`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/member-management/${pageNo}/2`);
  }
};

export const setCurrentPageMembers = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/member-management/${pageNo}/1`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/member-management/${pageNo}/1`);
  }
};
