import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddFundInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  FundStatus,
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  office: string;
  received_fund: any;
  expended_fund: any;
  document: string;
};

export const FundSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Edit
  if (editMode) {
    api
      .patch(`/fund/${id}`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("fund Details Updated ",{toastId:"fund_updated"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"fund_error"});
        }
      });
  }
  // Adding
  else {
    api
      .post("/fund", values, true)
      .then(async function ([success, response]: any) {
        toast.success("fund Added ",{toastId:"fund_added"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"fund_error"});
        }
      });
  }
};
export const FundStatusSubmitHandler = (
  setIsOpen: Function,
  refetch: any,
  id: any,
  status: any
) => {
  // Edit

  api
    .patch(`/fund/publish/${id}`, { status: status }, true)
    .then(async function ([success, response]: any) {
      if (response?.data === true) {
        toast.success(
          `Fund Details ${status === true ? "Published" : "UnPublished"} `,{toastId:"fund_published"}
        );
        setIsOpen(false);
        refetch();
      }
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"fund_published_error"});
      }
    });
};

// extract Departmentdata for table
export const extractFundData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.office?.name,
      office: item?.office?.id,
      mobile: item?.phone_no,
      email: item?.email,
      expended_fund: item?.expended_fund,
      received_fund: item?.received_fund,
      document: item?.document_file,
      is_published: item?.is_published,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      name: modalEditData?.[0]?.office?.name,
      office: modalEditData?.[0]?.office?.id,
      expended_fund: modalEditData?.[0]?.expended_fund,
      received_fund: modalEditData?.[0]?.received_fund,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddFundInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  fund: any
) => {
  setModalEditData([]);
  setModalEditData(fund?.data?.filter((itm: any) => itm?.id === selectedId));

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/fund/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"fund_deleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"fund_deleted_error"});
    });
};

export const setCurrentPageFund = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/fund-management/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/fund-management/${pageNo}`)
  }
};
