import axios from "axios";
import { api } from "../../../../Api/Api";
import { processJsonResponse } from "../../../../Utils/helpers/helpers";
import { BaseUrl } from "../../../../Api/BaseUrl";

export const getAdminTransferData = async (
  currentPage: string,
  searchQuery: string
) => {
  const [, data] = await api.get(
    `/transfer?page=${currentPage}&search=${searchQuery}&limit=10&`,
    true
  );

  const filteredData = data?.data?.items?.map((itm: any, i: number) => {
    const pg_no =
      (data?.data?.meta.currentPage - 1) * data?.data?.meta?.itemsPerPage +
      i +
      1;

    return {
      no: pg_no,
      id: itm?.id,
      to_office: itm?.office?.name,
      to_office_id: itm?.office_id,
      panchayath: itm?.panchayath?.name,
      block: itm?.block?.name,
      district: itm?.district?.name,
      reason: itm?.reason,
      description: itm?.description,
      status_type: itm?.type,
      status: itm?.status,
      block_verify: itm?.block_verify,
      district_verify: itm?.district_verify,
      Go_order: itm?.GO_order,
      Go_order_date: itm?.GO_order_date,
      user_name: itm?.user?.first_name,
      block_id: itm?.block?.id,
      office_id: itm?.office?.id,
      district_id: itm?.district?.id,
      designation_id: itm?.designation_id,
      user: itm?.user?.id,
      cadre_id: itm?.designation_id?.designation?.[0]?.cadre?.id,
    };
  });

  return { data: data?.data, filteredData: filteredData };
};

// export const handleAdminTransferSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
//     if (e?.target?.value?.length > 2) {
//         navigate('/admin/transfer-request')
//         setSearchQuery(e?.target?.value)
//     }
//     else {
//         setSearchQuery('')
//     }
// }

export const handleAdminTransferSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    if (e?.target?.value?.length > 2) {
      setSearchQuery(e?.target?.value);
      navigate(`/admin//transfer-request/1`);
    } else {
      setSearchQuery("");
    }
  } else {
    if (e?.target?.value?.length > 2) {
      setSearchQuery(e?.target?.value);
      navigate(`/member/transfer-request/1`);
    } else {
      setSearchQuery("");
    }
  }
};

export const officeChange = async (
  id: any,
  block_id: any,
  district_id: any,
  office_id: any,
  designation_id: any,
  cadre_id: any
) => {
  let is_working: any = 1;
  // Assuming formData is defined elsewhere
  const formData = new FormData();

  // Append data to the formData objectF
  formData.append("id", id);
  formData.append("block_id", block_id);
  formData.append("district_id", district_id);
  formData.append("office_id", office_id);
  formData.append("is_working", is_working);
  if(cadre_id){
    formData.append("cadre_id", cadre_id);
  }
  if (designation_id) {
    formData.append("designation_id", designation_id);
  }

  try {
    const response = await axios.patch(`${BaseUrl}/members/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error updating member:", error);
    throw error; // Throw the error to be caught by the caller
  }
};
