import { Container, InputGroup, Input, Row, Col, Table } from "reactstrap";
import { getdashboard, getdashboardmember } from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { formatDateTimeDash } from "../../../../Utils/helpers/helpers";
export default function AdminDashboard() {
  const { currentPage = "1" } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    data: dashboardData,
    error: downloadError,
    isLoading: downloadLoading,
    refetch: downloadListRefetch,
  }: any = useQuery(
    [`dashboard${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getdashboard(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const {
    data: dashboardmemberData,
    error: dashboardmemberError,
    isLoading: dashboardmemberLoading,
    refetch: dashboardmemberListRefetch,
  }: any = useQuery(
    [`dashboardmember${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getdashboardmember(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  return (
    <>
      <section className="dashboard-wrapper">
        <div className="content-outer-wrap">
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col md={6}>
                <div className="welcome-wrap">
                  <h5>Welcome </h5>
                  <small>AOAOK Member Dashboard</small>
                </div>
              </Col>
              <Col md={3}>
                <div className="search-bar">
                  <InputGroup>
                    <Input placeholder="Search here..." />
                    <span className="icon-container">
                      <i className="icon xl icon-search"></i>
                    </span>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="py-4 gy-4">
              <Col md={6}>
                <div className="widget-card bg-secondary">
                  <i className="icon xxxl icon-total-members"></i>
                  <h3>Total Members</h3>
                  <h2>{dashboardData?.totalmembercount}</h2>
                </div>
              </Col>

              <Col md={6}>
                <div className="widget-card bg-tertiary">
                  <i className="icon xxxl icon-total-prescription"></i>
                  <h3>Total Prescription</h3>
                  <h2>{dashboardData?.prescriptionCount}</h2>
                </div>
              </Col>

              <Col md={6}>
                <div className="payment-widget-card bg-white">
                  <h6>Payment Details</h6>
                  <div className="card-outer-wrap">
                    <div className="card-inner-wrap">
                      <h5>District Share</h5>
                      <div className="payment-icon-wrap">
                        <i className="icon xl icon-ruppee"></i>
                        <span>{dashboardData?.totalDistrictShare}</span>
                      </div>
                    </div>
                    <div className="card-inner-wrap">
                      <h5>State Share</h5>
                      <div className="payment-icon-wrap">
                        <i className="icon xl icon-ruppee"></i>
                        <span>{dashboardData?.totalStateShare}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="table-outer-wrap">
                  <div className="table-heading">
                    <h6>Membership History</h6>
                  </div>

                  <div className="table-outer">
                    <Table className="table-section">
                      <thead>
                        <tr>
                          <th>Office Name</th>
                          <th>District</th>
                          <th>Designation</th>
                          <th>Membership Id</th>
                          <th>Year</th>
                        </tr>
                      </thead>

                      <tbody>
                        {dashboardmemberData?.map((item: any, index: any) => (
                          <tr key={index}>
                            <td data-th="Office Name">
                              <span className="mobile-heading">
                                Office Name
                              </span>
                              <span className="table-data">
                                {item.offices_name}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">District</span>
                              <span className="table-data">
                                {item.districts_name}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">
                                Designation
                              </span>
                              <span className="table-data">
                                {item.designationMeta_name}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">
                                Membership Id
                              </span>
                              <span className="table-data">
                                {item.membershiplog_membership_no}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">Year</span>
                              <span className="table-data">
                                {formatDateTimeDash(
                                  item.membershiplog_created_at
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="widget-card active-card">
                  <i className="icon lg icon-profile-green"></i>
                  <h6>Active Members</h6>
                  <h4>
                    {dashboardData?.totalMemberStatusCount?.active_member_count}
                  </h4>
                </div>
              </Col>

              <Col md={4}>
                <div className="widget-card suspended-card">
                  <i className="icon lg icon-suspended-profile"></i>
                  <h6>Suspended Members</h6>
                  <h4>
                    0
                    {/* {dashboardData?.totalMemberStatusCount?.suspended_members} */}
                  </h4>
                </div>
              </Col>

              {/* <Col md={4}>
                <div className="widget-card active-card">
                  <i className="icon lg icon-profile-green"></i>
                  <h6>Approved Members</h6>
                  <h4>{dashboardData?.approvedmemberscount}</h4>
                </div>
              </Col> */}
              <Col md={4}>
                <div className="widget-card pending-card">
                  <i className="icon lg icon-pending-profile"></i>
                  <h6>Pending For Payment</h6>
                  <h4>
                    {dashboardData?.totalMemberStatusCount?.pending_for_payment}
                  </h4>
                </div>
              </Col>

              <Col md={4}>
                <div className="widget-card seat-widget">
                  <i className="icon xxxl icon-seats-white"></i>
                  <h6>Total Seats</h6>
                  <h4>{dashboardData?.officeDetails?.total_seat_count}</h4>
                </div>
              </Col>

              <Col md={4}>
                <div className="widget-card seat-widget">
                  <i className="icon xxxl icon-seats-white"></i>
                  <h6>Total Seats Filled</h6>
                  <h4>{dashboardData?.officeDetails?.occupied_seat_count}</h4>
                </div>
              </Col>
              <Col md={4}>
                <div className="widget-card seat-widget">
                  <i className="icon xxxl icon-seats-white"></i>
                  <h6>Remaining Seats </h6>
                  <h4>{dashboardData?.officeDetails?.remaining_seat_count}</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
