import { ErrorMessage, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import {
  DownloadSubmitHandler,
  addModalInitialValuesHandler,
  handleFileChange,
  handleRowRemove,
} from "./AddDownloadMethods/AddDownloadModalMethods";
import {
  AddDownloadInitialValues,
  DownloadValidationSchema,
} from "./AddDownloadMethods/FormikValidation";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";

const AddDownload = ({ setIsOpen, modalEditData, refetch }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddDownloadInitialValues
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  console.log(editMode,"d")
  const [files, setFiles] = useState([
    {
      pdfname: null,
      name: "",
      original: null,
    },
  ]);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={DownloadValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        DownloadSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          files
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.category_name}
                    name="category_name"
                    placeholder="Category name"
                  />
                  <ErrorMessage name="category_name" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddDownload;
