// croppedimages.tsx

import React, { useState, useRef } from 'react';
import {
    Button
} from "reactstrap";
import { Cropper, CropperRef } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

interface CroppedImagesProps {
    src: string;
    onCrop: (croppedImage: any) => void;
    from_blogs?: boolean;
    from_vision?: boolean;
    from_banner?: boolean;
}

const CroppedImages: React.FC<CroppedImagesProps> = ({ src, onCrop, from_blogs, from_vision, from_banner }) => {
    const cropperRef = useRef<CropperRef>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);

    const handleCrop = (e: React.MouseEvent) => {
        e.preventDefault();

        if (cropperRef.current) {
            const canvas = cropperRef.current.getCanvas();
            const croppedImage = canvas ? canvas.toDataURL() : null;

            if (croppedImage) {
                setCroppedImageUrl(croppedImage);
                const blob = dataURLtoBlob(croppedImage);
                const file = new File([blob], 'croppedImage.png', { type: 'image/png' });
                onCrop(file);
            } else {
                onCrop(null);
            }
        }
    };

    const dataURLtoBlob = (dataURL: string) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };


    return (
        <div className='mywebsite-edit-modal'>
            <Cropper
                ref={cropperRef}
                src={src}
                className={'cropper'}
                style={{
                    height: from_blogs ? '280px' : from_banner ? '300px' : from_vision ? '300px' : '300px',
                    width: from_blogs ? '282px' : from_banner ? '400px' : from_vision ? '510px' : '400px'
                }}
                aspectRatio={() => {
                    if (from_blogs) {
                        return 282 / 280;
                    } else if (from_vision) {
                        return 500 / 300;
                    } else if (from_banner) {
                        return 1600/800;
                    } else {
                        return NaN;
                    }
                }}
            />



            {croppedImageUrl && (
                <div>
                    <p>Cropped Image:</p>
                    <img src={croppedImageUrl} alt="Cropped" />
                </div>
            )}
            {src && (
                <div className='crop-image-container'>
                 <p onClick={handleCrop}><i  className="icon icon-crop xl"></i>
                    Click here to crop</p>
                </div>)}
            {/* <div className='btn-container'>
                <Button outline className='sm' color='primary' onClick={handleCrop}>Crop Image</Button>
            </div> */}
        </div>
    );
};

export default CroppedImages;
