import * as Yup from "yup";

export const LeaveValidationSchema = Yup.object().shape({
  present_employement_status: Yup.string()
    .required("Field is required")
    .nullable(),
  leave_type: Yup.number().required("Field is required").nullable(),
  start_date: Yup.string().required("Field is required").nullable(),
  end_date: Yup.string().required("Field is required").nullable(),
  sanction_document: Yup.string().required("Field is required").nullable(),
  other_leave_type: Yup.string().when("leave_type", ([leave_type], sch) => {
    return leave_type === 6
      ? sch.required("Field is required").nullable()
      : sch.notRequired();
  }),
});

export const AddLeaveInitialValues = {
  present_employement_status: "",
  leave_type: "",
  start_date: "",
  end_date: "",
  sanction_document: "",
  other_leave_type: "",
};
