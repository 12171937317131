import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";
import {
  DateFormatter,
  setValidationErrors,
} from "../../../../Utils/helpers/helpers";
import { FormikHelpers } from "formik";
import constants from "../../../../Utils/Constants/Constants";
import { BaseUrl } from "../../../../Api/BaseUrl";
import axios from "axios";
import moment from "moment";

type AddInitialValues = {
  email: string;
  mobile_number: string;
  whatsapp_number: string;
  profile_pic: string;
  employment_status: string;
  from_where: string;
  start_date: string;
  end_date: string;
  bachelor_degree: string;
  year_of_pass: string;
  pg_year_of_pass: string;
  pg_degree: string;
  doctoral_degree: string;
  doctoral_degree_pass: string;
  pg_course: string;
  pg_other: string;
  doctoral_degree_course: string;
  linkedin_id: any;
  twitter_id: any;
  instagram: any;
  about: any;
  bachelor_degree_course: any;
  if_any_other_bachelor_degree: any;
  doctoral_degree_pg_other: any;
  any_other_education: any;
  // any_other_education: [{
  //   other_degree: any,
  //   other_degree_course: any,
  //   other_degree_pass_year: any,
  //   other_degree_other: any,
  // }];
  // other_degree: string;
  // other_degree_course: string;
  // other_degree_pass_year: string;
  // other_degree_other: string;
};

type OfficeInitialValues = {
  email: string;
  phone_no: string;
  address: string;
};
type AddEducationInitialValues = {
  any_other_education: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  actions: any
) => {
  var eduData: any = new Array();

  console.log(
    values?.bachelor_degree,
    values?.pg_degree,
    values?.doctoral_degree,
    "v"
  );
  console.log(moment(values.year_of_pass).format("YYYY"), "atgjXH");

  if (Number(values?.bachelor_degree) === 1) {
    eduData.push({
      qualification_id: values?.bachelor_degree === "1" ? "1" : "",
      edu_category_id: values?.bachelor_degree_course,
      year_of_pass: moment(values.year_of_pass).format("YYYY"),
      other_category_value: values?.if_any_other_bachelor_degree,
    });
  }

  if (Number(values?.pg_degree) == 1) {
    eduData.push({
      qualification_id: values?.pg_degree === "1" ? "2" : "",
      edu_category_id: values?.pg_course,
      year_of_pass: moment(values.pg_year_of_pass).format("YYYY"),
      other_category_value: values?.pg_other,
    });
  }

  if (Number(values?.doctoral_degree) == 1) {
    eduData.push({
      qualification_id: values?.doctoral_degree === "1" ? "3" : "",
      edu_category_id: values?.doctoral_degree_course,
      year_of_pass: moment(values?.doctoral_degree_pass).format("YYYY"),
      other_category_value: values?.doctoral_degree_pg_other,
    });
  }

  if (values?.any_other_education?.length !== 0) {
    values?.any_other_education?.map((item: any) => {
      eduData?.push({
        qualification_id: item?.other_degree === "1" ? "4" : "",
        edu_category_id: item?.other_degree_course,
        year_of_pass: moment(item?.other_degree_pass_year).format("YYYY"),
        other_category_value: item?.other_degree_other,
      });
    });
  }
  const formData = new FormData();

  formData.append("email", values?.email);
  formData.append("phone_no", values?.mobile_number);
  formData.append("whatsapp_no", values?.whatsapp_number);
  formData.append("employment_status", values?.employment_status);
  formData.append("about", values?.about);
  formData.append("profile_image", values?.profile_pic);
  formData.append("twitter_profile", values?.twitter_id);
  formData.append("instagram_profile", values?.instagram);
  formData.append("linkedin_profile", values?.linkedin_id);

  if (eduData?.length > 0) {
    formData.append("educational_qualification", JSON.stringify(eduData));
  }

  axios({
    method: "patch",
    url: `${BaseUrl}/profile`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response) {
      if (response?.status === 200) {
        actions?.setSubmitting(false);
        setIsOpen(false);
        toast.success("Profile Details Updated ",{toastId:"9290"});
        refetch();
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"9291"});
      }
    });
};
export const OfficeSubmitHandler = (
  values: OfficeInitialValues,
  actions: FormikHelpers<OfficeInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add

  api
    .patch(`/profile/my-office`, values, true)
    .then(async function ([success, response]: any) {
      toast.success("Office Details Updated ",{toastId:"9293"});
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"9294"});
      }
    });
};
export const EducationSubmitHandler = (
  values: AddEducationInitialValues,
  actions: FormikHelpers<AddEducationInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .put(`/staff/${id}`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Details Updated ",{toastId:"9295"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"9296"});
        }
      });
  }
  // Edit
  else {
    api
      .post("/staff", values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Added ",{toastId:"9297"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"9298"});
        }
      });
  }
};

export async function getMemberProfileData() {
  const [, data] = await api.get(`/profile/me`, true);
  return data?.data;
}

export async function getMemberPaymentData(date?: any) {
  const [, data] = await api.get(
    `/payment/member-details?year=${moment(date).format("YYYY")}`,
    true
  );
  return data?.data;
}

export async function getMemberOfficeData() {
  const [, response] = await api.get(`/profile/me/office`, true);
  const data = response[0];
  return data;
}

export async function getEducationData() {
  const [, data] = await api.get(`/education/qualifications`, true);

  let newData = data?.map((itm: any) => {
    return {
      ...itm,
      category: itm?.category?.map((ob: any) => {
        return {
          value: ob?.id,
          label: ob?.name,
        };
      }),
    };
  });
  return newData;
}

export const getEducationListData = (data: any, degree: string) => {
  return data?.filter((itm: any) => itm?.degree_name === degree)?.[0]?.category;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  filteredEduList: any,
  seImageView: Function,
  setStartDate: any,
  startDatepg: any,
  startDatedo: any
) => {
  const filteredData = modalEditData?.educationQualification?.filter(
    (item: any) => item.degree_name === "OTHER"
  );
  let new_array_: any = [];
  filteredData?.map((item: any) => {
    new_array_?.push({
      other_degree: item?.educat_cat_name ? "1" : "2",
      other_degree_course: item?.educational_categories_id,
      other_degree_pass_year: item?.year_of_pass?new Date(item?.year_of_pass,0,1):"",
      other_degree_other: item?.other_category_value,
    });
  });
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    seImageView(modalEditData?.profile_image_url);

    setAddModalInitialValues({
      id: modalEditData?.user_id,
      email: modalEditData?.user_email,
      mobile_number: modalEditData?.member_phone_no,
      whatsapp_number: modalEditData?.member_whatsapp_no,
      profile_pic: modalEditData?.member?.profile_img,
      employment_status: modalEditData?.member_employment_status?.toString(),
      about: modalEditData?.member_about,
      from_where: "",
      start_date: "",
      end_date: "",
      bachelor_degree: filteredEduList?.BACHELORSDEGREE ? "1" : "",
      year_of_pass: filteredEduList?.BACHELORSDEGREE?.year_of_pass?new Date(filteredEduList?.BACHELORSDEGREE?.year_of_pass,0,1):"",
      pg_degree: filteredEduList?.POSTGRADUATION ? "1" : "2",
      pg_year_of_pass: filteredEduList?.POSTGRADUATION?.year_of_pass?new Date(filteredEduList?.POSTGRADUATION?.year_of_pass,0,1):"",
      doctoral_degree_pass:filteredEduList?.DOCTORALDEGREE?.year_of_pass?new Date(filteredEduList?.DOCTORALDEGREE?.year_of_pass,0,1):"",
      doctoral_degree: filteredEduList?.DOCTORALDEGREE ? "1" : "2",
      bachelor_degree_course:
        filteredEduList?.BACHELORSDEGREE?.educational_categories_id || "",
      if_any_other_bachelor_degree:
        filteredEduList?.BACHELORSDEGREE?.other_category_value || "",
      doctoral_degree_pg_other:
        filteredEduList?.DOCTORALDEGREE?.other_category_value || "",
      pg_other: filteredEduList?.POSTGRADUATION?.other_category_value || "",
      pg_course: filteredEduList?.POSTGRADUATION?.educational_categories_id,
      doctoral_degree_course:
        filteredEduList?.DOCTORALDEGREE?.educational_categories_id || "",

      linkedin_id: modalEditData?.member_linkedin_profile || "",
      twitter_id: modalEditData?.member_twitter_profile || "",
      instagram: modalEditData?.member_instagram_profile || "",
      any_other_education: new_array_,
    });
    
    setStartDate(
      // moment(filteredEduList?.BACHELORSDEGREE?.year_of_pass).format(
      //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
      // )
      filteredEduList?.BACHELORSDEGREE?.year_of_pass? new Date(filteredEduList?.BACHELORSDEGREE?.year_of_pass,0,1):""
    );
    startDatepg(
      // moment(filteredEduList?.POSTGRADUATION?.year_of_pass).format(
      //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
      // )
      filteredEduList?.POSTGRADUATION?.year_of_pass?new Date(filteredEduList?.POSTGRADUATION?.year_of_pass,0,1):""
    );
    startDatedo(
      // moment(filteredEduList?.DOCTORALDEGREE?.year_of_pass).format(
      //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
      // )
      filteredEduList?.DOCTORALDEGREE?.year_of_pass?new Date(filteredEduList?.DOCTORALDEGREE?.year_of_pass,0,1):""
    );
  } else {
    setEditMode(false);
    setAddModalInitialValues((prev: any) => {
      return { ...prev };
    });
  }
};
export const addOfficeModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function,
  filteredEduList: any,
  seImageView: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);

    setAddModalInitialValues({
      id: modalEditData?.user_id,
      email: modalEditData?.office_email,
      phone_no: modalEditData?.office_phone_no,
      address: modalEditData?.office_address,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues((prev: any) => {
      return { ...prev };
    });
  }
};

export const getEmploymentStatus = (data: any) => {
  return constants?.EMPLOYMENT_STATUS_VIEW?.filter(
    (itm: any) => itm?.value === data?.member?.employment_status
  )?.[0]?.name;
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/staff/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"9299"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"9190"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/office-management/${pageNo}`);
};

export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    navigate("/admin/of");
    setSearchQuery(e?.target?.value);
  } else {
    setSearchQuery("");
  }
};

export const handleFilterEduList = (
  memberProfileData: any,
  setFilteredEduList: any
) => {
  const resultObject: any = {};
  memberProfileData?.educationQualification.forEach((item: any) => {
    const key = item?.degree_name?.replace(/\s+/g, "");
    resultObject[key] = {
      educational_categories_id: item?.educational_categories_id,
      degree_name: item?.degree_name,
      educat_cat_name: item?.educat_cat_name,
      other_category_value: item?.other_category_value,
      year_of_pass: item?.year_of_pass,
    };
  });
  setFilteredEduList(resultObject);
};
