import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { AddOtherfundProps, AddSeatProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddOtherfundMethods/AddOtherfundModalMethods";
import {
  AddOtherInitialValues,
  OtherValidationSchema,
} from "./AddOtherfundMethods/FormikValidation";
import Select from "react-select";

const AddOtherfund = ({
  setIsOpen,
  modalEditData,
  refetch,
  district,
}: AddOtherfundProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddOtherInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={OtherValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
       
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={6}>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.title}
                    name="title"
                    placeholder="Title"
                  />
                  <ErrorMessage name="title" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Start date</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    value={values?.start_date}
                    name="start_date"
                    placeholder="Start date"
                  />
                  <ErrorMessage name="start_date" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>End date</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    value={values?.end_date}
                    name="end_date"
                    placeholder="End date"
                  />
                  <ErrorMessage name="end_date" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Amount</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.amount}
                    name="amount"
                    placeholder="Amount"
                  />
                  <ErrorMessage name="amount" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>District</Label>
                  <Select
                    placeholder="Select District"
                    name="district_id"
                    options={district}
                    value={
                      district?.filter(
                        (itm: any) => itm?.value === values?.district_id
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("district_id", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching district"}
                    noOptionsMessage={() => "Discrict appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="district_id" component={TextError} />
                </Col>

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddOtherfund;
