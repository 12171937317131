function CurrentTab(pathname: any) {
  // Check if the pathname includes a page number
  const hasPageNumber = pathname.match(/\/\w+\/\d+/);

  return ["/admin/dashboard", "/member/dashboard"]?.includes(pathname)
    ? "Dashboard"
    : pathname.startsWith("/admin/doctors") ||
      pathname.startsWith("/admin/doctors/table") ||
      pathname.startsWith("/admin/doctors/card")
    ? "Patient"
    : pathname.startsWith("/admin/office-management/:id")
    ? "Office Management"
    : pathname.startsWith("/admin/member-management")
    ? "Members"
    : pathname.startsWith("/admin/seat-settings")
    ? "Seat Management"
    : pathname.startsWith("/admin/fund-management")
    ? "Fund Management"
    : pathname.startsWith("/admin/accomadation-management")
    ? "Accommodation Management"
    : pathname.startsWith("/admin/accomodation")
    ? "Accommodation Data"
    : pathname.startsWith("/admin/attandance")
    ? "Attendance Management"
    : pathname.startsWith("/admin/magazine")
    ? "Magazine"
    : pathname.startsWith("/admin/blog")
    ? "Blog"
    : pathname.startsWith("/admin/prescription")
    ? "Prescription Management"
    : pathname.startsWith("/admin/reports")
    ? "Reports"
    : pathname.startsWith("/admin/asset-setting")
    ? "Asset Management"
    : pathname.startsWith("/admin/download-settings")
    ? "Downloads"
    : pathname.startsWith("/admin/meetings")
    ? "Meetings"
    : pathname.startsWith("/admin/announcement")
    ? "Announcement"
    : pathname.startsWith("/admin/settings")
    ? "Settings"
    : pathname.startsWith("/admin/roles")
    ? "Role Management"
    : pathname.startsWith("/admin/designation/1")
    ? "Designation Management"
    : pathname.startsWith("/admin/user-settings")
    ? "User Management"
    : pathname.startsWith("/admin/notification-settings")
    ? "Notification Management"
    : pathname.startsWith("/admin/membership-settings")
    ? "Membership Management"
    : pathname.startsWith("/admin/prescription-settings")
    ? "Prescription Management"
    : pathname.startsWith("/admin/crops")
    ? "Crop Management"
    : pathname.startsWith("/admin/pdd")
    ? "PEST/DISEASE/DEFICIENCY"
    : pathname.startsWith("/admin/name-pdd")
    ? "NAME OF PEST/DISEASE/DEFICIENCY"
    : pathname.startsWith("/admin/symptoms")
    ? "Crop Details"
    : pathname.startsWith("/admin/controll-measures")
    ? "Control Measures"
    : pathname.startsWith("/admin/cader-settings")
    ? "Cadre Management"
    : pathname.startsWith("/admin/bank-accounts")
    ? "Bank Accounts"
    : pathname.startsWith("/admin/asset-category-settings")
    ? "Asset Category "
    : pathname.startsWith("/admin/leave-management")
    ? "Leave Management"
    : pathname.startsWith("/admin/my-leave")
    ? "My Leave"
    : pathname.startsWith("/admin/crops")
    ? "Crops Management"
    : pathname.startsWith("/admin/office-categorey")
    ? "Office Category"
    : pathname.startsWith("/admin/asset-settings")
    ? "Asset Management"
    : pathname.startsWith("/admin/other-fund/1")
    ? "Other fund Management"
    : pathname.startsWith("/admin/office-management")
    ? "Office Management"
    : pathname.startsWith("/admin/transfer-request")
    ? "Office Change Request"
    : pathname.startsWith("/admin/mywebsite")
    ? "Website Settings"
    : pathname.startsWith("/admin/getintouch")
    ? "Get In Touch"
    : pathname.startsWith("/admin/membershippending-report")
    ? "Membership Pending Status"
    : pathname.startsWith("/admin/report-prescription")
    ? "Prescription Reports"
    : pathname.startsWith("/admin/membercontribution-report")
    ? "Members Contribution Reports"
    : pathname.startsWith("/admin/memberreport-list")
    ? "Members List Reports"
    : pathname.startsWith("/admin/designation-management")
    ? "Designation Management"
    : pathname.startsWith("/admin/meta/")
    ? "Meta Management"
    : pathname.startsWith("/admin/vacancyreport-list/")
    ? "Vacany Report"
    : //Member
    pathname.startsWith("/member/profile")
    ? "Profile"
    : pathname.startsWith("/member/meta/")
    ? "Meta Management"
    : pathname.startsWith("/member/vacancyreport-list/")
    ? "Vacany Report"
    : pathname.startsWith("/member/magazine")
    ? "Magazine Management"
    : pathname.startsWith("/member/blog")
    ? "Blogs Management"
    : pathname.startsWith("/member/prescriptionmanagement")
    ? "Prescription Management"
    : pathname.startsWith("/member/transfer-request")
    ? "Office Change Request"
    : pathname.startsWith("/member/change-password")
    ? "Change Password"
    : pathname.startsWith("/member/settings")
    ? "Settings"
    : pathname.startsWith("/member/payment")
    ? "Payment Management"
    : pathname.startsWith("/member/accomodation")
    ? "Accomodation Data Management"
    : pathname.startsWith(" /member/notification-settings")
    ? "Notifivation Management"
    : pathname.startsWith("/member/getintouch")
    ? "Get In Touch"
    : pathname.startsWith("/member/membership-settings")
    ? "Membership Payment"
    : pathname.startsWith("/admin/asset-sub-category-settings")
    ? "Asset Subcategory"
    : pathname.startsWith("/associatemember/dashboard")
    ? "Associate Membership"
    : pathname.startsWith("/associativemember/change-password")
    ? "Change Password"
    : pathname.startsWith("/member/other-fund/1")
    ? "Other Fund Management"
    : pathname.startsWith("/member/leave-management")
    ? "Leave Management"
    : pathname.startsWith("/member/my-leave")
    ? "My Leave"
    : pathname.startsWith("/member/cader-settings")
    ? "Cadre Management"
    : pathname.startsWith("/member/designation")
    ? "Designation Management"
    : pathname.startsWith("/member/roles/")
    ? "Role Management"
    : pathname.startsWith("/member/user-settings")
    ? "User Management"
    : pathname.startsWith("/member/notification-settings")
    ? "Notification Management"
    : pathname.startsWith("/member/prescription-settings")
    ? "Prescription Management"
    : pathname.startsWith("/member/office-categorey")
    ? "Office Category Management"
    : pathname.startsWith("/member/asset-category-settings")
    ? "Asset Category Management"
    : pathname.startsWith("/member/crops")
    ? "Crop Management"
    : pathname.startsWith("/member/pdd")
    ? "Pest/Disease/Deficiency  Management"
    : pathname.startsWith("/member/symptoms")
    ? "Crop Symptoms Management"
    : pathname.startsWith("/member/memberreport-list")
    ? "Members List Reports"
    : pathname.startsWith("/member/office-management")
    ? "Office Management"
    : pathname.startsWith("/member/member-management")
    ? "Members Management"
    : pathname.startsWith("/member/fund-management")
    ? "Fund Management"
    : pathname.startsWith("/member/accomadation-management")
    ? "Accomodation Management"
    : pathname.startsWith("/member/reports")
    ? "Reports"
    : pathname.startsWith("/member/membershippending-report")
    ? "Membership Pending Status Report"
    : pathname.startsWith("/member/report-prescription")
    ? "Prescription Report"
    : pathname.startsWith("/member/membercontribution-report")
    ? "Member Contribution Report"
    : pathname.startsWith("/member/download-settings")
    ? "Downloads Management"
    : pathname.startsWith("/member/meetings")
    ? "Meetings Management"
    : pathname.startsWith("/member/announcement")
    ? "Announcement Management"
    : pathname.startsWith("/member/asset-settings")
    ? "Asset Management"
    : pathname.startsWith("/member/attandance")
    ? "Attendance Management"
    : pathname.startsWith("/member/bank-accounts")
    ? "Bank Management"
    : pathname.startsWith("/member/mywebsite")
    ? "My website"
    : pathname.startsWith("/member/bank-accounts")
    ? "Bank Management"
    : pathname.startsWith("/member/mywebsite")
    ? "My website"
    : "";
}

export { CurrentTab };
