import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  AddBlogInitialValues,
  BlogValidationSchema,
} from "./AddBlogsMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddBlogsMethods/AddBlogModalMethods";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { TinyMCE_Token } from "../../../../../Utils/Constants/TinyMCE_Token";
import { Editor } from "@tinymce/tinymce-react";
import Croppedimages from "../../WebsiteSettings/crop/croppedimages";

const AddBlog = ({ setIsOpen, modalEditData, refetch }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(AddBlogInitialValues);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [image_change, setImageChange] = useState(false);
  const [imageView, setImageView] = useState("");
  const [imagefile, setImageFile] = useState("");
  const [croppedImage, setCroppedImage] = useState<string | null>(null);


  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );


    if (modalEditData && modalEditData[0]?.image) {
      setImageView(modalEditData[0]?.image);
      setImageChange(true);
    }
  }, [modalEditData]);

  const create_imageURL = (imgfile: any) => {
    setImageView(URL?.createObjectURL(imgfile));
    setImageChange(true);
  };

  return (
    <Formik
      validationSchema={BlogValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          image_change
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        const handleCrop = (croppedImage: string) => {
          setCroppedImage(croppedImage);
          setFieldValue("blog_document", croppedImage);
        };
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Blog Title</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.blog_name}
                    name="blog_name"
                    placeholder="Blog Title"
                  />
                  <ErrorMessage name="blog_name" component={TextError} />
                </Col>
                <Col md={12}>
                  <Label>Blog Body</Label>
                  <Editor
                    apiKey={TinyMCE_Token}
                    // initialValue={values?.body}
                    value={values?.blog_body}
                    textareaName="body"
                    init={{
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar: [
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | code table help",
                      ],
                    }}
                    onEditorChange={(e: any) => {
                      setFieldValue("blog_body", e);
                    }}
                  />
                  <ErrorMessage name="blog_body" component={TextError} />
                </Col>
                <Col md={12}>
                  <Label>Blog Document</Label>
                  <Input
                    id="cha"
                    type="file"
                    onChange={(e: any) => {
                      const selectedFile = e.target.files[0];
                      setImageFile(e.target.files[0]);
                      create_imageURL(selectedFile);
                      setFieldValue("blog_document", selectedFile);
                    }}
                    name="blog_document"
                    placeholder="Body"
                  />
                  <div className="image-thumbnail-wrap">
                    <img
                      src={
                        image_change === true
                          ? imageView
                          : modalEditData?.[0]?.image
                            ? modalEditData?.[0]?.image
                            : "/images/empty-banner.webp"
                      }
                      alt={modalEditData?.[0]?.banner_image}
                    />
                  </div>

                  <Croppedimages
                    src={
                      imagefile
                        ? URL.createObjectURL(new Blob([imagefile]))
                        : modalEditData?.[0]?.image
                    }
                    onCrop={handleCrop}
                    from_blogs={true}
                  />
                  <ErrorMessage name="blog_document" component={TextError} />
                </Col>


                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddBlog;
