import React, { useEffect, useMemo, useState } from "react";
import {
  List,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";
import { AdminSidebarType, SidebarMenuItemType } from "../../../Type";
import { activateSidebarTab } from "../../../Utils/helpers/ActivateSidebarTabs";
import { MemberSidebarMenuItems } from "../../../Utils/Constants/Sidebar/MemberSidebarData";
import { getMemberProfileData } from "../../../Pages/Private/MemberModule/MemberProfile/MemberProfileMethods";
import { useQuery } from "react-query";
import { AsoociateSidebarMenuItems } from "../../../Utils/Constants/Sidebar/AssociateSidebarData";
import { usePermission } from "../../Permissions/Admin/AdminPermission";

const MemberSidebar = ({ isOpen }: AdminSidebarType) => {
  const pathname = window.location.pathname;
  const { checkPermission } = usePermission();

  const { data: memberProfileData, refetch: memberProfileDataRefetch }: any =
    useQuery([`profile_data`], () => getMemberProfileData(), {
      keepPreviousData: true,
    });

 
  const MemberSidebarMenuItems_data: any = [
    {
      icon: <i className="icon xl icon-profile"></i>,
      label: "Profile",
      path: "/member/profile",
    },
  ];

  return (
    <>
      <Col
        sm={3}
        md={3}
        lg={2}
        className={`fixed ${isOpen ? "hamburger-open" : "hamburger-closed"}`}
      >
        <div className="admin-sidebar">
          <div className="sidebar-item">
            <div className="site-logo-wrap">
              <div className="site-logo-inner">
                <div>
                  {isOpen ? (
                    <img src="/images/site-logo.png" alt="Site Logo" />
                  ) : (
                    <img src="/images/site-logo.png" alt="Site Logo" />
                  )}
                </div>
                <div>
                  <span className="logo-text">AOAOK</span>
                </div>
              </div>
            </div>
            {/* Sidebar links */}

            <List type="unstyled">
              {memberProfileData?.educationQualification?.length === 0 &&
                MemberSidebarMenuItems_data?.map(
                  (element: SidebarMenuItemType, i: number) => {
                    if (
                      !element.permission_key ||
                      checkPermission(element.permission_key)
                    ) {
                      return (
                        <Link to={element?.path} title={element?.label}>
                          <li
                            key={element?.label}
                            className={activateSidebarTab(element, pathname)}
                          >
                            {element?.icon}{" "}
                            <span className="link-label">{element?.label}</span>
                          </li>
                        </Link>
                      );
                    }
                  }
                )}{" "}
              {memberProfileData?.educationQualification?.length !== 0 && (
                <>
                  {memberProfileData?.retirement_status === "1" &&
                  memberProfileData?.member_membership_type === 1
                    ? AsoociateSidebarMenuItems?.map(
                        (element: SidebarMenuItemType, i: number) => {
                          return (
                            <li
                              key={element?.label}
                              className={activateSidebarTab(element, pathname)}
                            >
                              <Link to={element?.path} title={element?.label}>
                                {element?.icon}{" "}
                                <span className="link-label">
                                  {element?.label}
                                </span>
                              </Link>
                            </li>
                          );
                        }
                      )
                    : MemberSidebarMenuItems?.map(
                        (element: SidebarMenuItemType, i: number) => {
                          if (
                            !element.permission_key ||
                            checkPermission(element.permission_key)
                          ) {
                            return (
                              <Link to={element?.path} title={element?.label}>
                                <li
                                  key={element?.label}
                                  className={activateSidebarTab(
                                    element,
                                    pathname
                                  )}
                                >
                                  {element?.icon}{" "}
                                  <span className="link-label">
                                    {element?.label}
                                  </span>
                                </li>
                              </Link>
                            );
                          }
                        }
                      )}
                </>
              )}
            </List>
          </div>
        </div>
      </Col>
      {/* Mobile Sidebar */}

      <Offcanvas className="admin-sidebar-mobile">
        <OffcanvasHeader>
          <div className="site-logo">
            <img src="/images/logo-blue.svg" alt="Site Logo" />
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="sidebar-item">
            {/* Sidebar links */}
            <List type="unstyled">
              {memberProfileData?.retirement_status === "1" &&
              memberProfileData?.member_membership_type === 1
                ? AsoociateSidebarMenuItems?.map(
                    (element: SidebarMenuItemType, i: number) => {
                      return (
                        <li
                          key={element?.label}
                          className={activateSidebarTab(element, pathname)}
                        >
                          <Link to={element?.path} title={element?.label}>
                            {element?.icon}{" "}
                            <span className="link-label">{element?.label}</span>
                          </Link>
                        </li>
                      );
                    }
                  )
                : MemberSidebarMenuItems?.map(
                    (element: SidebarMenuItemType, i: number) => {
                      if (true) {
                        return (
                          <li
                            key={element?.label}
                            className={activateSidebarTab(element, pathname)}
                          >
                            <Link to={element?.path} title={element?.label}>
                              {element?.icon}{" "}
                              <span className="link-label">
                                {element?.label}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                    }
                  )}
            </List>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default MemberSidebar;
