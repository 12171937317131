import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddPDD/AddPDDMethods/AddPDDModalMethods";
import { handleAdminStaffSearch } from "./PDDMethods";
import AddPDD from "./AddPDD";
import {
  getCropCategoryList,
  getCropFullList,
  getOfficeList,
} from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const PDDIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    data: category,
    error: categoryError,
    isLoading: categoryLoading,
    refetch: categoryListRefetch,
  }: any = useQuery(
    [`crop-cat${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getCropCategoryList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const {
    data: crop,
    error: cropError,
    isLoading: cropLoading,
    refetch: cropListRefetch,
  }: any = useQuery([`crop`], () => getCropFullList(), {
    keepPreviousData: true,
  });

  const staffData = useMemo(() => extractStaffData(category), [category]);


  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Prescription Management", link: "/admin/prescription-settings" },
      { label: "PEST/DISEASE/DEFICIENCY", link: "/admin/pdd" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Prescription Management", link: "/member/prescription-settings" },
      { label: "PEST/DISEASE/DEFICIENCY", link: "/member/pdd" },
    ];
  }

  const breadcrumb = generateBreadcrumb(breadcrumbItems);


  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">  
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <div className="button-wrap">
                    {checkPermission("pest:write") ? <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Add
                      PEST/DISEASE/DEFICIENCY
                    </Button> : null}
                  </div>
                </div>
              </div>
            </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="PEST/DISEASE/DEFICIENCY DETAILS "
              tableFields={["Crop", "PEST/DISEASE/DEFICIENCY"]}
              Arraykeys={["crop", "pest_disease_nutrition_def"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("pest:write") ? "edit" : "",
                checkPermission("pest:delete") ? "delete" : "",
              ]}
              isLoading={categoryLoading}
              error={categoryError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("pest:write")) onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  category
                )
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, categoryListRefetch)
              }
              paginationData={category?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </div>
      </Container>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0
            ? "Edit PEST/DISEASE/DEFICIENCY"
            : "Add PEST/DISEASE/DEFICIENCY"
        }
      >
        <AddPDD
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={categoryListRefetch}
          crop={crop}
        />
      </ModalContainer>
    </section>
  );
};

export default PDDIndex;
