import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";
import {
  LeaveStatus,
  LeaveType,
  capitalizeWord,
  convertISODateToDate,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { AddLeaveInitialValues } from "./FormikValidation";
import moment from "moment";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

type AddInitialValues = {
  present_employement_status: string;
  leave_type: string;
  start_date: string;
  end_date: string;
  sanction_document: string;
  other_leave_type: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  data: any,
  image_change: any
) => {
  // Add
  if (editMode) {
    const formData = new FormData();
    formData.append("user", data?.[0]?.user?.id);
    formData.append("leave_type", values?.leave_type);
    formData.append("start_date", values?.start_date);
    formData.append("end_date", values?.end_date);
    if (values?.other_leave_type) {
      formData.append("comment", values?.other_leave_type);
    }
    if (image_change === true) {
      formData.append("leave_sanction", values?.sanction_document);
    }
    formData.append("working_status", values?.present_employement_status);
    axios({
      method: "patch",
      url: `${BaseUrl}/leave/${data?.[0]?.id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 200) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Leave Updated",{toastId:"LeaveUpdate"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);
        if (response?.response?.data) {
          actions.setFieldError(
            "sanction_document",
            response?.response?.data?.message
          );
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"LeaveUpdateerror"});
        }
      });
  }
  // Edit
  else {
    const formData = new FormData();
    formData.append("user", data?.[0]?.user?.id);
    formData.append("leave_type", values?.leave_type);
    formData.append("start_date", values?.start_date);
    formData.append("end_date", values?.end_date);
    if (values?.other_leave_type) {
      formData.append("comment", values?.other_leave_type);
    }
    if (image_change === true) {
      formData.append("leave_sanction", values?.sanction_document);
    }

    formData.append("working_status", values?.present_employement_status);
    axios({
      method: "post",
      url: `${BaseUrl}/leave`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {

        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Leave Added",{toastId:"Leaveadd"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data) {
          actions.setFieldError(
            "sanction_document",
            response?.response?.data?.message
          );
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Leaveadderror"});
        }
      });
  }
};
export const AcceptRejectSubmitHandler = (
  setIsOpen: Function,
  refetch: Function,
  id: any,
  status: any,
  blockVerify: number | undefined,
  from_transfer_req?: boolean,
) => {
  // return null
  let URL = "/leave/status"
  if (from_transfer_req){  
    if(blockVerify === 3){ 
    api
      .patch(
        `/transfer/block-verify/${id}`,
        { status: 1 },
        true
      )
      .then(async function ([success, response]: any) {
        if (response?.success) {
          toast.success("Block Verified",{toastId:"blockverified"});
          setIsOpen(false);
          refetch();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"blockverifiederror"});
        }
      });
  } 
  
  if(blockVerify === 1){
    api
      .patch(
        `/transfer/district-verify/${id}`,
        { status: 1 },
        true
      )
      .then(async function ([success, response]: any) {
        if (response?.success) {
          toast.success("District verified",{toastId:"districtverified"});
          setIsOpen(false);
          refetch();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }

}
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {

  const res = data?.items?.map((item: any) => {
    return {
      id: item?.id,
      end_date: convertISODateToDate(item?.end_date),
      start_date: convertISODateToDate(item?.start_date),
      comment: item?.comment ? item?.comment : "No comment",
      leave_type: LeaveType(item?.leave_type),
      status: LeaveStatus(item?.status),
      status_type: item?.status,
      user_id: item?.user?.id,
      user_name: item?.user?.first_name
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      start_date: moment(modalEditData?.[0]?.start_date).format("YYYY-MM-DD"),
      end_date: moment(modalEditData?.[0]?.end_date).format("YYYY-MM-DD"),
      present_employement_status: modalEditData?.[0]?.working_status,
      sanction_document: modalEditData?.[0]?.sanction_doc,
      leave_type: modalEditData?.[0]?.leave_type?.toString(),
      other_leave_type: modalEditData?.[0]?.comment,
      user_id: modalEditData?.[0]?.user?.id,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddLeaveInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/leave/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"leavedelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/leave-management/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/leave-management/${pageNo}`)
  }
};
