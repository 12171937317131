import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddDownloadInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import axios from "axios";
import { PDFDocument, rgb, degrees } from "pdf-lib";

export const DownloadSubmitHandler = (
  values: any,
  actions: any,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  files: any
) => {
  if (id) {
    api
      .patch(`/downloads/${id}`, { name: values?.category_name }, true)
      .then(async function ([success, response]: any) {
        toast.success("Documents Updated ",{toastId:"Documents_update"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Documents_update_error"});
        }
      });
  } else {
    api
      .post(
        "/downloads",
        {
          name: values?.category_name,
        },
        true
      )
      .then(async function ([success, response]: any) {
        // If the request is successful, display a success toast, close the modal, and trigger a refetch of data
        toast.success("Download Document Added ",{toastId:"Documents_Added"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        // If an error occurs, setSubmitting to false to stop form submission process and handle errors
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          // If validation errors are present in the response data, handle them
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          // If no validation errors, display a generic error toast
          toast.error("Unexpected Error Occurred",{toastId:"Documents_error"});
        }
      });
  }
};

export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      category_name: modalEditData?.[0]?.name,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(setAddModalInitialValues);
  }
};
//table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  data: any
) => {
  setModalEditData([]);
  setModalEditData(
    data?.data?.data?.filter((itm: any) => itm?.id === selectedId)
  );
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteCategoryClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/downloads/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Documents_Deleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Documents_Deleted_error"});
    });
};

export const setCurrentPageDownload = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/download-settings/${pageNo}`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/download-settings/${pageNo}`);
  }
};

const svgToPng = async (svg: string): Promise<Uint8Array> => {
  const svgBlob = new Blob([svg], { type: "image/svg+xml" });
  const svgUrl = URL.createObjectURL(svgBlob);
  const svgImage = new Image();

  return new Promise((resolve) => {
    svgImage.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = svgImage.width;
      canvas.height = svgImage.height;

      context?.drawImage(svgImage, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const result = new Uint8Array(reader.result as ArrayBuffer);
            resolve(result);
          };
          reader.readAsArrayBuffer(blob);
        }
      }, "image/png");
    };

    svgImage.src = svgUrl;
  });
};

const addWatermark = async (
  pdfBuffer: Uint8Array,
  svgContent: string
): Promise<Uint8Array> => {
  const pdfDoc = await PDFDocument.load(pdfBuffer);

  // Convert SVG to PNG
  const watermarkPngBytes = await svgToPng(svgContent);

  // Embed the PNG image into the PDF
  const watermarkImage = await pdfDoc.embedPng(watermarkPngBytes);

  pdfDoc.getPages().forEach((page) => {
    const { width, height } = page.getSize();
    const scale = 1.5; // Adjust the scale factor for both width and height
    const opacity = 0.04; // Adjust the opacity value

    page.drawImage(watermarkImage, {
      x: width / 2 - (watermarkImage.width * scale) / 2,
      y: height / 2 - (watermarkImage.height * scale) / 2,
      width: watermarkImage.width * scale,
      height: watermarkImage.height * scale,
      opacity,
    });
  });

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const handleFileChange = async (
  e: any,
  setFiles: Function,
  setFieldValue: Function,
  index: number
) => {
  if (e?.target?.type === "file") {
    const fileBuffer = await e.target.files?.[0].arrayBuffer();
    const svgFilePath = "/images/site-logo.svg";
    const svgContent = await fetch(svgFilePath).then((response) =>
      response.text()
    );
    const watermarkedBuffer = await addWatermark(
      new Uint8Array(fileBuffer),
      svgContent
    );
    const blob = new Blob([watermarkedBuffer], { type: "application/pdf" });
    let file = new File([blob], "pdf-doc-with-watermark.pdf");

    setFiles((prev: any) => {
      const newArray = [...prev];
      newArray[index] = {
        ...newArray[index],
        pdfname: file,
        original: e.target.files?.[0],
      };
      return newArray;
    });
    setFieldValue(`categories.${index}.pdfname`, "dummy-value");
  }
  if (e?.target?.type === "text") {
    setFiles((prev: any) => {
      const newArray = [...prev];
      newArray[index] = { ...newArray[index], name: e?.target?.value };
      return newArray;
    });
    setFieldValue(`categories.${index}.name`, "dummy-value");
  }
};

export const handleRowRemove = (
  index: number,
  remove: Function,
  setFiles: Function
) => {
  setFiles((prev: any) => {
    const newArray = prev?.filter((itm: any, i: number) => i !== index);
    return newArray;
  });
  remove(index);
};

export const handleRemoveExistingDoc = (id: any, refetch: Function) => {
  api
    .delete(`/accommodafggtrtion/${id}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const extractDownloadData = (data: any) => {
  const res = data?.data?.data?.map((item: any) => {
    const officeAccomodation = item?.OfficeAccomodation || {};
    return {
      id: item?.id,
      category_name: item?.name,
      status: item?.status,
      is_published: item?.is_published,
    };
  });

  return res;
};
