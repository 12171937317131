import axios from "axios";
import { clearData } from "../lib/keystorage";
import { toast } from "react-toastify";
const urlRegex = new RegExp("^https?:\\/\\/\\S+$");
const isUrl = (str: any) => urlRegex.test(str);

class Api {
  url: any;
  constructor() {
    this.url = process.env.REACT_APP_LOCAL_MODE || "";
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (!error?.response?.request?.responseURL.includes("/auth/login")) {
            clearData()
            window.location.reload();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async get(path: string, auth: boolean = false, headers: any = {}) {
    var success = false;
    const response = await axios(this.getApiUrl(path), {
      method: "GET",
      timeout: 20000,
      headers: {
        Accept: "application/json",
        mode: "cors",

        referrerPolicy: "origin",



        
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...(auth
          ? { Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}` }
          : null),
        ...headers,
      },
    });
    if (response) {
      success = true;
      let data = await response.data;
      let status = response.status;
      return [true, data, status];
    } else {
      return [success, null];
    }
  }
  async post(
    path: any,
    data: object = {},
    auth: boolean = false,
    headers: object = {}
  ) {
    var success = false;
    const response = await axios(this.getApiUrl(path), {
      method: "POST",
      headers: {
        mode: "cors",
        referrerPolicy: "origin",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        ...(auth
          ? { Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}` }
          : null),
        "X-Requested-With": "XMLHttpRequest",
        ...headers,
      },
      data: JSON.stringify(data),
    });
    if (response) {
      success = true;
      let data = await response.data;
      let status = response.status;
      return [true, data, status];
    } else {
      return [success, null];
    }
  }
  async delete(
    path: any,
    data: object = {},
    auth: boolean = false,
    headers: object = {}
  ) {
    var success = false;
    const response = await axios(this.getApiUrl(path), {
      method: "DELETE",
      headers: {
        mode: "cors",
        referrerPolicy: "origin",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        ...(auth
          ? { Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}` }
          : null),
        "X-Requested-With": "XMLHttpRequest",
        ...headers,
      },
      data: JSON.stringify(data),
    });
    if (response) {
      success = true;
      let data = await response.data;
      let status = response.status;
      return [true, data, status];
    } else {
      return [success, null];
    }
  }
  async put(
    path: any,
    data: object = {},
    auth: boolean = false,
    headers: object = {}
  ) {
    var success = false;
    const response = await axios(this.getApiUrl(path), {
      method: "PUT",
      headers: {
        mode: "cors",
        referrerPolicy: "origin",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        ...(auth
          ? { Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}` }
          : null),
        "X-Requested-With": "XMLHttpRequest",
        ...headers,
      },
      data: JSON.stringify(data),
    });
    if (response) {
      success = true;
      let data = await response.data;
      let status = response.status;
      return [true, data, status];
    } else {
      return [success, null];
    }
  }
  async patch(
    path: any,
    data: object = {},
    auth: boolean = false,
    headers: any = {},
    timeout = 15000
  ) {
    var success = false;
    const response = await axios(this.getApiUrl(path), {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        mode: "cors",
        referrerPolicy: "origin",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...(auth
          ? { Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}` }
          : null),
        ...headers,
      },
      data: JSON.stringify(data),
    });
    if (response) {
      success = true;
      let data = await response.data;
      let status = response.status;
      return [true, data, status];
    } else {
      return [success, null];
    }
  }
  getApiUrl(path: any) {
    return isUrl(path) ? path : this.url + path;
  }
  async postFormData(
    path: any,
    formData: FormData,
    auth: boolean = false,
    headers: object = {}
  ) {
    var success = false;
    try {
      const response = await axios(this.getApiUrl(path), {
        method: "POST",
        headers: {
          mode: "cors",
          referrerPolicy: "origin",
          "Content-Type": "multipart/form-data", // Set content type to FormData
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          ...(auth
            ? { Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}` }
            : null),
          "X-Requested-With": "XMLHttpRequest",
          ...headers,
        },
        data: formData,
      });

      if (response) {
        success = true;
        let data = await response.data;
        let status = response.status;
        return [true, data, status];
      } else {
        return [success, null];
      }
    } catch (error) {
      console.error("FormData submission error:", error);
      return [success, null];
    }
  }
}
export const api = new Api();
