import * as Yup from "yup";

export const BlogValidationSchema = Yup.object().shape({
  blog_name: Yup.string().required("Field is required"),
  blog_body: Yup.string().required("Field is required"),
  blog_document: Yup.string().required("Field is required"),
});

export const AddBlogInitialValues = {
  blog_name: "",
  blog_body: "",
  blog_document: "",
};
