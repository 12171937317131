// BreadcrumbHelper.js
import React from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem } from "reactstrap"; // Replace with your actual breadcrumb library

export const generateBreadcrumb = (items: any) => {
  return (
    <Breadcrumb>
      {items.map((item: any, index: any) => (
        <BreadcrumbItem key={index} active={index === items.length - 1}>
          {index === items.length - 1 ? (
            item.label
          ) : (
            <Link to={item.link}>{item.label}</Link>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
