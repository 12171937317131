import { Route } from "react-router-dom";
import BrowserRouterProvider from "./BrowserRouterWrap";
import PublicAuthProvider from "./Auth/PublicAuthProvider";
import PrivateAuthProvider from "./Auth/PrivateAuth";
import Login from "../Public/LoginPage/Login";
import RoleProvider from "./Auth/RoleProvider";
import { USER_ROLES } from "../../Utils/AuthenticationConstants";
import ForgotPasswordIndex from "../Public/ForgotPassword";
import ResetPassword from "../Public/ChangePassword";
import AssetCategoryIndex from "../Private/Admin/AssetCategory";
import PrescriptionSettings from "../Private/Admin/PrescriptionSettings";
import ControlmeasuresIndex from "../Private/Admin/PrescriptionSettings/ControlMeasures";
import PDDIndex from "../Private/Admin/PrescriptionSettings/Pest_Disease_Defficency";
import NamePDDIndex from "../Private/Admin/PrescriptionSettings/NamePestDiseaseDefficency";
import SymptomIndex from "../Private/Admin/PrescriptionSettings/Symptoms";
import CropIndex from "../Private/Admin/PrescriptionSettings/Crops";
import DownloadIndex from "../Private/Admin/Download";
import MeetingsIndex from "../Private/Admin/Meetings";
import BlogIndex from "../Private/Admin/Blogs";
import MagazineIndex from "../Private/Admin/Magazine";
import AssetIndex from "../Private/Admin/Assets";
import CadreIndex from "../Private/Admin/Cadre";
import FundIndex from "../Private/Admin/FundManagment";
import MemberShipIndex from "../Private/Admin/MemberShip";
import SeatIndex from "../Private/Admin/Seat";
import NotificationSettings from "../Private/Admin/NotificationsSettings";
import UserIndex from "../Private/Admin/UserManagement";
import DesignationIndex from "../Private/Admin/Designation/Desingationview";
import RoleIndex from "../Private/Admin/Roles";
import PrescriptionIndex from "../Private/Admin/Prescription";
import AccomadationIndex from "../Private/Admin/Accomadation";
import SettingsIndex from "../Private/Admin/Settings";
import MemberIndex from "../Private/Admin/Members";
import OfficeIndex from "../Private/Admin/OfficeManagement";
import AdminDashboard from "../Private/Admin/Dashboard";
import AssetSubCategoryIndex from "../Private/Admin/AssetSubCategory";
import MemberProfileIndex from "../Private/MemberModule/MemberProfile";
import MemberMagazineIndex from "../Private/MemberModule/Magazine";
import MemberBlogIndex from "../Private/MemberModule/Blogs";
import LeaveIndex from "../Private/MemberModule/LeaveMangement";
import TransferIndex from "../Private/MemberModule/TransferRequest";
import MemberDashboard from "../Private/MemberModule/Dashboard";
import ChangePassword from "../Private/MemberModule/ChangePassword";
import MemberSettingsIndex from "../Private/MemberModule/Settings/Settings";
import PaymentIndex from "../Private/MemberModule/Payments";
import AdminLeaveIndex from "../Private/Admin/LeaveMangement";
import ReportIndex from "../Private/Admin/Reports";
import OfficeCategoryIndex from "../Private/Admin/OfficeCategory";
import Register from "../Public/Register/Register";
import MeetingMemberIndex from "../Private/Admin/Meetings/MeetingMemberIndex";
import OtherfundIndex from "../Private/Admin/Otherfund";
import OtherfundMemberIndex from "../Private/Admin/Otherfund/OtherfundView/MemberList";
import AdminTransferRequest from "../Private/Admin/TransferRequest";
import AssociativeMemberDashboard from "../Private/MemberModule/AssociativeMember";
import AssociativeMemberApply from "../Private/MemberModule/AssociativeMember/AssociateMembershipApply";
import WebsiteSettings from "../Private/Admin/WebsiteSettings";
import PaymentSuccess from "../Public/PaymentGateWay/PaymentSuccess";
import PyamentCheckoutPage from "../Public/PaymentGateWay";
import GetIndex from "../Private/Admin/GetInTouch";
import MetaIndex from "../Private/Admin/Meta";
import AccomodationIndex from "../Private/Admin/AccomodationData";
import AttendanceIndex from "../Private/Admin/Attendance";
import BankAccounts from "../Private/Admin/BankAccounts";
import AssociateIndex from "../Private/Admin/AssociateMemberShip";
import OTPVerification from "../Public/ChangePassword/OTP";
import LoginHitMember from "../Private/Admin/Reports/LoginHitReport/LoginHitMemberList";
import AnnouncementIndex from "../Private/Admin/Announcement";
import Announcement from "../Private/Admin/Announcement/AnnouncementIndex";
import PrescriptionMember from "../Private/Admin/Reports/PrescriptionReport/PrescriptionList";
import MemberList from "../Private/Admin/Reports/MemberContributionReport/MemberList";
import MemberListMemberReport from "../Private/Admin/Reports/MemberList Report/MemberReportList";
import DesignationSingleIndex from "../Private/Admin/Designation/Desingationview/Singledesingation";
import DesignationCadreIndex from "../Private/Admin/Designation/Desingationview";
import DesignationSettingIndex from "../Private/Admin/Designation";
import Vacanyreport from "../Private/Admin/Reports/VacancyReport/VacancyList";
import DownloadCategoreyIndex from "../Private/Admin/Download/DownloadCategory";

const RoutesHandler = () => {
  return (
    <BrowserRouterProvider>
      <Route element={<PublicAuthProvider loginStatus={false} />}>
        <Route path="/" element={<Login />} />

        <Route path="/forgot-password" element={<ForgotPasswordIndex />} />
        <Route path="/otp-verification/:token" element={<OTPVerification />} />
        <Route path="/password/reset/:token" element={<ResetPassword />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route
          path="/membership-checkout/:token"
          element={<PyamentCheckoutPage />}
        />
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Route>

      <Route element={<PrivateAuthProvider loginStatus={true} />}>
        {/* Admin  */}
        <Route
          element={<RoleProvider Role={USER_ROLES?.ADMIN} loginStatus={true} />}
        >
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route
            path="/admin/office-management/:currentPage"
            element={<OfficeIndex />}
          />
          <Route
            path="/admin/member-management/:currentPage?/:tab?"
            element={<MemberIndex />}
          />
          <Route
            path="/admin/bank-accounts/:pageNo?"
            element={<BankAccounts />}
          />
          <Route path="/admin/mywebsite" element={<WebsiteSettings />} />
          <Route
            path="/admin/fund-management/:currentPage"
            element={<FundIndex />}
          />
          <Route path="/admin/settings" element={<SettingsIndex />} />
          <Route
            path="/admin/accomadation-management/:currentPage"
            element={<AccomadationIndex />}
          />
          <Route
            path="/admin/prescription/:currentPage"
            element={<PrescriptionIndex />}
          />
          <Route path="/admin/roles/:currentPage" element={<RoleIndex />} />

          <Route
            path="/admin/designation-management/:currentPage?/:tab?/"
            element={<DesignationSettingIndex />}
          />
          <Route
            path="/admin/notification-settings"
            element={<NotificationSettings />}
          />
          <Route
            path="/admin/user-settings/:currentPage"
            element={<UserIndex />}
          />
          <Route
            path="/admin/seat-settings/:currentPage/:officeID/:page_number"
            element={<SeatIndex />}
          />
          <Route
            path="/admin/membership-settings"
            element={<MemberShipIndex />}
          />
          <Route path="/admin/otherfund-settings" element={<FundIndex />} />
          <Route
            path="/admin/cader-settings/:currentPage"
            element={<CadreIndex />}
          />
          <Route
            path="/admin/asset-settings/:currentPage"
            element={<AssetIndex />}
          />
          <Route
            path="/admin/magazine/:currentPage"
            element={<MagazineIndex />}
          />
          <Route path="/admin/blog/:currentPage" element={<BlogIndex />} />
          <Route
            path="/admin/meetings/:currentPage"
            element={<MeetingsIndex />}
          />
          <Route
            path="/admin/download-settings/:currentPage"
            element={<DownloadIndex />}
          />
          <Route path="/admin/crops/:currentPage" element={<CropIndex />} />
          <Route
            path="/admin/symptoms/:currentPage"
            element={<SymptomIndex />}
          />
          <Route path="/admin/name-pdd" element={<NamePDDIndex />} />
          <Route path="/admin/pdd/:currentPage" element={<PDDIndex />} />
          <Route
            path="/admin/controll-measures"
            element={<ControlmeasuresIndex />}
          />
          <Route
            path="/admin/prescription-settings"
            element={<PrescriptionSettings />}
          />
          <Route
            path="/admin/asset-category-settings/:currentPage"
            element={<AssetCategoryIndex />}
          />
          <Route
            path="/admin/asset-sub-category-settings/:id"
            element={<AssetSubCategoryIndex />}
          />
          <Route
            path="/admin/leave-management/:currentPage"
            element={<AdminLeaveIndex />}
          />
           <Route
            path="/admin/my-leave/1"
            element={<LeaveIndex />}
          />
          <Route
            path="/admin/transfer-request/:currentPage?"
            element={<AdminTransferRequest />}
          />
          {/* <Route
            path="/admin/my-transfer-request/:currentPage?"
            element={<TransferIndex />}
          /> */}
          <Route path="/admin/reports" element={<ReportIndex />} />
          <Route
            path="/admin/office-categorey/:currentPage"
            element={<OfficeCategoryIndex />}
          />
          <Route
            path="/admin/meeting-invite/:currentPage/:id"
            element={<MeetingMemberIndex />}
          />
          <Route
            path="/admin/announcement/:currentPage"
            element={<AnnouncementIndex />}
          />
          <Route
            path="/admin/announcement-invite/:currentPage/:id"
            element={<Announcement />}
          />
          <Route
            path="/admin/other-fund/:currentPage"
            element={<OtherfundIndex />}
          />
          <Route
            path="/admin/other-fund-invite/:currentPage/:id"
            element={<OtherfundMemberIndex />}
          />

          <Route path="/admin/meta/:currentPage" element={<MetaIndex />} />

          <Route
            path="/admin/accomodation/:currentPage"
            element={<AccomodationIndex />}
          />

          <Route
            path="/admin/attandance/:currentPage"
            element={<AttendanceIndex />}
          />
          <Route
            path="/admin/associate/:currentPage"
            element={<AssociateIndex />}
          />
          <Route
            path="/admin/membershippending-report/:currentPage"
            element={<LoginHitMember />}
          />

          <Route
            path="/admin/report-prescription/:currentPage"
            element={<PrescriptionMember />}
          />

          <Route
            path="/admin/membercontribution-report/:currentPage"
            element={<MemberList />}
          />

          <Route
            path="/admin/memberlist-report/:currentPage"
            element={<MemberList />}
          />
          {/* <Route
            path="/admin/membership-checkout"
            element={<PaymentCheckout />}
          />
           <Route
            path="/admin/payment-success"
            element={<PaymentSuccess />}
          /> */}
        </Route>
        <Route path="/admin/getintouch/:currentPage" element={<GetIndex />} />

        <Route
          path="/admin/memberreport-list/:currentPage"
          element={<MemberListMemberReport />}
        />
        <Route
          path="/admin/vacancyreport-list/:currentPage"
          element={<Vacanyreport />}
        />

        <Route
          path="/admin/download-category/:id"
          element={<DownloadCategoreyIndex />}
        />

        {/* <Route
          path='/crop'
          element={<GettingStartedExample/>} /> */}

        {/* Member */}
        <Route
          element={
            <RoleProvider Role={USER_ROLES?.MEMBER} loginStatus={true} />
          }
        >
          <Route path="/member/dashboard" element={<MemberDashboard />} />
          <Route path="/member/profile" element={<MemberProfileIndex />} />

          <Route path="/member/blog/:currentPage" element={<BlogIndex />} />
          <Route
            path="/member/prescriptionmanagement/:currentPage"
            element={<PrescriptionIndex />}
          />
          <Route
            path="/member/transfer-request/:currentPage?"
            // element={<TransferIndex />}
            element={<AdminTransferRequest />}
          />
          <Route
            path="/member/mytransfer-request/:currentPage?"
            element={<TransferIndex />}
            // element={<AdminTransferRequest />}
          />
          <Route path="/member/change-password" element={<ChangePassword />} />
          <Route path="/member/settings" element={<MemberSettingsIndex />} />
          <Route
            path="/member/payment/:currentPage"
            element={<PaymentIndex />}
          />
          <Route
            path="/member/accomadation-management/:currentPage"
            element={<AccomadationIndex />}
          />
          <Route
            path="/member/magazine/:currentPage"
            element={<MagazineIndex />}
          />
          <Route
            path="/member/office-management/:currentPage"
            element={<OfficeIndex />}
          />
          <Route
            path="/member/member-management/:currentPage?/:tab?"
            element={<MemberIndex />}
          />
          <Route
            path="/member/seat-settings/:currentPage/:officeID/:page_number"
            element={<SeatIndex />}
          />
          <Route
            path="/member/fund-management/:currentPage"
            element={<FundIndex />}
          />
          <Route path="/member/reports" element={<ReportIndex />} />
          <Route
            path="/member/asset-settings/:currentPage"
            element={<AssetIndex />}
          />
          <Route
            path="/member/download-settings/:currentPage"
            element={<DownloadIndex />}
          />
          <Route
            path="/member/meetings/:currentPage"
            element={<MeetingsIndex />}
          />
          <Route
            path="/member/meeting-invite/:currentPage/:id"
            element={<MeetingMemberIndex />}
          />
          <Route
            path="/member/announcement/:currentPage"
            element={<AnnouncementIndex />}
          />
          <Route
            path="/member/announcement-invite/:currentPage/:id"
            element={<Announcement />}
          />
          <Route
            path="/member/attandance/:currentPage"
            element={<AttendanceIndex />}
          />
          <Route
            path="/member/bank-accounts/:pageNo?"
            element={<BankAccounts />}
          />
          <Route path="/member/mywebsite" element={<WebsiteSettings />} />
          <Route
            path="/member/membershippending-report/:currentPage"
            element={<LoginHitMember />}
          />

          <Route
            path="/member/report-prescription/:currentPage"
            element={<PrescriptionMember />}
          />

          <Route
            path="/member/membercontribution-report/:currentPage"
            element={<MemberList />}
          />
          <Route
            path="/member/other-fund/:currentPage"
            element={<OtherfundIndex />}
          />
          <Route
            path="/member/my-leave/:currentPage"
            element={<LeaveIndex />}
          />
          <Route
            path="/member/leave-management/:currentPage"
            element={<AdminLeaveIndex />}
          />
          <Route
            path="/member/cader-settings/:currentPage"
            element={<CadreIndex />}
          />
          <Route
            path="/member/designation/:currentPage"
            element={<DesignationIndex />}
          />
          <Route
            path="/member/other-fund-invite/:currentPage/:id"
            element={<OtherfundMemberIndex />}
          />
          <Route path="/member/roles/:currentPage" element={<RoleIndex />} />

          <Route
            path="/member/user-settings/:currentPage"
            element={<UserIndex />}
          />

          <Route
            path="/member/accomodation/:currentPage"
            element={<AccomodationIndex />}
          />
          <Route
            path="/member/notification-settings"
            element={<NotificationSettings />}
          />
          <Route
            path="/member/prescription-settings"
            element={<PrescriptionSettings />}
          />
          <Route path="/member/crops/:currentPage" element={<CropIndex />} />
          <Route
            path="/member/symptoms/:currentPage"
            element={<SymptomIndex />}
          />
          <Route path="/member/pdd/:currentPage" element={<PDDIndex />} />

          <Route
            path="/member/getintouch/:currentPage"
            element={<GetIndex />}
          />

          <Route
            path="/member/office-categorey/:currentPage"
            element={<OfficeCategoryIndex />}
          />
          <Route
            path="/member/asset-category-settings/:currentPage"
            element={<AssetCategoryIndex />}
          />
          <Route
            path="/member/asset-sub-category-settings/:id"
            element={<AssetSubCategoryIndex />}
          />

          <Route
            path="/member/membership-settings"
            element={<MemberShipIndex />}
          />
        </Route>
        <Route
          path="/member/memberreport-list/:currentPage"
          element={<MemberListMemberReport />}
        />

        <Route
          element={
            <RoleProvider Role={USER_ROLES?.ASSOCIATIVE} loginStatus={true} />
          }
        >
          <Route
            path="/associatemember/dashboard"
            element={<AssociativeMemberDashboard />}
          />
          <Route
            path="/associativemember/change-password"
            element={<ChangePassword />}
          />
        </Route>
      </Route>
    </BrowserRouterProvider>
  );
};

export default RoutesHandler;
