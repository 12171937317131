import * as Yup from "yup";

export const SeatValidationSchema = Yup.object().shape({
  seat_name: Yup.string().required("Field is required"),
  cadre: Yup.string().required("Field is required").nullable(),
});

export const AddSeatInitialValues = {
  seat_name: "",
  cadre: "",
};
