import * as Yup from "yup";

export const BannerValidationSchema = Yup.object().shape({
  image: Yup.string().required("Feild is required"),
  heading: Yup.string()
    // .required("Field is required")
    .min(10, "Must have more than 10 characters"),

  sub_heading: Yup.string()
    // .required("Field is required")
    .min(10, "Must have more than 10 characters"),

  content: Yup.string()
    // .required("Field is required")
    .min(10, "Must have more than 10 characters"),

  sub_content: Yup.string()
    // .required("Field is required")
    .min(10, "Must have more than 10 characters"),
});

export const AddBannerInitialValues = {
  image: "",
  heading: "",
  sub_heading: "",
  content: "",
  sub_content: "",
};
