import * as Yup from "yup";

export const MeetingsValidationSchema = Yup.object().shape({

  title: Yup.string().required("Field is required"),
  document_upload: Yup.string().required("Field is required"),
});

export const AddMeetingsInitialValues = {

  document_upload: "",
  title: ""

};
