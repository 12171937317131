import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddAssets/AddAssetsMethods/AddAssetModalMethods";
import AddAssets from "./AddAssets";
import {
  getAssetCategoryFullList,
  getAssetList,
  getAssetSubCategoryFullList,
  getaccommodationFullLists,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./AssetMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { api } from "../../../../Api/Api";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const AssetIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sub_cat_id, setSubCatId] = useState();
  const {
    data: asset,
    error: assetError,
    isLoading: assetLoading,
    refetch: assetListRefetch,
  }: any = useQuery(
    [`asset${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getAssetList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const { data: accommodationlist }: any = useQuery(
    [`ofice-full-list`],
    () => getaccommodationFullLists(),
    {
      keepPreviousData: true,
    }
  );

  const { data: category }: any = useQuery(
    [`asset-category`],
    () => getAssetCategoryFullList(),
    {
      keepPreviousData: true,
    }
  );
  const { data: subcategory }: any = useQuery(
    [`asset-category`, sub_cat_id],
    () => (sub_cat_id ? getAssetSubCategoryFullList(sub_cat_id) : ""),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(() => extractStaffData(asset), [asset]);

  const editdata = (id: any) => {
    if (id) {
      api
        .get(`/assets/${id}`, true)
        .then(async function ([success, response]) {
          setModalEditData(response?.data);
          return response;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {checkPermission("asset:write") ? <div className="button-wrap">
                  <Button
                    onClick={() =>
                      modalHandler(
                        setModalEditData,
                        setIsOpen,
                        editdata,
                        setSubCatId
                      )
                    }
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i> Asset
                  </Button>
                </div> : null}
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Asset Details"
              tableFields={["Title", "Building", "Asset Coustodian"]}
              Arraykeys={["title", "office", "user"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("asset:write") ? "edit" : "",
                checkPermission("asset:delete") ? "delete" : "",
              ]}
              isLoading={assetLoading}
              error={assetError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("asset:write")) {
                  editdata(selectedId);
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    modalEditData
                  ); 
                }
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, assetListRefetch)
              }
              paginationData={asset?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Asset" : "Add Asset"}
      >
        <AddAssets
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          modalEditData={modalEditData}
          refetch={assetListRefetch}
          category={category}
          setSubCatId={setSubCatId}
          subcategory={subcategory}
          office={accommodationlist}
        />
      </ModalContainer>
    </section>
  );
};

export default AssetIndex;
