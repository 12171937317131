import { useState, useRef } from "react";
import { Button, Col, Card, CardBody } from "reactstrap";
import { useQuery } from "react-query";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";
import {
    modalHandler
} from "../History/AddHistory/AddHistoryMethods/AddHistoryMethods";
import AddVisionMission from "./AddVisionMission";
import { aboutDeleteClickHandler, aboutStatusClickHandler, onAboutEditClickHandler } from "./AboutUsMethods";
import { getVisionData } from "../../../../../Api/AdminApis";
import VisionPhoto from "../VisionPhoto";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const Vision = () => {
    const myDivRef: any = useRef(null);
    const { checkPermission } = usePermission();
    const [currentPage, setCurrentPage] = useState<string>("1");
    const [isOpen, setIsOpen] = useState(false); // Add doctor modal
    const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
        useState(false);
    const [selectedId, setSelectedId] = useState<string | number>("");

    const {
        data: aboutUsData,
        error: aboutUsError,
        isLoading: aboutUsDataIsLoading,
        refetch: aboutUsDataRefetch,
    }: any = useQuery(
        [`vision${currentPage}`, currentPage],
        () => getVisionData(currentPage),
        { keepPreviousData: true }
    );

    const handleDeleteConfirmation = (selectedId: string | number) => {
        setSelectedId(selectedId);
        setIsDeleteConfirmationOpen(true);
    };

    return (
        <>
            <Col lg={12}>
                <Card className="widget-card">
                    <CardBody className="widget-inner-wrap">
                        <div className="widget-head-outer">
                            <div className="widget-heading" ref={myDivRef}>
                                <h6>We Do & We Respond</h6>
                            </div>
                        </div>
                        {aboutUsData?.data.length < 1 ?  (
                            <div className="button-container">
                                {checkPermission("website:write") ? <Button
                                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                                    color="primary"
                                    className="sm"
                                    outline
                                >
                                    <i className="icon icon-add-green xl"></i> Add
                                </Button> : null}
                            </div>
                        ) : (
                            <>
                                <VisionPhoto />
                                <CardsWithIcon
                                    type={"testimonials"}
                                    data={aboutUsData?.data}
                                    tableTitle="About Us"
                                    Arraykeys={["title", "body"]}
                                    viewType="table"
                                    toURL={"/admin/hh"}
                                        actions={[
                                            checkPermission("website:write") ? "edit" : "",
                                            checkPermission("website:delete") ? "delete" : ""
                                        ]}
                                    isLoading={aboutUsDataIsLoading}
                                    error={aboutUsError}
                                    onEditClick={(selectedId: any) =>
                                        onAboutEditClickHandler(selectedId, setModalEditData, setIsOpen, aboutUsData)
                                    }
                                    onDeleteClick={(selectedId: string | number) =>
                                        handleDeleteConfirmation(selectedId)
                                    }
                                    onStatusClick={(selectedId: string | number, newStatus: any) =>
                                        aboutStatusClickHandler(selectedId, aboutUsDataRefetch, newStatus)
                                    }
                                />
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
            {/* {aboutUsData?.meta?.total !== 0 && (
                <div className="pagination">
                    <CustomPagination
                        totalItems={aboutUsData?.meta?.totalItems}
                        itemsPerPage={aboutUsData?.meta?.itemsPerPage}
                        activePage={aboutUsData?.meta?.currentPage}
                        disableScrollAfterPageChange
                        setCurrentPage={(pageNo: string) => {
                            ScrollToContainerTop(myDivRef);
                            setCurrentPage(pageNo);
                        }}
                    />
                </div>
            )} */}
            <ModalContainer
                isOpen={isOpen}
                setIsopen={setIsOpen}
                title="Add  Vision&Mision"
            >
                <AddVisionMission
                    setIsOpen={setIsOpen}
                    refetch={aboutUsDataRefetch}
                    modalEditData={modalEditData}
                />
            </ModalContainer>

            <DeleteConfirmation
                isOpen={isDeleteConfirmationOpen}
                setIsOpen={setIsDeleteConfirmationOpen}
                onDelete={() => {
                    aboutDeleteClickHandler(selectedId, aboutUsDataRefetch);
                    setIsDeleteConfirmationOpen(false);
                }}
            />
        </>
    );
};

export default Vision;
