import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    if (e?.target?.value?.length > 2) {
      setSearchQuery(e?.target?.value);
      navigate(`/admin/prescription/1`);
    } else {
      setSearchQuery("");
    }
  } else {
    if (e?.target?.value?.length > 2) {
      setSearchQuery(e?.target?.value);
      navigate(`/member/prescription/1`);
    } else {
      setSearchQuery("");
    }
  }
}
// export const handleAdminStaffSearch = (
//   e: any,
//   setSearchQuery: Function,
//   navigate: Function
// ) => {
//   if (e?.target?.value?.length > 2) {
//     setSearchQuery(e?.target?.value);
//     navigate(`/admin/prescription/1`);
//   } else {
//     setSearchQuery("");
//   }
// };
export const downloadPDF = (contentId: any) => {
  const input: any = document.getElementById(contentId);
  html2canvas(input)
    .then((canvas) => {
      const imgData: any = canvas.toDataURL("image/png");
      const pdf: any = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("download.pdf");
    })
    .catch((error) => {
      console.error("Error during capture:", error);
    });
};
