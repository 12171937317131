
import { useMemo, useState } from "react";
import {
  Container,
  Row,
  Button,
  InputGroup,
  Input,
  Col,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AddOffice from "./AddOffice";
import {
  StatusClickHandler,
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddOffice/AddOfficeMethods/AddOfficeModalMethods";
import {
  getBlock,
  getCadreFullList,
  getDistrict,
  getOfficeList,
  getOfficeSeat,
  getState,
} from "../../../../Api/AdminApis";
import { api } from "../../../../Api/Api";
import { handleAdminStaffSearch } from "./OfficeMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { useSearch } from "../../../../Context/ContextProvider";
import Select from "react-select";

const OfficeIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { searchQuery, setSearchQuery } = useSearch();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");
  const { setPermissions, checkPermission } = usePermission();
  const [value, setValue] = useState({
    district_id: "",
    block_id: "",
    panachayth_id: "",
    cadre: "",
  });
  const handleChange = (e: any, feild_name: any) => {
    if (feild_name === "district") {
      setValue({
        ...value,
        district_id: e,
      });
    }
    if (feild_name === "block") {
      setValue({
        ...value,
        block_id: e,
      });
    }
    if (feild_name === "cadre") {
      setValue({
        ...value,
        cadre: e,
      });
    }
  };

  const {
    data: office,
    error: officeError,
    isLoading: officeLoading,
    refetch: officeListRefetch,
  }: any = useQuery(
    [
      `office${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      value?.district_id,
      value?.block_id,
    ],
    () =>
      getOfficeList(
        currentPage,
        searchQuery,
        value?.district_id,
        value?.block_id
      ),
    { keepPreviousData: true }
  );

  const {
    data: officecount,
    error: officecountError,
    isLoading: officecountLoading,
    refetch: officecountListRefetch,
  }: any = useQuery(
    [
      `office${currentPage + searchQuery}`,
      value?.district_id,
      value?.cadre,
      value?.block_id,
    ],
    () => getOfficeSeat(value?.district_id, value?.cadre, value?.block_id),
    { keepPreviousData: true }
  );

  const { data: stateList }: any = useQuery([`state`], () => getState(), {
    keepPreviousData: true,
  });

  const {
    data: districtList,
    error: districError,
    isLoading: districtLoading,
    refetch: districtListRefetch,
  }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });

  const officeData = useMemo(
    () => extractStaffData(office, currentPage, 10),
    [office]
  );

  const { data: block }: any = useQuery(
    [`block`, value.district_id],
    () => (value.district_id ? getBlock(value.district_id) : ""),
    {
      keepPreviousData: true,
    }
  );

  const editdata = (id: any) => {
    api
      .get(`/office/${id}`, true)
      .then(async function ([success, response]) {
        if (response && response.data) {
          const dataArray = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setModalEditData(dataArray[0]);
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data: cadre }: any = useQuery([`cadre`], () => getCadreFullList(), {
    keepPreviousData: true,
  });

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    value={searchValue}
                    onChange={(e) =>
                      handleAdminStaffSearch(
                        e,
                        setSearchQuery,
                        navigate,
                        setSearchValue
                      )
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>

            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={districtList}
                value={
                  districtList?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) =>
                 handleChange(e?.value, "district")
                }
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Block"
                options={Array.isArray(block) ? block : []}
                value={
                  Array.isArray(block)
                    ? block?.filter(
                        (itm: any) => itm?.value === value?.block_id
                      ) || ""
                    : ""
                }
                onChange={(e: any) => handleChange(e?.value, "block")}
                name="block"
                isLoading={false}
                loadingMessage={() => "Fetching Block"}
                noOptionsMessage={() => "Block appears here"}
                isSearchable={true}
                isClearable
              />
            </div>

            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Cadre"
                options={Array.isArray(cadre) ? cadre : []}
                value={
                  Array.isArray(cadre)
                    ? cadre?.filter(
                        (itm: any) => itm?.value === value?.cadre
                      ) || ""
                    : ""
                }
                onChange={(e: any) => handleChange(e?.value, "cadre")}
                name="cadre"
                isLoading={false}
                loadingMessage={() => "Fetching cadre"}
                noOptionsMessage={() => "cadre appears here"}
                isSearchable={true}
                isClearable
              />
            </div>

            <div className="right-side-wrap">
              {checkPermission("office:write") ? (
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Office
                  </Button>
                </div>
              ) : null}
              <Button
                outline
                color="primary"
                className="sm"
                onClick={() => {
                  setValue({
                    district_id: "",
                    panachayth_id: "",
                    block_id: "",
                    cadre: "",
                  });
                  setSearchQuery("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
          <Col md={12}>
            <Row className="my-3 gy-3">
              <Col md={3} sm={12}>
                <div className="block-wrap">
                  <h5>Office Count</h5>
                  <p>{officecount?.data?.office_count}</p>
                </div>
              </Col>
              <Col md={3} sm={12}>
                <div className="block-wrap">
                  <h5>Total Seat Count</h5>
                  <p>{officecount?.data?.total_seat_count}</p>
                </div>
              </Col>
              <Col md={3} sm={12}>
                <div className="block-wrap">
                  <h5>Seat Mapped</h5>
                  <p>{officecount?.data?.occupied_seat_count}</p>
                </div>
              </Col>

              <Col md={3} sm={12}>
                <div className="block-wrap">
                  <h5>Remaining Seat Count</h5>
                  <p>{officecount?.data?.remaining_seat_count}</p>
                </div>
              </Col>
            </Row>
          </Col>

          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={officeData}
              tableTitle="Office Details"
              tableFields={[
                "Office Category",
                "Office Name",
                "Block",
                "Total Count",
                "Seat Mapped",
                // "Address",
                // "Pincode",
                // "Phone",
              ]}
              Arraykeys={[
                "office_category",
                "name",
                "block",
                "total_seat_count",
                "occupied_seat_count",
                // "address",
                // "pincode",
                // "phone_no",
              ]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("office:write") ? "edit" : "",
                checkPermission("office:delete") ? "delete" : "",
              ]}
              // actions={["edit", "delete"]}
              isLoading={officeLoading}
              error={officeError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("office:write")) {
                  editdata(selectedId);
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    modalEditData,
                    officeListRefetch
                  );
                }
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, officeListRefetch)
              }
              paginationData={office?.meta}
              setCurrentPage={(pageNo: string) => {
                setCurrentPageStaff(navigate, pageNo, pathname);
              }}
              from_office={"office"}
              page_number={currentPage}
              searchQuery={searchQuery}
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(selectedId, officeListRefetch, newStatus)
              }
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Office" : "Add Office"}
      >
        <AddOffice
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={officeListRefetch}
          stateList={stateList}
          districtList={districtList}
        />
      </ModalContainer>
    </section>
  );
};

export default OfficeIndex;
