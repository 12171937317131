import { ErrorMessage, Formik } from 'formik';
import { Col, Form, Input, Label, Row } from 'reactstrap';
import CustomButton from '../../../../../Components/CustomElements/FormElements/CustomButton';
import TextError from '../../../../../Components/FormikError/TextError';
import { addBankAccountsInitialValue, bankAccountValidationSchema, getBankDetails, handleBankAccountDelete, handleBankAccountSubmit, handleReInitializeValues, resetBankDetails } from '../BankAccountsMethods';
import { useEffect, useState } from 'react';
import { usePermission } from '../../../../../Components/Permissions/Admin/AdminPermission';

const AddBankAccountForm = ({ editData, editable, refetch }: any) => {
    const { checkPermission } = usePermission();
    const [initialValues, setInitialValues] = useState(addBankAccountsInitialValue)
    const [editMode, setEditMode] = useState(false)
    const [editForm, setEditForm] = useState(editable)

    useEffect(() => {
        handleReInitializeValues(setInitialValues, editData, setEditMode)
    }, [editData])

    return (
        <Formik
            validationSchema={bankAccountValidationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, actions) => handleBankAccountSubmit(values, actions, refetch, editMode, setEditForm)}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                setFieldValue,
                setFieldError,
                handleBlur,
            }) => {

                return (
                    <div className="form-wrap bank-accounts-wrap">
                        <Form onSubmit={handleSubmit}>
                            {!editable && 
                            <div className="icon-container">
                                    {checkPermission('bank:write') ? <span onClick={() => setEditForm(!editForm)} >
                                    {editForm ?
                                        <i title="Close" className="icon xl icon-close"></i>
                                        :
                                        <i title="Edit" className="icon icon-edit xl"></i>}
                                    </span> : null}
                                    {checkPermission('bank:delete') ? <span onClick={() => handleBankAccountDelete(editData?.id, refetch)}>
                                    <i title="Delete" className="icon icon-delete xl"></i>
                                    </span> : null}
                            </div>}
                            <Row className="gy-4">
                                <Col lg={4}>
                                    <Label>Account Number</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.account_no}
                                        name="account_no"
                                        placeholder="Account Number"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="account_no"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>IFSC Code</Label>
                                    <Input
                                        type="text"
                                        onChange={(e: any) => {
                                            resetBankDetails(setFieldValue)
                                            getBankDetails(e?.target?.value, setFieldValue, setFieldError)
                                            handleBlur(e)
                                            handleChange(e)
                                        }}
                                        value={values?.ifsc}
                                        name="ifsc"
                                        placeholder="IFSC Code"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="ifsc"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>Bank Name</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.bank_name}
                                        name="bank_name"
                                        placeholder="Bank Name"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="bank_name"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>Branch</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.branch}
                                        name="branch"
                                        placeholder="Branch Name"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="branch"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>City</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.city}
                                        name="city"
                                        placeholder="City"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="city"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>District</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.district}
                                        name="district"
                                        placeholder="District"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="district"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>State</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.state}
                                        name="state"
                                        placeholder="State"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="state"
                                        component={TextError}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>Amount</Label>
                                    <Input
                                        type="text"
                                        onChange={handleChange}
                                        value={values?.amount}
                                        name="amount"
                                        placeholder="Amount"
                                        min={1}
                                        readOnly={!editForm}
                                    />
                                    <ErrorMessage
                                        name="amount"
                                        component={TextError}
                                    />
                                </Col>

                                <Col lg={12}>
                                    {editForm ? <div className="text-end">
                                        <CustomButton
                                            isLoading={isSubmitting}
                                            label="Submit"
                                            color="primary"
                                            className="sm submit-btn"
                                        />
                                    </div> : null}
                                </Col>
                            </Row>
                        </Form>
                        <hr />
                    </div>
                );
            }}
        </Formik>
    )
}

export default AddBankAccountForm
