import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddMemberinitialValues } from "../../../Members/AddMembers/AddMemberMethods/FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";

type AddMbershipInitialValues = {
  start_date: string;
  amount: any;
  end_date: string;
  other_share: any;
  district_share: any;
  state_share: any;
};
type AddOtherfundInitialValues = {
  start_date: string;
  amount: string;
  end_date: string;
  members_type: string;
};
type AddAssoMbershipInitialValues = {
  start_date: string;
  amount: any;
  end_date: string;
  other_share: any;
  state_share: any;
};

export const MembershipSubmitHandler = (
  values: AddMbershipInitialValues,
  actions: FormikHelpers<AddMbershipInitialValues>,
  setIsOpen: Function,
  id: any
) => {
  // Add
  if (id) {
    api
      .patch(
        `/membership-settings/${id}`,
        {
          start_date: values?.start_date,
          end_date: values?.end_date ? values?.end_date : null,
          state_share: values?.state_share,
          district_share: values?.district_share,
          total_amount: values?.amount,
          other_share: values?.other_share,
          membership_type: 1,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Membership Added");
        actions?.setSubmitting(false);
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.message) {
          actions.setFieldError(
            "amount",
            err?.response?.data?.message[0]?.total_amount
          );
          // setValidationErrors(err?.response?.data?.message, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  } else {
    api
      .post(
        "/membership-settings",
        {
          start_date: values?.start_date,
          end_date: values?.end_date,
          state_share: values?.state_share,
          district_share: values?.district_share,
          total_amount: values?.amount,
          other_share: values?.other_share,
          membership_type: 1,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Membership updated");
        actions?.setSubmitting(false);
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};
export const AssociativeMembershipSubmitHandler = (
  values: AddAssoMbershipInitialValues,
  actions: FormikHelpers<AddAssoMbershipInitialValues>,
  setIsOpen: Function,
  id: any
) => {
  // Add
  if (id) {
    api
      .patch(
        `/membership-settings/${id}`,
        {
          start_date: values?.start_date,
          end_date: values?.end_date,
          state_share: values?.state_share,
          total_amount: values?.amount,
          other_share: values?.other_share,
          membership_type: 2,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Assosciate Membership Updated");
        actions?.setSubmitting(false);
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.message) {
          actions.setFieldError(
            "amount",
            err?.response?.data?.message[0]?.total_amount
          );
          // setValidationErrors(err?.response?.data?.message, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  } else {
    api
      .post(
        "/membership-settings",
        {
          start_date: values?.start_date,
          end_date: values?.end_date,
          state_share: values?.state_share,
          total_amount: values?.amount,
          other_share: values?.other_share,
          membership_type: 2,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Assosciate Membership Added");
        actions?.setSubmitting(false);
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.message) {
          actions.setFieldError(
            "amount",
            err?.response?.data?.message[0]?.total_amount
          );
          // setValidationErrors(err?.response?.data?.message, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};
export const MembershipStatusSubmitHandler = (
  values: AddMbershipInitialValues,
  actions: FormikHelpers<AddMbershipInitialValues>,
  setIsOpen: Function
) => {
  // Add

  api
    .post("/staff", values, true)
    .then(async function ([success, response]: any) {
      toast.success("Staff Added ");
      setIsOpen(false);
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};

export const OtherFundSubmitHandler = (
  values: AddOtherfundInitialValues,
  actions: FormikHelpers<AddOtherfundInitialValues>,
  setIsOpen: Function
) => {
  // Add

  api
    .post("/staff", values, true)
    .then(async function ([success, response]: any) {
      toast.success("Staff Added ");
      setIsOpen(false);
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};
// extract Departmentdata for table
export const extractMmebershipData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      stat_date: item?.stat_date,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      start_date: modalEditData?.[0]?.start_date,
      amount: modalEditData?.[0]?.total_amount,
      end_date: modalEditData?.[0]?.end_date
        ? modalEditData?.[0]?.end_date
        : "",
      other_share: modalEditData?.[0]?.other_share,
      district_share: modalEditData?.[0]?.district_share,
      state_share: modalEditData?.[0]?.state_share,
    });
  } else {
    setAddModalInitialValues(AddMemberinitialValues);
  }
};
export const addAssociativeModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      start_date: modalEditData?.[0]?.start_date,
      amount: modalEditData?.[0]?.total_amount,
      end_date: modalEditData?.[0]?.end_date,
      other_share: modalEditData?.[0]?.other_share,
      district_share: modalEditData?.[0]?.district_share,
      state_share: modalEditData?.[0]?.state_share,
    });
  } else {
    setAddModalInitialValues(AddMemberinitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/staff/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/office-management/${pageNo}`);
};
