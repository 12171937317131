import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";
import {
  LeaveStatus,
  LeaveType,
  convertISODateToDate,
} from "../../../../../../Utils/helpers/helpers";
import { AddLeaveInitialValues } from "./FormikValidation";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import moment from "moment";

type AddInitialValues = {
  present_employement_status: string;
  leave_type: string;
  start_date: string;
  end_date: string;
  sanction_document: string;
  other_leave_type: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  image_change: any
) => {
  // Add
  if (editMode) {
    const formData = new FormData();
    // formData.append("user", "2");
    formData.append("leave_type", values?.leave_type);
    formData.append("start_date", values?.start_date);
    formData.append("end_date", values?.end_date);
    if (values?.other_leave_type) {
      formData.append("comment", values?.other_leave_type);
    }
    if (image_change === true) {
      formData.append("leave_sanction", values?.sanction_document);
    }

    formData.append("working_status", values?.present_employement_status);

    axios({
      method: "post",
      url: `${BaseUrl}/leave/me`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Leave Updated",{toastId:"LeaveUpdated"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data) {
          actions.setFieldError(
            "sanction_document",
            response?.response?.data?.message
          );
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"LeaveUpdatederror"});
        }
      });
  }
  // Edit
  else {
    const formData = new FormData();
    // formData.append("user", "2");
    formData.append("leave_type", values?.leave_type);
    formData.append("start_date", values?.start_date);
    formData.append("end_date", values?.end_date);
    if (values?.other_leave_type) {
      formData.append("comment", values?.other_leave_type);
    }
    if (image_change === true) {
      formData.append("leave_sanction", values?.sanction_document);
    }

    formData.append("working_status", values?.present_employement_status);
    axios({
      method: "post",
      url: `${BaseUrl}/leave/me`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status === 201) {
          actions?.setSubmitting(false);
          setIsOpen(false);
          toast.success("Leave Added",{toastId:"LeaveAdd"});
          refetch();
        }
      })
      .catch(function (response) {
        actions?.setSubmitting(false);

        if (response?.response?.data) {
          actions.setFieldError(
            "sanction_document",
            response?.response?.data?.message
          );
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Leaveerror"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      end_date: convertISODateToDate(item?.end_date),
      start_date: convertISODateToDate(item?.start_date),
      comment: item?.comment ? item?.comment : "No comment",
      leave_type: LeaveType(item?.leave_type),
      status: LeaveStatus(item?.status),
     
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.[0]?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      start_date: moment(modalEditData?.[0]?.start_date).format("YYYY-MM-DD"),
      end_date: moment(modalEditData?.[0]?.end_date).format("YYYY-MM-DD"),
      present_employement_status: modalEditData?.[0]?.working_status,
      sanction_document: modalEditData?.[0]?.sanction_doc,
      leave_type: modalEditData?.[0]?.leave_type?.toString(),
      other_leave_type: modalEditData?.[0]?.comment,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddLeaveInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/leave/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Leavedelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Leavedeleteerror"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {

  navigate(`/prescriptionmanagement/${pageNo}`);
};
