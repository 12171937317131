import { useMemo, useState } from "react";
import { Container, Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const SettingsIndex = () => {
  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="settings-menu-wrap">
            <div>
              <h6>Settings</h6>
            </div>
            <Row className="my-1 gy-4 justify-content-center">
              <Col md={3}>
                <Link to="/admin/roles/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-profile-grey"></i>
                      <p>Role Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/cader-settings/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-cadre"></i>
                      <p>Cadre Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/designation-management/1/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-designation-grey"></i>
                      <p>Designation Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/user-settings/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-user-grey"></i>
                      <p>User Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/notification-settings">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-notification-grey"></i>
                      <p>Notification Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/prescription-settings">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-prescription-grey"></i>
                      <p>Prescription Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/asset-category-settings/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-asset-grey"></i>
                      <p>Asset Category Settings</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/leave-management/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-leave-grey"></i>
                      <p>Leave Management</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/office-categorey/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-office-grey"></i>
                      <p>Office Category Settings</p>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3}>
                <Link to="/admin/membership-settings">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-membership"></i>
                      <p className="mb-0">Membership Management</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/admin/other-fund/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-fund-grey"></i>
                      <p className="mb-0">Other fund Management</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/admin/getintouch/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-get-in-touch"></i>
                      <p className="mb-0">Get In Touch</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/admin/meta/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-meta"></i>
                      <p className="mb-0"> Meta</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/admin/accomodation/1">
                  <div className="settings-menu-outer">
                    <div>
                      <i className="icon xxl icon-accomodation-data"></i>
                      <p className="mb-0"> Accomodation Data</p>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default SettingsIndex;
