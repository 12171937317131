import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddUser from "./AddUser";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddUser/AddUserMethods/AddUserModalMethods";
import {
  getDummyList,
  getRoleFullList,
  getUserList,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./UserMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const UserIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [data, setData] = useState<any>([]);

  const {
    data: user,
    error: userError,
    isLoading: userLoading,
    refetch: userListRefetch,
  }: any = useQuery(
    [`user-list${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getUserList(currentPage, searchQuery),
    { keepPreviousData: true }
  );
  const { data: role }: any = useQuery([`role-list`], () => getRoleFullList(), {
    keepPreviousData: true,
  });
  const userData = useMemo(() => extractStaffData(user), [user]);

  const onClickCheckBox = (selectedId: any, refetch: any, index: any) => {
    var new_data = [...user?.data];
    new_data[index].isCheck = !new_data[index]?.isCheck;

    let checkValues = new_data?.filter((item) => item?.isCheck == true);

    let only_ids: any = [];
    checkValues?.map((item: any) => {
      only_ids?.push(item?.user?.id);
    });

    setData(only_ids);
  };

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "User Management", link: "/admin/user-settings" },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "User Management", link: "/member/user-settings/1" },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("user:write") ? (
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                      disabled={data?.length === 0 ? true : false}
                    >
                      <i className="icon icon-add-new xl"></i>Role
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={userData}
              tableTitle="User Details"
              tableFields={["User name", "PEN number", "Role"]}
              Arraykeys={["user", "pen_no", "role"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission("user:write") ? "edit" : "",
                // checkPermission("user:delete") ? "delete" : "",
              ]}
              isLoading={userLoading}
              error={userError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("user:write"))
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    user
                  );
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, userListRefetch)
              }
              paginationData={user?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
              form_={"user"}
              onClickCheck={(selectedId: string | number, i: any) =>
                onClickCheckBox(selectedId, userListRefetch, i)
              }
              from_user_managemet={true}
              Refetch={userListRefetch}
            />
          </div>
          <div className="right-side-wrap">
            <div className="filter-wrap">
              <div className="button-wrap"></div>
            </div>
          </div>
        </div>
      </Container>

      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title={"Add User"}>
        <AddUser
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={userListRefetch}
          data={data}
          role={role}
          setData={setData}
        />
      </ModalContainer>
    </section>
  );
};

export default UserIndex;
