import { useState } from "react";
import { Badge, Button, Input, Table } from "reactstrap";
import { CustomTableType } from "../../../Type";

import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  ActiveRevokeStatusStyle,
  FundStatus,
  LeaveStatusStyle,
  TransferRequestStyle,
  getDownloadStatus,
} from "../../../Utils/helpers/helpers";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import ModalContainer from "../../Containers/ModalContainer/ModalContainer";
import DeleteAlertModal from "../../Containers/AlertModal/DeleteAlertModal";
import PreviewModal from "../../../Pages/Private/Admin/Prescription/PrescriptionView/Preview";
import VerifyMember from "../../../Pages/Private/Admin/Members/MemberView/MemberVerifyModal";
import SendLoginModal from "../../../Pages/Private/Admin/Members/MemberView/SendLoginModal";
import { downloadPrescription } from "../../../Pages/Private/Admin/Prescription/AddPrescription/AddPrescriptionMethods/AddPrescriptionsModalMethods";
import AddAssociate from "../../../Pages/Private/Admin/AssociateMemberShip/AddAssociate";
import { usePermission } from "../../Permissions/Admin/AdminPermission";
import PaymentLinkModal from "../../../Pages/Private/Admin/Members/AddMembers/PaymentLinkLogin";
import ActiveAlertModal from "../../../Pages/Private/Admin/UserManagement/UserView/ActiveAlertModal";
import { api } from "../../../Api/Api";
import BlogAlertModal from "../../Containers/AlertModal/BlogVerifyModal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import TransferRejectModal from "../../Containers/AlertModal/TransferRejectModal";
import { transferGetOffice } from "../../../Api/AdminApis";
import { useQuery } from "react-query";
import Transfer_Modal from "../../Containers/AlertModal/TransferModal";
// import { getAllSubSubcategory } from "../../../Pages/Private/Admin/Download/AddDownload/AddSubDownloadMethods/AddDownloadModalMethods";

const CustomTable = ({
  data,
  tableFields,
  Arraykeys,
  actions,
  isLoading,
  error,
  onEditClick,
  onDeleteClick,
  url,
  meta,
  Refetch,
  ActionsLabel = "Actions",
  form_,
  onClickCheck,
  from_office,
  from_cat,
  from_leave,
  from_pre,
  from_user_managemet,
  from_member,
  onPaymentClick,
  from_meeting,
  from_fund,
  activePage,
  itemsPerPage,
  from_otherfund,
  from_magazine,
  from_transfer_req = false,
  from_getintouch,
  from_downloads,
  handleDownloadPublishClick,
  page_number,
  searchQuery,
  from_associtive,
  from_blog,
  from_attendance,
  from_associate_request,
  from_anno,
  from_report,
  from_abouttoretire,
  onStatusClick,
  from_des,
  from_office_cate,
  from_categorydownloads,
  download_cat_id,
  from_login_report,
  from_my_transfer_req,
}: CustomTableType) => {
  const [openMoadl, setModal] = useState(false);
  const [delete_id, setDeleteId] = useState();
  const [status, setStatus] = useState();
  const [meta_status, setMetaStatus] = useState();
  const [previe_modal, setPreviewModal] = useState(false);
  const [Blog_modal, setBlogModal] = useState(false);
  const [verify_modal, setVerifyModal] = useState(false);
  const [mmeber_verifiction_type, setMemberVerifiavtionType] = useState<any>();
  const [transfer_verifiction_type, setTransferVerifiavtionType] =
    useState<any>();
  const [send_modal, setSendModal] = useState(false);
  const [preview_id, setPreview] = useState();
  const [associate_data, setAssociateAdata] = useState([]);
  const [types, setType] = useState<any>();
  const [user_id, setUserId] = useState();
  const [associate_open, setAssociateOpen] = useState(false);
  const [payment_modal, setPaymentModal] = useState(false);
  const [paymnet_login_id, setPaymentLoginid] = useState();
  const [active_revoke, setActiveRevoke] = useState(false);
  const navigate = useNavigate();
  const { checkPermission } = usePermission();
  const { pathname } = useLocation();
  const [sub__data, setSubData] = useState([]);
  const [blog_verify, setBlogVerify] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [blockVerify, setBlockVerify] = useState<number | undefined>(undefined);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectId, setRejectId] = useState();
  const [trasnferModal, setTransferModal] = useState(false);
  const [seat, setSeat] = useState<any>();
  const [district, setDistrict] = useState<any>();
  const [block, setBlock] = useState<any>();
  const [office, setOffice] = useState<any>();
  const [cadre, setCadre] = useState<any>();
  const [designation, setDesignation] = useState<any>();

  const delete_Confirm = async (
    id: any,
    status: any,
    block?: any,
    district?: any,
    office_id?: any,
    type?: any,
    user_id?: any,
    block_verify?: number
  ) => {
    {
      setModal(true);
      setDeleteId(id);
      setStatus(status);
      setMetaStatus(status);
      setType(type);
      setUserId(user_id);
      setBlockVerify(block_verify);
    }
  };

  const transfer_Modal = async (
id: any, status: any, block_id?: any, district_id?: any, office_id?: any, type?: any, user_id?: any, block_verify?: number, designation_id?: number, cadre_id?: any) => {
    try {
      
      const [response, data] = await api.get(
        `/transfer/office-list?district_id=${district_id}&block_id=${block_id}&office_id=${office_id}`,
        true
      );
      setTransferModal(true);
      setDeleteId(id);
      setStatus(status);
      setMetaStatus(status);
      setType(type);
      setUserId(user_id);
      setBlockVerify(block_verify);
      setOffice(office_id);
      setBlock(block_id);
      setDistrict(district_id);
      setDesignation(designation_id);
      setCadre(cadre_id);

      const availableSeats = Number(data?.data[0]?.available_seats);
      setSeat(availableSeats > 0 ? "available" : "notavailable");
    } catch (error) {
      console.error("Error fetching transfer modal data:", error);
    }
  };

  const transfer_reject = (id: any) => {
    setRejectModal(true);
    setRejectId(id);
  };
  const Blog_Confirm = (id: any, status: any, type?: any, user_id?: any) => {
    setBlogModal(true);
    setDeleteId(id);
    setStatus(status);
    setMetaStatus(status);
    setType(type);
    setUserId(user_id);
  };
  const getAllSubSubcategory = async (main_id: any, id: string) => {
    const [, datas] = await api.get(
      `/download-categories/${main_id}?parent_id=${id}`,
      true
    );
    const idToFind = id;
    const result = findDataById(idToFind);
    const tableData = datas?.map((item: any) => {
      return {
        value: item?.id,
        label: item?.name,
        id: id,
      };
    });
    appendSubcategory(id, tableData);

    setSubData(tableData);
  };

  function findDataById(id: any) {
    const result: any = [];

    // Iterate through the outer array
    data?.map((obj: any) => {
      // Iterate through each inner array
      obj?.subcategories.map((innerObj: any) => {
        // Check if the ID matches
        if (innerObj.id === id) {
          // Push the matching object to the result array
          result.push(innerObj);
        }
      });
    });

    return result;
  }
  function appendSubcategory(itemId: any, newSubcategory: any) {
    // Find the item with the specified ID
    const item = data?.map((item: any) => {
      item?.subcategories?.find((items: { id: any }) =>
        console.log(items?.id === itemId, "va")
      );
    });
    // items.id === itemId
    if (item) {
      // Append the new subcategory to the item's subcategories array
      // item.subcategories.push(newSubcategory);
    } else {
      console.error(`Item with ID ${itemId} not found.`);
    }
  }
  return (
    <>
      <div className="table-outer">
        <Table>
          <thead>
            <tr>
              {from_user_managemet === true && (
                <>
                  <th>Multi Select</th>
                </>
              )}
              <th>ID</th>

              {tableFields?.map((itm: any, i: number) => (
                <th key={i}>{itm}</th>
              ))}

              {from_office === "office" && (
                <>
                  <th>Seat</th>
                  {checkPermission("non-member:write") ? (
                    <>
                      {" "}
                      <th>Member/Non Member Status</th>
                    </>
                  ) : null}
                </>
              )}
              {from_cat === true && (
                <>
                  <th>Sub Category</th>
                </>
              )}
              {/* {from_leave === true && (
                <>
                  <th>Leave Status</th>
                </>
              )} */}
              {form_ === "user" && (
                <>
                  <th>Status</th>
                </>
              )}
              {from_downloads === true && (
                <>
                  <th>Categories</th>
                  <th>Status</th>
                </>
              )}
              {localStorage?.getItem("ROLE") === "admin" &&
                from_meeting === true && (
                  <>
                    <th>Invite</th>
                  </>
                )}

              {from_anno === true && (
                <>
                  <th>Documents</th>
                  {/* <th>Invite</th> */}
                </>
              )}
              {from_fund === true && (
                <>
                  <th>Publish</th>
                </>
              )}
              {from_otherfund === true && (
                <>
                  <th>View Members</th>
                </>
              )}
              {from_transfer_req && (
                <>
                  <th>Status</th>
                </>
              )}

              {from_my_transfer_req && (
                <>
                  <th>Status</th>
                  <th>Actions</th>
                </>
              )}
              {from_magazine && (
                <>
                  <th>Document</th>
                </>
              )}
              {from_attendance && (
                <>
                  <th>Document</th>
                </>
              )}
              {from_des && (
                <>
                  <th>District Name</th>
                </>
              )}
              {from_categorydownloads === true && <th>Subcategory</th>}
              {!from_getintouch &&
                !from_report &&
                !from_abouttoretire &&
                !from_leave &&
                localStorage?.getItem("ROLE") === "admin" && (
                  <>
                    <th>{ActionsLabel}</th>
                  </>
                )}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="empty-data" colSpan={12}>
                  <CustomSpinner />
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((itm: any, i: number) => {
                const currentIndex = (activePage - 1) * itemsPerPage + i + 1;

                return (
                  <tr key={i}>
                    {from_user_managemet === true && (
                      <>
                        <td>
                          <Input
                            type="checkbox"
                            value={itm?.id}
                            onClick={() => onClickCheck(itm?.id, i)}
                          />
                        </td>
                      </>
                    )}
                    <td>
                      <span className="mobile-heading">ID</span>
                      <span className="table-data">{currentIndex}</span>
                    </td>
                    {Arraykeys?.map((el: any, num: number) => (
                      <td key={num}>
                        <span className="mobile-heading">
                          {tableFields[num]}
                        </span>

                        <span className="table-data">
                          {el === "image" ? (
                            itm?.image ? (
                              <img
                                src={itm?.image}
                                style={{ maxWidth: "200px" }}
                                alt="User"
                                className="img-fluid"
                              />
                            ) : (
                              ""
                            )
                          ) : typeof itm?.[el] === "string" ? (
                            itm?.[el].startsWith("<p>") ? (
                              <span
                                className="tiny_mce_span"
                                onClick={() => onEditClick(itm?.id)}
                                dangerouslySetInnerHTML={{
                                  __html: itm?.[el].slice(0, 250),
                                }}
                              ></span>
                            ) : (
                              <span onClick={() => onEditClick(itm?.id)}>
                                {itm?.[el].length > 250
                                  ? `${itm?.[el].slice(0, 250)}...`
                                  : itm?.[el]}
                              </span>
                            )
                          ) : (
                            <span onClick={() => onEditClick(itm?.id)}>
                              {typeof itm?.[el] === "string"
                                ? itm?.[el].length > 250
                                  ? `${itm?.[el].slice(0, 250)}...`
                                  : itm?.[el]
                                : itm?.[el]}
                            </span>
                          )}
                        </span>
                      </td>
                    ))}
                    {from_magazine && (
                      <>
                        <td>
                          <span className="mobile-heading">Document</span>
                          <span className="table-data">
                            {itm?.document_file?.includes("pdf") ? (
                              <embed
                                src={itm?.document_file}
                                className="embed-pdf"
                              />
                            ) : (
                              <img
                                src={itm?.document_file}
                                alt="img"
                                className="img-fluid"
                              />
                            )}
                          </span>
                        </td>
                      </>
                    )}
                    {from_anno && (
                      <>
                        <td>
                          <span className="mobile-heading">Document</span>
                          <span className="table-data">
                            {itm?.documents?.includes("pdf") ? (
                              <embed
                                src={itm?.documents}
                                className="embed-pdf"
                              />
                            ) : (
                              <img src={itm?.documents} className="img-fluid" />
                            )}
                          </span>
                        </td>
                      </>
                    )}
                    {from_attendance && (
                      <>
                        <td>
                          <span className="mobile-heading">Status</span>
                          <span className="table-data">
                            {itm?.document_upload?.includes("pdf") ? (
                              <embed
                                src={itm?.document_upload}
                                className="embed-pdf"
                              />
                            ) : (
                              <img
                                src={itm?.document_upload}
                                className="img-fluid"
                              />
                            )}
                          </span>
                        </td>
                      </>
                    )}
                    {from_member === true && (
                      <>
                        <td>
                          <span className="mobile-heading">Status</span>
                          <div className="badge-container">
                            {/* Annual Member  */}
                            {itm?.member_membership_type_ === 1 && (
                              <>
                                {itm?.member_block_verify === 3 ? (
                                  <>
                                    <span className="table-data">
                                      {checkPermission(
                                        "members:block-verify"
                                      ) ? (
                                        <Badge
                                          className="primary mt-2"
                                          onClick={() => {
                                            setVerifyModal(true);
                                            setPreview(itm?.id);
                                            Refetch();
                                            setMemberVerifiavtionType("block");
                                            setMemberVerifiavtionType(
                                              itm?.member_block_verify
                                            );
                                          }}
                                        >
                                          Block Verify
                                        </Badge>
                                      ) : null}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                                {itm?.member_district_verify === 1 ? null : (
                                  <>
                                    {itm?.member_block_verify === 1 ? (
                                      <>
                                        <span className="table-data">
                                          {checkPermission(
                                            "members:district-verify"
                                          ) ? (
                                            <Badge
                                              className="primary mt-2"
                                              onClick={() => {
                                                setVerifyModal(true);
                                                setPreview(itm?.id);
                                                setMemberVerifiavtionType(
                                                  itm?.member_block_verify
                                                );
                                                Refetch();
                                              }}
                                            >
                                              District Verify
                                            </Badge>
                                          ) : null}
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                                {itm?.member_district_verify === 1 &&
                                itm?.membership_status_ === 3 ? (
                                  itm?.payment_order_status === 1 ? null : (
                                    <span className="table-data">
                                      {checkPermission("members:write") ? (
                                        <Badge
                                          className="success mt-2"
                                          onClick={() => {
                                            // onPaymentClick(itm?.id);
                                            setPaymentModal(true);
                                            setPaymentLoginid(itm?.id);
                                            setType("payment");
                                          }}
                                        >
                                          Send Payment Link
                                        </Badge>
                                      ) : null}
                                    </span>
                                  )
                                ) : null}

                                {itm?.payment_order_status === 1 ? (
                                  <>
                                    <span className="table-data">
                                      {checkPermission("members:write") ? (
                                        <Badge
                                          className={`mt-2 ${
                                            itm?.max_used_count == 0
                                              ? "bg-success"
                                              : itm?.max_used_count == 1
                                              ? "bg-tertiary"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setSendModal(true);
                                            setPreview(itm?.id);
                                          }}
                                        >
                                          Send Login Data
                                        </Badge>
                                      ) : null}
                                    </span>
                                  </>
                                ) : null}
                                {itm?.associative_status === 3 ? (
                                  <span className="table-data">
                                    <Badge
                                      className="primary mt-2"
                                      onClick={() => {
                                        setAssociateOpen(true);
                                        setAssociateAdata(itm);
                                        Refetch();
                                      }}
                                    >
                                      New Request Associate
                                    </Badge>
                                  </span>
                                ) : null}
                              </>
                            )}

                            {/* Life */}

                            {itm?.member_membership_type_ === 3 && (
                              <>
                                {itm?.member_block_verify === 3 ? (
                                  <>
                                    <span className="table-data">
                                      <Badge
                                        className="primary mt-2"
                                        onClick={() => {
                                          setVerifyModal(true);
                                          setPreview(itm?.id);
                                          Refetch();
                                          setMemberVerifiavtionType(
                                            itm?.member_block_verify
                                          );
                                        }}
                                      >
                                        Block Verify
                                      </Badge>
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                                {itm?.member_district_verify === 1 ? null : (
                                  <>
                                    {itm?.member_block_verify === 1 ? (
                                      <>
                                        <span className="table-data">
                                          <Badge
                                            className="primary mt-2"
                                            onClick={() => {
                                              setVerifyModal(true);
                                              setPreview(itm?.id);
                                              setMemberVerifiavtionType(
                                                itm?.member_block_verify
                                              );
                                              Refetch();
                                            }}
                                          >
                                            District Verify
                                          </Badge>
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}

                                {itm?.member_district_verify === 1 && (
                                  <>
                                    <span className="table-data">
                                      <Badge
                                        // className={`mt-2 ${buttonClicked ? 'bg-success':'bg-secondary'  }`}
                                        className="success mt-2"
                                      >
                                        Send Login Data
                                      </Badge>
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                            {/* Associate */}
                            {itm?.member_membership_type_ === 2 && (
                              <>
                                {itm?.member_district_verify === 3 ? (
                                  <>
                                    <span className="table-data">
                                      <Badge
                                        className="primary mt-2"
                                        onClick={() => {
                                          setVerifyModal(true);
                                          setPreview(itm?.id);
                                          Refetch();
                                          setMemberVerifiavtionType(4);
                                        }}
                                      >
                                        District Verify
                                      </Badge>
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}

                                {itm?.payment_order_status === 1 &&
                                itm?.membership_status === 3 ? (
                                  <>
                                    <span className="table-data">
                                      <Badge
                                        className="success mt-2"
                                        onClick={() => {
                                          setSendModal(true);
                                          setPreview(itm?.id);
                                        }}
                                      >
                                        Send Login Data
                                      </Badge>
                                    </span>
                                  </>
                                ) : null}
                                {itm?.member_district_verify === 1 ? (
                                  <span className="table-data">
                                    <Badge
                                      className="success mt-2"
                                      onClick={() => {
                                        // onPaymentClick(itm?.id);
                                        setPaymentModal(true);
                                        setPaymentLoginid(itm?.id);
                                        setType("payment");
                                      }}
                                    >
                                      Send Payment Link
                                    </Badge>
                                  </span>
                                ) : null}
                              </>
                            )}
                          </div>
                        </td>
                      </>
                    )}
                    {from_fund === true && (
                      <>
                        <td>
                          <span className="mobile-heading">Status</span>
                          <span className="table-data">
                            <Badge
                              color={`${
                                itm?.is_published === true
                                  ? "success"
                                  : "warning"
                              }`}
                              onClick={() =>
                                delete_Confirm(
                                  itm?.id,
                                  itm?.is_published,
                                  "publish"
                                )
                              }
                            >
                              {FundStatus(itm?.is_published)}
                            </Badge>
                          </span>
                        </td>
                      </>
                    )}
                    {from_downloads && (
                      <>
                        <td>
                          <span className="mobile-heading">category</span>
                          <span className="table-data">
                            <Badge
                              className="secondary"
                              onClick={() => {
                                if (pathname?.startsWith("/member")) {
                                  navigate(
                                    `/member/download-category/${itm?.id}`
                                  );
                                }
                                if (pathname?.startsWith("/admin")) {
                                  navigate(
                                    `/admin/download-category/${itm?.id}`
                                  );
                                }
                              }}
                            >
                              Category
                            </Badge>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">Status</span>
                          <span className="table-data">
                            <Badge
                              color={`${
                                itm?.is_published === 1 ? "success" : "warning"
                              }`}
                              onClick={() => {
                                if (checkPermission("downloads:write")) {
                                  if (handleDownloadPublishClick)
                                    handleDownloadPublishClick(
                                      itm?.id,
                                      itm?.is_published
                                    );
                                }
                              }}
                            >
                              {getDownloadStatus(itm?.is_published)}
                            </Badge>
                          </span>
                        </td>
                      </>
                    )}
                    {localStorage?.getItem("ROLE") === "admin" &&
                      from_meeting === true && (
                        <>
                          <td>
                            <span className="mobile-heading">Status</span>{" "}
                            <span className="table-data">
                              <Badge
                                className="success mt-2"
                                onClick={() => {
                                  if (pathname?.startsWith("/member")) {
                                    navigate(
                                      `/member/meeting-invite/1/${itm?.id}`
                                    );
                                  }
                                  if (pathname?.startsWith("/admin")) {
                                    navigate(
                                      `/admin/meeting-invite/1/${itm?.id}`
                                    );
                                  }
                                }}
                              >
                                Invite Member
                              </Badge>
                            </span>
                          </td>
                        </>
                      )}
                    {from_anno === true &&
                      localStorage?.getItem("ROLE") === "admin" && (
                        <>
                          <td>
                            <span className="mobile-heading">Status</span>{" "}
                            <span className="table-data">
                              <Badge
                                className="success mt-2"
                                onClick={() => {
                                  if (pathname?.startsWith("/member")) {
                                    navigate(
                                      `/member/announcement-invite/1/${itm?.id}`
                                    );
                                  }
                                  if (pathname?.startsWith("/admin")) {
                                    navigate(
                                      `/admin/announcement-invite/1/${itm?.id}`
                                    );
                                  }
                                }}
                              >
                                Invite Member
                              </Badge>
                            </span>
                          </td>
                        </>
                      )}
                    {from_otherfund === true && (
                      <>
                        <td>
                          <span className="mobile-heading">Status</span>
                          <span className="table-data">
                            <Badge
                              className="success mt-2"
                              onClick={() => {
                                if (pathname?.startsWith("/member")) {
                                  navigate(
                                    `/member/other-fund-invite/1/${itm?.id}`
                                  );
                                }
                                if (pathname?.startsWith("/admin")) {
                                  navigate(
                                    `/admin/other-fund-invite/1/${itm?.id}`
                                  );
                                }
                              }}
                            >
                              View
                            </Badge>
                          </span>
                        </td>
                      </>
                    )}
                    {from_office === "office" && (
                      <>
                        <td>
                          <span className="mobile-heading">Seat</span>
                          <span className="table-data">
                            <Badge
                              className="secondary"
                              onClick={() => {
                                if (pathname?.startsWith("/member")) {
                                  navigate(
                                    `/member/seat-settings/1/${itm?.id}/${page_number}`
                                  );
                                }
                                if (pathname?.startsWith("/admin")) {
                                  navigate(
                                    `/admin/seat-settings/1/${itm?.id}/${page_number}`
                                  );
                                }
                              }}
                            >
                              Manage Seat
                            </Badge>
                          </span>
                        </td>
                      </>
                    )}
                    {from_office === "office" && (
                      <>
                        {checkPermission("non-member:write") ? (
                          <td>
                            <span className="mobile-heading">
                              Member/Non Member Status
                            </span>
                            <span className="table-data">
                              <Input
                                type="checkbox"
                                checked={itm?.member_status !== 2}
                                // disabled={itm?.occupied_seat_count >= 1}
                                onChange={() => {
                                  const newStatus =
                                    itm?.member_status === 2 ? 1 : 2;
                                  onStatusClick(itm.id, newStatus);
                                }}
                              />
                            </span>
                          </td>
                        ) : null}
                      </>
                    )}
                    {from_des === true && (
                      <td>
                        <span className="mobile-heading">District Name</span>
                        <span className="table-data">
                          {itm?.district_name === null
                            ? "All districts"
                            : itm?.district_name}
                        </span>
                      </td>
                    )}
                    {form_ === "user" && (
                      <>
                        <td>
                          <span className="mobile-heading">Status</span>
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActiveRevoke(true);
                              setPaymentLoginid(itm?.user_id);
                              setPreview(itm?.status);
                            }}
                          >
                            {ActiveRevokeStatusStyle(itm?.status)}
                          </span>
                        </td>
                      </>
                    )}
                    {/* {from_leave === true && (
                      <>
                        <td>
                          <span className="mobile-heading">Leave Status</span>
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              delete_Confirm(
                                itm?.id,
                                itm?.status_type,
                                "change",
                                itm?.user_id
                              )
                            }
                          >
                            {LeaveStatusStyle(itm?.status_type)}
                          </span>
                        </td>
                      </>
                    )} */}
                    {/* Transfer Request */}
                    {from_transfer_req && (
                     
                      <td>
                        <>
                          <span className="mobile-heading">Status</span>
                          {itm?.block_verify === 3 ? (
                            <span className="table-data">
                              {checkPermission("transfer:block-verify") ? (
                                <Badge
                                  className="primary mt-2"
                                  onClick={() => {
                                    
                                    transfer_Modal(
                                      itm?.id,
                                      itm?.status_type,
                                      itm?.block_id,
                                      itm?.district_id,
                                      itm?.office_id,
                                      "change",
                                      itm?.user,
                                      itm?.block_verify,
                                      itm?.designation_id?.id,
                                      itm?.cadre_id,
                                      
                                    );
                                    Refetch();
                                  }}
                                >
                                  Block Approve
                                </Badge>
                              ) : null}
                            </span>
                          ) : null}

                          {itm?.block_verify === 1 && (
                            <span className="table-data">
                              {(checkPermission("transfer:district-verify") ||
                                checkPermission("transfer:block-verify")) &&
                                "Block Approved"}
                            </span>
                          )}

                          {itm?.block_verify === 4 ? (
                            <span className="table-data">
                              <Badge
                                color="danger"
                                className="primary mt-2 text-white"
                              >
                                Rejected
                              </Badge>
                            </span>
                          ) : null}
                        </>
                      </td>
                    )}
                    {from_my_transfer_req &&
                      localStorage?.getItem("ROLE") === "member" && (
                        <td>
                          <>
                            <span className="mobile-heading">Status</span>
                            {itm?.block_verify === 3 ? (
                              <span className="table-data">
                                <Badge className="primary mt-2">Pending</Badge>
                              </span>
                            ) : null}

                            {itm?.block_verify === 1 &&
                            itm?.district_verify !== 1 ? (
                              <span className="table-data">
                                <Badge className="primary mt-2">
                                  Block Approved
                                </Badge>
                              </span>
                            ) : null}
                            {itm?.district_verify === 1 &&
                            itm?.block_verify === 1 ? (
                              <span className="table-data">
                                <Badge className="primary mt-2">
                                  District Approved
                                </Badge>
                              </span>
                            ) : null}
                            {itm?.block_verify === 4 ? (
                              <span className="table-data">
                                <Badge
                                  color="danger"
                                  className="primary mt-2 text-white"
                                >
                                  Rejected
                                </Badge>
                              </span>
                            ) : null}
                          </>
                        </td>
                      )}
                    {/* Reject Button */}
                    {from_transfer_req && (
                      <td>
                        <>
                          <span className="mobile-heading">Status</span>
                          {itm?.block_verify === 3 ? (
                            <span className="table-data">
                              {checkPermission("transfer:block-verify") ? (
                                <Link
                                  to=""
                                  className="primary mt-2"
                                  onClick={() => {
                                    transfer_reject(itm?.id);
                                    Refetch();
                                  }}
                                >
                                  <i
                                    className="icon xl icon-close-red"
                                    title="Reject"
                                  ></i>
                                </Link>
                              ) : null}
                            </span>
                          ) : null}
                        </>
                      </td>
                    )}
                    {from_cat === true && (
                      <>
                        <td>
                          <span className="mobile-heading">Seat</span>
                          <span className="table-data">
                            <Badge
                              className="secondary"
                              onClick={() => {
                                if (pathname?.startsWith("/member")) {
                                  navigate(
                                    `/member/asset-sub-category-settings/${itm?.id}`
                                  );
                                }
                                if (pathname?.startsWith("/admin")) {
                                  navigate(
                                    `/admin/asset-sub-category-settings/${itm?.id}`
                                  );
                                }
                              }}
                            >
                              Add/Edit Sub Category
                            </Badge>
                          </span>
                        </td>
                      </>
                    )}{" "}
                    {from_categorydownloads === true && (
                      <>
                        <td>
                          {itm?.subcategories?.map((item: any) => {
                            return (
                              <>
                                <ul>
                                  <li
                                    onClick={() => {
                                      getAllSubSubcategory(
                                        download_cat_id,
                                        item?.id
                                      );
                                    }}
                                  >
                                    {item?.name} - View Subcategories
                                  </li>
                                </ul>
                              </>
                            );
                          })}
                        </td>
                      </>
                    )}
                    {!from_report && (
                      <td>
                        <span className="mobile-heading">Actions</span>
                        <span className="table-data">
                          <div className="action-flex-gap">
                            {from_associate_request === true ? (
                              <Button
                                title="Status"
                                to="#"
                                onClick={() => onEditClick(itm?.id)}
                              >
                                Request
                              </Button>
                            ) : (
                              <>
                                {actions?.includes("edit") && (
                                  <Link
                                    title="Edit"
                                    to="#"
                                    onClick={() =>
                                      from_des === true
                                        ? onEditClick(itm)
                                        : from_office_cate === true
                                        ? onEditClick(itm)
                                        : onEditClick(itm?.id)
                                    }
                                  >
                                    <i className="icon icon-edit xl"></i>
                                  </Link>
                                )}
                                {actions?.includes("delete") && (
                                  <Link
                                    title="Delete"
                                    to="#"
                                    onClick={() => delete_Confirm(itm?.id, "")}
                                  >
                                    <i className="icon icon-delete xl"></i>
                                  </Link>
                                )}
                              </>
                            )}

                            {localStorage?.getItem("ROLE") === "admin" && (
                              <>
                                {actions?.includes("status") && (
                                  <>
                                    {from_blog === true ? (
                                      <>
                                        {checkPermission("blog:publish") ? (
                                          <>
                                            {" "}
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                delete_Confirm(
                                                  itm?.id,
                                                  itm?.status,
                                                  "status_updation"
                                                );
                                              }}
                                            >
                                              <div>
                                                {itm?.status === 2 ? (
                                                  <i
                                                    title="Publish"
                                                    className="icon xl icon-toast-check"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    title="Unpublish"
                                                    className="icon xl icon-close-red"
                                                  ></i>
                                                )}
                                              </div>
                                            </Link>
                                          </>
                                        ) : null}
                                        {checkPermission("blog:verify") ? (
                                          <>
                                            {" "}
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                Blog_Confirm(
                                                  itm?.id,
                                                  itm?.blog_verify,
                                                  "status_updation"
                                                );
                                                setBlogVerify(
                                                  itm?.blog_verify === 2
                                                    ? "blog_un"
                                                    : "blog_verify"
                                                );
                                              }}
                                            >
                                              <div>
                                                {itm?.blog_verify === 2 ? (
                                                  <>Blog Verify</>
                                                ) : (
                                                  <>Blog Verified</>
                                                )}
                                              </div>
                                            </Link>
                                          </>
                                        ) : null}
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            delete_Confirm(
                                              itm?.id,
                                              itm?.status,
                                              "status_updation"
                                            )
                                          }
                                        >
                                          <div>
                                            {itm?.status === 2 ? (
                                              <i
                                                title="Publish"
                                                className="icon xl icon-toast-check"
                                              ></i>
                                            ) : (
                                              <i
                                                title="Unpublish"
                                                className="icon xl icon-close-red"
                                              ></i>
                                            )}
                                          </div>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {from_attendance && (
                              <>
                                <Link to={itm?.document_upload} target="_blank">
                                  <i
                                    title="View"
                                    className="icon xl icon-eye-open"
                                  ></i>
                                </Link>
                              </>
                            )}
                            {from_magazine && (
                              <>
                                <Link to={itm?.document_file} target="_blank">
                                  <i
                                    title="View"
                                    className="icon xl icon-eye-open"
                                  ></i>
                                </Link>
                              </>
                            )}
                            {from_pre === true && (
                              <>
                                {" "}
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setPreviewModal(true);
                                    setPreview(itm?.id);
                                  }}
                                >
                                  <i
                                    title="View"
                                    className="icon xl icon-eye-open"
                                  ></i>
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    // downloadPrescription(itm?.id);
                                    setPreviewModal(true);
                                    setPreview(itm?.id);
                                    setType("from_down");
                                  }}
                                >
                                  <i
                                    title="Download"
                                    className="icon xl icon-download"
                                  ></i>
                                </Link>
                              </>
                            )}
                            {from_downloads &&
                              checkPermission("downloads:write") &&
                              (itm?.is_published === 2 ? (
                                <>
                                  <i
                                    title="Publish"
                                    className="icon xl icon-toast-check"
                                    onClick={() => {
                                      if (checkPermission("downloads:write")) {
                                        if (handleDownloadPublishClick)
                                          handleDownloadPublishClick(
                                            itm?.id,
                                            itm?.is_published
                                          );
                                      }
                                    }}
                                  ></i>
                                </>
                              ) : (
                                <>
                                  <i
                                    title="Unpublish"
                                    className="icon xl icon-close-red"
                                    onClick={() => {
                                      if (checkPermission("downloads:write")) {
                                        if (handleDownloadPublishClick)
                                          handleDownloadPublishClick(
                                            itm?.id,
                                            itm?.is_published
                                          );
                                      }
                                    }}
                                  ></i>
                                </>
                              ))}
                          </div>
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : error ? (
              <tr>
                <td className="empty-data" colSpan={12}>
                  Error Occurred
                </td>
              </tr>
            ) : (
              <td colSpan={12} className="empty-text">
                <span>No Data</span>
              </td>
            )}
          </tbody>
        </Table>
      </div>
      {/* Delete */}
      <ModalContainer isOpen={openMoadl} setIsopen={setModal} title="">
        <DeleteAlertModal
          setModal={setModal}
          onDeleteClick={onDeleteClick}
          delete_id={delete_id}
          meta={meta}
          status={status}
          Refetch={Refetch}
          meta_status={meta_status}
          from_leave={from_leave}
          type={types}
          user_id={user_id}
          preview_id={preview_id}
          from_magazine={from_magazine}
          from_transfer_req={from_transfer_req}
          blog_verify={blog_verify}
          from_blog={from_blog}
          blockVerify={blockVerify}
        />
      </ModalContainer>
      <ModalContainer isOpen={rejectModal} setIsopen={setRejectModal} title="">
        <TransferRejectModal
          setRejectModal={setRejectModal}
          rejectId={rejectId}
          Refetch={Refetch}
        />
      </ModalContainer>

      {/* Transfer Modal */}
      <ModalContainer
        isOpen={trasnferModal}
        setIsopen={setTransferModal}
        title=""
      >
        <Transfer_Modal
          setModal={setTransferModal}
          onDeleteClick={onDeleteClick}
          delete_id={delete_id}
          meta={meta}
          status={status}
          Refetch={Refetch}
          meta_status={meta_status}
          from_leave={from_leave}
          type={types}
          user_id={user_id}
          preview_id={preview_id}
          from_magazine={from_magazine}
          from_transfer_req={from_transfer_req}
          blog_verify={blog_verify}
          from_blog={from_blog}
          blockVerify={blockVerify}
          seat={seat}
          district={district}
          block={block}
          office={office}
          designation={designation}
          cadre_id={cadre}
        />
      </ModalContainer>
      <ModalContainer isOpen={Blog_modal} setIsopen={setBlogModal} title="">
        <BlogAlertModal
          setModal={setBlogModal}
          onDeleteClick={onDeleteClick}
          delete_id={delete_id}
          meta={meta}
          status={status}
          Refetch={Refetch}
          meta_status={meta_status}
          from_leave={from_leave}
          type={types}
          user_id={user_id}
          preview_id={preview_id}
          from_magazine={from_magazine}
          from_transfer_req={from_transfer_req}
          blog_verify={blog_verify}
          from_blog={from_blog}
        />
      </ModalContainer>
      {/* Preview */}
      <ModalContainer
        isOpen={previe_modal}
        setIsopen={setPreviewModal}
        title={""}
      >
        <PreviewModal
          setIsOpen={setPreviewModal}
          preview_id={preview_id}
          type={types}
        />
      </ModalContainer>

      {/* verify Modal */}
      <ModalContainer
        isOpen={verify_modal}
        setIsopen={setVerifyModal}
        title={"Member View"}
      >
        <VerifyMember
          setIsOpens={setVerifyModal}
          preview_id={preview_id}
          Refetch={Refetch}
          member_block_verify={mmeber_verifiction_type}
        />
      </ModalContainer>

      <ModalContainer
        isOpen={send_modal}
        setIsopen={setSendModal}
        title={"Login Data"}
      >
        <SendLoginModal
          setIsOpen={setSendModal}
          preview_id={preview_id}
          Refetch={Refetch}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={associate_open}
        setIsopen={setAssociateOpen}
        title={"Associate"}
      >
        <AddAssociate
          setIsOpen={setAssociateOpen}
          modalEditData={associate_data}
          refetch={Refetch}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={payment_modal}
        setIsopen={setPaymentModal}
        title={""}
      >
        <PaymentLinkModal
          setIsOpen={setPaymentModal}
          paymnet_login_id={paymnet_login_id}
          onPaymentClick={onPaymentClick}
          Refetch={Refetch}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={active_revoke}
        setIsopen={setActiveRevoke}
        title={""}
      >
        <ActiveAlertModal
          setIsOpen={setActiveRevoke}
          paymnet_login_id={paymnet_login_id}
          onPaymentClick={onPaymentClick}
          Refetch={Refetch}
          preview_id={preview_id}
        />
      </ModalContainer>
    </>
  );
};

export default CustomTable;
