import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  NavLink,
} from "reactstrap";
import { AddOfficeCatgoreyProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddOfficeCategoryInitialValues,
  OfficeCategoryValidationSchema,
} from "./AddOfficeCategoryMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddOfficeCategoryMethods/AddOfficeCategoryModalMethods";
import Select from "react-select";
import { useQuery } from "react-query";
import { getOpenBlock } from "../../../../../Api/AdminApis";
import NewCategory from "../OfficeCategoryView/NewCategory";
import ExistCategory from "../OfficeCategoryView/ExistCategory";

const AddOfficeCategory = ({
  setIsOpen,
  modalEditData,
  refetch,
  district,
  office_cat,
  office_fullRefetch,
}: AddOfficeCatgoreyProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddOfficeCategoryInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [edit_cat, setEditcat] = useState(true);
  const [tab, setTab] = useState("1");

  const [district_id, setDistrictId] = useState<any>();

  const { data: block }: any = useQuery(
    [`block`, district_id, addModalInitialValues?.district_id],
    () =>
      district_id || addModalInitialValues?.district_id
        ? getOpenBlock(
            district_id ? district_id : addModalInitialValues?.district_id
          )
        : "",
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <>
      {modalEditData?.length === 0 && (
        <>
          <Nav pills className="office-navpill-wrap">
            <NavItem>
              <NavLink
                className={`tab-button ${tab === "1" ? "active" : ""}`}
                onClick={() => {
                  setEditcat(true);
                  setTab("1");
                }}
              >
                Add New office Category
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`tab-button ${tab === "2" ? "active" : ""}`}
                onClick={() => {
                  setEditcat(false);
                  setTab("2");
                }}
              >
                Use Existing office Category
              </NavLink>
            </NavItem>
          </Nav>
        </>
      )}

      {modalEditData?.length === 0 ? (
        <>
          {tab === "1" && (
            <>
              <NewCategory
                setIsOpen={setIsOpen}
                refetch={refetch}
                editMode={editMode}
                modalEditData={modalEditData}
                office_fullRefetch={office_fullRefetch}
              />
            </>
          )}
          {tab === "2" && (
            <>
              <ExistCategory
                setIsOpen={setIsOpen}
                refetch={refetch}
                editMode={editMode}
                modalEditData={modalEditData}
                addModalInitialValues={addModalInitialValues}
                edit_cat={edit_cat}
                office_cat={office_cat}
                district={district}
                setDistrictId={setDistrictId}
                block={block}
                office_fullRefetch={office_fullRefetch}
              />
            </>
          )}
        </>
      ) : (
        <ExistCategory
          setIsOpen={setIsOpen}
          refetch={refetch}
          editMode={editMode}
          modalEditData={modalEditData}
          addModalInitialValues={addModalInitialValues}
          edit_cat={edit_cat}
          office_cat={office_cat}
          district={district}
          setDistrictId={setDistrictId}
          block={block}
          office_fullRefetch={office_fullRefetch}
        />
      )}
    </>
  );
};

export default AddOfficeCategory;
