import * as Yup from "yup";
import { phoneSchema } from "../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const ProfileValidationSchema = Yup.object().shape({
  email: Yup.string().required("Field is required"),
  mobile_number: Yup.string().required("Field is required"),
  whatsapp_number: Yup.string().required("Field is required"),
  profile_pic: Yup.string(),
  employment_status: Yup.string().required("Field is required"),
  about: Yup.string().required("Field is required"),
  from_where: Yup.string().when(
    "employment_status",
    ([employment_status], sch) => {
      return employment_status === "2"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),

  start_date: Yup.string().when(
    "employment_status",
    ([employment_status], sch) => {
      return employment_status === "3"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  end_date: Yup.string().when(
    "employment_status",
    ([employment_status], sch) => {
      return employment_status === "3"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),

  bachelor_degree: Yup.string().required("Field is required"),

  year_of_pass: Yup.string().when(
    "bachelor_degree",
    ([bachelor_degree], sch) => {
      return bachelor_degree === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  bachelor_degree_course: Yup.string().when(
    "bachelor_degree",
    ([bachelor_degree], sch) => {
      return bachelor_degree === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  if_any_other_bachelor_degree: Yup.string().when(
    "bachelor_degree_course",
    ([bachelor_degree_course], sch) => {
      return bachelor_degree_course === "44"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  pg_degree: Yup.string().required("Field is required"),
  pg_course: Yup.string().when("pg_degree", ([pg_degree], sch) => {
    return pg_degree === "1"
      ? sch.required("Field is required").nullable()
      : sch.notRequired();
  }),
  pg_year_of_pass: Yup.string().when("pg_degree", ([pg_degree], sch) => {
    return pg_degree === "1"
      ? sch.required("Field is required").nullable()
      : sch.notRequired();
  }),
  pg_other: Yup.string().when("pg_course", ([pg_course], sch) => {
    return pg_course === "21"
      ? sch.required("Field is required").nullable()
      : sch.notRequired();
  }),

  doctoral_degree: Yup.string().required("Field is required"),
  doctoral_degree_pass: Yup.string().when(
    "doctoral_degree",
    ([doctoral_degree], sch) => {
      return doctoral_degree === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  doctoral_degree_course: Yup.string().when(
    "doctoral_degree",
    ([doctoral_degree], sch) => {
      return doctoral_degree === "1"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  doctoral_degree_pg_other: Yup.string().when(
    "doctoral_degree_course",
    ([doctoral_degree_course], sch) => {
      return doctoral_degree_course === "38"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),

  // other_degree: Yup.string().required("Field is required"),
  // other_degree_pass_year: Yup.string().when(
  //   "other_degree",
  //   ([other_degree], sch) => {
  //     return other_degree === "1"
  //       ? sch.required("Field is required").nullable()
  //       : sch.notRequired();
  //   }
  // ),
  // other_degree_course: Yup.string().when(
  //   "other_degree",
  //   ([other_degree], sch) => {
  //     return other_degree === "1"
  //       ? sch.required("Field is required").nullable()
  //       : sch.notRequired();
  //   }
  // ),
  // other_degree_other: Yup.string().when(
  //   "other_degree_course",
  //   ([other_degree_course], sch) => {
  //     return other_degree_course === "38"
  //       ? sch.required("Field is required").nullable()
  //       : sch.notRequired();
  //   }
  // ),
  twitter_id: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  linkedin_id: Yup.string().url("Invalid URL"),
});
export const OfficeValidationSchema = Yup.object().shape({
  email: Yup.string().required("Field is required"),
  phone_no: phoneSchema.required("Field is required"),
  address: Yup.string().required("Field is required"),
});
export const ProfileInitialValues = {
  email: "",
  mobile_number: "",
  whatsapp_number: "",
  profile_pic: "",
  employment_status: "",
  from_where: "",
  start_date: "",
  end_date: "",
  bachelor_degree: "",
  year_of_pass: "",
  pg_degree: "",
  pg_year_of_pass: "",
  doctoral_degree_pass: "",
  doctoral_degree: "",
  pg_course: "",
  pg_other: "",
  doctoral_degree_course: "",
  linkedin_id: "",
  twitter_id: "",
  instagram: "",
  about: "",
  bachelor_degree_course: "",
  if_any_other_bachelor_degree: "",
  doctoral_degree_pg_other: "",
  other_degree: "",
  other_degree_course: "",
  other_degree_pass_year: "",
  other_degree_other: "",
  any_other_education: [
    {
      other_degree: "",
      other_degree_course: "",
      other_degree_pass_year: "",
      other_degree_other: "",
    },
  ],
};
export const OfficeInitialValues = {
  email: "",
  phone_no: "",
  address: "",
};

export const EducationValidationSchema = Yup.object().shape({
  any_other_education: Yup.array().of(
    Yup.object().shape({
      degree: Yup.string().required("Field is required"),
      school_college: Yup.string().required("Field is required"),
      start_date: Yup.date().required("Field is required"),
      end_date: Yup.date().required("Field is required"),
    })
  ),
});
export const EducationInitialValues = {
  any_other_education: [
    { degree: "", school_college: "", start_date: "", end_date: "" },
  ],
};
