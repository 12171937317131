import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  aboutUsValidationSchema,
  handleAboutUsInitializer,
  handleAboutUsSubmit,
} from "./AboutUsMethods";
import { Editor } from "@tinymce/tinymce-react";
import { TinyMCE_Token } from "../../../../../Utils/Constants/TinyMCE_Token";
interface AddAboutUsType {
  setIsOpen: Function;
  refetch: Function;
  modalEditData: any;
}

const AboutUsValues = {
  title: "",
  body: "",
};

const AddAboutUs = ({ setIsOpen, refetch, modalEditData }: AddAboutUsType) => {
  const [aboutUsInitialValue, setAboutUsInitialValue] = useState(AboutUsValues);
  const editMode = modalEditData?.length !== 0;

  useEffect(() => {
    handleAboutUsInitializer(
      setAboutUsInitialValue,
      modalEditData,
      AboutUsValues
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={aboutUsValidationSchema}
      initialValues={aboutUsInitialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        handleAboutUsSubmit(values, actions, setIsOpen, refetch, editMode);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
      }) => {

        return (
          <>
            <div className="Add-web-settings-wrap">
              <div className="form-wrap">
                <Form onSubmit={handleSubmit}>
                  <Row className="gy-4">
                    <Col md={12}>
                      <Label>Title</Label>
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values?.title}
                        name="title"
                        placeholder="Title"
                      />
                      <ErrorMessage name="title" component={TextError} />
                    </Col>

                    <Col md={12}>
                      <Label>Description</Label>

                      <Editor
                        apiKey={TinyMCE_Token}
                        // initialValue={values?.body}
                        value={values?.body}
                        textareaName="body"
                        init={{
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar: [
                            "undo redo | casechange blocks | bold italic backcolor | " +
                              "alignleft aligncenter alignright alignjustify | " +
                              "bullist numlist checklist outdent indent | removeformat | code table help",
                          ],
                        }}
                        onEditorChange={(e: any) => {
                          setFieldValue("body", e);
                        }}
                      />
                      <ErrorMessage name="body" component={TextError} />
                    </Col>
                    <Col lg={12}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Add"
                        color="primary"
                        className="sm submit-btn"
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddAboutUs;
