import { Button, Col, Container, Row } from "reactstrap";

const PaymentSuccess = () => {
  return (
    <>
      <div>
        <section className="login-wrapper">
          <Container>
            <Row className="align-items-center justify-content-center p-sm-2 p-5">
              <Col md={6}>
                <div className="logo-content-wrap cssanimation sequence fadeInBottom">
                  <h2>Welcome to AOAOK Membership Payment</h2>
                  <div className="login-form-wrap">
                    <div className="form-wrap reg-form-wrap">
                      <h4>
                        Payment success,your membership under verification
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default PaymentSuccess;
