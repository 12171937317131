import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddSeat/AddSeatMethods/AddSeatModalMethods";
import AddSeat from "./AddSeat";
import {
  getCadreFullList,
  getDesignationFullList,
  getOfficeFullList,
  getSeatList,
  getSingleOffice,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./SeatMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import Select from "react-select";
import { api } from "../../../../Api/Api";

const SeatIndex = () => {
  const { currentPage = "1", officeID, page_number } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const param1Value = urlParams.get('searchQuery');
  const [modalEditData, setModalEditData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [value, setValue] = useState({
    cadre_id: "",
    cader_value: "",
  });

  const handleChange = (e: any) => {
    const selectedValues = e?.value;
    setValue({
      ...value,
      cadre_id: selectedValues,
    });
  };
  const {
    data: seat,
    error: seatError,
    isLoading: seatLoading,
    refetch: seatListRefetch,
  }: any = useQuery(
    [
      `seat${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      officeID,
      value?.cadre_id,
    ],
    () => getSeatList(currentPage, officeID, searchQuery, value?.cadre_id),
    { keepPreviousData: true }
  );

  const { data: sigleoffice }: any = useQuery(
    [`office`, officeID],
    () => (officeID ? getSingleOffice(officeID) : ""),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: office,
    error: officeError,
    isLoading: officeLoading,
    refetch: officeListRefetch,
  }: any = useQuery([`office`], () => getOfficeFullList(), {
    keepPreviousData: true,
  });
  const {
    data: cadre,
    error: cadreError,
    isLoading: cadreLoading,
    refetch: cadreListRefetch,
  }: any = useQuery([`cadre`], () => getCadreFullList(), {
    keepPreviousData: true,
  });
  const {
    data: designation,
    error: designationError,
    isLoading: designationLoading,
    refetch: designationListRefetch,
  }: any = useQuery(
    [`designation`, value?.cader_value],
    () => getDesignationFullList(value?.cader_value),
    {
      keepPreviousData: true,
    }
  );

  const staffData = useMemo(() => extractStaffData(seat), [seat]);
  const breadcrumbItems = [
    { label: "Settings", link: `/admin/office-management/${page_number}` },
    { label: "Seat Management", link: "/admin/seat-settings" },
  ];
  const breadcrumb = generateBreadcrumb(breadcrumbItems);
  const editdata = (id: any) => {
    api
      .get(`/seats/${id}`, true)
      .then(async function ([success, response]) {
        setModalEditData(response?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section>
      {/* <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
      </Container> */}
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>
            
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Cadre"
                options={cadre}
                value={
                  cadre?.filter((itm: any) =>
                    value?.cadre_id?.includes(itm?.value)
                  ) || []
                }
                onChange={(e: any) => handleChange(e)}
                name="cadre"
                isLoading={false}
                loadingMessage={() => "Fetching cadre"}
                noOptionsMessage={() => "Cadre appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <div className="right-side-wrap">
              <div className="button-wrap">
                <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="primary"
                  className="sm"
                >
                  <i className="icon icon-add-new xl"></i> Seat
                </Button>
              </div>
              <Button
                outline
                color="primary" className="sm"
              >
                Reset
              </Button>
            </div>
          </div>

          <div className="office-name-wrapper">
            <h6>Office Name : {sigleoffice?.data?.name}</h6>
          </div>
          {/* cards section */}
          <div>
            <CardsWithTable
              data={staffData}
              tableTitle="Seat Details"
              tableFields={["Cadre", "Seat count", "Used Seat", "Remaing seat"]}
              Arraykeys={[
                "cadre",
                "seat_count",
                "used_seat_count",
                "remaining_seat_count",
              ]}
              viewType="table"
              toURL={""}
              actions={["edit", "delete"]}
              isLoading={seatLoading}
              error={seatError}
              onEditClick={(selectedId: any) => {
                editdata(selectedId);
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  seat
                );
              }}
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, seatListRefetch)
              }
              paginationData={seat?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, officeID)
              }
              
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Seat" : "Add Seat"}
      >
        <AddSeat
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={seatListRefetch}
          designation={designation}
          cadre={cadre}
          id={officeID}
          office={office}
          value={value}
          setValue={setValue}
        />
      </ModalContainer>
    </section>
  );
};

export default SeatIndex;
