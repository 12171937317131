const EMPLOYEE_STATUS = [
  {
    value: 1,
    label: "STATUTORY PENSION SCHEME",
  },
  {
    value: 2,
    label: "NEW PENSION SCHEME",
  },
];

const MEMBERSHIP_TYPE = [
  {
    value: "1",
    label: "ANNUAL MEMBER",
  },
  {
    value: "2",
    label: "ASSOCIATE MEMBER",
  },
  {
    value: "3",
    label: "LIFE MEMBER",
  },
];

const PRESENT_EMPLOYMENT_STATUS = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "1",
    label: "DEPARTMENT",
  },
  {
    value: "2",
    label: "DEPUTATION",
  },
  {
    value: "3",
    label: "LEAVE",
  },
  {
    value: "4",
    label: "WORKING ARRANGEMENT",
  },
];

const Bachelor_Degree_SELECT_DATA = [
  {
    value: "1",
    label: "BSc AGRICULTURE",
  },
  {
    value: "2",
    label: "Others",
  },
];

const YES_NO_SELECT_DATA = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "2",
    label: "No",
  },
];
const YES_NO_SELECT_DATAS = [
  {
    value: "1",
    label: "Yes",
  },
];

const EMPLOYMENT_TYPE_VALS = {
  DEPARTMENT: "1",
  DEPUTATION: "2",
  LEAVE: "3",
  WORKING_ARRANGEMENT: "4",
};

const MEMBER_TYPE = [
  {
    value: "1",
    label: "WORKING",
  },
  {
    value: "2",
    label: "RETIRED",
  },
  {
    value: "3",
    label: "TRANSIT",
  },
];
const YES_NO_DATA: any = [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
];

const TRANSIT_TYPE: any = [
  { value: 1, label: "Normal Transfer" },
  { value: 2, label: "Transfer On Promotion" },
];

const LEAVE_TYPES: any = [
  { value: "1", label: "Leave  for study purpose" },
  { value: "2", label: "Leave for joining spouse abroad" },
  { value: "3", label: "Leave for taking employment abroad" },
  { value: "4", label: "Maternity leave" },
  { value: "5", label: "LPR(Leave prior to Retirement)" },
  { value: "6", label: "Others" },
];
const JOIN_STATUS: any = [
  { value: 1, label: "Existing User" },
  { value: 2, label: "New User" },
];
const LEVAE_STATUS: any = [
  { value: 1, label: "PENDING" },
  { value: 2, label: "APPROVED" },
  { value: 3, label: "REJECTED" },
];
const MEETING_TYPE: any = [
  { value: 1, label: "State Meeting" },
  { value: 2, label: "Counsel Meeting" },
];
const ASSOCIATIVE_STATUS: any = [
  { value: 1, label: "Accepted" },
  { value: 2, label: "Rejected" },
];
const MEMBER_ACCEPT_STATUS: any = [
  { value: 1, label: "Approve" },
  { value: 2, label: "Suspend" },
];
const MEMBER_ACTIVE_STATUS: any = [
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];
export {
  EMPLOYEE_STATUS,
  MEMBERSHIP_TYPE,
  PRESENT_EMPLOYMENT_STATUS,
  MEMBER_TYPE,
  YES_NO_DATA,
  TRANSIT_TYPE,
  LEAVE_TYPES,
  JOIN_STATUS,
  LEVAE_STATUS,
  MEETING_TYPE,
  EMPLOYMENT_TYPE_VALS,
  YES_NO_SELECT_DATA,
  Bachelor_Degree_SELECT_DATA,
  ASSOCIATIVE_STATUS,
  YES_NO_SELECT_DATAS,
  MEMBER_ACTIVE_STATUS,
  MEMBER_ACCEPT_STATUS,
};
