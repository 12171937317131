import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Addaccomodation from "./AddAccomodation";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddAccomodation/AddAccomodationMethods/AddAccomodationModalMethods";
import { getAccomodationMaster, getDistrict } from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./AccomodationMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAccomodation from "./AddAccomodation";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";

const AccomodationIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [district_id, setDistrictId] = useState();
  const {
    data: accomodation,
    error: accomodationError,
    isLoading: accomodationLoading,
    refetch: accomodationListRefetch,
  }: any = useQuery([`accomodation${currentPage + searchQuery}`, currentPage, searchQuery], () => getAccomodationMaster(currentPage, searchQuery), {
    keepPreviousData: true,
  });
  const { data: district }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });
  const accomodationData = useMemo(() => extractStaffData(accomodation), [accomodation]);

  let breadcrumbItems
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    breadcrumbItems = [
      { label: "Settings", link: "/admin/settings" },
      { label: "Accomodation Data Management", link: "/admin/accomodation/1" },
    ];
  } else if (userRole === 'member') {
    breadcrumbItems = [
      { label: "Settings", link: "/member/settings" },
      { label: "Accomodation Data Management", link: "/member/accomodation/1" }
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("accomodation:write") ?
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Accomodation
                    </Button> : null}

                </div>
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={accomodationData}
              tableTitle="Accomodation Details"
              tableFields={["Name", "District"]}
              Arraykeys={["name", "district"]}
              viewType="table"
              toURL={""}
              actions={[checkPermission("accommodation:write") ? "edit" : "",
              checkPermission("accommodation:delete") ? "delete" : "",]}
              isLoading={accomodationLoading}
              error={accomodationError}
              onEditClick={(selectedId: any) => { if (checkPermission("accommodation:write")) onEditClickHandler(selectedId, setModalEditData, setIsOpen, accomodation) }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, accomodationListRefetch)
              }
              paginationData={accomodation?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </Container>

      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Accomodation" : "Add Accomodation"}
      >
        <AddAccomodation
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={accomodationListRefetch}
          districtList={district}
        />
      </ModalContainer>
    </section>
  );
};

export default AccomodationIndex;
