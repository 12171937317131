import { Col, Container, Nav, NavItem, NavLink, Row, Table } from "reactstrap";
import { MyPayment } from "../../../../Type/index";
import { getMemberPaymentData } from "../MemberProfile/MemberProfileMethods";
import { useQuery } from "react-query";
import { useState } from "react";
import Myotherfund from "./AddPayment/MyOffice";
import MyPaymentData from "./AddPayment/MyProfile";

const PaymentIndex = () => {
  const [tab, setTab] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const {
    data: memberPaymentData,
    isError: memberPaymentError,
    isLoading: memberPaymentLoading,
  } = useQuery([`payment_data`,startDate], () => getMemberPaymentData(startDate), {
    keepPreviousData: true,
  });

  return (
    <>
      <div className="content-list-outer-wrap payment-nav-wrap">
        <Container>
          <Row>
            <Nav pills>
              <NavItem>
                <NavLink
                  className={`tab-button ${tab === "1" ? "active" : ""}`}
                  onClick={() => setTab("1")}
                >
                  My Payment
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`tab-button ${tab === "2" ? "active" : ""}`}
                  onClick={() => setTab("2")}
                >
                  My Other Fund
                </NavLink>
              </NavItem>
            </Nav>

            {tab === "1" && (
              <>
                <MyPaymentData
                  memberPaymentData={memberPaymentData}
                  isLoading={memberPaymentLoading}
                  setStartDate={setStartDate}
                  startDate={startDate}
                />
              </>
            )}
            {tab === "2" && (
              <>
                <Myotherfund memberPaymentData={memberPaymentData} />
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaymentIndex;
