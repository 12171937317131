import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { MemberShipNotificationinitialValues } from "./FormikValidation";
import { api } from "../../../../../Api/Api";
import { setValidationErrors } from "../../../../../Utils/helpers/helpers";

type AddMembershipInitialValues = {
  sms: string;
  whatsapp: string;
  email: string;
};

type AddMeetingsInitialValues = {
  sms: string;
  whatsapp: string;
  email: string;
};

type AddOtherfundInitialValues = {
  sms: string;
  whatsapp: string;
  email: string;
};

export const MembershipNotificationSubmitHandler = (
  values: AddMembershipInitialValues,
  actions: FormikHelpers<AddMembershipInitialValues>
) => {
  // Add

  api
    .put(`/staff`, values, true)
    .then(async function ([success, response]: any) {
      toast.success("Staff Details Updated ");
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};
export const MeetingsNotificationSubmitHandler = (
  values: AddMeetingsInitialValues,
  actions: FormikHelpers<AddMeetingsInitialValues>
) => {
  // Add

  api
    .put(`/staff`, values, true)
    .then(async function ([success, response]: any) {
      toast.success("Staff Details Updated ");
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};
export const OtherfundNotificationSubmitHandler = (
  values: AddOtherfundInitialValues,
  actions: FormikHelpers<AddOtherfundInitialValues>
) => {
  // Add

  api
    .put(`/staff`, values, true)
    .then(async function ([success, response]: any) {
      toast.success("Staff Details Updated ");
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};
// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  if (data?.data?.length === undefined) {
    const res = data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    });
    return res;
  } else {
  }
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      office_name: "",
      phone_number: "",
      email: "",
      district: "",
      block: "",
      panchayat: "",
      pincode: "",
      location: "",
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(MemberShipNotificationinitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/staff/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  navigate(`/admin/office-management/${pageNo}`);
};
