import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  AddMeetingsInitialValues,
  MeetingsValidationSchema,
} from "./AddAttendanceMethods/FormikValidation";
import {
  MeetingSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddAttendanceMethods/AddAttendanceModalMethods";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { MEETING_TYPE } from "../../../../../Utils/Constants/selection_options";

const AddAttendance = ({ setIsOpen, modalEditData, refetch, meeting }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddMeetingsInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [imagefile, setImageFile] = useState("");
  const [image_change, setImageChange] = useState(false);
  const [type_file, setFileType] = useState();
  const create_imageURL = (imgfile: any) => {
    setImageFile(URL?.createObjectURL(imgfile));
    setFileType(imgfile?.type);
    setImageChange(true);
  };
  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);


  return (
    <Formik
      enableReinitialize
      validationSchema={MeetingsValidationSchema}
      initialValues={addModalInitialValues}

      onSubmit={(values, actions) => {
        MeetingSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        console.log(errors, "errors");
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit} enctype="multipart/form-data">
              <Row className="gy-4">

                <Col lg={12}>
                  <Label>Meeting Name</Label>
                  <Select
                    placeholder="Select meetings"
                    name="title"
                    options={meeting}
                    value={
                      meeting?.filter(
                        (itm: any) => itm?.value === values?.title
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("title", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Meeting"}
                    noOptionsMessage={() => "Meetings appears here"}
                    isSearchable={true}
                    isClearable
                  />
                </Col>


                <Col md={12}>


                  <Label>Magazine Document</Label>

                  <Input
                    type="file"
                    onChange={(e: any) => {
                      setImageFile(e.target.files[0]);
                      setFieldValue("document_upload", e.target.files[0]);
                      create_imageURL(e.target.files[0]);
                    }}
                    name="document_upload"
                    placeholder="Body"
                    className="mb-3"
                  />
                  {modalEditData && modalEditData[0] && (
                    <>
                      {modalEditData[0]?.meeting_document?.includes("pdf") ? (
                        <embed src={modalEditData[0].meeting_document} />
                      ) : (
                        <img src={modalEditData[0].meeting_document} />
                      )}
                    </>
                  )}


                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAttendance;
