import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLeaveListAdmin, getOfficeList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddLeave/AddLeaveMethods/AddLeaveModalMethods";
import { handleAdminStaffSearch } from "./LeaveMethods";
import AddLeave from "./AddLeave";
import Select from "react-select";
import { LEAVE_TYPES } from "../../../../Utils/Constants/selection_options";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";


const AdminLeaveIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [value, setValue] = useState({
    leave_type: "",
  });
  const handleChange = (e: any) => {
    setValue({
      ...value,
      leave_type: e,
    });
  };
  const {
    data: staff,
    error: staffError,
    isLoading: staffLoading,
    refetch: staffListRefetch,
  }: any = useQuery(
    [
      `leave${currentPage + searchQuery + value?.leave_type}`,
      currentPage,
      searchQuery,
      value?.leave_type,
    ],
    () => getLeaveListAdmin(currentPage, searchQuery, value?.leave_type),
    { keepPreviousData: true }
  );
  const staffData = useMemo(() => extractStaffData(staff), [staff]);
  
  const breadcrumbItems = [
    { label: "Settings", link: "/member/settings" },
    { label: "Leave Management", link: "/member/leave-management/1" },
  ];
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>

      <div className="content-outer-wrap">
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="select-wrap-spacing">
              <Select
                placeholder="Select Leave Type"
                options={LEAVE_TYPES}
                value={
                  LEAVE_TYPES?.filter(
                    (itm: any) => itm?.value === value?.leave_type
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value)}
                name="leave_type"
                isLoading={false}
                loadingMessage={() => "Fetching Leave type"}
                noOptionsMessage={() => "Leave Type appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            <Button
                color="primary"
                className="sm"
                outline
                onClick={() => {
                  setValue({
                    leave_type: ""
                  });
                }}
              >
                Reset
              </Button>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {/* <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Leave
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle="Leave Details"
            tableFields={[
              "User",
              "Leave Type",
              "Start date",
              "End Date",
              "Comment",
            ]}
            Arraykeys={[
              "user_name",
              "leave_type",
              "start_date",
              "end_date",
              "comment",
            ]}
            viewType="table"
            toURL={""}
            // actions={[
            //   checkPermission("leave:write") ? "edit" : "",
            //   checkPermission("leave:delete") ? "delete" : "",
            // ]}
            isLoading={staffLoading}
            error={staffError}
            onEditClick={(selectedId: any) => { if (checkPermission("leave:write")) onEditClickHandler(selectedId, setModalEditData, setIsOpen, staff) }
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, staffListRefetch)
            }
            paginationData={staff?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo, pathname)
            }
            from_leave={true}
            page_number={currentPage}
            searchQuery={searchQuery}
            Refetch={staffListRefetch}
          />
        </div>
      </div>
      </Container>


      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Leave" : "Add Leave"}
      >
        <AddLeave
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={staffListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default AdminLeaveIndex;
