import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddLeaveInitialValues,
  LeaveValidationSchema,
} from "./AddLeaveMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddLeaveMethods/AddLeaveModalMethods";
import {
  LEAVE_TYPES,
  PRESENT_EMPLOYMENT_STATUS,
} from "../../../../../Utils/Constants/selection_options";

const AddLeave = ({ setIsOpen, modalEditData, refetch }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddLeaveInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);
  const [imageView, seImageView] = useState("");
  const [imagefile, setImageFile] = useState("");
  const [image_change, setImageChange] = useState(false);
  const [type_file, setFileType] = useState();
  const create_imageURL = (imgfile: any) => {
    seImageView(URL?.createObjectURL(imgfile));
    setFileType(imgfile?.type);
    setImageChange(true);
  };

  return (
    <Formik
      validationSchema={LeaveValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData,
          image_change
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        console.log(errors,"errors")
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={6}>
                  <Label>Present Employee Status</Label>
                  <Select
                    placeholder="Select Working Status"
                    name="present_employement_status"
                    options={PRESENT_EMPLOYMENT_STATUS}
                    value={
                      PRESENT_EMPLOYMENT_STATUS?.filter(
                        (itm: any) =>
                          itm?.value === values?.present_employement_status
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("present_employement_status", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Present Employee Status"}
                    noOptionsMessage={() =>
                      "Present Employee Status appears here"
                    }
                    isSearchable={true}
                  />
                  <ErrorMessage
                    name="present_employement_status"
                    component={TextError}
                  />
                </Col>
                <Col md={6}>
                  <Label>Leave Type</Label>
                  <Select
                    placeholder="Select Working Status"
                    name="leave_type"
                    options={LEAVE_TYPES}
                    value={
                      LEAVE_TYPES?.filter(
                        (itm: any) => itm?.value === values?.leave_type
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("leave_type", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Leave Type"}
                    noOptionsMessage={() => "Leave Type appears here"}
                    isSearchable={true}
                  />
                  <ErrorMessage name="leave_type" component={TextError} />
                </Col>
                {values?.leave_type === "6" && (
                  <>
                    <Col md={6}>
                      <Label>Other Reson</Label>
                      <Input
                        type="textarea"
                        onChange={handleChange}
                        name="other_leave_type"
                        placeholder="Other Reason"
                        value={values?.other_leave_type}
                      />
                      <ErrorMessage
                        name="other_leave_type"
                        component={TextError}
                      />
                    </Col>
                  </>
                )}
                <Col md={6}>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    name="start_date"
                    placeholder="Start Date"
                    value={values?.start_date}
                  />
                  <ErrorMessage name="start_date" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>End Date</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    name="end_date"
                    placeholder="End Date"
                    value={values?.end_date}
                  />
                  <ErrorMessage name="end_date" component={TextError} />
                </Col>
                {/* <Col md={6}>
                  <Label>GO Document</Label>
                  <Input
                    type="file"
                    onChange={(e: any) => {
                      setImageFile(e.target.files[0]);
                      setFieldValue("sanction_document", e.target.files[0]);
                      create_imageURL(e.target.files[0]);
                    }}
                    name="sanction_document"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="sanction_document"
                    component={TextError}
                  />
                </Col> */}
                {type_file === "application/pdf" ? (
                  <div>
                    <embed src={imageView} />
                  </div>
                ) : (
                  <img
                    src={
                      image_change === true
                        ? imageView
                        : modalEditData?.[0]?.sanction_doc
                        ? modalEditData?.[0]?.sanction_doc
                        : ""
                    }
                    alt={modalEditData?.[0]?.sanction_doc}
                  />
                )}

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddLeave;
