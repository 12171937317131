import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddAccomadation from "./AddAccomadation";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddAccomadation/AddAccomadationMethods/AddAccomadationModalMethods";
import {
  getAccomadtionList, getDistrict, getaccommodationFullLists,
} from "../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "./AccomadationMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import Select from "react-select";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const AccomadationIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { checkPermission } = usePermission();
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false);
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [value, setValue] = useState({
    district_id: "",
  });
  const {
    data: accommodation,
    error: accommodationError,
    isLoading: accommodationLoading,
    refetch: accommodationListRefetch,
  }: any = useQuery(
    [`accomadition${currentPage + searchQuery}`, currentPage, searchQuery,value?.district_id],
    () => getAccomadtionList(currentPage, searchQuery,value?.district_id),
    { keepPreviousData: true }
  );

  const handleChange = (e: any, feild_name: any) => {
    if (feild_name === "district_id") {
      setValue({
        ...value,
        district_id: e,
      });
    }
  };

  const {
    data: accommodationlist,
    error: accommodationlisterror,
    isLoading: accommodationlistLoading,
    refetch: accommodationlistRefetch,
  }: any = useQuery([`ofice-full-list`], () => getaccommodationFullLists(), {
    keepPreviousData: true,
  });

  const staffData = useMemo(
    () => extractStaffData(accommodation),
    [accommodation]
  );
  const {
    data: districtList,
    error: districError,
    isLoading: districtLoading,
    refetch: districtListRefetch,
  }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });


  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>

            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={districtList}
                value={
                  districtList?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "district_id")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>
            
            <div className="right-side-wrap">
                {checkPermission("accommodation:write") ? 
                  <div className="button-wrap">
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Accommodation
                    </Button>
                  </div>
                : null}
                <Button outline
                color="primary" className="sm"
                onClick={() => {
                  setValue({
                    district_id: "",
                  });
                  setSearchQuery("");
                }}
              >
                Reset
              </Button>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Accommodation Details"
              tableFields={[
                "Name",
                "Accommodation Manager Details",
                "Alternative Phone Number",
                "District",
                "Number of Rooms",
                "Room Occupied",
                "Phone Number",

              ]}
              Arraykeys={[
                "office_name",
                "acc_manager_details",
                "alternative_phone_no",
                "office_district",
                "no_of_rooms",
                "room_occupied",
                "phone_no",

              ]}
              viewType="table"
              toURL={""}
              // actions={["edit", "delete"]}
              actions={[
                checkPermission("accommodation:write") ? "edit" : "",
                checkPermission("accommodation:delete") ? "delete" : "",
              ]}
              isLoading={accommodationLoading}
              error={accommodationError}
              onEditClick={(selectedId: any) => {
                if (checkPermission("accommodation:write")) {
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    accommodation
                  )
                }
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, accommodationListRefetch)
              }
              paginationData={accommodation?.data?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
            />
          </div>
        </Container>
      </div>
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0 ? "Edit Accomadation" : "Add Accomadation"
        }
      >
        <AddAccomadation
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={accommodationListRefetch}
          office={accommodationlist}
        />
      </ModalContainer>
    </section>
  );
};

export default AccomadationIndex;
