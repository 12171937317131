
export const handleAdminStaffSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    const userRole = localStorage.getItem("ROLE");
    if (userRole === "admin") {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/admin/member-management/1`);
      } else {
        setSearchQuery("");
      }
    } else {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/member/member-management/1`);
      } else {
        setSearchQuery("");
      }
    }
  };
  
export const handleassociatememberStaffSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    const userRole = localStorage.getItem("ROLE");
    if (userRole === "admin") {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/admin/member-management/1/2`);
      } else {
        setSearchQuery("");
      }
    } else {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/member/member-management/1/2`);
      } else {
        setSearchQuery("");
      }
    }
  };

export const handleretirememberStaffSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function,
  ) => {
    const userRole = localStorage.getItem("ROLE");
    if (userRole === "admin") {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/admin/member-management/1/3`);
      } else {
        setSearchQuery("");
      }
    } else {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/member/member-management/1/3`);
      } else {
        setSearchQuery("");
      }
    }
  };

// export const handleretirememberStaffSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
//     if (e?.target?.value?.length > 2) {
//         setSearchQuery(e?.target?.value)
//         navigate(`/admin/member-management/1/3`);
//     }
//     else {
//         setSearchQuery('')
//     }
// }