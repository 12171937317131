import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  AccomadationValidationSchema,
  AddAccomadationInitialValues,
} from "./AddAccomadationMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddAccomadationMethods/AddAccomadationModalMethods";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";

const AddAccomadation = ({
  setIsOpen,
  modalEditData,
  refetch,
  office,
}: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddAccomadationInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={AccomadationValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {


        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={6}>
                  <Label>Name</Label>
                  <Select
                    placeholder="Select Name"
                    name="office"
                    options={office}
                    value={
                      office?.filter(
                        (itm: any) => itm?.value === values?.office
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("office", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Name"}
                    noOptionsMessage={() => "Names will appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="office" component={TextError} />
                </Col>

                <Col md={6}>
                  <Label>Rooms Available</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    name="room_availble_count"
                    value={values?.room_availble_count}
                    placeholder="Rooms available"
                  />
                  <ErrorMessage
                    name="room_availble_count"
                    component={TextError}
                  />
                </Col>
                <Col md={6}>
                  <Label>Room Occupied Count</Label>
                  <Input
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onChange={handleChange}
                    name="room_occupied_count"
                    value={values?.room_occupied_count}
                    placeholder="Room occupied Count"
                  />
                  <ErrorMessage
                    name="room_occupied_count"
                    component={TextError}
                  />
                </Col>
                <Col md={6}>
                  <Label>Phone Number</Label>
                  <Input
                    type="tel"
                    onChange={handleChange}
                    name="phone_number"
                    value={values?.phone_number}
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="phone_number" component={TextError} />
                </Col>

                <Col md={12}>
                  <Label>Alternative Phone Number</Label>
                  <Input
                    type="tel"
                    onChange={handleChange}
                    name="alter_phone_number"
                    value={values?.alter_phone_number}
                    placeholder="Alternative Phone Number"
                  />
                  <ErrorMessage
                    name="alter_phone_number"
                    component={TextError}
                  />
                </Col>
                <Col md={12}>
                  <Label>Accomodation Manager Details</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    name="manager_name"
                    value={values?.manager_name}
                    placeholder="Accomodation Manager Details"
                  />
                  <ErrorMessage
                    name="manager_name"
                    component={TextError}
                  />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAccomadation;
