import { ErrorMessage, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import {
  FileSubmitHandler,
  addFileInitialValuesHandler,
  addModalInitialValuesHandler,
  // getAllSubcategory,
  handleFileChange,
  handleRowRemove,
} from "./AddSubDownloadMethods/AddDownloadModalMethods";
import {
  AddDownloadInitialValues,
  DownloadValidationSchema,
} from "./AddSubDownloadMethods/FormikValidation";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { useQuery } from "react-query";
import Select from "react-select";
import TableData from "../../../../../Components/Containers/TableData";

const AddFile = ({ setIsOpen, modalEditData, refetch, id }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddDownloadInitialValues
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [files, setFiles] = useState([
    {
      pdfname: null,
      name: "",
      original: null,
    },
  ]);

  useEffect(() => {
    addFileInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode,
      setFiles,
      id
    );
  }, [modalEditData]);

//   const {
//     data: subCategoryList
//   }: any = useQuery(
//     [`AllSubcategoryList`, id],
//     () => getAllSubcategory(id),
//     { 
//       keepPreviousData: true,
//     }
//   );

  return (
    <Formik
      validationSchema={DownloadValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        FileSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          files,
          
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.category_name}
                    name="category_name"
                    placeholder="Category name"
                    disabled
                  />
                  <ErrorMessage name="category_name" component={TextError} />
                </Col>
                <FieldArray name="categories">
                  {({ push, remove }) => (
                    <>
                      {values?.categories?.map((_, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="download-form-group-wrap">
                                <Col md={12}>
                                  <Label>Document</Label>
                                  <Input
                                    name={`categories.${index}.pdfname`}
                                    type="file"
                                    placeholder="Document name"
                                    accept=".pdf"
                                    onChange={(e: any) =>
                                      handleFileChange(
                                        e,
                                        setFiles,
                                        setFieldValue,
                                        index
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`categories.${index}.pdfname`}
                                    component={TextError}
                                  />
                                </Col>
                                <Col md={12} className="my-4">
                                  <Label>File Name</Label>
                                  <Input
                                    type="text"
                                    name={`categories.${index}.name`}
                                    value={files?.[index]?.name}
                                    onChange={(e: any) =>
                                      handleFileChange(
                                        e,
                                        setFiles,
                                        setFieldValue,
                                        index
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`categories.${index}.name`}
                                    component={TextError}
                                  />
                                </Col>
                            {files?.length !== 0 &&
                              files?.map((it: any, i: number) => {
                                return (
                                  <>
                                    {index === i && (
                                      <Col md={12} key={i}>
                                        {(it?.original || it?.pdfname) && (
                                          <>
                                            {it?.original ? (
                                              <embed
                                                src={URL.createObjectURL(
                                                  it?.original
                                                )}
                                                type={it?.original?.type}
                                              />
                                            ) : (
                                              <embed
                                                src={it?.pdfname}
                                                type={it?.original?.type}
                                              />
                                            )}
                                          </>
                                        )}
                                      </Col>
                                    )}
                                  </>
                                );
                              })}
                            {values?.categories?.length > 1 && (
                              <div className="close-icon-wrap">
                                 <i
                                  onClick={() =>
                                    handleRowRemove(index, remove, setFiles)
                                  }                            
                                  className="icon icon-close-red"
                                ></i>
                              </div>
                            )}
                            </div>
                            <hr />
                            
                          </React.Fragment>
                        );
                      })}

                      <div className="add-wrap">
                        <i
                          className="icon xl icon-plus-new"
                          onClick={() => push({ name: "", pdfname: "" })}
                        ></i>
                      </div>
                    </>
                  )}
                </FieldArray>
                <Col md={12}>
                  <Row className="gy-4 mt-2 image-input-show-wrap">
                    {/* {existingFiles?.map((it: any, i: number) => {
                      return (
                        <Col md={6} key={i}>
                          <div >
                            <div >
                              <embed src={it?.pdfname} />
                            </div>
                            <p onClick={() => handleRemoveExistingDoc(it?.id, refetch)}>Remove</p>
                            <span>{it?.name}</span>
                          </div>
                        </Col>)
                    })} */}
                  </Row>
                </Col>
                {/* <Col lg={12}>
                  <Label>Parent Category</Label>
                  <Select
                    placeholder="Select Parent Category"
                    name="parent_id"
                    options={subCategoryList}
                    value={
                      subCategoryList?.filter(
                        (itm: any) => itm?.value === values?.parent_id
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("parent_id", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Parent Category"}
                    noOptionsMessage={() => "Parent Category appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="parent_id" component={TextError} />
                </Col> */}
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddFile;
