import { Col, Form, Input, Label, Row } from "reactstrap";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { ErrorMessage, Formik } from "formik";
import { OfficeCategoryValidationSchema } from "../AddOfficeCategory/AddOfficeCategoryMethods/FormikValidation";
import { StaffSubmitHandler } from "../AddOfficeCategory/AddOfficeCategoryMethods/AddOfficeCategoryModalMethods";
import TextError from "../../../../../Components/FormikError/TextError";
import Select from "react-select";

const ExistCategory = ({
  setIsOpen,
  refetch,
  editMode,
  modalEditData,
  addModalInitialValues,
  edit_cat,
  office_cat,
  district,
  setDistrictId,
  block,
}: any) => {
  return (
    <>
      <Formik
        validationSchema={OfficeCategoryValidationSchema}
        initialValues={addModalInitialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          StaffSubmitHandler(
            values,
            actions,
            setIsOpen,
            refetch,
            editMode,
            modalEditData?.id,
            edit_cat
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          errors,
        }) => {
          return (
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col lg={12}>
                    <Select
                      placeholder="Select office Category"
                      name="office_cat_name"
                      options={office_cat}
                      value={
                        office_cat?.find(
                          (itm: any) => itm.value === values?.office_cat_name
                        ) || null
                      }
                      onChange={(e: any) => {
                        setFieldValue("office_cat_name", e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching office Category"}
                      noOptionsMessage={() => "No options available"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage
                      name="office_cat_name"
                      component={TextError}
                    />
                  </Col>
                  <Col lg={12}>
                    <Label>District</Label>
                    <Select
                      placeholder="Select District"
                      name="district_id"
                      options={district ? district : []}
                      value={
                        district?.find(
                          (itm: any) => itm.value === values?.district_id
                        ) || null
                      }
                      onChange={(e: any) => {
                        setFieldValue("district_id", e?.value);
                        setDistrictId(e?.value);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching District"}
                      noOptionsMessage={() => "No options available"}
                      isSearchable={true}
                      isClearable
                    />
                    <ErrorMessage name="district_id" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <Label>Block</Label>
                    <Select
                      placeholder="Select Block"
                      name="block_id"
                      options={block ? block : []}
                      value={
                        block?.length !== 0
                          ? block?.filter((itm: any) =>
                              values?.block_id?.includes(itm?.value)
                            )
                          : []
                      }
                      onChange={(selectedOptions: any) => {
                        const selectedValues = selectedOptions.map(
                          (option: any) => option.value
                        );
                        setFieldValue("block_id", selectedValues);
                      }}
                      isLoading={false}
                      loadingMessage={() => "Fetching Block"}
                      noOptionsMessage={() => "No options available"}
                      isSearchable={true}
                      isClearable
                      isMulti
                    />
                  </Col>
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ExistCategory;
