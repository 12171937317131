import * as Yup from "yup";

export const MetaValidationSchema = Yup.object().shape({
  meta_body: Yup.string().required("Description is required").nullable(),
  meta_title: Yup.string().required("Title is required").nullable(),
});

export const AddMetaInitialValues = {
  meta_body: "",
  meta_title: "",
  status: "",
};
