import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddOfficeInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeFirst,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  office_name: string;
  phone_number: string;
  email: string;
  district: string;
  block: string;
  panchayat: string;
  pincode: string;
  location: string;
  latitude: string;
  longitude: string;
  state: string;
  address: string;
  office_no: string;
  office_cat_id: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .patch(
        `/office/${id}`,
        {
          name: values?.office_name,
          phone_no: values?.phone_number,
          address: values?.address,
          block_id: parseInt(values?.block),
          district_id: parseInt(values?.district),
          panchayath_id: parseInt(values?.panchayat),
          office_no: values?.office_no,
          email: values?.email,
          pincode: parseInt(values?.pincode),
          office_category_id: parseInt(values?.office_cat_id),
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Office Details Updated ",{toastId:"OfficeUpdated"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"OfficeUpdatederror"});
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/office",
        {
          name: values?.office_name,
          phone_no: values?.phone_number,
          address: values?.address,
          block_id: parseInt(values?.block),
          district_id: parseInt(values?.district),
          panchayath_id: parseInt(values?.panchayat),
          office_no: values?.office_no,
          email: values?.email,
          pincode: parseInt(values?.pincode),
          office_category_id: parseInt(values?.office_cat_id),
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Office Added ",{toastId:"Officeadded"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Officeaddederror"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const res = data?.items?.map((item: any, index: number) => {
    return {
      id: item?.id,
      name: item?.name,
      phone_no: item?.phone_no,
      block: capitalizeFirst(item?.block_name),
      panchayath: capitalizeFirst(item?.panchayath?.name),
      pincode: item?.pincode,
      address: item?.address,
      office_no: item?.office_no,
      office_category: item?.office_category_name,
      member_status: item?.member_status,
      total_seat_count: item?.total_seat_count,
      occupied_seat_count: item?.occupied_seat_count,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.id) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.id,
      office_name: modalEditData?.name,
      phone_number: modalEditData?.phone_no,
      email: modalEditData?.email,
      district: modalEditData?.district?.id,
      block: modalEditData?.block?.id,
      panchayat: modalEditData?.panchayath?.id,
      pincode: modalEditData?.pincode,
      location: modalEditData?.location,
      latitude: modalEditData?.latitude,
      longitude: modalEditData?.longitude,
      address: modalEditData?.address,
      office_cat_id: modalEditData?.office_category?.id,
      office_no: modalEditData?.office_no,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddOfficeInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any,
  officeListRefetch: Function
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );
  officeListRefetch();
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/office/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Officedeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Officedeletederror"});
    });
};

export const setCurrentPageStaff = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/office-management/${pageNo}`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/office-management/${pageNo}`);
  }
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .patch(
      `/office/member/status-update`,
      {
        office_id: selectedId,
        memberStatus: newStatus,
      },
      true
    )
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated",{toastId:"statusupdate"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"statusupdateerro"});
    });
};
