import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddAssetCategoryInitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  name: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .patch(
        `/assets-categories/${id}`,
        {
          name: values?.name,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Seats Details Updated ",{toastId:"Seatupdate"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Seatupdaterr"});
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/assets-categories",
        {
          name: values?.name,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Seats Added",{toastId:"Seatadd"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"Seatadderr"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      name: modalEditData?.[0]?.name,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddAssetCategoryInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/assets-categories/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"Seatdelete"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"Seatdeleterr"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any, pathname: string) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/asset-category-settings/${pageNo}`)
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/asset-category-settings/${pageNo}`)
  }
};
