import { api } from "../../../../../Api/Api";
import { BaseUrl } from "../../../../../Api/BaseUrl";

export const extractStaffData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const res = data?.data?.items?.map((item: any, index: number) => {
    return {
      id: item?.id,
      approved_members_count: item?.approved_members_count,
      total_office_count: item?.total_office_count,
      pending_approved_member_count: item?.pending_approved_member_count,
      district_name: item?.district_name,
      submitted_member_count: item?.submitted_member_count,
      not_submitted: item?.not_submitted,
      active_member: item?.active_member_count,
      total_seat_count:item?.total_seat_count
    };
  });
  return res;
};

export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    setSearchQuery(e?.target?.value);
  } else {
    setSearchQuery("");
  }
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    navigate(`/admin/loginhit-report/${pageNo}`);
  } else if (userRole === 'member') {
    navigate(`/member/loginhit-report/${pageNo}`);
  }
};

export const handleDownloadClick = (year: string, designation: string) => {
  const requestData = {
    year,
    designation,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Content-Type": "application/json",
      responseType: "blob",
    },
    body: JSON.stringify({ requestData }),
  };
  fetch(`${BaseUrl}/exports/member-status`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "LoginHit.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      return blob;
    })
    .catch((e) => {
      console.log(e, ">>>");
    });
};
