import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAssociate from "./AddAssociate";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddAssociate/AddAssociateMethods/AddAssociateMethods";
import { getCadreList } from "../../../../Api/AdminApis";

const AssociateIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    data: cadre,
    error: cadreError,
    isLoading: cadreLoading,
    refetch: cadreListRefetch,
  }: any = useQuery(
    [`cadre${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getCadreList(currentPage, searchQuery),
    {
      keepPreviousData: true,
    }
  );

  const cadreData = useMemo(() => extractStaffData(cadre), [cadre]);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input placeholder="Search here..." />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap"></div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={cadreData}
              tableTitle="Associate Details"
              tableFields={["Name"]}
              Arraykeys={["name"]}
              viewType="table"
              toURL={""}
              actions={["edit", "delete"]}
              isLoading={cadreLoading}
              error={cadreError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  cadre
                )
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, cadreListRefetch)
              }
              paginationData={cadre?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo)
              }
              from_associate_request={true}
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Associate" : "Add Associate"}
      >
        <AddAssociate
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={cadreListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default AssociateIndex;
