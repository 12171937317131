import * as Yup from 'yup';
import { validateEmail, validatePhone } from './FormikCommonMethods';

// Common validation schemas
// phone number 
const phoneSchema = Yup.string().matches(/^\d{10}$/, 'Invalid phone number');

// Age 
const ageSchema = Yup.number().typeError('Age must be a number').required('Age is required').integer('Age must be an integer').min(0.1, 'Invalid age').max(120, 'Invalid age')

// email 
const emailSchema = Yup.string().email('Invalid email format');

// Date of birth 
const dobSchema = Yup.date().max(new Date(), 'Date of birth cannot be in the future').min(new Date('1900-01-01'), 'Invalid date of birth').required('Date of birth is required');

// password 
const PasswordSchema = Yup.string().min(8, "Password must be at least 8 characters").max(40, "Password must not exceed 40 characters")

// phone or email field 
const PhoneOrEmailSchema = Yup.string().test('email_or_phone', 'Email / Phone is invalid', (value: any) => validateEmail(value) || validatePhone(parseInt(value ?? '0')))

export { phoneSchema, emailSchema, PhoneOrEmailSchema, PasswordSchema, ageSchema, dobSchema }