import * as Yup from "yup";
import { PasswordSchema } from "../../../../Utils/Formik/FormikCommonValidationSchemas";

// validation schema
export const LoginValidation = Yup.object().shape({
  email: Yup.string().required("Field is required").nullable(),
  password: PasswordSchema.required("Password is required"),
  remember_me: Yup.boolean().typeError("Invalid Value"),
});

// initial values
export const initializeLoginInitialValues = () => {
  return {
    email: localStorage.getItem("USERNAME") || "",
    password: localStorage.getItem("PASSWORD") || "",
    remember_me: localStorage.getItem("USERNAME") ? true : false,
  };
};
