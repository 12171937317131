const constants = {
  USER_ROLE: {
    ADMIN: 1,
    USER: 2,
  },

  STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  DEFAULT_IMAGE: {
    IMAGE: "/images/patient-avatar.webp",
  },
  EMPLOYEE_STATUS: {
    STATUTORY_PENSION: 1,
    NEW_PENSION_SCHEME: 2,
  },
  JOIN_STATUS: {
    EXUSER: 1,
    NEWUSER: 2,
  },
  MEMBERSHIP_TYPE: {
    ANNUALMEMBER: 1,
    RETIRED_MEMBER: 2,
    LIFE_MEMBER: 3,
  },
  WORKING_STATUS: {
    WORKING: 1,
    RETIRED: 2,
    TRANSIT: 3,
  },
  PRESENT_EMPLOYMENT_STATUS: {
    DEPARTMENT: 1,
    DEPUTATION: 2,
    LEAVE: 3,
    WORKING_ARRANGEMENT: 4,
  },
  EMPLOYMENT_STATUS_VIEW: [
    { value: 1, name: "Department" },
    { value: 2, name: "Deputation" },
    { value: 3, name: "Leave" },
    { value: 4, name: "Working Arrangement" },
  ],
  LEAVE_STATUS: {
    PENDING: 1,
    ACCEPETED: 2,
    REJECTED: 3,
  },
  TRANSFER_REQ_STATUS: {
    PENDING: "1",
    ACCEPETED: "2",
    REJECTED: "3",
  },
  LEAVE_TYPES: {
    Leave_for_study_purpose: 1,
    Leave_for_joining_spouse_abroad: 2,
    Leave_for_taking_employment_abroad: 3,
    Maternity_leave: 4,
    LPR: 5,
    Others: 6,
  },
  MEETING_TYPE: {
    STATE: 1,
    COUNSEL: 2,
  },
  FUND_PUBLISH: {
    UN_PUBLISH: false,
    PUSBLISHED: true,
  },
  MEMBER_ACTIVE_REVOKE: {
    ACTIVE: 1,
    REVOKE: 2,
  },
  PEST_DISEASE_DEFICIENCY:[
    { value: 1, name: "Pest" },
    { value: 2, name: "Disease" },
    { value: 3, name: "Deficiency" },
  ]
};

export default constants;
