import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AddUserinitialValues } from "./FormikValidation";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";

type AddInitialValues = {
  role_status: any;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  data: any,
  setData: any
) => {
  // Add
  if (id) {
    api
      .post(
        `/user-manage/assign`,
        {
          users: [data?.[0]?.user?.id],
          roles: values?.role_status,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Updated",{toastId:"userupdate"});
        setIsOpen(false);
        refetch();
        setData([]);
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"userupdaterr"});
        }
      });
  }
  // Edit
  else {
    api
      .post(
        "/user-manage/assign",
        { users: data, roles: values?.role_status },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success("Role assigned",{toastId:"userassigned"});
        setIsOpen(false);
        refetch();
        setData([]);
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"usererr"});
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      user_id: item?.user?.id,
      user: item?.user?.first_name,
      pen_no: item?.pen_no,
      role: item?.user?.roles?.map((role: any) => role.name).join(", "),
      status: item?.user?.is_active,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    let new_array: any = [];
    modalEditData?.[0]?.user?.roles?.map((item: any) => {
      new_array?.push(item?.id);
    });
    
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      role_status: new_array,
      users: [modalEditData?.[0]?.user?.id],
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddUserinitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/user-manage/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"userdeleted"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"userdeleteder"});
    });
};

export const activeRevokeClickHandler = (
  selectedId: string | number,
  status: any,
  refetch: Function,
  setIsOpen: any
) => {
  api
    .post(
      `/users/change-status`,
      {
        user_id: selectedId,
        status: status,
      },
      true
    )
    .then(async function ([success, response]: any) {
      setIsOpen(false);
      refetch();
      toast.success("Updated Successfully",{toastId:"userstatus"});
    })
    .catch((err) => {
      // toast.error("Unexpected Error Occurred");
    });
};

// export const onClickCheckBox = (selectedId: any, refetch: any) => {
//   api
//     .delete(`/staff/${selectedId}`, {}, true)
//     .then(async function ([success, response]: any) {
//       refetch();
//       toast.success("Deleted");
//     })
//     .catch((err) => {
//       toast.error("Unexpected Error Occurred");
//     });
// };

export const setCurrentPageStaff = (
  navigate: Function,
  pageNo: any,
  pathname: string
) => {
  if (pathname?.startsWith("/member")) {
    navigate(`/member/user-settings/${pageNo}`);
  }
  if (pathname?.startsWith("/admin")) {
    navigate(`/admin/user-settings/${pageNo}`);
  }
};
