import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Form, Input, InputGroup, Row } from "reactstrap";

import TextError from "../../../Components/FormikError/TextError";
import {
  OtpValidationSchema,
  ResetPasswordValidationSchema,
  handleOtpsend,
  handlePasswordReset,
  togglePasswordVisibility,
  tokenValidator,
} from "./ChangePasswordMethods";
import { useQuery } from "react-query";
import CustomSpinner from "../../../Components/CustomElements/Spinner/SpinnerCustom";
import CustomButton from "../../../Components/CustomElements/FormElements/CustomButton";

const OTPVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirmpassword: false,
  });
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [otpSent, setOtpSent] = useState(false);
  const { isError, isLoading: tokenValidating }: any = useQuery(
    `tokenValidator${token}`,
    () => tokenValidator(token),
    {
      keepPreviousData: true,
      enabled: token !== undefined,
      retry: 0,
    }
  );
  useEffect(() => {
    let intervalId: any = 0;

    // Function to decrement the timer every second
    const decrementTimer = () => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    };

    // Start the timer when OTP is sent
    if (otpSent && timer > 0) {
      intervalId = setInterval(decrementTimer, 1000);
    }

    // Clean up the interval when the component unmounts or when the timer reaches 0
    return () => {
      clearInterval(intervalId);
    };
  }, [otpSent, timer]);

  return (
    <>
      <section className="login-wrapper">
        <Container>
          <Row className="align-items-center p-sm-2 justify-content-center">
            <Col md={6}>
              <div className="logo-content-wrap cssanimation sequence fadeInBottom">
                <h2>Welcome to AOAOK</h2>
                <p>Uniting Agriculture for a Sustainable Future</p>
              </div>
            </Col>
            <Col md={5}>
              <div className="login-form-wrap">
                <div className="heading">
                  <h3>OTP </h3>
                </div>
                <Formik
                  validationSchema={OtpValidationSchema}
                  initialValues={{
                    phone_number: "",
                  }}
                  onSubmit={(values, actions) =>
                    handleOtpsend(values, actions, navigate,setOtpSent,setTimer)
                  }
                >
                  {({ handleSubmit, handleChange, isSubmitting, values }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-inner-wrap">
                          <Row className="gy-4">
                            <Col lg={12}>
                              <InputGroup>
                                <Input
                                  type={"text"}
                                  placeholder="Phone number"
                                  name="phone_number"
                                  onChange={handleChange}
                                  value={values?.phone_number}
                                />
                              </InputGroup>
                              <ErrorMessage
                                name="phone_number"
                                component={TextError}
                              />
                            </Col>

                            <Col lg={12}>
                              <div className="submit-btn-wrap end">
                                <CustomButton
                                  label="Get OTP"
                                  color={"secondary"}
                                  className="sm"
                                  isLoading={isSubmitting}
                                  disabled={otpSent}
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="remember-password-wrap">
                                <div>
                                  <Link to="/forgot-password">Resend? {otpSent && <p>Time remaining: {timer} seconds</p>}</Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OTPVerification;
