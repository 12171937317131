import { useEffect, useMemo, useState } from "react";
import {
  Container,
  Button,
  InputGroup,
  Input,
  Row,
  Col,
  Label,
  Form,
} from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  AssociativeMembershipSubmitHandler,
  MembershipSubmitHandler,
  addAssociativeModalInitialValuesHandler,
  addModalInitialValuesHandler,
  extractMmebershipData,
} from "./AddMemberShip/AddMembershipMethods/AddMembershipMethods";
import { ErrorMessage, Formik } from "formik";
import {
  AddAssociativemembershipInitialValues,
  AddmembershipInitialValues,
  AssociativeMembershipValidationSchema,
  MembershipValidationSchema,
} from "./AddMemberShip/AddMembershipMethods/FormikValidation";
import { getMembershipSetting, getOfficeList } from "../../../../Api/AdminApis";
import TextError from "../../../../Components/FormikError/TextError";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";
import { generateBreadcrumb } from "../../../../Components/BreadCrum/BreadCrum";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import DeleteAlertModal from "../../../../Components/Containers/AlertModal/DeleteAlertModal";
import CustomSpinner from "../../../../Components/CustomElements/Spinner/SpinnerCustom";
import moment from "moment";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";

const MemberShipIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddmembershipInitialValues
  );
  const [addAssoModalInitialValues, setAddAssoModalInitialValues] = useState(
    AddAssociativemembershipInitialValues
  );
  const {
    data: mebershipfund,
    error: mebershipError,
    isLoading: mebershipLoading,
    refetch: mebershipListRefetch,
  }: any = useQuery([`getMembershipSetting`], () => getMembershipSetting(), {
    keepPreviousData: true,
  });

  const filteredData_member_ship: any = mebershipfund?.filter(
    (item: any) => item.membership_type === 1
  );
  const filteredData_associative_ship: any = mebershipfund?.filter(
    (item: any) => item.membership_type === 2
  );

  const meetingData = useMemo(
    () =>
      addModalInitialValuesHandler(
        filteredData_member_ship,
        setAddModalInitialValues
      ),
    [mebershipfund]
  );
  const asoociativeData = useMemo(
    () =>
      addAssociativeModalInitialValuesHandler(
        filteredData_associative_ship,
        setAddAssoModalInitialValues
      ),
    [mebershipfund]
  );
  const currentDate: any = moment(new Date()).format("DD-MM-YYYY");

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      {
        label: "Settings",
        link: "/admin/settings",
      },
      {
        label: "Membership Management",
        link: "/admin/membership-settings",
      },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      {
        label: "Settings",
        link: "/member/settings",
      },
      {
        label: "Membership Management",
        link: "/member/membership-settings",
      },
    ];
  }
  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <Container>
        <div className="breadcrumb-wrap">{breadcrumb}</div>
        <div className="content-outer-wrap">
          {/* <div className="search-bar-full-wrap">
            <div className="search-bar"></div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap"></div>
              </div>
            </div>
          </div> */}
          <div className="membership-table-outer">
            <div className="membership-table-head">
              <div>
                <h6>Membership Renewal</h6>
              </div>
              <div>
                <Col lg={6}>
                  <div className="text-end">
                    {checkPermission("membership-settings:write") ? (
                      <Button
                        label="Active / Inactive"
                        color="tertiary"
                        className="sm submit-btn"
                        onClick={() => setIsOpen(true)}
                      >
                        Active
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </div>
            </div>

            {/* cards section */}
            <div className="content-list-outer-wrap">
              <Formik
                validationSchema={MembershipValidationSchema}
                initialValues={addModalInitialValues}
                enableReinitialize
                onSubmit={(values, actions) => {
                  checkPermission("membership-settings:write") &&
                    MembershipSubmitHandler(
                      values,
                      actions,
                      setIsOpen,
                      filteredData_member_ship?.[0]?.id
                    );
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldError,
                  errors,
                }) => {
                  return (
                    <div className="form-wrap">
                      <Form onSubmit={handleSubmit}>
                        {!mebershipLoading ? (
                          <Row className="gy-4">
                            <Col lg={4}>
                              <Label>Start Date</Label>
                              <Input
                                type="date"
                                onChange={handleChange}
                                value={values?.start_date}
                                name="start_date"
                                placeholder="Start date"
                                min={currentDate}
                              />
                              <ErrorMessage
                                name="start_date"
                                component={TextError}
                              />
                            </Col>
                            <Col lg={4}>
                              <Label>End Date</Label>
                              <Input
                                type="date"
                                onChange={handleChange}
                                value={values.end_date}
                                name="end_date"
                                placeholder="End date"
                                min={values.start_date || currentDate}
                              />
                              {values.end_date &&
                                values.start_date &&
                                values.end_date < values.start_date && (
                                  <div style={{ color: "red" }}>
                                    End date must be greater than start date
                                  </div>
                                )}
                            </Col>
                            <Col lg={4}>
                              <Label>Total Amount</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.amount}
                                name="amount"
                                placeholder="Amount"
                                min={1}
                              />
                              <ErrorMessage
                                name="amount"
                                component={TextError}
                              />
                            </Col>

                            <Col lg={4}>
                              <Label>State Share</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.state_share}
                                name="state_share"
                                placeholder="State share"
                                min={1}
                              />
                              <ErrorMessage
                                name="state_share"
                                component={TextError}
                              />
                            </Col>
                            <Col lg={4}>
                              <Label>District Share</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.district_share}
                                name="district_share"
                                placeholder="District Share"
                                min={1}
                              />
                              <ErrorMessage
                                name="district_share"
                                component={TextError}
                              />
                            </Col>
                            <Col lg={4}>
                              <Label>Others</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.other_share}
                                name="other_share"
                                placeholder="Other Share"
                                min={1}
                              />
                              <ErrorMessage
                                name="other_share"
                                component={TextError}
                              />
                            </Col>

                            <Col lg={12}>
                              <div className="text-end">
                                {checkPermission(
                                  "membership-settings:write"
                                ) ? (
                                  <CustomButton
                                    isLoading={isSubmitting}
                                    label="Submit"
                                    color="primary"
                                    className="sm submit-btn"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <div className="text-center">
                            <CustomSpinner />
                          </div>
                        )}
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>

          <div className="membership-table-outer">
            <div className="membership-table-head">
              <div>
                <h6>Assosciate Membership</h6>
              </div>
              <div>
                <Col lg={6}>
                  <div className="text-end">
                    {checkPermission("membership-settings:write") ? (
                      <Button
                        label="Active / Inactive"
                        color="tertiary"
                        className="sm submit-btn"
                        onClick={() => setIsOpen(true)}
                      >
                        Active
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </div>
            </div>

            {/* cards section */}
            <div className="content-list-outer-wrap">
              <Formik
                validationSchema={AssociativeMembershipValidationSchema}
                initialValues={addAssoModalInitialValues}
                enableReinitialize
                onSubmit={(values, actions) => {
                  checkPermission("membership-settings:write") &&
                    AssociativeMembershipSubmitHandler(
                      values,
                      actions,
                      setIsOpen,
                      filteredData_associative_ship?.[0]?.id
                    );
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldError,
                  errors,
                }) => {
                  return (
                    <div className="form-wrap">
                      <Form onSubmit={handleSubmit}>
                        {!mebershipLoading ? (
                          <Row className="gy-4">
                            <Col lg={4}>
                              <Label>Start Date</Label>
                              <Input
                                type="date"
                                onChange={handleChange}
                                value={values?.start_date}
                                name="start_date"
                                placeholder="Start date"
                                min={currentDate}
                              />
                              <ErrorMessage
                                name="start_date"
                                component={TextError}
                              />
                            </Col>
                            <Col lg={4}>
                              <Label>End Date</Label>
                              <Input
                                type="date"
                                onChange={handleChange}
                                value={values.end_date}
                                name="end_date"
                                placeholder="End date"
                                min={values.start_date || currentDate}
                              />
                              {values.end_date &&
                                values.start_date &&
                                values.end_date < values.start_date && (
                                  <div style={{ color: "red" }}>
                                    End date must be greater than start date
                                  </div>
                                )}
                            </Col>

                            <Col lg={4}>
                              <Label>Total Amount</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.amount}
                                name="amount"
                                placeholder="Amount"
                                min={1}
                              />
                              <ErrorMessage
                                name="amount"
                                component={TextError}
                              />
                            </Col>

                            <Col lg={4}>
                              <Label>State Share</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.state_share}
                                name="state_share"
                                placeholder="State share"
                                min={1}
                              />
                              <ErrorMessage
                                name="state_share"
                                component={TextError}
                              />
                            </Col>

                            <Col lg={4}>
                              <Label>Others</Label>
                              <Input
                                type="text"
                                onChange={handleChange}
                                value={values?.other_share}
                                name="other_share"
                                placeholder="Other Share"
                                min={1}
                              />
                              <ErrorMessage
                                name="other_share"
                                component={TextError}
                              />
                            </Col>

                            <Col lg={12}>
                              <div className="text-end">
                                {checkPermission(
                                  "membership-settings:write"
                                ) ? (
                                  <CustomButton
                                    isLoading={isSubmitting}
                                    label="Submit"
                                    color="primary"
                                    className="sm submit-btn"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <div className="text-center">
                            <CustomSpinner />
                          </div>
                        )}
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Container>

      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="">
        <DeleteAlertModal
          setModal={setIsOpen}
          type="mebership_renewwal"
          status={1}
          Refetch={mebershipListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default MemberShipIndex;
