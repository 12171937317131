import { useEffect, useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddMagazine from "./AddMagazine";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddMagazine/AddMagazineMethods/AddMagazineModalMethods";
import { handleAdminStaffSearch } from "./MagazineMethods";
import { getMagazineList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import { ROLES_PERMISSIONS } from "../../../../Utils/Constants/ROLES_PERMISSIONS";

const MagazineIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation()

  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { checkPermission } = usePermission();

  const {
    data: magazine,
    error: magazineError,
    isLoading: magazineLoading,
    refetch: magazineListRefetch,
  }: any = useQuery(
    [`magazine${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getMagazineList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(magazine), [magazine]);
  // useEffect(() => {
  //   setPermissions(["magazine:read", "OFFICE ADD", "EDIT"]);
  // }, [currentPage]);
  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                {checkPermission(ROLES_PERMISSIONS?.MAGAZINE?.WRITE) ? <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Magazine
                  </Button>
                </div> : null}
              </div>
            </div>
          </div>
          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Magazine Details"
              tableFields={["Title", "Body", "Published Date", "Status"]}
              Arraykeys={["title", "body", "release", "status_data"]}
              viewType="table"
              toURL={""}
              actions={[
                checkPermission(ROLES_PERMISSIONS?.MAGAZINE?.WRITE) ? "edit" : "",
                checkPermission(ROLES_PERMISSIONS?.MAGAZINE?.DELETE) ? "delete" : "",
                checkPermission(ROLES_PERMISSIONS?.MAGAZINE?.STATUS) ? "status" : "status",
              ]}
              isLoading={magazineLoading}
              error={magazineError}
              onEditClick={(selectedId: any) => {
                if (checkPermission(ROLES_PERMISSIONS?.MAGAZINE?.WRITE)) {
                  onEditClickHandler(
                    selectedId,
                    setModalEditData,
                    setIsOpen,
                    magazine
                  )
                }
              }
              }
              onDeleteClick={(selectedId: string | number) =>
                deleteClickHandler(selectedId, magazineListRefetch)
              }
              paginationData={magazine?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo, pathname)
              }
              from_magazine={true}
              Refetch={magazineListRefetch}
            />
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit  Magazine" : "Add  Magazine"}
      >
        <AddMagazine
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={magazineListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default MagazineIndex;
