import { useState,useEffect, useRef } from "react";
import { Collapse, Row, Container, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ModalContainer from "../../Containers/ModalContainer/ModalContainer";
import AlertModal from "../../Containers/AlertModal/AlertModal";
import { capitalizeWord } from "../../../Utils/helpers/helpers";
import { CurrentTab } from "../../../Utils/helpers/CurrentTab";
import { useQuery } from "react-query";
import { getPermissionAllList } from "../../../Api/AdminApis";

const Header = ({ ROLE, toggleMenu }: any) => {
  // Notifications
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // Messages
  const [isOpen2, setIsOpen2] = useState(false);
  const toggle2 = () => setIsOpen2(!isOpen2);

  // Profile
  const [isOpen3, setIsOpen3] = useState(false);
  const toggle3 = () => setIsOpen3(!isOpen3);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const profileRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsOpen3(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const pathname: any = window.location.pathname;

  const {
    data: permissionData,
    error: permissionError,
    isLoading: permissionLoading,
    refetch: permissionListRefetch,
  }: any = useQuery(
    ['permissions'], 
    () => getPermissionAllList(), 
    { keepPreviousData: true }
  );

  return (
    <>
      <header className="header-wrap">
        <Container>
          <div className="header-wrap-inner">
            <Col lg={12} md={12} sm={12}>
              <div className="header-full-wrap">
                <div className="header-left-wrap">
                  <span onClick={toggleMenu}>
                    <i className="icon xl icon-menu"></i>
                  </span>

                  <span>
                    <h4>{CurrentTab(pathname)}</h4>
                  </span>
                </div>

                <div className="header-right-wrap">
                  {/* Profile */}
                  <div className="notifications-wrap"  ref={profileRef}>
                    <Link
                      to="#"
                      onClick={toggle3}
                      className="admin-profile-outer"
                    >
                      <img src="/images/dp.png" alt="avatar-img" className="img-fluid w-100" />
                      <small className="admin-name">
                        Hello {permissionData?.first_name}
                      </small>
                    </Link>
                    <Collapse isOpen={isOpen3} className="profile-collapse">
                      <Card className="widget-card notification-card">
                        <CardBody className="notification-list-full-wrap">
                          <div className="notification-inner-wrap">
                            <ul className="menu-list-wrap">
                              <Link to={`/${ROLE}/settings`} onClick={toggle3}>
                                <li>
                                  <i className="icon icon-settings-2 xl"></i>
                                  Settings
                                </li>
                              </Link>

                              <Link
                                to="#"
                                onClick={() => setIsOpenLogout(true)}
                              >
                                <li>
                                  <i className="icon icon-logout xl"></i>Log out
                                </li>
                              </Link>

                              <Link to="https://aoaok.co.in/">
                                <li>
                                  <i className="icon icon-logout xl"></i>Back To
                                  Website
                                </li>
                              </Link>
                            </ul>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Container>
      </header>

      <ModalContainer
        isOpen={isOpenLogout}
        setIsopen={setIsOpenLogout}
        title=""
      >
        <AlertModal setIsOpenLogout={setIsOpenLogout} />
      </ModalContainer>
    </>
  );
};

export default Header;
