import * as Yup from "yup";

export const AssociateValidationSchema = Yup.object().shape({
  status: Yup.string().required("Field is required"),
  reject_reason:Yup.string().when(
    "status",
    ([status], sch) => {
      return status === "2"
        ? sch.required("Field is required").nullable()
        : sch.notRequired();
    }
  ),
  
});

export const AddAssociateInitialValues = {
  status: "",
  reject_reason: "",
};
