import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageDes,
} from "../AddDesignationSIngle/AddDesignationMethods/AddDesignationModalMethods";
import { getDesignationList } from "../../../../../Api/AdminApis";
import { handleAdminStaffSearch } from "../DesignationSingleMethods";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";
import AddDesignationSingle from "../AddDesignationSIngle";

const DesignationSingleIndex = () => {
  const { currentPage } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkPermission } = usePermission();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [district_id, setDistrictId] = useState();

  const [value, setValue] = useState({
    cadre_id: "",
  });

  const handleChange = (e: any, feild_name: any) => {
    if (feild_name === "cadre") {
      setValue({
        ...value,
        cadre_id: e?.value,
      });
    }
  };

  const {
    data: designation,
    error: designationError,
    isLoading: designationLoading,
    refetch: designationListRefetch,
  }: any = useQuery(
    [
      `designationlList${currentPage + searchQuery}`,
      currentPage,
      searchQuery,
      value?.cadre_id,
    ],
    () => getDesignationList(currentPage, searchQuery, value?.cadre_id),
    { keepPreviousData: true }
  );

  const designationData = useMemo(
    () => extractStaffData(designation),
    [designation]
  );

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <Col md={2}>
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    onChange={(e) =>
                      handleAdminStaffSearch(e, setSearchQuery, navigate)
                    }
                  />
                  <span className="icon-container">
                    <i className="icon xl icon-search"></i>
                  </span>
                </InputGroup>
              </div>
            </Col>

            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  {checkPermission("designation:write") ? (
                    <Button
                      onClick={() => modalHandler(setModalEditData, setIsOpen)}
                      color="primary"
                      className="sm"
                    >
                      <i className="icon icon-add-new xl"></i>Designation
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={designationData}
            tableTitle="Designation Details"
            tableFields={["Name"]}
            Arraykeys={["name"]}
            viewType="table"
            toURL={""}
            actions={[
              checkPermission("designation:write") ? "edit" : "",
              checkPermission("designation:delete") ? "delete" : "",
            ]}
            isLoading={designationLoading}
            error={designationError}
            onEditClick={(selectedId: any) => {
              if (checkPermission("designation:write"))
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  designation
                );
            }}
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, designationListRefetch)
            }
            paginationData={designation?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageDes(navigate, pageNo, pathname)
            }
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length !== 0 ? "Edit Designation" : "Add Designation"
        }
      >
        <AddDesignationSingle
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={designationListRefetch}
          setDistrictId={setDistrictId}
        />
      </ModalContainer>
    </section>
  );
};

export default DesignationSingleIndex;
