import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import {
  AddMagazineInitialValues,
  MagazineValidationSchema,
} from "./AddMagazineMethods/FormikValidation";
import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddMagazineMethods/AddMagazineModalMethods";
import { AddOfficeProps } from "../../../../../Type";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { Editor } from "@tinymce/tinymce-react";
import { TinyMCE_Token } from "../../../../../Utils/Constants/TinyMCE_Token";

const AddMagazine = ({ setIsOpen, modalEditData, refetch }: AddOfficeProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddMagazineInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [imageView, seImageView] = useState("");
  const [imagefile, setImageFile] = useState("");
  const [image_change, setImageChange] = useState(false);
  const [type_file, setFileType] = useState();
  const create_imageURL = (imgfile: any) => {
    seImageView(URL?.createObjectURL(imgfile));
    setFileType(imgfile?.type);
    setImageChange(true);
  };

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <Formik
      validationSchema={MagazineValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          imagefile
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Magazine Title</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.magazine_name}
                    name="magazine_name"
                    placeholder="Magazine Title"
                  />
                  <ErrorMessage name="magazine_name" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>Magazine Publish Date</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    value={values?.magazine_release}
                    name="magazine_release"
                    placeholder="Magazine Publish Date"
                    max={currentDate}
                  />
                  <ErrorMessage name="magazine_release" component={TextError} />
                </Col>
                <Col md={12}>
                  <Label>Magazine Body</Label>
                  <Editor
                    apiKey={TinyMCE_Token}
                    // initialValue={values?.body}
                    value={values?.magazine_body}
                    textareaName="body"
                    init={{
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar: [
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | code table help",
                      ],
                    }}
                    onEditorChange={(e: any) => {
                      setFieldValue("magazine_body", e);
                    }}
                  />
                  <ErrorMessage name="magazine_body" component={TextError} />
                </Col>
                <Col md={12}>
                  <Label>Magazine Document</Label>
                  <Input
                    type="file"
                    onChange={(e: any) => {
                      setImageFile(e.target.files[0]);
                      setFieldValue("magazine_document", e.target.files[0]);
                      create_imageURL(e.target.files[0]);
                    }}
                    name="magazine_document"
                    placeholder="Body"
                    className="mb-3"
                  />
                  <ErrorMessage
                    name="magazine_document"
                    component={TextError}
                  />


                </Col>
                {type_file === "application/pdf" ? (
                  <div>
                    <embed src={imageView} />
                  </div>
                ) : (
                  <img
                    src={
                      image_change === true
                        ? imageView
                        : modalEditData?.[0]?.magazine_document
                          ? modalEditData?.[0]?.magazine_document
                          : ""
                    }
                    alt={modalEditData?.[0]?.magazine_document}
                  />
                )}

                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="primary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddMagazine;
