import { useState, useRef } from "react";
import {
  Button, Col,
  Card,
  CardBody
} from "reactstrap";
import { useQuery } from "react-query";
import { getGalleryList, getTestimonialList } from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import {
  StatusClickHandler,
  deleteClickHandler, modalHandler,
  onEditClickHandler
} from "./AddGallery/AddGalleryMethods/AddGalleryMethods";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddTestimonial from "./AddGallery";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const Gallery = () => {
  const myDivRef: any = useRef(null);
  const { checkPermission } = usePermission();
  const [currentPage, setCurrentPage] = useState<string>('1')
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const {
    data: gallery,
    error: galleryError,
    isLoading: galleryLoading,
    refetch: galleryListRefetch,
  }: any = useQuery(
    [`gallery${currentPage}`, currentPage],
    () => getGalleryList(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };


  return (
    <>
      <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading" ref={myDivRef}>
                <h6>Gallery</h6>
              </div>
              <div className="button-container">
                {checkPermission("website:write") ? <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="primary"
                  className="sm"
                  outline
                >
                  <i className="icon icon-add-green xl"></i> Add
                </Button> : null}
              </div>
            </div>
            <CardsWithIcon
              type={"gallery"}
              data={gallery?.data}
              tableTitle="Gallery"
              Arraykeys={["title"]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={[
                checkPermission("website:delete") ? "delete" : ""
              ]}
              isLoading={galleryLoading}
              error={galleryError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  gallery
                )
              }
              onDeleteClick={(selectedId: string | number) => handleDeleteConfirmation(selectedId)}
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(
                  selectedId,
                  galleryListRefetch,
                  newStatus
                )
              }
              from={true} 
            />
          </CardBody>
        </Card>
      </Col>
      {gallery?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={gallery?.meta?.totalItems}
            itemsPerPage={gallery?.meta?.itemsPerPage}
            activePage={gallery?.meta?.currentPage}
            disableScrollAfterPageChange
            setCurrentPage={(pageNo: string) => {
              ScrollToContainerTop(myDivRef)
              setCurrentPage(pageNo)
            }}
          />
        </div>
      )}
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add  Gallery"
      >
        <AddTestimonial
          setIsOpen={setIsOpen}
          refetch={galleryListRefetch}
          modalEditData={modalEditData}
          testimonialError={galleryError}
        />
      </ModalContainer>

      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, galleryListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default Gallery;
