import { useLocation } from "react-router-dom";
import { api } from "../../../../../Api/Api";
import { BaseUrl } from "../../../../../Api/BaseUrl";
import { formatDateTime, formatDateTimeDash } from "../../../../../Utils/helpers/helpers"
import { useState, useEffect } from "react";



export const extractStaffData = (
  data: any,
  currentPage: any,
  itemsPerPage: number
) => {
  const res = data?.data?.items?.map((item: any, index: number) => {
    return {
      id: item?.id,
      user_first_name: item?.user_first_name,
      user_email: item?.user_email,
      user_last_name: item?.user_last_name,
      office_name: item?.office_name,
      district_name: item?.district_name,
      designation_name: item?.designationMeta_name,
      member_date_of_birth: formatDateTimeDash(item?.member_date_of_birth),
      member_date_of_joining_service: formatDateTimeDash(item?.member_date_of_joining_service),
      member_date_of_retirement: formatDateTimeDash(item?.member_date_of_retirement),
      member_membership_type: item?.member_membership_type,
      member_phone_no: item?.member_phone_no,
      member_pen_no: item?.member_pen_no,
      membership_status: 
      item?.membership_status === 1 ? "Active" : "Inactive",
      member_education: item?.max_degree_name,
      member_cadre_name: item?.cadre_name
    };
  });
  return res;
};

export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    setSearchQuery(e?.target?.value);
  } else {
    setSearchQuery("");
  }
};



export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {

  const userRole = localStorage.getItem('ROLE');
  if (userRole === 'admin') {
    navigate(`/admin/memberreport-list/${pageNo}`);
  } else if (userRole === 'member') {
    navigate(`/member/memberreport-list/${pageNo}`);
  }
};


export const handleDownloadClick = (district_id : any,qualification_id:any,cadre_id:any, designation_id:any) => {
  const queryParams = new URLSearchParams({
    district_id,
    qualification_id,
    cadre_id,
    designation_id,
  });
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Content-Type": "application/json",
      responseType: "blob",
    },
    // body: JSON.stringify({ requestData }),
  };
  fetch(`${BaseUrl}/exports/member-list?${queryParams}`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Memberlist Report.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      return blob;
    })
    .catch((e) => {
      console.log(e, ">>>");
    });
};
