import * as Yup from "yup";

export const AssetCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  document: Yup.boolean().required("Field is required")
});

export const AddAssetCategoryInitialValues = {
  name: "",
  document: false
};
