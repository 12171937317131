import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getLeaveList } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddLeave/AddLeaveMethods/AddLeaveModalMethods";
import { handleAdminStaffSearch } from "./LeaveMethods";
import AddLeave from "./AddLeave";

const LeaveIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    data: staff,
    error: staffError,
    isLoading: staffLoading,
    refetch: staffListRefetch,
  }: any = useQuery(
    [`staff${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getLeaveList(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(staff), [staff]);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) =>
                    handleAdminStaffSearch(e, setSearchQuery, navigate)
                  }
                />
                <span className="icon-container">
                  <i className="icon xl icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="primary"
                    className="sm"
                  >
                    <i className="icon icon-add-new xl"></i>Leave
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="content-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle="Leave Details"
            tableFields={[
              "Leave Type",
              "Start Date",
              "End Date",
              "Comment",
              "Status",
              "Action",
             
            ]}
            Arraykeys={[
              "leave_type",
              "start_date",
              "end_date",
              "comment",
              "status",
              
            ]}
            viewType="table"
            toURL={""}
            actions={["edit", "delete"]}
            isLoading={staffLoading}
            error={staffError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(selectedId, setModalEditData, setIsOpen, staff)
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, staffListRefetch)
            }
            paginationData={staff?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo)
            }
            from_leave={true}
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length !== 0 ? "Edit Leave" : "Add Leave"}
      >
        <AddLeave
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={staffListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default LeaveIndex;
