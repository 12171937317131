import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { AddMetaInitialValues } from "./FormikValidation";

type AddInitialValues = {
  meta_body: string;
  meta_title: string;
  status: any;
};

export const MetaSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .patch(`/seo/${id}`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Details Updated ",{toastId:"9090"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"9091"});
        }
      });
  }
  // Edit
  else {
    api
      .post("/seo", values, true)
      .then(async function ([success, response]: any) {
        toast.success("Meta Added",{toastId:"9092"});
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred",{toastId:"9093"});
        }
      });
  }
};
export const MetaSatusSubmitHandler = (
  id: any,
  status: any,
  refetch: Function,
  setIsOpen: Function
) => {


  api
    .patch(
      `/seo/publish/${id}`,
      {
        status:status ,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Meta Status Updated",{toastId:"9094"});
      refetch();
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
      } else {
        toast.error("Unexpected Error Occurred",{toastId:"9095"});
      }
    });
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      meta_body: item?.meta_body,
      meta_title: item?.meta_title,
      status: item?.status,
      type:item?.type,
      is_published:item?.is_published
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {

  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      meta_body: modalEditData?.[0]?.meta_body,
      meta_title: modalEditData?.[0]?.meta_title,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddMetaInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/seo/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted",{toastId:"9096"});
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred",{toastId:"9097"});
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {

  navigate(`/admin/staff/${pageNo}`);
};

export const handleAdminStaffSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
  if (e?.target?.value?.length > 2) {
      setSearchQuery(e?.target?.value)
      navigate(`/admin/meta/1`);

  }
  else {
      setSearchQuery('')
  }
}