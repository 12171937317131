import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RoleProvider = ({ Role, loginStatus }: any) => {
  const [roleUser, setRoleUser] = useState(true);
  const location = useLocation();
  // console.log(localStorage.getItem("ROLE"), "localStorage.getItem");
  useEffect(() => {
    if (localStorage.getItem("ROLE") === Role) {
      setRoleUser(true);
      
    } else {
      setRoleUser(false);
    }
  });

  return roleUser ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export default RoleProvider;
