import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Col } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDistrict,
  getMembercontributionList,
} from "../../../../../Api/AdminApis";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import {
  extractStaffData,
  handleDownloadClick,
  setCurrentPageStaff,
} from "./MemberMethods";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { generateBreadcrumb } from "../../../../../Components/BreadCrum/BreadCrum";
import { usePermission } from "../../../../../Components/Permissions/Admin/AdminPermission";

const MemberList = () => {
  const { currentPage = "1" } = useParams();
  const { checkPermission } = usePermission();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [value, setValue] = useState({
    district_id: "",
    block: "",
    cadre: "",
    designation: "",
  });
  const handleChange = (e: any, field_name: any) => {
    setValue({
      ...value,
      [field_name]: e,
    });
  };

  const {
    data: membercontribution,
    error: membercontributionError,
    isLoading: membercontributionLoading,
    refetch: membercontributionListRefetch,
  }: any = useQuery(
    [`Membercontribution${currentPage}`, currentPage, value?.district_id],
    () =>
      getMembercontributionList(
        currentPage,
        startDate.getFullYear().toString(),
        value?.district_id
      ),
    { keepPreviousData: true }
  );

  const { data: district }: any = useQuery([`district`], () => getDistrict(), {
    keepPreviousData: true,
  });

  const staffData = useMemo(
    () => extractStaffData(membercontribution, currentPage, 10),
    [membercontribution]
  );

  const handleClickDownload = () => {
    handleDownloadClick(startDate.getFullYear().toString(), value?.district_id);
  };

  let breadcrumbItems;
  const userRole = localStorage.getItem("ROLE");
  if (userRole === "admin") {
    breadcrumbItems = [
      { label: "Reports", link: "/admin/reports" },
      {
        label: "Members Contribution Reports",
        link: "/admin/membercontribution-report/1",
      },
    ];
  } else if (userRole === "member") {
    breadcrumbItems = [
      { label: "Reports", link: "/member/reports" },
      {
        label: "Members Contribution Reports",
        link: "/member/membercontribution-report/1",
      },
    ];
  }

  const breadcrumb = generateBreadcrumb(breadcrumbItems);

  return (
    <section>
      <div className="content-outer-wrap">
        <Container>
          <div className="breadcrumb-wrap">{breadcrumb}</div>
          <div className="search-bar-full-wrap">
              <div className="calendar-wrap">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  placeholderText="Year"
                />
              </div>

            <div className="select-wrap-spacing">
              <Select
                placeholder="Select District"
                options={district}
                value={
                  district?.filter(
                    (itm: any) => itm?.value === value?.district_id
                  ) || ""
                }
                onChange={(e: any) => handleChange(e?.value, "district_id")}
                name="district_id"
                isLoading={false}
                loadingMessage={() => "Fetching District"}
                noOptionsMessage={() => "District appears here"}
                isSearchable={true}
                isClearable
              />
            </div>

            <div className="right-side-wrap">
              {checkPermission("reports:write") ? (
                <div className="button-wrap">
                  <Button
                    color="primary"
                    className="sm"
                    onClick={handleClickDownload}
                  >
                    <i className="icon xl icon-download-white"></i>Download
                  </Button>
                </div>
              ) : null}

              <Button
                color="primary"
                className="sm"
                outline
                onClick={() => {
                  setValue({
                    district_id: "",
                    block: "",
                    cadre: "",
                    designation: "",
                  });
                  setStartDate(new Date());
                }}
              >
                Reset
              </Button>
            </div>
          </div>

          {/* cards section */}
          <div className="content-list-outer-wrap">
            <CardsWithTable
              data={staffData}
              tableTitle="Members Contribution Reports"
              tableFields={[
                "District",
                "Name",
                "PEN NO",
                "Transaction Date",
                "Membership Fee",
                "Other Amount Paid",
                "Total Contribution",
              ]}
              Arraykeys={[
                "district_name",
                "name",
                "pen_no",
                "Enrollment_Date",
                "Membership_Fee",
                "Other_amount_paid",
                "total_contributions",
              ]}
              viewType="table"
              toURL={""}
              actions={["", ""]}
              isLoading={membercontributionLoading}
              error={membercontributionError}
              onEditClick={(selectedId: any) => {}}
              Refetch={membercontributionListRefetch}
              onDeleteClick={(selectedId: string | number) => {}}
              onPaymentClick={(selectedId: string | number) => {}}
              paginationData={membercontribution?.data?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo)
              }
              from_report={true}
            />
          </div>
        </Container>
      </div>
    </section>
  );
};

export default MemberList;
