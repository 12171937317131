import { useState } from "react";
import { Container, InputGroup, Input, Button } from "reactstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { setCurrentPageTransferReq, transferGetOffice } from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import { getAdminTransferData, handleAdminTransferSearch } from "./TransferRequestMethods";
import { deleteClickHandler, onEditClickHandler } from "../../MemberModule/TransferRequest/AddTransferRequest/AddTransferRequestMethods/AddTransferRequestModalMethods";
import { usePermission } from "../../../../Components/Permissions/Admin/AdminPermission";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddTransferRequest from "../../MemberModule/TransferRequest/AddTransferRequest";

const AdminTransferRequest = () => {
    const { currentPage = "1" } = useParams();
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false); // Add doctor modal
    const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
    const [searchQuery, setSearchQuery] = useState<string>("");
    const { checkPermission } = usePermission();

    const {
        data: adminTransferData,
        isLoading: adminTransferDataIsLoading,
        error: adminTransferDataError,
        refetch: adminTransferDataRefetch
    }: any = useQuery(
        [`adminTransferData`, currentPage, searchQuery],
        () => getAdminTransferData(currentPage, searchQuery),
        { keepPreviousData: true }
    );
 
    // const {
    //     data: office,
    // }: any = useQuery([`office`], () => getOfficeFullList(), {
    //     keepPreviousData: true,
    // });

    return (
        <section>
            <div className="content-outer-wrap">
                <Container>
                    <div className="search-bar-full-wrap">
                        <div className="search-bar">
                            <InputGroup>
                                <Input
                                    placeholder="Search here..."
                                    onChange={(e) => handleAdminTransferSearch(e, setSearchQuery, navigate)}
                                />
                                <span className="icon-container">
                                    <i className="icon xl icon-search"></i>
                                </span>
                            </InputGroup>
                        </div>
                        <div className="right-side-wrap">
                            <div className="filter-wrap">
                                {/* <div className="button-wrap">
                                    { checkPermission("transfer:write")?
                                    <Button
                                        onClick={() => {
                                            setModalEditData([]);
                                            setIsOpen(true);
                                        }}
                                        color="primary"
                                        className="sm"
                                    >
                                        <i className="icon icon-add-new xl"></i>Transfer Request
                                    </Button>: null}
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* cards section */}
                    <div className="content-list-outer-wrap">
                        <CardsWithTable
                            data={adminTransferData?.filteredData}
                            tableTitle="Office Change Request"
                            tableFields={[
                                "User Name",
                                "To Office",
                                "Block",
                                "District",
                                "GOV Order",
                                "GOV Order Date",
                                "Rejected Reason"
                            ]}
                            Arraykeys={[
                                "user_name",
                                "to_office",
                                "block",
                                "district",
                                "Go_order",
                                "Go_order_date",
                                "reason",
                            ]}
                            viewType="table"
                            toURL={""}
                            // temporarily removed "edit" 
                            // actions={["delete"]}
                            actions={[
                                checkPermission("transfer:delete")
                                    ? "delete"
                                    : "",
                            ]}
                            isLoading={adminTransferDataIsLoading}
                            error={adminTransferDataError}
                            onEditClick={(selectedId: any) =>
                                onEditClickHandler(
                                    selectedId,
                                    setModalEditData,
                                    setIsOpen,
                                    adminTransferData?.data
                                )
                            }
                            onDeleteClick={(selectedId: string | number) =>
                                deleteClickHandler(selectedId, adminTransferDataRefetch)
                            }
                            paginationData={adminTransferData?.data?.meta}
                            setCurrentPage={(pageNo: string) =>
                                (pageNo: string) => setCurrentPageTransferReq(navigate, pageNo)
                            }
                            Refetch={adminTransferDataRefetch}
                            from_transfer_req
                        />
                    </div>
                </Container>
            </div>

            <ModalContainer
                isOpen={isOpen}
                setIsopen={setIsOpen}
                title={modalEditData?.length !== 0 ? "Edit Transfer request" : "Add Transfer request"}
            >
                <AddTransferRequest
                    setIsOpen={setIsOpen}
                    modalEditData={modalEditData}
                    refetch={adminTransferDataRefetch}
                // office={office}
                />
            </ModalContainer>
        </section>
    );
};

export default AdminTransferRequest;
