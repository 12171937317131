
// export const handleAdminStaffSearch = (e: any, setSearchQuery: Function, navigate: Function) => {
//     if (e?.target?.value?.length > 2) {
//         setSearchQuery(e?.target?.value)
//         navigate(`/admin/leave-management/1`);
//     }
//     else {
//         setSearchQuery('')
//     }
// }

export const handleAdminStaffSearch = (
    e: any,
    setSearchQuery: Function,
    navigate: Function
  ) => {
    const userRole = localStorage.getItem("ROLE");
    if (userRole === "admin") {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/admin/leave-management/1`);
      } else {
        setSearchQuery("");
      }
    } else {
      if (e?.target?.value?.length > 2) {
        setSearchQuery(e?.target?.value);
        navigate(`/member/leave-management/1`);
      } else {
        setSearchQuery("");
      }
    }
}