import * as Yup from "yup";

export const TestimonialValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Name is required")
    .max(100, "The name must not exceed 100 characters."),

  image: Yup.array()
    .of(Yup.string())
    .min(1, "Atleast 1 image is required")
    .max(10, "Only 10 image is allowed")
    .required("File is required"),
});

export const AddTestimonialInitialValues = {
  title: "",
  image: [],
};
