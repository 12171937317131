import { Col, Row, Table } from "reactstrap";
import { MyProfileProps } from "../../../../../Type";
import { modalHandler } from "../MemberProfileMethods";
import {
  DateFormatter,
  DateFormatterYearOfPass,
  MembershipStatus,
  convertISODateToDate,
} from "../../../../../Utils/helpers/helpers";
import CustomSpinner from "../../../../../Components/CustomElements/Spinner/SpinnerCustom";
import EducationIndex from "./Education";

const MyProfile = ({
  memberProfileData,
  setModalEditData,
  setIsOpen,
  filteredEduList,
  memberProfileError,
  memberProfileLoading,
  memberProfileDataRefetch,
}: MyProfileProps) => {
  const filteredData = memberProfileData?.educationQualification?.filter(
    (item: any) => item.degree_name === "OTHER"
  );

  return (
    <div className="content-list-outer-wrap">
      {/* <Container> */}
      <Row className="my-4 justify-content-center">
        {memberProfileError && (
          <div className="text-center">
            <p>Error Loading Content</p>
          </div>
        )}
        {memberProfileLoading && (
          <div className="text-center">
            <CustomSpinner />
          </div>
        )}
        {memberProfileData ? (
          <Col md={8}>
            <div className="table-outer-wrap member-profile-table-wrap">
              <div className="table-head-outer">
                <Col md={12}>
                  <div className="profile-head-wrap">
                    <div className="profile-head-inner">
                      <div>
                        {/* <img src="/images/user.png" alt="user" /> */}
                        <img
                          src={
                            memberProfileData?.profile_image_url
                              ? memberProfileData?.profile_image_url
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                          }
                          alt="user"
                        />
                        <p>
                          {memberProfileData?.user_first_name +
                            " " +
                            memberProfileData?.user_last_name}
                        </p>
                      </div>
                    </div>
                    <div className="edit-wrap">
                      <i
                        className="icon xl icon-edit"
                        onClick={() =>
                          modalHandler(setModalEditData, setIsOpen)
                        }
                      ></i>
                    </div>
                  </div>
                </Col>
              </div>

              <div className="table-outer">
                <Table>
                  <tbody>
                    {memberProfileData?.member?.membership_no && (
                      <tr>
                        <td>Membership id</td>
                        <td>{memberProfileData?.member?.membership_no}</td>
                      </tr>
                    )}
                    {memberProfileData?.member_pen_no && (
                      <tr>
                        <td>PEN No</td>
                        <td>{memberProfileData?.member_pen_no}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Membership Type</td>
                      <td>
                        {MembershipStatus(
                          memberProfileData?.member_membership_type
                        )}
                      </td>
                    </tr>
                    {memberProfileData?.user_email && (
                      <tr>
                        <td>Email</td>
                        <td>{memberProfileData?.user_email}</td>
                      </tr>
                    )}
                    {memberProfileData?.member_phone_no && (
                      <tr>
                        <td>Contact Number</td>
                        <td>{memberProfileData?.member_phone_no}</td>
                      </tr>
                    )}
                    {memberProfileData?.member_whatsapp_no && (
                      <tr>
                        <td>Whatsapp Number</td>
                        <td>{memberProfileData?.member_whatsapp_no}</td>
                      </tr>
                    )}
                    {memberProfileData?.district_name && (
                      <tr>
                        <td>District</td>
                        <td>{memberProfileData?.district_name}</td>
                      </tr>
                    )}
                    {memberProfileData?.designation_name && (
                      <tr>
                        <td>Designation</td>
                        <td>{memberProfileData?.designation_name}</td>
                      </tr>
                    )}

                    {memberProfileData?.member_date_of_birth && (
                      <tr>
                        <td>Date Of Birth</td>
                        <td>
                          {convertISODateToDate(
                            memberProfileData?.member_date_of_birth
                          )}
                        </td>
                      </tr>
                    )}
                    {memberProfileData?.member_date_of_retirement && (
                      <tr>
                        <td>Date of retirement</td>
                        <td>
                          {convertISODateToDate(
                            memberProfileData?.member_date_of_retirement
                          )}
                        </td>
                      </tr>
                    )}
                    {memberProfileData?.member_instagram_profile && (
                      <tr>
                        <td>Instagram</td>
                        <td>{memberProfileData?.member_instagram_profile}</td>
                      </tr>
                    )}
                    {memberProfileData?.member_linkedin_profile && (
                      <tr>
                        <td>LinkedIn</td>
                        <td>{memberProfileData?.member_linkedin_profile}</td>
                      </tr>
                    )}
                    {memberProfileData?.member_twitter_profile && (
                      <tr>
                        <td>Twitter</td>
                        <td>{memberProfileData?.member_twitter_profile}</td>
                      </tr>
                    )}
                    {memberProfileData?.member_about && (
                      <tr>
                        <td>About me</td>
                        <td>{memberProfileData?.member_about}</td>
                      </tr>
                    )}

                    {filteredEduList?.BACHELORSDEGREE && (
                      <tr>
                        <td colSpan={2}>Bachelor Degree</td>
                      </tr>
                    )}
                    {filteredEduList?.BACHELORSDEGREE?.educat_cat_name && (
                      <tr>
                        <td>Bachelor Degree Course</td>
                        <td>
                          {filteredEduList?.BACHELORSDEGREE?.educat_cat_name}
                        </td>
                      </tr>
                    )}
                    {filteredEduList?.BACHELORSDEGREE?.other_category_value ==
                      0 && (
                      <tr>
                        <td>Bachelor Degree Course</td>
                        <td>
                          {
                            filteredEduList?.BACHELORSDEGREE
                              ?.other_category_value
                          }
                        </td>
                      </tr>
                    )}
                    {filteredEduList?.BACHELORSDEGREE?.year_of_pass && (
                      <tr>
                        <td>Year Of Pass</td>
                        <td>
                          {
                              filteredEduList?.BACHELORSDEGREE?.year_of_pass
                            
                          }
                        </td>
                      </tr>
                    )}

                    {filteredEduList?.POSTGRADUATION && (
                      <tr>
                        <td colSpan={2}>Post Graduation Degree</td>
                      </tr>
                    )}
                    {filteredEduList?.POSTGRADUATION?.educat_cat_name && (
                      <tr>
                        <td>Post Graduation Degree Course</td>
                        <td>
                          {filteredEduList?.POSTGRADUATION?.educat_cat_name}
                        </td>
                      </tr>
                    )}
                    {filteredEduList?.POSTGRADUATION?.other_category_value ==
                      0 && (
                      <tr>
                        <td>Post Graduation Degree Course</td>
                        <td>
                          {
                            filteredEduList?.POSTGRADUATION
                              ?.other_category_value
                          }
                        </td>
                      </tr>
                    )}
                    {filteredEduList?.POSTGRADUATION?.year_of_pass && (
                      <tr>
                        <td>Year Of Pass</td>
                        <td>
                          {
                              filteredEduList?.POSTGRADUATION?.year_of_pass
                          
                          }
                        </td>
                      </tr>
                    )}

                    {filteredEduList?.DOCTORALDEGREE && (
                      <tr>
                        <td colSpan={2}>Doctoral Degree</td>
                      </tr>
                    )}
                    {filteredEduList?.DOCTORALDEGREE?.educat_cat_name && (
                      <tr>
                        <td>Doctoral Degree Course</td>
                        <td>
                          {filteredEduList?.DOCTORALDEGREE?.educat_cat_name}
                        </td>
                      </tr>
                    )}
                    {filteredEduList?.DOCTORALDEGREE?.other_category_value ==
                      0 && (
                      <tr>
                        <td>Doctoral Degree Course</td>
                        <td>
                          {
                            filteredEduList?.DOCTORALDEGREE
                              ?.other_category_value
                          }
                        </td>
                      </tr>
                    )}
                    {filteredEduList?.DOCTORALDEGREE?.year_of_pass && (
                      <tr>
                        <td>Year Of Pass</td>
                        <td>
                          {
                              filteredEduList?.DOCTORALDEGREE?.year_of_pass
                          
                          }
                        </td>
                      </tr>
                    )}
                    {filteredData?.length !== 0 && (
                      <>
                        <th colSpan={12}>Other Degree</th>
                        <tr>
                          <th>Other Degree Course</th>
                          <th>Other Degree Year Of Pass</th>
                          <th>Details of other qualification</th>
                        </tr>
                        {filteredData?.map((item: any) => {
                          return (
                            <>
                              <tr>
                                <td>{item?.educat_cat_name}</td>
                                <td>
                                  {item?.year_of_pass}
                                </td>
                                <td>{item?.other_category_value}</td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        ) : null}

        {/* <Col md={6}>
          <EducationIndex
            setIsOpen={setIsOpen}
            modalEditData={memberProfileData}
            refetch={memberProfileDataRefetch}
          />
        </Col> */}
      </Row>
      {/* </Container> */}
    </div>
  );
};

export default MyProfile;
