export const handleAdminStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function
) => {
  if (e?.target?.value?.length > 2) {
    setSearchQuery(e?.target?.value);
    navigate(`/admin/designation-management/1/1`);
  } else {
    setSearchQuery("");
  }
};
export const handleAdminSecondTabStaffSearch = (
  e: any,
  setSearchQuery: Function,
  navigate: Function,
  path?: any
) => {
  if (e?.target?.value?.length > 2) {
    setSearchQuery(e?.target?.value);
    navigate(path?path:`/admin/designation-management/1/2`);
  } else {
    setSearchQuery("");
  }
};
