import * as Yup from "yup";

export const OtherValidationSchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  start_date: Yup.string().required("Field is required"),
  end_date: Yup.string().required("Field is required"),
  amount: Yup.string().required("Field is required"),
  district_id: Yup.string().required("Field is required"),
});

export const AddOtherInitialValues = {
  title: "",
  start_date: "",
  amount: "",
  end_date: "",
  district_id: "",
};
